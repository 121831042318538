import { Reference, gql, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Card, Cell, ConfirmModal, FormModal, HasProductRole, InventoryRoles, Link, ModalLauncher, NotFound, OptionBar, OptionItem, Products, ProgressBar, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, View, useAuthState } from "@barscience/global-components";
import { useNavigate, useParams } from "react-router-dom";

/* Get Prep List Query */
const GET_PREP_LIST_DETAILS = gql`
query getPrepListDetails($id: ID!) {
  prepList(id: $id) {
    id
    name
    date
    items {
      recipe {
        id
        name
        recipeCategory {
          id
        }
      }
      casesToMake
      status
    }
  }
}
`;

type GetPrepListDetailsResponse = {
  prepList: PrepList | null;
}

type PrepList = {
  id: string;
  name: string;
  date: string;
  items: PrepListItem[];
}

type PrepListItem = {
  recipe: {
    id: string;
    name: string;
    recipeCategory: {
      id: string;
    };
  };
  casesToMake: number;
  status: string;
}

/* Update Item Status Mutation */
const UPDATE_RECIPE_STATUS = gql`
mutation setPrepListItemStatus($prepListId: ID!, $recipeId: ID!, $status: PrepListItemStatus!) {
  setPrepListItemStatus(prepListId: $prepListId, recipeId: $recipeId, status: $status) {
    recipe {
      id
    }
    status
  }
}
`;

type UpdateRecipeStatusResponse = {
  setPrepListItemStatus: {
    recipe: {
      id: string;
    };
    status: string;
  } | null;
}

/* Edit Cases To Make Mutation */
const EDIT_CASES_TO_MAKE = gql`
mutation setPrepListItemCasesToMake($prepListId: ID!, $recipeId: ID!, $casesToMake: Float!) {
  setPrepListItemCasesToMake(prepListId: $prepListId, recipeId: $recipeId, casesToMake: $casesToMake) {
    recipe {
      id
      name
    }
    casesToMake
  }
}
`;

type EditCasesToMakeResponse = {
  setPrepListItemCasesToMake: {
    recipe: {
      id: string;
      name: string;
    };
    casesToMake: number;
  };
}

type EditCasesToMakeInput = {
  recipeId: string;
  casesToMake: string;
}

/* Delete Prep List Mutation */
const DELETE_PREP_LIST = gql`
mutation deletePrepList($id: ID!) {
  deletePrepList(id: $id) {
    id
  }
}
`;

type DeletePrepListMutation = {
  deletePrepList: {
    id: string;
  } | null;
}

export default function PrepListDetails() {
  const { state } = useAuthState();
  const navigate = useNavigate();
  const { prepListId } = useParams();
  const { data: prepListData, loading: prepListIsLoading, error: prepListError } = useQuery<GetPrepListDetailsResponse>(GET_PREP_LIST_DETAILS, {
    variables: {
      id: prepListId,
    },
    fetchPolicy: 'network-only',
  });
  const [updateRecipeStatus] = useMutation<UpdateRecipeStatusResponse>(UPDATE_RECIPE_STATUS, {
    update(cache, _, { variables }) {
      if (!prepListData || !prepListData.prepList || !variables) {
        return;
      }

      cache.modify({
        id: cache.identify(prepListData.prepList),
        fields: {
          items(existingItemRefs = [], { readField }) {
            return existingItemRefs.map((itemRef: Reference) => {
              const recipe: Reference | undefined = readField('recipe', itemRef);

              if (readField('id', recipe) === variables.recipeId) {

                return {
                  ...itemRef,
                  status: variables.status,
                };
              }

              return itemRef;
            });
          },
        },
      });
    },
    optimisticResponse(variables) {
      return {
        setPrepListItemStatus: {
          recipe: {
            id: variables.recipeId,
          },
          status: variables.status,
        },
      };
    },
    refetchQueries: [GET_PREP_LIST_DETAILS],
  });
  const [setCasesToMake] = useMutation<EditCasesToMakeResponse>(EDIT_CASES_TO_MAKE, {
    update(cache, _, { variables }) {
      if (!prepListData || !prepListData.prepList || !variables) {
        return;
      }

      cache.modify({
        id: cache.identify(prepListData.prepList),
        fields: {
          items(existingItemRefs = [], { readField }) {
            if (variables.casesToMake === 0) {
              return existingItemRefs.filter((itemRef: Reference) => {
                const recipe: Reference | undefined = readField('recipe', itemRef);

                if (readField('id', recipe) === variables.recipeId) {
                  return false;
                }

                return true;
              });
            }

            return existingItemRefs.map((itemRef: Reference) => {
              const recipe: Reference | undefined = readField('recipe', itemRef);

              if (readField('id', recipe) === variables.recipeId) {
                return {
                  ...itemRef,
                  casesToMake: variables.casesToMake,
                };
              }

              return itemRef;
            });
          },
        },
      });
    },
  });
  const [deletePrepList] = useMutation<DeletePrepListMutation>(DELETE_PREP_LIST);

  if (prepListIsLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (!prepListId || prepListError?.graphQLErrors[0].extensions.status === 404) {
    return (
      <StandardGrid>
        <NotFound />
      </StandardGrid>
    );
  }

  const handleStatusChange = (recipeId: string, status: string) => {
    updateRecipeStatus({
      variables: {
        prepListId: prepListId,
        recipeId: recipeId,
        status: status,
      },
    });
  }

  const handleEditCasesToMake = async (values: EditCasesToMakeInput) => {
    await setCasesToMake({
      variables: {
        prepListId: prepListId,
        recipeId: values.recipeId,
        casesToMake: parseFloat(values.casesToMake),
      },
    });
  }

  const handleRemoveRecipe = async (recipeId: string) => {
    await setCasesToMake({
      variables: {
        prepListId: prepListId,
        recipeId: recipeId,
        casesToMake: 0,
      },
    });
  }

  const validateCasesToMake = (_: string, value: string) => {
    if (Number.isNaN(value)) {
      return 'Enter a number';
    }

    const num = parseFloat(value);
    if (num < 0) {
      return 'Must be at least 0';
    }

    return null;
  }

  const getCompletedItemCount = () => {
    let count = 0;

    prepListData?.prepList?.items.forEach((recipe) => {
      if (recipe.status === 'DONE') {
        count++;
      }
    });

    return count;
  }

  const handleDeletePrepList = async () => {
    const { errors } = await deletePrepList({
      variables: {
        id: prepListId,
      },
    });

    if (!errors) {
      navigate('/prep-lists');
    }
  }

  const editCasesToMakeModal = (
    <FormModal<EditCasesToMakeInput> title='Edit Item' onSubmit={handleEditCasesToMake} initialValues={{ recipeId: '', casesToMake: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Cases To Make' name='casesToMake' type='number' inputMode='decimal' style={{ width: '50%' }} required validate={validateCasesToMake} />
      </View>
    </FormModal>
  );

  const removeRecipeModal = (
    <ConfirmModal title='Remove Item?' onConfirm={handleRemoveRecipe} confirmLabel='Remove' destructive>
      <StyledParagraph>The recipe will be removed from this prep list.</StyledParagraph>
    </ConfirmModal>
  );

  const confirmDeleteModal = (
    <ConfirmModal title='Delete Prep List?' onConfirm={handleDeletePrepList} confirmLabel='Delete' destructive>
      <StyledParagraph>This prep list will be permanently deleted.</StyledParagraph>
    </ConfirmModal>
  );

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Prep Lists' to='/prep-lists' />
          <Breadcrumb label={prepListData?.prepList?.name || ''} />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', '@media (max-width: 1151px)': { alignItems: 'flex-start' } }}>
          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '32px', '@media (max-width: 1151px)': { alignItems: 'flex-start', flexDirection: 'column', gap: '12px' } }}>
            <StyledHeading tag='h3'>{prepListData?.prepList?.name}</StyledHeading>
            <View style={{ minWidth: '400px' }}>
              <StyledParagraph>Completion Progress</StyledParagraph>
              <ProgressBar progress={getCompletedItemCount()} total={prepListData?.prepList?.items.length || 0} showAsPercent />
            </View>
          </View>
          <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Employee, InventoryRoles.Manager, InventoryRoles.Admin]}>
            <ModalLauncher modal={confirmDeleteModal}>
              {({ openModal: openDeleteModal }) => (
                <ActionMenu alignment='right'>
                  <ActionItem label='Edit' onClick={() => { navigate(`/prep-lists/${prepListId}/edit`); }} />
                  <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Admin]}>
                    <ActionItem label='Delete' onClick={openDeleteModal} />
                  </HasProductRole>
                </ActionMenu>
              )}
            </ModalLauncher>
          </HasProductRole>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <Card size='medium'>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell style={{ minWidth: '100px' }}>Recipe</TableHeaderCell>
                <TableHeaderCell>Batches To Make</TableHeaderCell>
                <TableHeaderCell style={{ minWidth: '300px', width: '300px' }}>Status</TableHeaderCell>
                <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Employee, InventoryRoles.Manager, InventoryRoles.Admin]}>
                  <TableHeaderCell style={{ margin: '0', padding: '0', width: '16px' }}></TableHeaderCell>
                </HasProductRole>
              </TableRow>
            </TableHeader>
            <TableBody>
              {prepListData?.prepList?.items.map((recipe, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell><Link href={`/recipes/${recipe.recipe.recipeCategory.id}/${recipe.recipe.id}`}>{recipe.recipe.name}</Link></TableCell>
                    <TableCell>{recipe.casesToMake}</TableCell>
                    <TableCell style={{ padding: '8px' }}>
                      {(state.user?.roles.Inventory === InventoryRoles.Employee || state.user?.roles.Inventory === InventoryRoles.Manager || state.user?.roles.Inventory === InventoryRoles.Admin) ?
                        <OptionBar selectedValue={recipe.status} onChange={(value) => { handleStatusChange(recipe.recipe.id, value); }}>
                          <OptionItem label='Done' value='DONE' />
                          <OptionItem label='In Progress' value='IN_PROGRESS' />
                          <OptionItem label='Not Started' value='NOT_STARTED' />
                        </OptionBar>
                        :
                        recipe.status
                      }
                    </TableCell>
                    <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Employee, InventoryRoles.Manager, InventoryRoles.Admin]}>
                      <TableCell style={{ margin: '0', padding: '0', width: '16px' }}>
                        <ModalLauncher modal={editCasesToMakeModal}>
                          {({ openModal: openEditModal }) => (
                            <ModalLauncher modal={removeRecipeModal}>
                              {({ openModal: openRemoveModal }) => (
                                <ActionMenu alignment='right'>
                                  <ActionItem label='Edit' onClick={() => { openEditModal({ recipeId: recipe.recipe.id, casesToMake: recipe.casesToMake }); }} />
                                  <ActionItem label='Remove' onClick={() => { openRemoveModal(recipe.recipe.id); }} />
                                </ActionMenu>
                              )}
                            </ModalLauncher>
                          )}
                        </ModalLauncher>
                      </TableCell>
                    </HasProductRole>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Card>
      </Cell>
    </StandardGrid>
  );
}