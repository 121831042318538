import { gql, useLazyQuery } from "@apollo/client";
import { AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, CircularSpinner, Colors, DatePicker, ErrorPage, Link, PageButtons, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, View, useForm } from "@barscience/global-components";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

/* Get Waste Report Query */
const GET_WASTE_REPORT = gql`
query getWasteReport($startDate: Date!, $endDate: Date!) {
  wasteReport(startDate: $startDate, endDate: $endDate) {
    total
    byCategory {
      category {
        id
        name
      }
      total
    }
  }
}
`;

type GetWasteReportResponse = {
  wasteReport: {
    total: string;
    byCategory: {
      category: {
        id: string;
        name: string;
      };
      total: string;
    }[];
  } | null;
}

/* Get Waste Report Items */
const GET_WASTE_REPORT_ITEMS = gql`
query getWasteReportItems($startDate: Date!, $endDate: Date!, $page: Int!) {
  wasteReport(startDate: $startDate, endDate: $endDate) {
    byItem(page: $page) {
      item {
        ... on InventoryItem {
          id
          name
          category {
            id
            name
          }
        }
        ... on Recipe {
          id
          name
          itemCategory {
            id
            name
          }
          recipeCategory {
            id
          }
        }
      }
      units
      total
    }
  }
}
`;

type GetWasteReportItemsResponse = {
  wasteReport: {
    byItem: {
      item: Recipe | Item;
      units: number;
      total: string;
    }[];
  } | null;
}

type Recipe = {
  id: string;
  name: string;
  itemCategory: {
    id: string;
    name: string;
  };
  recipeCategory: {
    id: string;
  };
  __typename: 'Recipe'
}

type Item = {
  id: string;
  name: string;
  category: {
    id: string;
    name: string;
  };
  __typename: 'InventoryItem'
}

type DateFormInput = {
  startDate: string;
  endDate: string;
}

const ITEMS_PER_PAGE = 50;

export default function Waste() {
  const [queryParams, setQueryParams] = useSearchParams();
  const startDate = queryParams.get('startDate');
  const endDate = queryParams.get('endDate');
  const itemsPage = queryParams.get('page') || 0;
  const [generateReport, { data: reportData, loading: reportIsLoading, error: reportError }] = useLazyQuery<GetWasteReportResponse>(GET_WASTE_REPORT);
  const [generateReportItems, { data: reportItemsData, loading: reportItemsAreLoading, error: itemsError }] = useLazyQuery<GetWasteReportItemsResponse>(GET_WASTE_REPORT_ITEMS);

  useEffect(() => {
    if (startDate && endDate) {
      generateReport({
        variables: {
          startDate: startDate,
          endDate: endDate,
        },
      });
    }
  }, [startDate, endDate, generateReport]);

  useEffect(() => {
    if (startDate && endDate) {
      generateReportItems({
        variables: {
          startDate: startDate,
          endDate: endDate,
          page: parseInt(itemsPage || '0'),
        },
      });
    }
  }, [startDate, endDate, itemsPage, generateReportItems]);

  const handleGenerateReport = (values: DateFormInput) => {
    setQueryParams({ startDate: values.startDate, endDate: values.endDate, page: "0" });
  }

  const handlePageChange = (page: number) => {
    setQueryParams({ ...queryParams, page: page.toString() });
  }

  const dateValidator = () => {
    if (dateForm.values.startDate !== '' && dateForm.values.endDate !== '') {
      if (new Date(dateForm.values.startDate) > new Date(dateForm.values.endDate)) {
        return 'Start date must be before end date';
      } else if (new Date(dateForm.values.endDate).getTime() - new Date(dateForm.values.startDate).getTime() > (1000 * 60 * 60 * 24 * 366)) {
        return 'Date range must be less than 1 year';
      }
    }

    return null;
  }

  const getNumPages = (): number => {
    if ((reportItemsData?.wasteReport?.byItem.length || 0) === ITEMS_PER_PAGE) {
      return parseInt(itemsPage || '0') + 2;
    }

    return 0;
  }

  const dateForm = useForm<DateFormInput>({
    initialValues: {
      startDate: '',
      endDate: '',
    },
    onSubmit: handleGenerateReport,
  });

  if (reportError || itemsError) {
    return <ErrorPage />;
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Reports' to='/reports' />
          <Breadcrumb label='Waste Report' />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', justifyContent: 'space-between', '@media (max-width: 1151px)': { alignItems: 'flex-start', flexDirection: 'column' } }}>
          <StyledHeading tag='h3'>Waste Report</StyledHeading>

          {((startDate && endDate) && !reportIsLoading) &&
            <View>
              <StyledParagraph bold> Report For:</StyledParagraph>
              <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                <StyledParagraph>{`${startDate} - ${endDate}`}</StyledParagraph>
                <Button label='Change' variant='tertiary' role='button' action={() => { setQueryParams({}); }} />
              </View>
            </View>
          }
        </View>
      </Cell>
      {(startDate && endDate) ?
        (reportIsLoading ?
          <Cell lg={12} md={8} sm={4}>
            <View style={{ alignItems: 'center', marginTop: '64px' }}>
              <AtomSpinner size='medium' />
              <StyledHeading tag='h6' style={{ textAlign: 'center' }}>Hang tight, we're working on this report for you...</StyledHeading>
            </View>
          </Cell>
          :
          <>
            <Cell lg={6} md={6} sm={4}>
              <Card size='medium'>
                <View style={{ gap: '16px' }}>
                  <StyledHeading tag='h6'>Total Waste Cost</StyledHeading>
                  <StyledParagraph style={{ fontSize: '32px', fontWeight: 600 }}>{reportData?.wasteReport?.total}</StyledParagraph>
                </View>
              </Card>
            </Cell>
            <Cell lg={6} md={6} sm={4}>
              <Card size='medium'>
                <View style={{ gap: '16px' }}>
                  <StyledHeading tag='h6'>Cost By Category</StyledHeading>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHeaderCell>Category</TableHeaderCell>
                        <TableHeaderCell>Total Cost</TableHeaderCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {reportData?.wasteReport?.byCategory.map((c, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{c.category.name}</TableCell>
                            <TableCell>{c.total}</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </View>
              </Card>
            </Cell>
            <Cell lg={12} md={8} sm={4}>
              <Card size='medium'>
                <View style={{ gap: '16px' }}>
                  <View style={{ flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
                    <StyledHeading tag='h6'>Cost By Item</StyledHeading>
                    <PageButtons currentPage={parseInt(itemsPage || '0')} numPages={getNumPages()} onPageChange={handlePageChange} />
                  </View>
                  {reportItemsAreLoading ?
                    <View style={{ alignItems: 'center', width: '100%' }}>
                      <CircularSpinner size='medium' />
                    </View>
                    :
                    <View style={{ maxWidth: '100%', overflowX: 'auto' }}>
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHeaderCell style={{ minWidth: '150px' }}>Item</TableHeaderCell>
                            <TableHeaderCell>Category</TableHeaderCell>
                            <TableHeaderCell>Units Wasted</TableHeaderCell>
                            <TableHeaderCell>Total Cost</TableHeaderCell>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {reportItemsData?.wasteReport?.byItem.map((i, index) => {
                            if (i.item.__typename === 'InventoryItem') {
                              return (
                                <TableRow key={index}>
                                  <TableCell><Link href={`/items/${i.item.id}`}>{i.item.name}</Link></TableCell>
                                  <TableCell>{i.item.category.name}</TableCell>
                                  <TableCell>{i.units}</TableCell>
                                  <TableCell>{i.total}</TableCell>
                                </TableRow>
                              )
                            } else if (i.item.__typename === 'Recipe') {
                              return (
                                <TableRow key={index}>
                                  <TableCell><Link href={`/recipes/${i.item.recipeCategory.id}/${i.item.id}`}>{i.item.name}</Link></TableCell>
                                  <TableCell>{i.item.itemCategory.name}</TableCell>
                                  <TableCell>{i.units}</TableCell>
                                  <TableCell>{i.total}</TableCell>
                                </TableRow>
                              )
                            } else {
                              return <></>
                            }
                          })}
                        </TableBody>
                      </Table>
                    </View>
                  }
                </View>
              </Card>
            </Cell>
          </>
        )
        :
        <Cell lg={12} md={8} sm={4}>
          <View style={{ alignItems: 'center', marginTop: '64px' }}>
            <Card size='medium' style={{ boxSizing: 'border-box', maxWidth: '400px', minWidth: '400px', width: '400px', '@media (max-width: 767px)': { maxWidth: '100%', minWidth: '100%', width: '100%' } }}>
              <View style={{ gap: '32px' }}>
                <StyledHeading tag='h6'>Select a date range</StyledHeading>
                <DatePicker label='Start Date' name='startDate' value={dateForm.values.startDate} error={dateForm.errors.startDate} onChange={dateForm.handleChange} onValidate={dateForm.handleValidate} required />
                <DatePicker label='End Date' name='endDate' value={dateForm.values.endDate} error={dateForm.errors.endDate} onChange={dateForm.handleChange} onValidate={dateForm.handleValidate} required />

                {dateValidator() && <StyledParagraph style={{ color: Colors.error500 }}>{dateValidator()}</StyledParagraph>}

                <Button label='Generate Report' variant='primary' role='button' action={dateForm.handleSubmit} disabled={dateForm.hasError || dateValidator() !== null} />
              </View>
            </Card>
          </View>
        </Cell>
      }
    </StandardGrid>
  );
}