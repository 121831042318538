import { ExternalRedirect, HasProductRole, Header, Icons, InventoryRoles, Products, Sidebar, SidebarItem, SidebarSection, useAlertState, useAuthState, View } from '@barscience/global-components';
import React, { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import HeaderNewActions from './components/HeaderNewActions';
import AllItemCategories from './pages/categories/AllItemCategories';
import AllItems from './pages/items/AllItems';
import ItemDetails from './pages/items/ItemDetails';
import AllPeriods from './pages/periods/AllPeriods';
import PeriodDetail from './pages/periods/PeriodDetail';
import AllVendors from './pages/vendors/AllVendors';
import VendorDetail from './pages/vendors/VendorDetail';
import ItemLocations from './pages/itemLocations/ItemLocations';
import AllPars from './pages/itemPars/AllPars';
import ParDetail from './pages/itemPars/ParDetail';
import AllOrders from './pages/orders/AllOrders';
import OrderDetail from './pages/orders/OrderDetail';
import EditOrder from './pages/orders/EditOrder';
import AllInvoices from './pages/invoices/AllInvoices';
import InvoiceDetail from './pages/invoices/InvoiceDetail';
import AllRecipeCategories from './pages/recipes/AllRecipeCategories';
import RecipeCategoryDetail from './pages/recipes/RecipeCategoryDetail';
import RecipeDetail from './pages/recipes/RecipeDetail';
import AllPrepLists from './pages/prepLists/AllPrepLists';
import EditPrepList from './pages/prepLists/EditPrepList';
import PrepListDetails from './pages/prepLists/PrepListDetails';
import AllItemCounts from './pages/itemCounts/AllItemCounts';
import ItemCountDetails from './pages/itemCounts/ItemCountDetails';
import EditItemCount from './pages/itemCounts/EditItemCount';
import AllSalesPeriods from './pages/sales/AllSalesPeriods';
import SalesPeriodDetails from './pages/sales/SalesPeriodDetails';
import EditCategorySales from './pages/sales/EditCategorySales';
import EditItemSales from './pages/sales/EditItemSales';
import WasteLogs from './pages/waste/WasteLogs';
import Reports from './pages/reports/Reports';
import ItemCountValuation from './pages/reports/ItemCountValuation';
import PeriodPC from './pages/reports/PeriodPC';
import Shrinkage from './pages/reports/Shrinkage';
import Waste from './pages/reports/Waste';

function App() {
  const { state } = useAuthState();
  const { alerts } = useAlertState();
  const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(false);

  const toggleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  }

  if (!state.user) {
    let authUrl = 'https://auth.barscience.us/login';
    if (window.location.href.includes('-dev')) {
      authUrl = 'https://auth-dev.barscience.us/login';
    } else if (!window.location.href.includes('barscience.us')) {
      authUrl = `http://${window.location.hostname}:6002/login`;
    }
    return (
      <ExternalRedirect to={authUrl + '?continue=' + window.location.href} />
    );
  }

  return (
    <>
      {alerts.length > 0 &&
        <View style={{ bottom: '24px', gap: '16px', left: '24px', position: 'fixed', zIndex: 1 }}>
          {alerts.map((a) => {
            return (
              <React.Fragment key={a.id}>
                {a.alert}
              </React.Fragment>
            );
          })}
        </View>
      }
      <View style={{ height: '100vh' }}>
        <Header product='inventory' onSidebarClick={toggleSidebar} actionButton={<HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}><HeaderNewActions /></HasProductRole>} />

        <View style={{ flexDirection: 'row', flexGrow: 1 }}>
          <Sidebar isOpen={sidebarIsOpen}>
            <SidebarSection label=''>
              <SidebarItem label='Periods' to='/periods' icon={Icons.Schedule} includeSubpaths />
              <SidebarItem label='Orders' to='/orders' icon={Icons.ShoppingCart} includeSubpaths />
              <SidebarItem label='Invoices' to='/invoices' icon={Icons.Receipt} includeSubpaths />
              <SidebarItem label='Items' to='/items' icon={Icons.Inventory} includeSubpaths />
              <SidebarItem label='Recipes' to='/recipes' icon={Icons.ChefHat} includeSubpaths />
              <SidebarItem label='Prep Lists' to='/prep-lists' icon={Icons.ClipboardList} includeSubpaths />
              <SidebarItem label='Item Counts' to='/item-counts' icon={Icons.Tally} includeSubpaths />
              <SidebarItem label='Sales' to='/sales' icon={Icons.Dollar} includeSubpaths />
              <SidebarItem label='Waste Logs' to='/waste' icon={Icons.TrashList} includeSubpaths />
              <SidebarItem label='Reports' to='/reports' icon={Icons.PieChart} includeSubpaths />
            </SidebarSection>
            <SidebarSection label='Settings'>
              <SidebarItem label='Item Categories' to='/categories' icon={Icons.Folder} includeSubpaths />
              <SidebarItem label='Item Locations' to='/item-locations' icon={Icons.Location} includeSubpaths />
              <SidebarItem label='Par Lists' to='/par-lists' icon={Icons.NumberList} includeSubpaths />
              <SidebarItem label='Vendors' to='/vendors' icon={Icons.Organization} includeSubpaths />
            </SidebarSection>
          </Sidebar>

          <Routes>
            <Route path='/items' element={<AllItems />} />
            <Route path='/items/:itemId' element={<ItemDetails />} />

            <Route path='/par-lists' element={<AllPars />} />
            <Route path='/par-lists/:parListId' element={<ParDetail />} />

            <Route path='/prep-lists' element={<AllPrepLists />} />
            <Route path='/prep-lists/:prepListId' element={<PrepListDetails />} />
            <Route path='/prep-lists/:prepListId/edit' element={<EditPrepList />} />

            <Route path='/item-counts' element={<AllItemCounts />} />
            <Route path='/item-counts/:itemCountId' element={<ItemCountDetails />} />
            <Route path='/item-counts/:itemCountId/edit' element={<EditItemCount />} />

            <Route path='/sales' element={<AllSalesPeriods />} />
            <Route path='/sales/:periodId' element={<SalesPeriodDetails />} />
            <Route path='/sales/:periodId/edit-categories' element={<EditCategorySales />} />
            <Route path='/sales/:periodId/edit-items' element={<EditItemSales />} />

            <Route path='/waste' element={<WasteLogs />} />

            <Route path='/reports' element={<Reports />} />
            <Route path='/reports/item-count-valuation' element={<ItemCountValuation />} />
            <Route path='/reports/period-pc' element={<PeriodPC />} />
            <Route path='/reports/shrinkage' element={<Shrinkage />} />
            <Route path='/reports/waste' element={<Waste />} />

            <Route path='/item-locations' element={<ItemLocations />} />

            <Route path='/periods' element={<AllPeriods />} />
            <Route path='/periods/:periodId' element={<PeriodDetail />} />

            <Route path='/vendors' element={<AllVendors />} />
            <Route path='/vendors/:vendorId' element={<VendorDetail />} />

            <Route path='/categories' element={<AllItemCategories />} />

            <Route path='/orders' element={<AllOrders />} />
            <Route path='/orders/:orderId' element={<OrderDetail />} />
            <Route path='/orders/:orderId/edit' element={<EditOrder />} />

            <Route path='/invoices' element={<AllInvoices />} />
            <Route path='/invoices/:invoiceId' element={<InvoiceDetail />} />

            <Route path='/recipes' element={<AllRecipeCategories />} />
            <Route path='/recipes/:categoryId' element={<RecipeCategoryDetail />} />
            <Route path='/recipes/:categoryId/:recipeId' element={<RecipeDetail />} />

            <Route path='/' element={<Navigate to='/periods' />} />
          </Routes>
        </View>
      </View>
    </>
  );
}

export default App;
