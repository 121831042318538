import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Choice, InfoPanel, Link, SingleSelect, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, View, useForm } from "@barscience/global-components";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

/* Get Periods Query */
const GET_PERIODS = gql`
query getPeriodsWithPCReportAvailable($page: Int!) {
  periodsWithPCReportAvailable(page: $page) {
    id
    name
    startDate
    endDate
  }
}
`;

type GetPeriodsResponse = {
  periodsWithPCReportAvailable: Period[] | null;
}

type Period = {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
}

/* Get Period PC Report Query */
const GET_PERIOD_PC_REPORT = gql`
query getPeriodPCReport($periodId: ID!) {
  periodPCReport(id: $periodId) {
    overall {
      percent
      error
    }
    byCategory {
      costs {
        category {
          id
          name
        }
        percent
      }
      error
    }
    period {
      id
      name
      startDate
      endDate
    }
  }
}
`;

type GetPeriodPCReportResponse = {
  periodPCReport: {
    overall: {
      percent: number;
      error: PCError;
    };
    byCategory: {
      costs: {
        category: {
          id: string;
          name: string;
        };
        percent: number | null;
      }[];
      error: PCError;
    };
    period: {
      id: string;
      name: string;
      startDate: string;
      endDate: string;
    };
  } | null;
}

type PCError = 'MISSING_START_COUNT' | 'MISSING_END_COUNT' | 'MISSING_CATEGORY_SALES';

const PERIODS_PER_PAGE = 12;

type PeriodFormInput = {
  periodId: string;
}

export default function PeriodPC() {
  const [queryParams, setQueryParams] = useSearchParams();
  const periodId = queryParams.get('periodId');
  const [periods, setPeriods] = useState<Period[]>([]);
  const [periodsPage, setPeriodsPage] = useState<number>(0);
  const { data: periodsData, loading: periodsAreLoading } = useQuery<GetPeriodsResponse>(GET_PERIODS, {
    variables: {
      page: 0,
    },
    onCompleted(data) {
      if (data.periodsWithPCReportAvailable) {
        setPeriods(data.periodsWithPCReportAvailable);
      }
    },
    fetchPolicy: 'network-only',
  });
  const [loadMorePeriods, { data: morePeriodsData, loading: morePeriodsAreLoading }] = useLazyQuery<GetPeriodsResponse>(GET_PERIODS, {
    onCompleted(data) {
      if (data.periodsWithPCReportAvailable) {
        setPeriods([...periods, ...data.periodsWithPCReportAvailable]);
      }
    },
    fetchPolicy: 'network-only',
  });
  const [generateReport, { data: reportData, loading: reportIsLoading }] = useLazyQuery<GetPeriodPCReportResponse>(GET_PERIOD_PC_REPORT);

  useEffect(() => {
    if (periodId) {
      generateReport({
        variables: {
          periodId: periodId,
        },
      });
    }
  }, [periodId, generateReport]);

  const hasMorePages = () => {
    // More than one page has been loaded, and the most recent page was full
    if (morePeriodsData?.periodsWithPCReportAvailable && morePeriodsData.periodsWithPCReportAvailable.length === PERIODS_PER_PAGE) {
      return true;
    }

    // The first page was full, and no more pages have been loaded yet
    if (!morePeriodsData && periodsData?.periodsWithPCReportAvailable && periodsData?.periodsWithPCReportAvailable?.length === PERIODS_PER_PAGE) {
      return true;
    }

    return false;
  }

  const handleGenerateReport = (values: PeriodFormInput) => {
    setQueryParams({ periodId: values.periodId });
  }

  const handleLoadMorePeriods = async () => {
    await loadMorePeriods({
      variables: {
        page: periodsPage + 1,
      },
    });
    setPeriodsPage(periodsPage + 1);
  }

  const periodForm = useForm<PeriodFormInput>({
    initialValues: {
      periodId: '',
    },
    onSubmit: handleGenerateReport,
  });
  
  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Reports' to='/reports' />
          <Breadcrumb label='Period PC Report' />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', justifyContent: 'space-between', '@media (max-width: 1151px)': { alignItems: 'flex-start', flexDirection: 'column' } }}>
          <StyledHeading tag='h3'>Period PC Report</StyledHeading>

          {(periodId && !reportIsLoading) &&
            <View>
              <StyledParagraph bold> Report For:</StyledParagraph>
              <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                <StyledParagraph>{`${reportData?.periodPCReport?.period.name} (${reportData?.periodPCReport?.period.startDate} - ${reportData?.periodPCReport?.period.endDate})`}</StyledParagraph>
                <Button label='Change' variant='tertiary' role='button' action={() => { setQueryParams({}); }} />
              </View>
            </View>
          }
        </View>
      </Cell>
      {periodId ?
        (reportIsLoading ?
          <Cell lg={12} md={8} sm={4}>
            <View style={{ alignItems: 'center', marginTop: '64px' }}>
              <AtomSpinner size='medium' />
              <StyledHeading tag='h6' style={{ textAlign: 'center' }}>Hang tight, we're working on this report for you...</StyledHeading>
            </View>
          </Cell>
          :
          <>
            <Cell lg={6} md={6} sm={4}>
              <Card size='medium'>
                <View style={{ gap: '16px' }}>
                  <StyledHeading tag='h6'>Overall PC</StyledHeading>
                  <StyledParagraph style={{ fontSize: '32px', fontWeight: 600 }}>{reportData?.periodPCReport?.overall.percent.toFixed(2)}%</StyledParagraph>
                </View>
              </Card>
            </Cell>
            <Cell lg={6} md={6} sm={4}>
              <Card size='medium'>
                <View style={{ gap: '16px' }}>
                  <StyledHeading tag='h6'>Category PCs</StyledHeading>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHeaderCell>Category</TableHeaderCell>
                        <TableHeaderCell>PC</TableHeaderCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {reportData?.periodPCReport?.byCategory.costs.map((category, index) => (
                        <TableRow key={index}>
                          <TableCell>{category.category.name}</TableCell>
                          <TableCell>{category.percent ? category.percent.toFixed(2) + '%' : '-----'}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </View>
              </Card>
            </Cell>
          </>
        )
        :
        (periodsAreLoading ?
          <Cell lg={12} md={8} sm={4}>
            <View style={{ alignItems: 'center', marginTop: '64px' }}>
              <AtomSpinner size='medium' />
            </View>
          </Cell>
          :
          <Cell lg={12} md={8} sm={4}>
            <View style={{ alignItems: 'center', marginTop: '64px' }}>
              <Card size='medium' style={{ boxSizing: 'border-box', maxWidth: '400px', minWidth: '400px', width: '400px', '@media (max-width: 767px)': { maxWidth: '100%', minWidth: '100%', width: '100%' } }}>
                <View style={{ gap: '32px' }}>
                  <SingleSelect label='Select a period' name='periodId' value={periodForm.values.periodId} error={periodForm.errors.periodId} onChange={periodForm.handleChange} onValidate={periodForm.handleValidate} required>
                    {periods.map((period, index) => (
                      <Choice label={period.name} description={`${period.startDate} - ${period.endDate}`} value={period.id} key={index} />
                    ))}
                    {hasMorePages() && <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                      <Button label='Load More' variant='tertiary' role='button' action={handleLoadMorePeriods} loading={morePeriodsAreLoading} />
                    </View>}
                  </SingleSelect>

                  <Button label='Generate Report' variant='primary' role='button' action={periodForm.handleSubmit} disabled={periodForm.hasError} />

                  <InfoPanel type='info'>
                    <StyledParagraph>Not seeing a period listed here? Make sure it meets <Link href='https://support.barscience.us/help-center/articles/2491bb58-7bbb-4a13-818b-9788cf33107a' target='_blank'>these requirements</Link>.</StyledParagraph>
                  </InfoPanel>
                </View>
              </Card>
            </View>
          </Cell>
        )
      }
    </StandardGrid>
  );
}