import { gql, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, Button, Card, Cell, DatePicker, FormModal, HasProductRole, InventoryRoles, Link, ModalLauncher, PageButtons, Products, StandardGrid, StyledHeading, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, View } from "@barscience/global-components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const GET_ALL_PREP_LISTS = gql`
query getAllPrepLists($page: Int!) {
  prepLists(page: $page) {
    prepLists {
      id
      name
      date
    }
    pages
  }
}
`;

type GetAllPrepListsResponse = {
  prepLists: {
    prepLists: PrepList[];
    pages: number;
  } | null;
}

type PrepList = {
  id: string;
  name: string;
  date: string;
}

/* Create Prep List Mutation */
const CREATE_PREP_LIST = gql`
mutation createPrepList($input: CreatePrepListInput!) {
  createPrepList(input: $input) {
    id
    name
    date
  }
}
`;

type CreatePrepListResponse = {
  createPrepList: PrepList | null;
}

type CreatePrepListInput = {
  name: string;
  date: string;
}

export default function AllPrepLists() {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);
  const { data: prepListsData, loading: prepListsAreLoading } = useQuery<GetAllPrepListsResponse>(GET_ALL_PREP_LISTS, {
    variables: {
      page: page,
    },
    nextFetchPolicy: 'network-only',
  });
  const [createPrepList] = useMutation<CreatePrepListResponse>(CREATE_PREP_LIST);

  const handleCreatePrepList = async (values: CreatePrepListInput) => {
    const { data } = await createPrepList({
      variables: {
        input: {
          name: values.name,
          date: values.date,
        },
      },
    });

    if (data?.createPrepList?.id) {
      navigate('/prep-lists/' + data.createPrepList.id + '/edit');
    }
  }

  const createPrepListModal = (
    <FormModal<CreatePrepListInput> title='Create Prep List' onSubmit={handleCreatePrepList} initialValues={{ name: '', date: new Date().toDateString() }} submitLabel='Create'>
      <View style={{ gap: '16px' }}>
        <TextField label='List Name' name='name' required />
        <DatePicker label='Date' name='date' description='The date this prep list will be completed' style={{ width: '100%' }} required />
      </View>
    </FormModal>
  );

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <StyledHeading tag='h3'>Prep Lists</StyledHeading>
          <View style={{ flexDirection: 'row', gap: '16px' }}>
            <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Employee, InventoryRoles.Manager, InventoryRoles.Admin]}>
              <ModalLauncher modal={createPrepListModal}>
                {({ openModal }) => (
                  <Button label='Create Prep List' role='button' variant='primary' action={openModal} />
                )}
              </ModalLauncher>
            </HasProductRole>
            <PageButtons currentPage={page} numPages={prepListsData?.prepLists?.pages || 0} onPageChange={setPage} />
          </View>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {prepListsAreLoading ?
          <AtomSpinner size='large' />
          :
          (
            <Card size='medium'>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell style={{ minWidth: '200px' }}>Name</TableHeaderCell>
                    <TableHeaderCell style={{ minWidth: '200px' }}>Date</TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {prepListsData?.prepLists?.prepLists.map((list, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell><Link href={'/prep-lists/' + list.id}>{list.name}</Link></TableCell>
                        <TableCell>{list.date}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Card>
          )
        }
      </Cell>
    </StandardGrid>
  );
}