import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Choice, InfoPanel, SingleSelect, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, View, useForm } from "@barscience/global-components";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

/* Get Item Count Lists Query */
const GET_ITEM_COUNT_LISTS = gql`
query getItemCountListsForReports($page: Int!) {
  itemCountLists(page: $page) {
    itemCountLists {
      id
      name
      date
      isLocked
    }
  }
}
`;

type GetItemCountListsResponse = {
  itemCountLists: {
    itemCountLists: ItemCountList[];
  } | null;
}

type ItemCountList = {
  id: string;
  name: string;
  date: string;
  isLocked: boolean;
}

type ItemCountFormInput = {
  itemCountId: string;
}

/* Get Item Count Valuation Query */
const GET_ITEM_COUNT_VALUATION = gql`
query getItemCountValuation($itemCountListId: ID!) {
  itemCountList(id: $itemCountListId) {
    id
    name
    date
    isLocked
    totalValue
    byCategory {
      category {
        id
        name
      }
      totalCost
    }
  }
}
`;

type GetItemCountValuationResponse = {
  itemCountList: {
    id: string;
    name: string;
    date: string;
    isLocked: boolean;
    totalValue: string;
    byCategory: {
      category: {
        id: string;
        name: string;
      };
      totalCost: string;
    }[];
  } | null;
}

const ITEM_COUNT_LISTS_PER_PAGE = 10;

export default function ItemCountValuation() {
  const [queryParams, setQueryParams] = useSearchParams();
  const itemCountId = queryParams.get('itemCountId');
  const [itemCountLists, setItemCountLists] = useState<ItemCountList[]>([]);
  const [itemCountListPage, setItemCountListPage] = useState<number>(0);
  const { data: itemCountListsData, loading: itemCountListsAreLoading } = useQuery<GetItemCountListsResponse>(GET_ITEM_COUNT_LISTS, {
    variables: {
      page: 0,
    },
    onCompleted: (data) => {
      if (data.itemCountLists) {
        setItemCountLists([...itemCountLists, ...data.itemCountLists.itemCountLists]);
      }
    },
    fetchPolicy: 'network-only',
  });
  const [loadMoreItemCountLists, { data: moreListsData, loading: moreListsAreLoading }] = useLazyQuery<GetItemCountListsResponse>(GET_ITEM_COUNT_LISTS, {
    onCompleted(data) {
      if (data.itemCountLists) {
        setItemCountLists([...itemCountLists, ...data.itemCountLists.itemCountLists]);
      }
    },
    fetchPolicy: 'network-only',
  });
  const [generateReport, { data: reportData, loading: reportIsLoading }] = useLazyQuery<GetItemCountValuationResponse>(GET_ITEM_COUNT_VALUATION);

  useEffect(() => {
    if (itemCountId) {
      generateReport({
        variables: {
          itemCountListId: itemCountId,
        },
      });
    }
  }, [itemCountId, generateReport]);

  const hasMorePages = () => {
    if (moreListsData?.itemCountLists?.itemCountLists && moreListsData.itemCountLists.itemCountLists.length === ITEM_COUNT_LISTS_PER_PAGE) {
      return true;
    }

    if (!moreListsData && itemCountListsData?.itemCountLists && itemCountListsData?.itemCountLists?.itemCountLists.length === ITEM_COUNT_LISTS_PER_PAGE) {
      return true;
    }

    return false;
  }

  const handleGenerateReport = (values: ItemCountFormInput) => {
    setQueryParams({ itemCountId: values.itemCountId });
  }

  const handleLoadMoreLists = async () => {
    await loadMoreItemCountLists({
      variables: {
        page: itemCountListPage + 1,
      },
    });
    setItemCountListPage(itemCountListPage + 1);
  }

  const itemCountForm = useForm<ItemCountFormInput>({
    initialValues: {
      itemCountId: '',
    },
    onSubmit: handleGenerateReport,
  });

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Reports' to='/reports' />
          <Breadcrumb label='Item Count Valuation' />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', justifyContent: 'space-between', '@media (max-width: 1151px)': { alignItems: 'flex-start', flexDirection: 'column' } }}>
          <StyledHeading tag='h3'>Item Count Valuation Report</StyledHeading>

          {(itemCountId && !reportIsLoading) &&
            <View>
              <StyledParagraph bold> Report For:</StyledParagraph>
              <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                <StyledParagraph>{`${reportData?.itemCountList?.name} (${reportData?.itemCountList?.date})`}</StyledParagraph>
                <Button label='Change' variant='tertiary' role='button' action={() => { setQueryParams({}); }} />
              </View>
            </View>
          }
        </View>
      </Cell>
      {itemCountId ?
        (reportIsLoading ?
          <Cell lg={12} md={8} sm={4}>
            <View style={{ alignItems: 'center', marginTop: '64px' }}>
              <AtomSpinner size='medium' />
              <StyledHeading tag='h6' style={{ textAlign: 'center' }}>Hang tight, we're working on this report for you...</StyledHeading>
            </View>
          </Cell>
          :
          <>
            <Cell lg={6} md={6} sm={4}>
              <Card size='medium'>
                <View style={{ gap: '16px' }}>
                  <StyledHeading tag='h6'>Total Value</StyledHeading>
                  <StyledParagraph style={{ fontSize: '32px', fontWeight: 600 }}>{reportData?.itemCountList?.totalValue}</StyledParagraph>
                </View>
              </Card>
            </Cell>
            <Cell lg={6} md={6} sm={4}>
              <Card size='medium'>
                <View style={{ gap: '16px' }}>
                  <StyledHeading tag='h6'>Total Value By Category</StyledHeading>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHeaderCell>Category</TableHeaderCell>
                        <TableHeaderCell>Total Value</TableHeaderCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {reportData?.itemCountList?.byCategory.map((c, index) => (
                        <TableRow key={index}>
                          <TableCell>{c.category.name}</TableCell>
                          <TableCell>{c.totalCost}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </View>
              </Card>
            </Cell>
          </>
        )
        :
        (itemCountListsAreLoading ?
          <Cell lg={12} md={8} sm={4}>
            <View style={{ alignItems: 'center', marginTop: '64px' }}>
              <AtomSpinner size='medium' />
            </View>
          </Cell>
          :
          <Cell lg={12} md={8} sm={4}>
            <View style={{ alignItems: 'center', marginTop: '64px' }}>
              <Card size='medium' style={{ boxSizing: 'border-box', maxWidth: '400px', minWidth: '400px', width: '400px', '@media (max-width: 767px)': { maxWidth: '100%', minWidth: '100%', width: '100%' } }}>
                <View style={{ gap: '32px' }}>
                  <SingleSelect label='Select an item count' name='itemCountId' value={itemCountForm.values.itemCountId} error={itemCountForm.errors.itemCountId} onChange={itemCountForm.handleChange} onValidate={itemCountForm.handleValidate} required>
                    {itemCountLists.map((itemCountList, index) => (
                      <Choice label={itemCountList.name} description={itemCountList.date} value={itemCountList.id} key={index} />
                    ))}
                    {hasMorePages() && <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                      <Button label='Load More' variant='tertiary' role='button' action={handleLoadMoreLists} loading={moreListsAreLoading} />
                    </View>}
                  </SingleSelect>

                  {(itemCountForm.values.itemCountId && !itemCountLists.find(itemCountList => itemCountList.id === itemCountForm.values.itemCountId)?.isLocked) &&
                    <InfoPanel type='info'>
                      <StyledParagraph>This item count has not been locked, so <span style={{ fontWeight: 600 }}>report generation may take a few minutes.</span></StyledParagraph>
                    </InfoPanel>
                  }

                  <Button label='Generate Report' variant='primary' role='button' action={itemCountForm.handleSubmit} disabled={itemCountForm.hasError} />
                </View>
              </Card>
            </View>
          </Cell>
        )
      }
    </StandardGrid>
  );
}