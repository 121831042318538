import { Reference, gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, Breadcrumb, BreadcrumbGroup, Card, Cell, Choice, CircularSpinner, Colors, Icon, Icons, InventoryRoles, Link, MultiSelect, NoPermission, NotFound, SingleSelect, Spring, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, View, generateId, useAlertState, useAuthState } from "@barscience/global-components";
import { CSSProperties } from "aphrodite";
import currency from "currency.js";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

/* Get Order Query */
const GET_ORDER_DETAILS_QUERY = gql`
query getInventoryOrderDetailsForEditOrder($id: ID!) {
  inventoryOrder(id: $id) {
    id
    vendor {
      id
      name
    }
    orderDate
    deliveryDate
    items {
      item {
        id
        name
        category {
          id
          name
        }
        unitsPerCase
        currentPrice {
          casePrice
        }
      }
      casesOrdered
    }
    itemCount
    caseCount
    estimatedTotalCost
  }
}
`;

type GetOrderDetailsQueryResponse = {
  inventoryOrder: InventoryOrder;
}

type InventoryOrder = {
  id: string;
  vendor: {
    id: string;
    name: string;
  };
  orderDate: string;
  deliveryDate: string;
  items: OrderItem[];
  itemCount: number;
  caseCount: number;
  estimatedTotalCost: string;
}

type OrderItem = {
  item: Item;
  casesOrdered: number;
}

type Item = {
  id: string;
  name: string;
  category: {
    id: string;
    name: string;
  };
  unitsPerCase: number;
  currentPrice: {
    casePrice: string;
  }
}

/* Update Order Item Mutation */
const UPDATE_ORDER_ITEM_MUTATION = gql`
mutation updateInventoryOrderItemAmount($orderId: ID!, $itemId: ID!, $input: UpdateOrderItemInput!) {
  orderItem: updateInventoryOrderItemAmount(orderId: $orderId, itemId: $itemId, input: $input) {
    item {
      id
    }
    casesOrdered
  }
}
`;

type UpdateOrderItemMutationResponse = {
  orderItem: {
    item: {
      id: string;
    }
    casesOrdered: number;
  }
}

/* Get Locations Query */
const GET_LOCATIONS_QUERY = gql`
query getItemLocationsForOrders {
  inventoryItemLocations(forOrders: true) {
    id
    name
    sublocations(forOrders: true) {
      id
      name
    }
  }
}
`;

type GetLocationsQueryResponse = {
  inventoryItemLocations: ItemLocation[];
}

type ItemLocation = {
  id: string;
  name: string;
  sublocations: ItemSublocationSummary[];
}

type ItemSublocationSummary = {
  id: string;
  name: string;
}

/* Get Sublocation Items Query */
const GET_SUBLOCATION_QUERY = gql`
query getInventoryItemSublocationForOrders(
  $id: ID!
  $startDate: Date
  $categories: [String!]
  $vendors: [String!]
  $parListId: ID
) {
  inventoryItemSublocation(id: $id) {
    id
    name
    items(categories: $categories, vendors: $vendors, listType: ORDERS) {
      ... on InventoryItemLocationAssignment {
        item {
          id
          name
          vendor {
            id
            name
          }
          category {
            name
          }
          par(sublocationId: $id, parListId: $parListId) {
            casePar
            unitPar
          }
          unitsPerCase
          currentPrice(startDate: $startDate) {
            casePrice
          }
        }
      }
    }
  }
}
`;

type GetSublocationQueryResponse = {
  inventoryItemSublocation: ItemSublocation;
}

type ItemSublocation = {
  id: string;
  name: string;
  items: {
    item: SublocationItem;
  }[];
}

type SublocationItem = {
  id: string;
  name: string;
  category: {
    name: string;
  };
  vendor: {
    id: string;
    name: string;
  };
  par: {
    casePar: number;
    unitPar: number;
  };
  unitsPerCase: number;
  currentPrice: {
    casePrice: string;
  };
}

/* Get Categories Query */
const GET_ALL_CATEGORIES = gql`
query getItemCategoriesForItemFilter {
  itemCategories {
    id
    name
  }
}
`;

type GetAllCategoriesResponse = {
  itemCategories: ItemCategory[];
}

type ItemCategory = {
  id: string;
  name: string;
}

/* Get All Par Lists */
const GET_ALL_PAR_LISTS = gql`
query getItemParListsForOrders {
  itemParLists {
    id
    name
    description
    isDefault
  }
}
`;

type GetAllParListsResponse = {
  itemParLists: ItemParList[];
}

type ItemParList = {
  id: string;
  name: string;
  description: string | null;
  isDefault: boolean;
}

const styles: { [name: string]: CSSProperties } = {
  hideOverFlowText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  cardContentContainer: {
    flexDirection: 'row',
    gap: '16px',
    height: '100%',
    '@media (max-width: 767px)': {
      flexDirection: 'column',
      overflowY: 'scroll'
    },
  },
  locationsListContainer: {
    borderRight: `1px solid ${Colors.neutral200}`,
    maxWidth: '350px',
    minWidth: '300px',
    overflowY: 'scroll',
    paddingRight: '16px',
    '@media (min-width: 768px)': {
      height: '100%'
    },
    '@media (max-width: 1151px)': {
      display: 'none',
    },
  },
  sublocationContainer: {
    alignItems: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: '56px',
    padding: '8px 16px'
  },
  leftFixedColumn: {
    backgroundColor: '#ffffff',
    borderRight: `1px solid ${Colors.neutral200}`,
    left: 0,
    maxWidth: '200px',
    minWidth: '200px',
    overflow: 'hidden',
    position: 'sticky',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    zIndex: 2,
    '@media (max-width: 767px)': {
      maxWidth: '150px',
      minWidth: '150px',
      position: 'relative',
    },
  },
  rightFixedColumn: {
    backgroundColor: '#ffffff',
    borderLeft: `1px solid ${Colors.neutral200}`,
    minWidth: '100px',
    paddingLeft: '16px',
    position: 'sticky',
    right: '128px',
    '@media (max-width: 1151px)': {
      position: 'relative',
      right: 0,
    },
  },
  rightOuterFixedColumn: {
    backgroundColor: '#ffffff',
    minWidth: '80px',
    position: 'sticky',
    right: 0,
    '@media (max-width: 1151px)': {
      position: 'relative',
    },
  },
}

export default function EditOrder() {
  const { state } = useAuthState();
  const { addAlert } = useAlertState();
  const { orderId } = useParams();
  const [queryParams, setQueryParams] = useSearchParams();
  const locationId = queryParams.get('locationId');
  const sublocationId = queryParams.get('sublocationId');
  const [selectedCategories, setSelectedCategories] = useState<{ [name: string]: boolean }>({});
  const [selectedParList, setSelectedParList] = useState<string>('DEFAULT');
  const [orderFields, setOrderFields] = useState<{ [itemId: string]: number }>({});
  const { data: locationsData, loading: locationsAreLoading } = useQuery<GetLocationsQueryResponse>(GET_LOCATIONS_QUERY, {
    onCompleted: (data) => {
      if (data.inventoryItemLocations.length > 0 && !queryParams.get('locationId')) {
        setQueryParams({
          locationId: data.inventoryItemLocations[0].id,
        });

        if (data.inventoryItemLocations[0].sublocations.length > 0) {
          setQueryParams({
            locationId: data.inventoryItemLocations[0].id,
            sublocationId: data.inventoryItemLocations[0].sublocations[0].id,
          });
        }
      }
    },
  });
  const [getSublocation, { data: sublocationData, loading: sublocationIsLoading }] = useLazyQuery<GetSublocationQueryResponse>(GET_SUBLOCATION_QUERY);
  const { data: parListData, loading: parListsAreLoading } = useQuery<GetAllParListsResponse>(GET_ALL_PAR_LISTS);
  const { data: orderData, loading: orderIsLoading, error: orderError } = useQuery<GetOrderDetailsQueryResponse>(GET_ORDER_DETAILS_QUERY, {
    variables: {
      id: orderId,
    },
  });
  const { loading: categoriesAreLoading, data: categoriesData } = useQuery<GetAllCategoriesResponse>(GET_ALL_CATEGORIES, {
    onCompleted: (data) => {
      const noneSelected: { [name: string]: boolean } = {};

      data.itemCategories.forEach((category) => {
        noneSelected[category.id] = false;
      });

      setSelectedCategories(noneSelected);
    },
  });
  const [updateOrderItem, { loading: updateItemIsLoading }] = useMutation<UpdateOrderItemMutationResponse>(UPDATE_ORDER_ITEM_MUTATION, {
    update(cache, { data }, { variables }) {
      if (!orderData || !variables) {
        return;
      }

      cache.modify({
        id: cache.identify(orderData.inventoryOrder),
        fields: {
          items(existingItemRefs = [], { readField, DELETE }) {
            return existingItemRefs.map((itemRef: Reference) => {
              const item: Reference | undefined = readField('item', itemRef);

              if (readField('id', item) === variables.itemId) {
                if (variables.input.casesOrdered === 0) {
                  return DELETE;
                }

                return {
                  ...itemRef,
                  casesOrdered: variables.input.casesOrdered,
                };
              }

              return itemRef;
            });
          },
        },
      });
    }
  });

  useEffect(() => {
    if (sublocationId !== null) {
      getSublocation({
        variables: {
          id: sublocationId,
          startDate: orderData?.inventoryOrder.orderDate,
          categories: Object.keys(selectedCategories).filter((key) => (selectedCategories[key])),
          vendors: orderData ? [orderData.inventoryOrder.vendor.id] : null,
          parListId: selectedParList === 'DEFAULT' ? null : selectedParList,
        },
      });
    }
  }, [sublocationId, getSublocation, orderData, selectedCategories, selectedParList]);

  useEffect(() => {
    const itemData: { [itemId: string]: number } = {};
    orderData?.inventoryOrder.items.forEach((item) => {
      itemData[item.item.id] = item.casesOrdered;
    });
    setOrderFields(itemData);
  }, [orderData]);

  const handleLocationClick = (id: string) => {
    if (id === locationId) {
      return;
    }

    setQueryParams({
      locationId: id,
    });
  }

  const handleSublocationClick = (id: string) => {
    if (id === sublocationId || !locationId) {
      return;
    }

    setQueryParams({
      locationId: locationId,
      sublocationId: id,
    });
  }

  const handleOrderFieldChange = async (itemId: string, value: string) => {
    let newValue = parseFloat(value);
    if (newValue < 0) {
      return;
    }

    let valueToSet = newValue;
    if (Number.isNaN(newValue)) {
      valueToSet = 0;
    }

    setOrderFields({
      ...orderFields,
      [itemId]: valueToSet,
    });

    const { errors } = await updateOrderItem({
      variables: {
        orderId: orderId,
        itemId: itemId,
        input: {
          casesOrdered: valueToSet,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = (
        <StandardAlert title='Failed to update item' description={errors[0].message} type='error' id={id} />
      );

      addAlert(id, alert);
    }
  }

  const getParListDescription = (text: string | null) => {
    if (!text) {
      return undefined;
    }

    if (text.length > 50) {
      return text.substring(0, 50) + '...';
    }

    return text;
  }

  if (!(state.user?.roles.Inventory === InventoryRoles.Manager || state.user?.roles.Inventory === InventoryRoles.Admin)) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <NoPermission />
        </Cell>
      </StandardGrid>
    );
  }

  if (orderIsLoading || categoriesAreLoading || parListsAreLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (!orderId || orderError?.graphQLErrors[0].extensions.status === 404) {
    return (
      <StandardGrid>
        <NotFound />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Orders' to='/orders' />
          <Breadcrumb label={orderData?.inventoryOrder.vendor.name + ' (' + orderData?.inventoryOrder.deliveryDate + ')'} to={'/orders/' + orderData?.inventoryOrder.id} />
          <Breadcrumb label='Edit Order' />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', '@media (max-width: 1151px)': { alignItems: 'flex-start', flexDirection: 'column' } }}>
          <StyledHeading tag='h3'>{orderData?.inventoryOrder.vendor.name + ' (' + orderData?.inventoryOrder.deliveryDate + ')'}</StyledHeading>

          <View style={{ alignItems: 'center', flexDirection: 'row', flexGrow: 1, gap: '16px', '@media (max-width: 767px)': { alignItems: 'flex-start', flexDirection: 'column-reverse', gap: '8px' }, '@media (max-width: 1151px)': { width: '100%' } }}>
            <View style={{ maxWidth: '240px', width: '240px' }}>
              <MultiSelect name='categoriesFilter' value={selectedCategories} onChange={(_, value) => { setSelectedCategories(value); }} entityLabel='categories' placeholder='Filter by category'>
                {categoriesData?.itemCategories.map((category) => {
                  return (
                    <Choice label={category.name} value={category.id} key={category.id} />
                  );
                })}
              </MultiSelect>
            </View>
            <View style={{ maxWidth: '240px', width: '240px' }}>
              <SingleSelect name='parList' value={selectedParList} onChange={(_, value) => { setSelectedParList(value || 'DEFAULT'); }} style={{ maxWidth: '240px' }}>
                {parListData?.itemParLists.map((parList, index) => (
                  <Choice label={parList.name} description={getParListDescription(parList.description)} value={parList.isDefault ? 'DEFAULT' : parList.id} key={index} />
                ))}
              </SingleSelect>
            </View>
            <Spring />
            {updateItemIsLoading ?
              <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                <CircularSpinner size='xsmall' />
                <StyledParagraph style={{ color: Colors.neutral700 }}>Saving changes...</StyledParagraph>
              </View>
              :
              <View>
                <StyledParagraph style={{ color: Colors.neutral700 }}>All changes saved</StyledParagraph>
              </View>
            }
          </View>
        </View>
      </Cell>
      <Cell lg={0} md={8} sm={4}>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '16px' }}>
          <SingleSelect label='Location' name='locationId' placeholder='Choose a location' value={locationId || ''} onChange={(_, value) => { handleLocationClick(value || ''); }} style={{ maxWidth: '300px', minWidth: '250px', width: 'calc(50% - 16px)' }}>
            {locationsData?.inventoryItemLocations.map((l, index) => {
              return (
                <Choice label={l.name} value={l.id} key={index} />
              );
            })}
          </SingleSelect>
          {locationId &&
            <SingleSelect label='Sublocation' name='sublocationId' placeholder='Choose a sublocation' value={sublocationId || ''} onChange={(_, value) => { handleSublocationClick(value || ''); }} style={{ maxWidth: '300px', minWidth: '250px', width: 'calc(50% - 16px)' }}>
              {(() => {
                if (!locationsData) {
                  return null;
                }

                const openLocation = locationsData.inventoryItemLocations.find((loc) => loc.id === locationId);
                if (!openLocation) {
                  return null;
                }

                if (openLocation.sublocations.length === 0) {
                  return (
                    <View style={{ maxWidth: '300px', minWidth: '250px', padding: '16px' }}>
                      <StyledParagraph>There are no sublocations in this location.</StyledParagraph>
                    </View>
                  );
                } else {
                  return (
                    <>
                      {openLocation.sublocations.map((s, index) => {
                        return (
                          <Choice label={s.name} value={s.id} key={index} />
                        );
                      })}
                    </>
                  );
                }
              })()}
            </SingleSelect>
          }
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {(locationsAreLoading) ?
          <AtomSpinner size='large' />
          :
          (
            <Card size='medium' style={{ height: 'calc(100vh - 272px)', minHeight: '300px' }}>
              {(locationsData?.inventoryItemLocations.length === 0) ?
                <StyledHeading tag='h6'>No item locations exist.</StyledHeading>
                :
                <View style={styles.cardContentContainer}>
                  <View style={styles.locationsListContainer}>
                    {locationsData?.inventoryItemLocations.map((l, index) => {
                      return (
                        <View key={index} style={{ minHeight: 'fit-content' }}>
                          <View style={{ alignItems: 'center', borderBottom: `1px solid ${Colors.neutral200}`, flexDirection: 'row', justifyContent: 'space-between', minHeight: '72px', padding: '16px 16px 16px 0px' }}>
                            <View style={{ alignItems: 'center', cursor: 'pointer', flexDirection: 'row', gap: '16px' }} onClick={() => { handleLocationClick(l.id); }}>
                              {locationId === l.id &&
                                <Icon size='small' icon={Icons.ChevronUp} />
                              }
                              {locationId !== l.id &&
                                <Icon size='small' icon={Icons.ChevronDown} />
                              }
                              <StyledHeading tag='h5' style={{ maxWidth: '200px', ...styles.hideOverFlowText }}>{l.name}</StyledHeading>
                            </View>
                          </View>
                          {locationId === l.id &&
                            <View style={{ padding: '8px 0' }}>
                              {l.sublocations.map((s, index) => {
                                return (
                                  <View key={index} style={{ ...styles.sublocationContainer, ...(s.id === sublocationId ? { backgroundColor: Colors.primary50 } : {}) }} onClick={() => { handleSublocationClick(s.id); }}>
                                    <StyledHeading tag='h6' style={{ maxWidth: '200px', ...styles.hideOverflowText }}>{s.name}</StyledHeading>
                                  </View>
                                );
                              })}
                              {l.sublocations.length === 0 &&
                                <StyledParagraph style={{ margin: '16px 0' }}>There are no sublocations in this location.</StyledParagraph>
                              }
                            </View>
                          }
                        </View>
                      );
                    })}
                  </View>
                  <View style={{ height: '100%', overflow: 'hidden', width: '100%' }}>
                    {sublocationId === null ?
                      <View style={{ alignContent: 'center', height: '100%', justifyContent: 'center', textAlign: 'center', width: '100%' }} >
                        <StyledHeading tag='h6' style={{ color: Colors.neutral700 }}>Select a sublocation to view items.</StyledHeading>
                      </View>
                      :
                      (sublocationIsLoading ?
                        <View style={{ alignContent: 'center', height: '100%', justifyContent: 'center', textAlign: 'center', width: '100%' }} >
                          <AtomSpinner size='medium' />
                        </View>
                        :
                        <View style={{ height: '100%', overflowX: 'auto' }}>
                          <Table>
                            <TableHeader>
                              <TableRow>
                                <TableHeaderCell style={styles.leftFixedColumn}>Item Name</TableHeaderCell>
                                <TableHeaderCell style={{ '@media (max-width: 450px)': { display: 'none' } }}>Case Price</TableHeaderCell>
                                <TableHeaderCell style={{ '@media (max-width: 450px)': { display: 'none' } }}>Units Per Case</TableHeaderCell>
                                <TableHeaderCell style={{ '@media (max-width: 450px)': { display: 'none' } }}>Case Par</TableHeaderCell>
                                <TableHeaderCell style={{ '@media (max-width: 450px)': { display: 'none' } }}>Unit Par</TableHeaderCell>
                                <TableHeaderCell style={styles.rightFixedColumn}>Cases To Order</TableHeaderCell>
                                <TableHeaderCell style={styles.rightOuterFixedColumn}>Estimated Cost</TableHeaderCell>
                              </TableRow>
                            </TableHeader>
                            <TableBody>
                              {sublocationData?.inventoryItemSublocation.items.map((itemAssignment, index) => {
                                return (
                                  <TableRow key={index} style={{ height: '72px' }}>
                                    <TableCell style={styles.leftFixedColumn}><Link href={'/items/' + itemAssignment.item.id}>{itemAssignment.item.name}</Link></TableCell>
                                    <TableCell style={{ minWidth: '80px', '@media (max-width: 450px)': { display: 'none' } }}>{itemAssignment.item.currentPrice.casePrice}</TableCell>
                                    <TableCell style={{ minWidth: '100px', '@media (max-width: 450px)': { display: 'none' } }}>{itemAssignment.item.unitsPerCase}</TableCell>
                                    <TableCell style={{ minWidth: '80px', '@media (max-width: 450px)': { display: 'none' } }}>{itemAssignment.item.par.casePar}</TableCell>
                                    <TableCell style={{ minWidth: '80px', '@media (max-width: 450px)': { display: 'none' } }}>{itemAssignment.item.par.unitPar}</TableCell>
                                    <TableCell style={styles.rightFixedColumn}>
                                      <TextField name={itemAssignment.item.id} type='number' inputMode='decimal' value={orderFields[itemAssignment.item.id]?.toString() || ''} onChange={handleOrderFieldChange} />
                                    </TableCell>
                                    <TableCell style={styles.rightOuterFixedColumn}>
                                      {orderFields[itemAssignment.item.id] ? (currency(itemAssignment.item.currentPrice.casePrice).multiply(orderFields[itemAssignment.item.id])).format() : (currency(0.00).format())}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                          {sublocationData?.inventoryItemSublocation.items.length === 0 &&
                            <View style={{ alignContent: 'center', height: '100%', justifyContent: 'center', textAlign: 'center', width: '100%' }} >
                              <StyledHeading tag='h6' style={{ color: Colors.neutral700 }}>There are no items in this sublocation.</StyledHeading>
                            </View>
                          }
                        </View>
                      )
                    }
                  </View>
                </View>
              }
            </Card>
          )
        }
      </Cell>
    </StandardGrid>
  );
}