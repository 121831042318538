import { gql, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, Breadcrumb, BreadcrumbGroup, Card, Cell, Checkbox, Choice, CircularSpinner, Colors, ErrorPage, Link, MultiSelect, NotFound, PageButtons, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, View, generateId, useAlertState } from "@barscience/global-components";
import { useState } from "react";
import { useParams } from "react-router-dom";

/* Get Sales Period Query */
const GET_SALES_PERIOD = gql`
query getSalesPeriodItemDetails($id: ID!, $page: Int!, $includeVendorItems: Boolean, $categories: [ID!]) {
  inventorySalesPeriod(id: $id) {
    id
    name
    startDate
    endDate
    salesByItem(page: $page, includeAllItems: true, includeVendorItems: $includeVendorItems, categories: $categories) {
      item {
        ... on InventoryItem {
          id
          name
          vendor {
            id
            name
          }
          category {
            id
            name
          }
        }
        ... on Recipe {
          id
          name
          recipeCategory {
            id
            name
          }
          itemCategory {
            id
            name
          }
        }
      }
      unitsSold
      totalRevenue
    }
    salesByItemPageCount(includeAllItems: true, includeVendorItems: $includeVendorItems, categories: $categories)
  }
}
`;

type GetSalesPeriodResponse = {
  inventorySalesPeriod: SalesPeriod | null;
}

type SalesPeriod = {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  salesByItem: SalesByItem[];
  salesByItemPageCount: number;
}

type SalesByItem = {
  item: Item | Recipe;
  unitsSold: number | null;
  totalRevenue: string;
}

type Item = {
  id: string;
  name: string;
  vendor: {
    id: string;
    name: string;
  };
  category: {
    id: string;
    name: string;
  };
  __typename: 'InventoryItem';
}

type Recipe = {
  id: string;
  name: string;
  recipeCategory: {
    id: string;
    name: string;
  };
  itemCategory: {
    id: string;
    name: string;
  };
  __typename: 'Recipe';
}

/* Get Item Categories Query */
const GET_ITEM_CATEGORIES = gql`
query getAllItemCategories {
  itemCategories {
    id
    name
    isArchived
  }
}
`;

type GetItemCategoriesResponse = {
  itemCategories: ItemCategory[] | null;
}

type ItemCategory = {
  id: string;
  name: string;
  isArchived: boolean;
}

/* Update Item Sales Mutation */
const UPDATE_ITEM_SALES = gql`
mutation setInventoryItemSales($salesPeriodId: ID!, $itemId: ID!, $totalRevenue: Decimal!, $unitsSold: Float) {
  setInventoryItemSales(salesPeriodId: $salesPeriodId, itemId: $itemId, totalRevenue: $totalRevenue, unitsSold: $unitsSold) {
    item {
      ... on InventoryItem {
        id
      }
      ... on Recipe {
        id
      }
    }
    unitsSold
    totalRevenue
  }
}
`;

type UpdateItemSalesResponse = {
  setInventoryItemSales: {
    item: {
      id: string;
    };
    unitsSold: number;
    totalRevenue: string;
  } | null;
}

export default function EditItemSales() {
  const { addAlert } = useAlertState();
  const { periodId } = useParams<{ periodId: string }>();
  const [periodInfo, setPeriodInfo] = useState<{ id: string; name: string; startDate: string; endDate: string; }>({ id: '', name: '', startDate: '', endDate: '' });
  const [page, setPage] = useState<number>(0);
  const [selectedCategories, setSelectedCategories] = useState<{ [name: string]: boolean }>({});
  const [includeVendorItems, setIncludeVendorItems] = useState<boolean>(false);
  const [salesValues, setSalesValues] = useState<{ [id: string]: { unitsSold: string; totalRevenue: string; } }>({});
  const { data: salesData, loading: salesAreLoading, error: salesError } = useQuery<GetSalesPeriodResponse>(GET_SALES_PERIOD, {
    variables: {
      id: periodId,
      page: page,
      includeVendorItems: includeVendorItems,
      categories: Object.keys(selectedCategories).filter((key) => (selectedCategories[key])),
    },
    onCompleted: (data) => {
      setPeriodInfo({
        id: data.inventorySalesPeriod?.id || '',
        name: data.inventorySalesPeriod?.name || '',
        startDate: data.inventorySalesPeriod?.startDate || '',
        endDate: data.inventorySalesPeriod?.endDate || '',
      });

      if (data?.inventorySalesPeriod) {
        const newSalesValues: { [id: string]: { unitsSold: string; totalRevenue: string; } } = {
          ...salesValues,
        };

        data.inventorySalesPeriod.salesByItem.forEach((item) => {
          newSalesValues[item.item.id] = {
            unitsSold: item.unitsSold ? item.unitsSold.toString() : '',
            totalRevenue: item.totalRevenue === '$0.00' ? '' : item.totalRevenue,
          };
        });

        setSalesValues(newSalesValues);
      }
    },
  });
  const { data: categoriesData, loading: categoriesAreLoading, error: categoriesError } = useQuery<GetItemCategoriesResponse>(GET_ITEM_CATEGORIES, {
    onCompleted: (data) => {
      const noneSelected: { [name: string]: boolean } = {};

      data.itemCategories?.forEach((category) => {
        noneSelected[category.id] = false;
      });

      setSelectedCategories(noneSelected);
    },
  });
  const [editItemSales, { loading: editItemSalesIsLoading }] = useMutation<UpdateItemSalesResponse>(UPDATE_ITEM_SALES, {
    update(cache, { data }, { variables }) {
      if (!variables || !variables.itemId) {
        return;
      }

      const { inventorySalesPeriod } = cache.readQuery<GetSalesPeriodResponse>({
        query: GET_SALES_PERIOD,
        variables: {
          id: periodId,
          page: page,
          includeVendorItems: includeVendorItems,
          categories: Object.keys(selectedCategories).filter((key) => (selectedCategories[key])),
        },
      }) || { inventorySalesPeriod: null };

      if (inventorySalesPeriod) {
        const updatedSalesByItem = inventorySalesPeriod.salesByItem.map((item) => {
          if (item.item.id === data?.setInventoryItemSales?.item.id) {
            return {
              ...item,
              unitsSold: data.setInventoryItemSales.unitsSold,
              totalRevenue: data.setInventoryItemSales.totalRevenue,
            };
          } else {
            return item;
          }
        });

        cache.writeQuery<GetSalesPeriodResponse>({
          query: GET_SALES_PERIOD,
          variables: {
            id: periodId,
            page: page,
            includeVendorItems: includeVendorItems,
            categories: Object.keys(selectedCategories).filter((key) => (selectedCategories[key])),
          },
          data: {
            inventorySalesPeriod: {
              ...inventorySalesPeriod,
              salesByItem: updatedSalesByItem,
            },
          },
        });
      }
    },
  });

  const handleEditUnitsSold = async (id: string, value: string) => {
    const { errors } = await editItemSales({
      variables: {
        salesPeriodId: periodId,
        itemId: id,
        unitsSold: value === '' ? 0 : parseFloat(value),
        totalRevenue: salesValues[id].totalRevenue ? salesValues[id].totalRevenue : '$0.00',
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to update item' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const handleEditTotalRevenue = async (id: string, value: string) => {
    const { errors } = await editItemSales({
      variables: {
        salesPeriodId: periodId,
        itemId: id,
        unitsSold: salesValues[id].unitsSold ? parseFloat(salesValues[id].unitsSold) : null,
        totalRevenue: value === '' ? '$0.00' : value,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to update item' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const handleUnitsSoldChange = (id: string, value: string) => {
    setSalesValues({
      ...salesValues,
      [id]: {
        ...salesValues[id],
        unitsSold: value,
      },
    });
  }

  const handleTotalRevenueChange = (id: string, value: string) => {
    setSalesValues({
      ...salesValues,
      [id]: {
        ...salesValues[id],
        totalRevenue: value,
      },
    });
  }

  if (categoriesAreLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (!periodId || salesError?.graphQLErrors[0]?.extensions.status === 404 || categoriesError?.graphQLErrors[0]?.extensions.status === 404) {
    return (
      <StandardGrid>
        <NotFound />
      </StandardGrid>
    );
  }

  if ((!salesAreLoading && !salesData?.inventorySalesPeriod) || !categoriesData?.itemCategories) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Sales Periods' to='/sales' />
          <Breadcrumb label={periodInfo.name} to={`/sales/${periodInfo.id}`} />
          <Breadcrumb label='Edit Item Sales' />
        </BreadcrumbGroup>
      </Cell>

      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
          <StyledHeading tag='h3'>Edit Item Sales</StyledHeading>
          {editItemSalesIsLoading ?
            <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
              <CircularSpinner size='xsmall' />
              <StyledParagraph style={{ color: Colors.neutral700 }}>Saving changes...</StyledParagraph>
            </View>
            :
            <View>
              <StyledParagraph style={{ color: Colors.neutral700 }}>All changes saved</StyledParagraph>
            </View>
          }
        </View>
      </Cell>

      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
            <MultiSelect name='categoriesFilter' value={selectedCategories} onChange={(_, value) => { setSelectedCategories(value); setPage(0); }} entityLabel='categories' placeholder='Filter by category' style={{ width: '240px' }}>
              {categoriesData?.itemCategories.map((category) => {
                return (
                  <Choice label={category.name} value={category.id} key={category.id} />
                );
              })}
            </MultiSelect>
            <Checkbox label='Display vendor items' name='includeVendorItems' checked={includeVendorItems} onChange={(_, value) => { setIncludeVendorItems(value); setPage(0); }} />
          </View>
          <PageButtons currentPage={page} numPages={salesData?.inventorySalesPeriod?.salesByItemPageCount || 0} onPageChange={setPage} />
        </View>
      </Cell>

      <Cell lg={12} md={8} sm={4}>
        <Card size='medium'>
          {salesAreLoading ?
            <AtomSpinner size='medium' />
            :
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Item</TableHeaderCell>
                  <TableHeaderCell>Sales Category</TableHeaderCell>
                  <TableHeaderCell>Units Sold</TableHeaderCell>
                  <TableHeaderCell>Total Revenue</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {salesData?.inventorySalesPeriod?.salesByItem.map((s, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <StyledParagraph style={{ color: Colors.neutral700, fontSize: '14px' }}>
                          <Link href={s.item.__typename === 'InventoryItem' ? `/items/${s.item.id}` : `/recipes/${s.item.recipeCategory.id}/${s.item.id}`} linkStyle={{ maxWidth: '500px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{s.item.name}</Link>
                          {` (${s.item.__typename === 'InventoryItem' ? s.item.vendor.name : s.item.recipeCategory.name})`}
                        </StyledParagraph>
                      </TableCell>
                      <TableCell style={{ maxWidth: '200px', minWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '200px' }}>{s.item.__typename === 'InventoryItem' ? s.item.category.name : s.item.itemCategory.name}</TableCell>
                      <TableCell style={{ padding: '8px', maxWidth: '150px', minWidth: '150px', width: '150px' }}>
                        <TextField type='number' inputMode='decimal' name={s.item.id} value={salesValues[s.item.id]?.unitsSold || ''} onChange={handleUnitsSoldChange} onBlur={handleEditUnitsSold} style={{ width: '100px' }} />
                      </TableCell>
                      <TableCell style={{ padding: '8px', maxWidth: '260px', minWidth: '260px', width: '260px' }}>
                        <TextField type='currency' inputMode='decimal' name={s.item.id} value={salesValues[s.item.id]?.totalRevenue || ''} onChange={handleTotalRevenueChange} onBlur={handleEditTotalRevenue} style={{ maxWidth: '250px', minWidth: '250px', width: '250px' }} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          }
        </Card>
      </Cell>
    </StandardGrid>
  );
}