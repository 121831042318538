import { gql, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, AutoOpen, Button, Card, Cell, Choice, Colors, DatePicker, FormModal, HasProductRole, InventoryRoles, ModalLauncher, PageButtons, Products, SingleSelect, StandardGrid, StyledHeading, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, View } from "@barscience/global-components";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { formatDate } from "../../util/dates";
import { formatDecimalAmount } from "../../util/formatters";

/* Get All Orders Query */
const GET_ALL_ORDERS = gql`
query getAllInventoryOrders($page: Int!, $dateRange: DateRange) {
  inventoryOrders(page: $page, dateRange: $dateRange) {
    orders {
      id
      vendor {
        id
        name
      }
      orderDate
      deliveryDate
      itemCount
      caseCount
      estimatedTotalCost
    }
    pages
  }
}
`;

type GetAllOrdersQueryResponse = {
  inventoryOrders: {
    orders: InventoryOrder[];
    pages: number;
  };
}

type InventoryOrder = {
  id: string;
  vendor: {
    id: string;
    name: string;
  };
  orderDate: string;
  deliveryDate: string;
  itemCount: number;
  caseCount: number;
  estimatedTotalCost: string;
}

type DateRange = {
  startDate: string;
  endDate: string;
}

/* Create Order Mutation */
const CREATE_ORDER_MUTATION = gql`
mutation createInventoryOrder($input: CreateInventoryOrderInput!) {
  order: createInventoryOrder(input: $input) {
    id
    vendor {
      id
      name
    }
    orderDate
    deliveryDate
    itemCount
    caseCount
    estimatedTotalCost
  }
}
`;

type CreateOrderMutationResponse = {
  order: InventoryOrder;
}

type CreateOrderInput = {
  vendorId: string;
  orderDate: string;
  deliveryDate: string;
}

/* Get Vendors Query */
const GET_ALL_VENDORS = gql`
query getVendorsForCreateOrder {
  vendors {
    id
    name
  }
}
`;

type GetAllVendorsResponse = {
  vendors: Vendor[];
}

type Vendor = {
  id: string;
  name: string;
}

export default function AllOrders() {
  const [page, setPage] = useState<number>(0);
  const [dateRange, setDateRange] = useState<DateRange | null>(null);
  const [createdOrderId, setCreatedOrderId] = useState<string | null>(null);
  const [selectedRange, setSelectedRange] = useState<string | null>('ALL_TIME');
  const { data: ordersData, loading: ordersAreLoading, refetch: refetchOrders } = useQuery<GetAllOrdersQueryResponse>(GET_ALL_ORDERS, {
    variables: {
      page: page,
      dateRange: selectedRange === 'CUSTOM' ? dateRange : null,
    },
    fetchPolicy: 'network-only',
  });
  const { data: vendorsData, loading: vendorsAreLoading } = useQuery<GetAllVendorsResponse>(GET_ALL_VENDORS);
  const [createOrder] = useMutation<CreateOrderMutationResponse>(CREATE_ORDER_MUTATION);

  useEffect(() => {
    refetchOrders({
      page: page,
      dateRange: selectedRange === 'CUSTOM' ? dateRange : null,
    });
  }, [selectedRange, dateRange, page, refetchOrders]);

  const handleDateFilterChange = (_: string, value: string | null) => {
    setSelectedRange(value);
  }

  const handleDateRangePick = async (values: DateRange) => {
    setDateRange({
      startDate: values.startDate,
      endDate: values.endDate,
    });

    setSelectedRange('CUSTOM');
  }

  const handleCancelDateRangePick = () => {
    setSelectedRange('ALL_TIME');
  }

  const getDateLabel = (): string => {
    if (!dateRange) {
      return '';
    }

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const start = new Date(dateRange.startDate);
    const end = new Date(dateRange.endDate);

    const label = `${months[start.getMonth()]} ${start.getDate()}${getDayLabel(start.getDate())} - ${months[end.getMonth()]} ${end.getDate()}${getDayLabel(end.getDate())}`;

    return label;
  }

  const getDayLabel = (day: number) => {
    const str = day.toString();
    if (str.charAt(str.length - 1) === '1') {
      return 'st';
    } else if (str.charAt(str.length - 1) === '2') {
      return 'nd';
    } if (str.charAt(str.length - 1) === '3') {
      return 'rd';
    } else {
      return 'th';
    }
  }

  const handleCreateOrder = async (values: CreateOrderInput) => {
    const date = new Date();
    const orderDate = formatDate(date);

    const { data } = await createOrder({
      variables: {
        input: {
          vendorId: values.vendorId,
          orderDate: orderDate,
          deliveryDate: values.deliveryDate,
        },
      },
    });

    await refetchOrders();

    setCreatedOrderId(data?.order.id || null);
  }

  const customRangeModal = (
    <FormModal<DateRange> title='Select date range' onSubmit={handleDateRangePick} onCancel={handleCancelDateRangePick} showCloseButton={false} submitLabel='Confirm' initialValues={{ startDate: dateRange?.startDate || '', endDate: dateRange?.endDate || '' }}>
      <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 1151px)': { flexDirection: 'column' } }}>
        <DatePicker label='Start Date' name='startDate' required />
        <DatePicker label='End Date' name='endDate' required />
      </View>
    </FormModal>
  );

  const createOrderModal = (
    <FormModal<CreateOrderInput> title='Create Order' onSubmit={handleCreateOrder} submitLabel='Create' initialValues={{ vendorId: '', orderDate: '', deliveryDate: '' }}>
      <View style={{ gap: '16px' }}>
        <SingleSelect label='Vendor' name='vendorId' required>
          {vendorsData?.vendors.map((v, index) => {
            return (
              <Choice label={v.name} value={v.id} key={index} />
            );
          })}
        </SingleSelect>
        <DatePicker label='Delivery Date' name='deliveryDate' required />
      </View>
    </FormModal>
  );

  if (createdOrderId) {
    return (
      <Navigate to={'/orders/' + createdOrderId + '/edit'} />
    );
  }

  return (
    <StandardGrid>
      <ModalLauncher modal={customRangeModal}>
        {({ openModal: openPickDateModal }) => {
          if (selectedRange === 'SET_CUSTOM') {
            return <AutoOpen openModal={openPickDateModal} />
          }

          return <></>
        }}
      </ModalLauncher>

      <Cell lg={12} md={8} sm={4}>
        <View style={{ gap: '16px' }}>
          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
            <StyledHeading tag='h3'>Orders</StyledHeading>
            <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
              <ModalLauncher modal={createOrderModal}>
                {({ openModal }) => (
                  <Button label='Create Order' variant='primary' role='button' action={openModal} />
                )}
              </ModalLauncher>
            </HasProductRole>
          </View>
          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
            <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
              <SingleSelect name='dateRange' value={selectedRange} onChange={handleDateFilterChange} style={{ width: '225px' }}>
                <Choice label='All Time' value='ALL_TIME' />
                {dateRange && <Choice label={getDateLabel()} value='CUSTOM' />}
                <View style={{ borderTop: `1px solid ${Colors.neutral200}`, marginTop: '4px', paddingTop: '4px' }}>
                  <Choice label='Custom Range' value='SET_CUSTOM' />
                </View>
              </SingleSelect>
            </View>
            <PageButtons currentPage={page} numPages={ordersData?.inventoryOrders.pages || 0} onPageChange={setPage} />
          </View>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {(ordersAreLoading || vendorsAreLoading) ?
          <AtomSpinner size='large' />
          :
          (
            <Card size='medium'>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell style={{ minWidth: '200px' }}>Vendor</TableHeaderCell>
                    <TableHeaderCell style={{ minWidth: '100px' }}>Delivery Date</TableHeaderCell>
                    <TableHeaderCell style={{ minWidth: '100px' }}>Order Date</TableHeaderCell>
                    <TableHeaderCell style={{ minWidth: '50px' }}># Items</TableHeaderCell>
                    <TableHeaderCell style={{ minWidth: '55px' }}># Cases</TableHeaderCell>
                    <TableHeaderCell style={{ minWidth: '200px' }}>Estimated Cost</TableHeaderCell>
                    <TableHeaderCell></TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {ordersData?.inventoryOrders.orders.map((order, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{order.vendor.name}</TableCell>
                        <TableCell>{order.deliveryDate}</TableCell>
                        <TableCell>{order.orderDate}</TableCell>
                        <TableCell>{order.itemCount}</TableCell>
                        <TableCell>{formatDecimalAmount(order.caseCount)}</TableCell>
                        <TableCell>{order.estimatedTotalCost}</TableCell>
                        <TableCell><Button label='View Order' variant='tertiary' role='link' href={'/orders/' + order.id} /></TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Card>
          )
        }
      </Cell>
    </StandardGrid>
  );
}