import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Choice, CircularSpinner, Colors, ConfirmModal, FormModal, FormModalValueProvider, generateId, HasProductRole, InfoPanel, InventoryRoles, Link, ModalLauncher, NotFound, Products, Row, SingleSelect, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextEditor, TextField, useAlertState, View } from "@barscience/global-components";
import { useNavigate, useParams } from "react-router-dom";
import { parseVolumeUnits, parseWeightUnits } from "../../util/measurements";
import currency from "currency.js";
import { convertCurrencyToFloat } from "../../util/currency";
import { GET_RECIPE_CATEGORY, GetRecipeCategoryResponse } from "./RecipeCategoryDetail";
import { ToolbarButtonConfig } from "@barscience/global-components/dist/typings/TextEditor/Toolbar/Toolbar";

const GET_RECIPE = gql`
query getRecipe($id: ID!) {
  recipe(id: $id) {
    id
    name
    recipeCategory {
      id
      name
    }
    itemCategory {
      id
      name
    }
    unitsPerBatch
    unitVolume
    unitVolumeType
    unitWeight
    unitWeightType
    batchCost
    salesPrice {
      batchPrice
      unitPrice
    }
    ingredients {
      item {
        ... on InventoryItem {
          id
          name
          currentPrice {
            unitPrice
          }
          unitVolume
          unitWeight
        }
        ... on Recipe {
          id
          name
          recipeCategory {
            id
          }
          batchCost
          unitsPerBatch
          unitVolume
          unitWeight
        }
      }
      amount
      amountType {
        ... on RecipeItemWeight {
          weightUnits: units
        }
        ... on RecipeItemVolume {
          volumeUnits: units
        }
        ... on RecipeItemUnits {
          units
        }
      }
      rawAmount
    }
    directions
    isArchived
  }
}
`;

type GetRecipeResponse = {
  recipe: Recipe | null;
}

type Recipe = {
  id: string;
  name: string;
  recipeCategory: {
    id: string;
    name: string;
  };
  itemCategory: {
    id: string;
    name: string;
  };
  unitsPerBatch: number;
  unitVolume: number | null;
  unitVolumeType: string | null;
  unitWeight: number | null;
  unitWeightType: string | null;
  batchCost: string;
  salesPrice: {
    batchPrice: string;
    unitPrice: string;
  };
  ingredients: Ingredient[];
  directions: string;
  isArchived: boolean;
}

type Ingredient = {
  item: IngredientItem;
  amount: number;
  amountType: AmountType;
  rawAmount: number;
}

type IngredientItem = Item | RecipeAsIngredient;

type Item = {
  id: string;
  name: string;
  currentPrice: {
    unitPrice: string;
  };
  unitVolume: number | null;
  unitWeight: number | null;
  __typename: 'InventoryItem'
}

type RecipeAsIngredient = {
  id: string;
  name: string;
  recipeCategory: {
    id: string;
  };
  batchCost: string;
  unitsPerBatch: number;
  unitVolume: number | null;
  unitWeight: number | null;
  __typename: 'Recipe'
}

type AmountType =
  | {
    weightUnits: string;
    __typename: 'RecipeItemWeight';
  }
  | {
    volumeUnits: string;
    __typename: 'RecipeItemVolume';
  }
  | {
    units: string;
    __typename: 'RecipeItemUnits';
  }

/* Add Ingredient Mutation */
type AddIngredientInput = {
  itemId: string;
  amount: string;
  inputType: string;
  volumeUnits: string;
  weightUnits: string;
}

const ADD_INGREDIENT_INPUT_INITIAL_VALUES = {
  itemId: '',
  amount: '',
  inputType: 'UNITS',
  volumeUnits: '',
  weightUnits: '',
}

const ADD_INGREDIENT = gql`
mutation addRecipeIngredient($recipeId: ID!, $itemId: ID!, $input: AddRecipeIngredientInput!) {
  addRecipeIngredient(recipeId: $recipeId, itemId: $itemId, input: $input) {
    item {
      ... on InventoryItem {
        id
      }
      ... on Recipe {
        id
      }
    }
    rawAmount
    amount
    amountType {
      ... on RecipeItemWeight {
        weightUnits: units
      }
      ... on RecipeItemVolume {
        volumeUnits: units
      }
      ... on RecipeItemUnits {
        units
      }
    }
  }
}
`;

type AddIngredientResponse = {
  addRecipeIngredient: Ingredient;
}

/* Edit Ingredient Mutation */
const EDIT_INGREDIENT = gql`
mutation editRecipeIngredient($recipeId: ID!, $itemId: ID!, $input: EditRecipeIngredientInput!) {
  editRecipeIngredient(recipeId: $recipeId, itemId: $itemId, input: $input) {
    item {
      ... on InventoryItem {
        id
      }
      ... on Recipe {
        id
      }
    }
    rawAmount
    amount
    amountType {
      ... on RecipeItemWeight {
        weightUnits: units
      }
      ... on RecipeItemVolume {
        volumeUnits: units
      }
      ... on RecipeItemUnits {
        units
      }
    }
  }
}
`;

type EditIngredientResponse = {
  editRecipeIngredient: Ingredient;
}

type EditIngredientInput = {
  itemId: string;
  amount: string;
  inputType: string;
  volumeUnits: string;
  weightUnits: string;
}

/* Get Items Query */
const GET_ITEMS = gql`
query getItemsToAddToRecipe($page: Int!, $name: String) {
  inventoryItems(page: $page, name: $name) {
    items {
      id
      name
      unitVolume
      unitVolumeType
      unitWeight
      unitWeightType
    }
  }
}
`;

type GetItemsQueryResponse = {
  inventoryItems: {
    items: {
      id: string;
      name: string;
      unitVolume: number | null;
      unitVolumeType: string | null;
      unitWeight: number | null;
      unitWeightType: string | null;
    }[];
  }
}

/* Get Recipes Query */
const GET_RECIPES = gql`
query getRecipesToAddToRecipe($name: String!) {
  recipes(name: $name) {
    id
    name
    unitVolume
    unitVolumeType
    unitWeight
    unitWeightType
  }
}
`;

type GetRecipesResponse = {
  recipes: {
    id: string;
    name: string;
    unitVolume: number | null;
    unitVolumeType: string | null;
    unitWeight: number | null;
    unitWeightType: string | null;
  }[];
}

/* Edit Recipe Mutation */
const EDIT_RECIPE = gql`
mutation editRecipe($recipeId: ID!, $input: EditRecipeInput!) {
  editRecipe(recipeId: $recipeId, input: $input) {
    id
    name
    recipeCategory {
      id
      name
    }
    salesPrice {
      batchPrice
      unitPrice
    }
    itemCategory {
      id
      name
    }
  }
}
`;

type EditRecipeResponse = {
  editRecipe: {
    id: String;
    name: string;
    recipeCategory: {
      id: string;
      name: string;
    };
    salesPrice: {
      batchPrice: string;
      unitPrice: string;
    };
    itemCategory: {
      id: string;
      name: string;
    };
  };
}

type EditRecipeInput = {
  name: string;
  recipeCategoryId: string;
  batchSalesPrice: string;
}

/* Get Recipe Categories Query */
const GET_RECIPE_CATEGORIES = gql`
query getAllRecipeCategories {
  recipeCategories {
    id
    name
    description
  }
}
`;

type GetAllRecipeCategoriesResponse = {
  recipeCategories: {
    id: string;
    name: string;
    description: string;
  }[];
}

/* Archive Recipe Mutation */
const ARCHIVE_RECIPE = gql`
mutation archiveRecipe($id: ID!) {
  archiveRecipe(id: $id) {
    id
    isArchived
  }
}
`;

type ArchiveRecipeResponse = {
  archiveRecipe: {
    id: string;
    isArchived: boolean;
  };
}

/* Edit Directions Mutation */
const EDIT_DIRECTIONS = gql`
mutation editRecipeDirections($recipeId: ID!, $directions: String!) {
  editRecipeDirections(recipeId: $recipeId, directions: $directions) {
    id
    directions
  }
}
`;

type EditDirectionsResponse = {
  editRecipeDirections: {
    id: string;
    directions: string;
  };
}

type EditDirectionsInput = {
  directions: string;
}

const directionEditorConfig: ToolbarButtonConfig = {
  undo: true,
  redo: true,
  bold: true,
  italic: true,
  underline: true,
  strikethrough: true,
  unorderedList: true,
  orderedList: true,
  quote: false,
  code: false,
  subscript: false,
  superscript: false,
  clearFormat: true,
  alignment: false,
  headings: true,
  link: false,
  youtube: false,
  infoPanel: false,
  image: false,
  table: false
}

export default function RecipeDetail() {
  const navigate = useNavigate();
  const { recipeId } = useParams();
  const { addAlert } = useAlertState();
  const { data: recipeData, loading: recipeIsLoading, error: recipeError, refetch: refetchRecipe } = useQuery<GetRecipeResponse>(GET_RECIPE, {
    variables: {
      id: recipeId,
    },
    errorPolicy: 'all',
  });
  const { data: recipeCategoriesData, loading: recipeCategoriesAreLoading } = useQuery<GetAllRecipeCategoriesResponse>(GET_RECIPE_CATEGORIES);
  const [getItemsToAdd, { data: itemSearchData, loading: itemSearchIsLoading }] = useLazyQuery<GetItemsQueryResponse>(GET_ITEMS);
  const [getRecipesToAdd, { data: recipeSearchData, loading: recipeSearchIsLoading }] = useLazyQuery<GetRecipesResponse>(GET_RECIPES);
  const [addIngredient] = useMutation<AddIngredientResponse>(ADD_INGREDIENT);
  const [editIngredient] = useMutation<EditIngredientResponse>(EDIT_INGREDIENT);
  const [editRecipe] = useMutation<EditRecipeResponse>(EDIT_RECIPE, {
    update(cache, { data }) {
      // If the recipe is moved to a different category, update the cache
      if (!data || data?.editRecipe.recipeCategory.id === recipeData?.recipe?.recipeCategory.id) {
        return;
      }

      // Remove the recipe from the old recipe category
      const oldRecipeCategory = cache.readQuery<GetRecipeCategoryResponse>({
        query: GET_RECIPE_CATEGORY,
        variables: {
          id: recipeData?.recipe?.recipeCategory.id,
        },
      });

      if (oldRecipeCategory?.recipeCategory) {
        cache.writeQuery<GetRecipeCategoryResponse>({
          query: GET_RECIPE_CATEGORY,
          variables: {
            id: recipeData?.recipe?.recipeCategory.id,
          },
          data: {
            recipeCategory: {
              ...oldRecipeCategory.recipeCategory,
              recipes: oldRecipeCategory.recipeCategory.recipes.filter((recipe) => recipe.id !== recipeData?.recipe?.id),
            },
          },
        });
      }

      // Add the recipe to the new recipe category
      const newRecipeCategory = cache.readQuery<GetRecipeCategoryResponse>({
        query: GET_RECIPE_CATEGORY,
        variables: {
          id: data?.editRecipe.recipeCategory.id,
        },
      });

      if (newRecipeCategory?.recipeCategory) {
        const recipe = data.editRecipe as Recipe;

        const newRecipes = [
          ...newRecipeCategory.recipeCategory.recipes,
          recipe,
        ];

        cache.writeQuery<GetRecipeCategoryResponse>({
          query: GET_RECIPE_CATEGORY,
          variables: {
            id: data?.editRecipe.recipeCategory.id,
          },
          data: {
            recipeCategory: {
              ...newRecipeCategory.recipeCategory,
              recipes: newRecipes,
            },
          },
        });
      }

    },
  });
  const [editDirections] = useMutation<EditDirectionsResponse>(EDIT_DIRECTIONS);
  const [archiveRecipe] = useMutation<ArchiveRecipeResponse>(ARCHIVE_RECIPE);

  if (recipeIsLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (!recipeId || recipeError?.graphQLErrors[0]?.extensions?.status === 404) {
    return (
      <StandardGrid>
        <NotFound />
      </StandardGrid>
    );
  }

  const getUnitSizes = () => {
    let unitSizes = '';

    if (recipeData?.recipe?.unitVolume) {
      const label = parseVolumeUnits(recipeData.recipe.unitVolumeType, recipeData.recipe.unitVolume);
      unitSizes += `${recipeData.recipe.unitVolume} ${label}`;
    }

    if (recipeData?.recipe?.unitWeight) {
      const label = parseWeightUnits(recipeData.recipe.unitWeightType, recipeData.recipe.unitWeight);
      if (unitSizes) {
        unitSizes += ' | ';
      }

      unitSizes += `${recipeData.recipe.unitWeight} ${label}`;
    }

    return unitSizes;
  }

  const getIngredientSizes = (ingredient: Ingredient): string => {
    let label = '';

    if (ingredient.amountType.__typename === 'RecipeItemUnits') {
      label = `unit${ingredient.amount > 1 ? 's' : ''}`;
    } else if (ingredient.amountType.__typename === 'RecipeItemVolume') {
      label = parseVolumeUnits(ingredient.amountType.volumeUnits, ingredient.amount);
    } else if (ingredient.amountType.__typename === 'RecipeItemWeight') {
      label = parseWeightUnits(ingredient.amountType.weightUnits, ingredient.amount);
    } else {
      label = 'unknown type'
    }

    return `${ingredient.amount} ${label}`;
  }

  const getIngredientCost = (ingredient: Ingredient): string => {
    if (ingredient.item.__typename === 'InventoryItem') {
      return currency(ingredient.item.currentPrice.unitPrice).multiply(ingredient.rawAmount).format();
    } else if (ingredient.item.__typename === 'Recipe') {
      return currency(ingredient.item.batchCost).divide(ingredient.item.unitsPerBatch).multiply(ingredient.rawAmount).format();
    } else {
      return '';
    }
  }

  const calculatePercentCost = (): string => {
    const batchCost = convertCurrencyToFloat(recipeData?.recipe?.batchCost || '');
    const salesPrice = convertCurrencyToFloat(recipeData?.recipe?.salesPrice.batchPrice || '');

    if (!batchCost || !salesPrice) {
      return '----';
    }

    const pc = ((batchCost / salesPrice) * 100).toFixed(2);

    return pc;
  }

  const handleAddIngredient = async (values: AddIngredientInput) => {
    const { errors } = await addIngredient({
      variables: {
        recipeId: recipeId,
        itemId: values.itemId,
        input: {
          amount: parseFloat(values.amount),
          volumeUnits: values.inputType === 'VOLUME' ? values.volumeUnits : null,
          weightUnits: values.inputType === 'WEIGHT' ? values.weightUnits : null,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to update recipe ingredients' type='error' description={errors[0].message} id={id} />
      addAlert(id, alert, 60);
    }

    refetchRecipe();
  }

  const getSelectedItem = (itemId: string) => {
    let foundItem = null;

    foundItem = itemSearchData?.inventoryItems.items.find((item) => (
      item.id === itemId
    ));

    if (!foundItem) {
      foundItem = recipeSearchData?.recipes.find((recipe) => (
        recipe.id === itemId
      ));
    }

    return foundItem;
  }

  const getIngredientById = (itemId: string) => {
    return recipeData?.recipe?.ingredients.find((item) => (
      item.item.id === itemId
    ));
  }

  const handleEditIngredient = async (values: EditIngredientInput) => {
    const { errors } = await editIngredient({
      variables: {
        recipeId: recipeId,
        itemId: values.itemId,
        input: {
          amount: parseFloat(values.amount),
          volumeUnits: values.inputType === 'VOLUME' ? values.volumeUnits : null,
          weightUnits: values.inputType === 'WEIGHT' ? values.weightUnits : null,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to update recipe ingredients' type='error' description={errors[0].message} id={id} />
      addAlert(id, alert);
    }

    refetchRecipe();
  }

  const handleRemoveIngredient = async (itemId: string) => {
    const { errors } = await editIngredient({
      variables: {
        recipeId: recipeId,
        itemId: itemId,
        input: {
          amount: 0,
          volumeUnits: null,
          weightUnits: null,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to update recipe ingredients' type='error' description={errors[0].message} id={id} />
      addAlert(id, alert);
    }

    refetchRecipe();
  }

  const handleEditRecipe = async (values: EditRecipeInput) => {
    const previousCategoryId = recipeData?.recipe?.recipeCategory.id;

    await editRecipe({
      variables: {
        recipeId: recipeId,
        input: {
          name: values.name,
          recipeCategoryId: values.recipeCategoryId,
          batchSalesPrice: values.batchSalesPrice,
        },
      },
    });

    if (previousCategoryId !== values.recipeCategoryId) {
      navigate(`/recipes/${values.recipeCategoryId}/${recipeId}`);
    }
  }

  const handleArchiveRecipe = async () => {
    if (!recipeId) {
      return;
    }

    const { errors } = await archiveRecipe({
      variables: {
        id: recipeId,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to archive recipe' type='error' description={errors[0].message} id={id} />
      addAlert(id, alert);
    }
  }

  const handleEditDirections = async (values: EditDirectionsInput) => {
    const { errors } = await editDirections({
      variables: {
        recipeId: recipeId,
        directions: values.directions,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to update recipe directions' type='error' description={errors[0].message} id={id} />
      addAlert(id, alert);
    }
  }

  const addIngredientModal = (
    <FormModal<AddIngredientInput> title='Add Ingredient' onSubmit={handleAddIngredient} initialValues={ADD_INGREDIENT_INPUT_INITIAL_VALUES} submitLabel='Add Ingredient'>
      <FormModalValueProvider>
        {({ getValue, setError }) => (
          <View style={{ gap: '16px' }}>
            <SingleSelect
              label='Item or Recipe'
              name='itemId'
              placeholder='Choose an item or recipe'
              onFilter={(_: string, value: string) => {
                getItemsToAdd({
                  variables: {
                    name: value,
                    page: 0,
                  },
                });
                getRecipesToAdd({
                  variables: {
                    name: value,
                  },
                });
              }}
              filterable
              filterPlaceholder='Enter item or recipe name'
              required
              autoFocusSearch
            >
              {(itemSearchIsLoading || recipeSearchIsLoading) ?
                <View style={{ alignItems: 'center', padding: '16px' }}>
                  <CircularSpinner size='medium' />
                </View>
                :
                <>
                  {((itemSearchData && itemSearchData.inventoryItems.items.length > 0) || (recipeSearchData && recipeSearchData.recipes.length > 0)) ?
                    (
                      <View style={{ gap: '16px' }}>
                        {(() => {
                          const items = itemSearchData?.inventoryItems.items.filter((item) => {
                            // Filter out items that are already assigned as an ingredient
                            let found = false;
                            recipeData?.recipe?.ingredients.forEach((i) => {
                              if (i.item.id === item.id) {
                                found = true;
                              }
                            })

                            return !found;
                          });

                          if (!items || items.length === 0) {
                            return null;
                          }

                          return (
                            <View>
                              <StyledHeading tag='h6' style={{ paddingBottom: '4px', paddingLeft: '32px' }}>Items</StyledHeading>
                              {items.map((item, index) =>
                                <Choice label={item.name} value={item.id} key={index} />
                              )
                              }
                            </View>
                          );
                        })()}

                        {(() => {
                          const recipes = recipeSearchData?.recipes.filter((recipe) => {
                            // Hide the current recipe from the list
                            if (recipe.id === recipeId) {
                              return false;
                            }

                            // Filter out recipes that are already assigned as an ingredient
                            let found = false;
                            recipeData?.recipe?.ingredients.forEach((i) => {
                              if (i.item.id === recipe.id) {
                                found = true;
                              }
                            });

                            return !found;
                          });

                          if (!recipes || recipes.length === 0) {
                            return null;
                          }

                          return (
                            <View>
                              <StyledHeading tag='h6' style={{ paddingBottom: '4px', paddingLeft: '32px' }}>Recipes</StyledHeading>
                              {recipes.map((recipe, index) =>
                                <Choice label={recipe.name} value={recipe.id} key={index} />
                              )}
                            </View>
                          );

                        })()}
                      </View>
                    )
                    :
                    <View style={{ padding: '16px' }}>
                      <StyledParagraph>No items or recipes found.</StyledParagraph>
                    </View>
                  }
                </>
              }
            </SingleSelect>
            <View>
              <SingleSelect label='Enter By' name='inputType' style={{ width: '50%', '@media (max-width: 767px)': { width: '100%' } }} required validate={(_, value) => {
                if (value === 'UNITS') {
                  setError && setError('volumeUnits', null);
                  setError && setError('weightUnits', null);
                } else if (value === 'VOLUME') {
                  setError && setError('weightUnits', null);
                } else if (value === 'WEIGHT') {
                  setError && setError('volumeUnits', null);
                }

                return null;
              }}>
                <Choice label='Units' value='UNITS' />

                {(getValue && getSelectedItem(getValue('itemId'))?.unitVolume) && <Choice label='Volume' value='VOLUME' />}
                {(getValue && getSelectedItem(getValue('itemId'))?.unitWeight) && <Choice label='Weight' value='WEIGHT' />}
              </SingleSelect>
            </View>
            <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
              <TextField label='Amount' name='amount' type='number' inputMode='decimal' required style={{ flexGrow: 0, width: '50%', '@media (max-width: 767px)': { width: '100%' } }} />
              {(getValue && getValue('inputType') === 'VOLUME') &&
                <SingleSelect label='Units' name='volumeUnits' required style={{ width: '50%', '@media (max-width: 767px)': { width: '100%' } }} >
                  <Choice label='tsp' value='TSP' />
                  <Choice label='tbsp' value='TBSP' />
                  <Choice label='fl oz' value='FLUID_OZ' />
                  <Choice label='cups' value='CUP' />
                  <Choice label='pints' value='PINT' />
                  <Choice label='quarts' value='QUART' />
                  <Choice label='gallons' value='GALLON' />
                  <Choice label='liters' value='LITER' />
                  <Choice label='milliliters' value='MILLILITER' />
                </SingleSelect>
              }
              {(getValue && getValue('inputType') === 'WEIGHT') &&
                <SingleSelect label='Units' name='weightUnits' required style={{ width: '50%', '@media (max-width: 767px)': { width: '100%' } }} >
                  <Choice label='oz' value='OUNCE' />
                  <Choice label='lbs' value='POUND' />
                  <Choice label='g' value='GRAM' />
                  <Choice label='kg' value='KILOGRAM' />
                </SingleSelect>
              }
            </View>
            <InfoPanel type='warning'>
              <View style={{ gap: '16px' }}>
                <StyledParagraph>If making significant changes to the ingredients, consider archiving this recipe and making a new one instead.</StyledParagraph>
                <Link href='https://support.barscience.us/help-center/articles/2e3bb02f-68b1-41d6-b1df-685aa73560f1' target='_blank'>Learn more here</Link>
              </View>
            </InfoPanel>
          </View>
        )}
      </FormModalValueProvider>
    </FormModal>
  );

  const editIngredientModal = (
    <FormModal<EditIngredientInput> title='Edit Ingredient' onSubmit={handleEditIngredient} initialValues={{ itemId: '', amount: '', inputType: 'UNITS', volumeUnits: '', weightUnits: '' }}>
      <FormModalValueProvider>
        {({ getValue, setError }) => (
          <View style={{ gap: '16px' }}>
            <View>
              <SingleSelect label='Enter By' name='inputType' style={{ width: '50%', '@media (max-width: 767px)': { width: '100%' } }} required validate={(_, value) => {
                if (value === 'UNITS') {
                  setError && setError('volumeUnits', null);
                  setError && setError('weightUnits', null);
                } else if (value === 'VOLUME') {
                  setError && setError('weightUnits', null);
                } else if (value === 'WEIGHT') {
                  setError && setError('volumeUnits', null);
                }

                return null;
              }}>
                <Choice label='Units' value='UNITS' />

                {(getValue && getIngredientById(getValue('itemId'))?.item.unitVolume) && <Choice label='Volume' value='VOLUME' />}
                {(getValue && getIngredientById(getValue('itemId'))?.item.unitWeight) && <Choice label='Weight' value='WEIGHT' />}
              </SingleSelect>
            </View>
            <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
              <TextField label='Amount' name='amount' type='number' inputMode='decimal' required style={{ flexGrow: 0, width: '50%', '@media (max-width: 767px)': { width: '100%' } }} />
              {(getValue && getValue('inputType') === 'VOLUME') &&
                <SingleSelect label='Units' name='volumeUnits' required style={{ width: '50%', '@media (max-width: 767px)': { width: '100%' } }} >
                  <Choice label='tsp' value='TSP' />
                  <Choice label='tbsp' value='TBSP' />
                  <Choice label='fl oz' value='FLUID_OZ' />
                  <Choice label='cups' value='CUP' />
                  <Choice label='pints' value='PINT' />
                  <Choice label='quarts' value='QUART' />
                  <Choice label='gallons' value='GALLON' />
                  <Choice label='liters' value='LITER' />
                  <Choice label='milliliters' value='MILLILITER' />
                </SingleSelect>
              }
              {(getValue && getValue('inputType') === 'WEIGHT') &&
                <SingleSelect label='Units' name='weightUnits' required style={{ width: '50%', '@media (max-width: 767px)': { width: '100%' } }} >
                  <Choice label='oz' value='OUNCE' />
                  <Choice label='lbs' value='POUND' />
                  <Choice label='g' value='GRAM' />
                  <Choice label='kg' value='KILOGRAM' />
                </SingleSelect>
              }
            </View>
            <InfoPanel type='warning'>
              <View style={{ gap: '16px' }}>
                <StyledParagraph>If making significant changes to the ingredients, consider archiving this recipe and making a new one instead.</StyledParagraph>
                <Link href='https://support.barscience.us/help-center/articles/2e3bb02f-68b1-41d6-b1df-685aa73560f1' target='_blank'>Learn more here</Link>
              </View>
            </InfoPanel>
          </View>
        )}
      </FormModalValueProvider>
    </FormModal>
  );

  const removeIngredientModal = (
    <ConfirmModal title='Remove Ingredient?' onConfirm={handleRemoveIngredient} confirmLabel='Remove' destructive>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>The ingredient will be removed from this recipe. This will affect the recipe cost and item usage history.</StyledParagraph>
        <InfoPanel type='warning'>
          <View style={{ gap: '16px' }}>
            <StyledParagraph>If making significant changes to the ingredients, consider archiving this recipe and making a new one instead.</StyledParagraph>
            <Link href='https://support.barscience.us/help-center/articles/2e3bb02f-68b1-41d6-b1df-685aa73560f1' target='_blank'>Learn more here</Link>
          </View>
        </InfoPanel>
      </View>
    </ConfirmModal>
  );

  const editRecipeModal = (
    <FormModal<EditRecipeInput> title='Edit Recipe' onSubmit={handleEditRecipe} initialValues={{ name: recipeData?.recipe?.name || '', recipeCategoryId: recipeData?.recipe?.recipeCategory.id || '', batchSalesPrice: recipeData?.recipe?.salesPrice.batchPrice || '' }}>
      {recipeCategoriesAreLoading ?
        <View style={{ alignItems: 'center' }}>
          <CircularSpinner size='medium' />
        </View>
        :
        (
          <View style={{ gap: '16px' }}>
            <TextField label='Recipe Name' name='name' type='text' required />
            <SingleSelect label='Recipe Category' name='recipeCategoryId' required >
              {recipeCategoriesData?.recipeCategories.map((c, index) => {
                return (
                  <Choice label={c.name} value={c.id} key={index} />
                );
              })}
            </SingleSelect>
            <TextField label='Batch Sales Price' name='batchSalesPrice' type='currency' inputMode='decimal' required validate={(_, value) => {
              if (currency(value).value < 0) {
                return 'Must be greater than 0'
              }
              return null;
            }} />
          </View>
        )
      }
    </FormModal>
  );

  const archiveRecipeModal = (
    <ConfirmModal title='Archive Recipe?' onConfirm={handleArchiveRecipe} confirmLabel='Archive' destructive>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This will remove the recipe from the recipe list and all item count and prep screens.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  const editDirectionsModal = (
    <FormModal<EditDirectionsInput> title='Edit Directions' onSubmit={handleEditDirections} initialValues={{ directions: recipeData?.recipe?.directions || '' }} style={{ width: 'min(90vw, 800px)', maxWidth: 'min(90vw, 800px)', height: 'min(90vh, 800px)', maxHeight: 'min(90vh, 800px)' }}>
      <TextEditor name='directions' isEditMode editorStyle={{ height: 'min(50vh, 480px)' }} buttonConfig={directionEditorConfig} />
    </FormModal>
  );

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Recipes' to='/recipes' />
          <Breadcrumb label={recipeData?.recipe?.recipeCategory.name || ''} to={`/recipes/${recipeData?.recipe?.recipeCategory.id}`} />
          <Breadcrumb label={recipeData?.recipe?.name || ''} />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
            <StyledHeading tag='h3'>{recipeData?.recipe?.name}</StyledHeading>
            {recipeData?.recipe?.isArchived && <StyledParagraph bold style={{ backgroundColor: Colors.error600, borderRadius: '4px', color: Colors.shades0, fontSize: '12px', padding: '4px 8px' }}>ARCHIVED</StyledParagraph>}
          </View>

          <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
            <ModalLauncher modal={editRecipeModal}>
              {({ openModal: openEditModal }) => (
                <ModalLauncher modal={archiveRecipeModal}>
                  {({ openModal: openArchiveModal }) => (
                    <ActionMenu alignment='right'>
                      <ActionItem label='Edit' onClick={openEditModal} />
                      {!recipeData?.recipe?.isArchived &&
                        <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Admin]}>
                          <ActionItem label='Archive' onClick={openArchiveModal} />
                        </HasProductRole>
                      }
                    </ActionMenu>
                  )}
                </ModalLauncher>
              )}
            </ModalLauncher>
          </HasProductRole>
        </View>
      </Cell>
      <Row>
        <Cell lg={6} md={4} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '32px' }}>
              <StyledHeading tag='h5'>Recipe Info</StyledHeading>
              <View style={{ flexDirection: 'row', gap: '32px' }}>
                <View style={{ gap: '4px' }}>
                  <StyledHeading tag='h6'>Recipe Category</StyledHeading>
                  <StyledParagraph>{recipeData?.recipe?.recipeCategory.name}</StyledParagraph>
                </View>
                <View style={{ gap: '4px' }}>
                  <StyledHeading tag='h6'>Item Category</StyledHeading>
                  <StyledParagraph>{recipeData?.recipe?.itemCategory.name}</StyledParagraph>
                </View>
              </View>
              <View style={{ flexDirection: 'row', gap: '32px' }}>
                <View style={{ gap: '4px' }}>
                  <StyledHeading tag='h6'>Units Per Batch</StyledHeading>
                  <StyledParagraph>{recipeData?.recipe?.unitsPerBatch}</StyledParagraph>
                </View>
                <View style={{ gap: '4px' }}>
                  <StyledHeading tag='h6'>Unit Size</StyledHeading>
                  <StyledParagraph>{getUnitSizes()}</StyledParagraph>
                </View>
              </View>
            </View>
          </Card>
        </Cell>
        <Cell lg={6} md={4} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '32px' }}>
              <StyledHeading tag='h5'>Costs</StyledHeading>
              <View style={{ flexDirection: 'row', gap: '32px' }}>
                <View style={{ gap: '4px' }}>
                  <StyledHeading tag='h6'>Batch Cost</StyledHeading>
                  <StyledParagraph>{recipeData?.recipe?.batchCost}</StyledParagraph>
                </View>
                <View style={{ gap: '4px' }}>
                  <StyledHeading tag='h6'>Sales Price</StyledHeading>
                  <StyledParagraph>{recipeData?.recipe?.salesPrice.batchPrice}</StyledParagraph>
                </View>
                <View style={{ gap: '4px' }}>
                  <StyledHeading tag='h6'>PC</StyledHeading>
                  <StyledParagraph>{calculatePercentCost()}%</StyledParagraph>
                </View>
              </View>
            </View>
          </Card>
        </Cell>
      </Row>
      <Row>
        <Cell lg={6} md={8} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '32px' }}>
              <View style={{ flexDirection: 'row', gap: '16px' }}>
                <StyledHeading tag='h5'>Directions</StyledHeading>
                <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
                  <ModalLauncher modal={editDirectionsModal}>
                    {({ openModal }) => (
                      <Button label='Edit' variant='tertiary' action={openModal} role='button' />
                    )}
                  </ModalLauncher>
                </HasProductRole>
              </View>
              <View>
                <TextEditor name='directions-view' isEditMode={false} value={recipeData?.recipe?.directions} containerStyle={{ height: 'fit-content', maxHeight: '800px', overflow: 'scroll' }} editorStyle={{ minHeight: '0' }} />
              </View>
            </View>
          </Card>
        </Cell>
        <Cell lg={6} md={8} sm={4}>
          <Card size='medium'>
            <View>
              <View style={{ flexDirection: 'row', gap: '16px' }}>
                <StyledHeading tag='h5'>Ingredients</StyledHeading>
                <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
                  <ModalLauncher modal={addIngredientModal}>
                    {({ openModal }) => (
                      <Button label='Add Ingredient' variant='tertiary' action={openModal} role='button' />
                    )}
                  </ModalLauncher>
                </HasProductRole>
              </View>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>Item</TableHeaderCell>
                    <TableHeaderCell>Amount</TableHeaderCell>
                    <TableHeaderCell>Cost</TableHeaderCell>
                    <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
                      <TableHeaderCell style={{ margin: '0', padding: '0', width: '8px' }}></TableHeaderCell>
                    </HasProductRole>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {recipeData?.recipe?.ingredients.map((ingredient, index) => {
                    let amountType = 'UNITS';
                    let volumeUnits = '';
                    let weightUnits = '';
                    if (ingredient.amountType.__typename === 'RecipeItemVolume') {
                      amountType = 'VOLUME';
                      volumeUnits = ingredient.amountType.volumeUnits;
                    } else if (ingredient.amountType.__typename === 'RecipeItemWeight') {
                      amountType = 'WEIGHT';
                      weightUnits = ingredient.amountType.weightUnits;
                    }

                    return (
                      <TableRow key={index}>
                        <TableCell><Link href={ingredient.item.__typename === 'InventoryItem' ? `/items/${ingredient.item.id}` : `/recipes/${ingredient.item.recipeCategory.id}/${ingredient.item.id}`}>{ingredient.item.name}</Link></TableCell>
                        <TableCell>{getIngredientSizes(ingredient)}</TableCell>
                        <TableCell>{getIngredientCost(ingredient)}</TableCell>
                        <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
                          <TableCell style={{ margin: '0', padding: '0', width: '8px' }}>
                            <ModalLauncher modal={editIngredientModal}>
                              {({ openModal: openEditModal }) => (
                                <ModalLauncher modal={removeIngredientModal}>
                                  {({ openModal: openRemoveModal }) => (
                                    <ActionMenu alignment='right'>
                                      <ActionItem label='Edit' onClick={() => { openEditModal({ itemId: ingredient.item.id, amount: ingredient.amount, inputType: amountType, volumeUnits: volumeUnits, weightUnits: weightUnits }); }} />
                                      <ActionItem label='Remove' onClick={() => { openRemoveModal(ingredient.item.id); }} />
                                    </ActionMenu>
                                  )}
                                </ModalLauncher>
                              )}
                            </ModalLauncher>
                          </TableCell>
                        </HasProductRole>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </View>
          </Card>
        </Cell>
      </Row>
    </StandardGrid>
  );
}