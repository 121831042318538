import { gql, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Button, Card, Cell, Colors, ConfirmModal, FormModal, HasProductRole, InventoryRoles, Link, ModalLauncher, Products, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextArea, TextField, View } from "@barscience/global-components";
import { StyleSheet, css } from "aphrodite";

/* Par Lists Query */
const GET_PAR_LISTS = gql`
query getItemParLists {
  itemParLists {
    id
    name
    description
    isDefault
  }
}
`;

type GetParListsQueryResponse = {
  itemParLists: ParList[];
}

type ParList = {
  id: string;
  name: string;
  description: string | null;
  isDefault: boolean;
}

/* Set Default List Mutation */
const SET_DEFAULT_PAR_LIST_MUTATION = gql`
mutation setDefaultParList($id: ID!) {
  setDefaultParList(id: $id) {
    id
    name
    description
    isDefault
  }
}
`;

type SetDefaultParListMutationResponse = {
  setDefaultParList: ParList;
}

const styles = StyleSheet.create({
  defaultLabel: {
    backgroundColor: Colors.primary500,
    borderRadius: '4px',
    color: Colors.shades0,
    fontSize: '12px',
    marginLeft: '16px',
    padding: '4px 8px',
    textTransform: 'uppercase',
  },
});

/* Create Par List Mutation */
const CREATE_PAR_LIST_MUTATION = gql`
mutation createParList($input: CreateParListInput!) {
  createParList(input: $input) {
    id
    name
    description
    isDefault
  }
}
`;

type CreateParListMutationResponse = {
  createParList: ParList;
}

type CreateParListInput = {
  name: string;
  description: string;
}

/* Edit Par List Mutation */
const EDIT_PAR_LIST_MUTATION = gql`
mutation editParList($id: ID!, $input: EditParListInput!) {
  editParList(id: $id, input: $input) {
    id
    name
    description
    isDefault
  }
}
`;

type EditParListMutationResponse = {
  editParList: ParList;
}

type EditParListInput = {
  id: string;
  name: string;
  description: string;
}

/* Delete Par List Mutation */
const DELETE_PAR_LIST_MUTATION = gql`
mutation deleteParList($id: ID!) {
  deleteParList(id: $id) {
    id
    name
    description
    isDefault
  }
}
`;

type DeleteParListMutationResponse = {
  deleteParList: ParList;
}

export default function AllPars() {
  const { data, loading, refetch: refetchParLists } = useQuery<GetParListsQueryResponse>(GET_PAR_LISTS);
  const [createParList] = useMutation<CreateParListMutationResponse>(CREATE_PAR_LIST_MUTATION, {
    update(cache, { data: createParListData }) {
      cache.updateQuery<GetParListsQueryResponse, GetParListsQueryResponse>({ query: GET_PAR_LISTS }, (data) => {
        if (!data || !createParListData) {
          return undefined;
        }

        const newParLists = [...data.itemParLists, createParListData.createParList];
        newParLists.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });

        return {
          itemParLists: newParLists,
        };
      });
    },
  });
  const [editParList] = useMutation<EditParListMutationResponse>(EDIT_PAR_LIST_MUTATION);
  const [deleteParList] = useMutation<DeleteParListMutationResponse>(DELETE_PAR_LIST_MUTATION, {
    update(cache, { data: deleteParListData }, { variables }) {
      cache.updateQuery<GetParListsQueryResponse, GetParListsQueryResponse>({ query: GET_PAR_LISTS }, (data) => {
        if (!data || !variables || !deleteParListData) {
          return undefined;
        }

        const newParLists = data.itemParLists.filter((list) => {
          return list.id !== variables.id;
        });

        return {
          itemParLists: newParLists,
        };
      });
    },
  });
  const [setDefaultList] = useMutation<SetDefaultParListMutationResponse>(SET_DEFAULT_PAR_LIST_MUTATION);

  const handleSetDefaultList = async (id: string) => {
    await setDefaultList({
      variables: {
        id: id,
      },
    });

    await refetchParLists();
  }

  const handleCreateParList = async (values: CreateParListInput) => {
    await createParList({
      variables: {
        input: {
          name: values.name,
          description: values.description,
        },
      },
    });
  }

  const handleEditParList = async (values: EditParListInput) => {
    await editParList({
      variables: {
        id: values.id,
        input: {
          name: values.name,
          description: values.description,
        },
      },
    });
  }

  const handleDeleteParList = async (id: string) => {
    await deleteParList({
      variables: {
        id: id,
      },
    });
  }

  const changeDefaultListModal = (
    <ConfirmModal title='Change default par list?' onConfirm={handleSetDefaultList} confirmLabel='Set as default'>
      <StyledParagraph>This will update the default list for all employees of your organization.</StyledParagraph>
    </ConfirmModal>
  );

  const createParListModal = (
    <FormModal<CreateParListInput> title='Create Par List' submitLabel='Create' onSubmit={handleCreateParList} initialValues={{ name: '', description: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Name' name='name' type='text' required />
        <TextArea label='Description' name='description' rows={5} disableResizing />
      </View>
    </FormModal>
  );

  const editParListModal = (
    <FormModal<EditParListInput> title='Edit Par List' submitLabel='Save' onSubmit={handleEditParList} initialValues={{ id: '', name: '', description: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Name' name='name' type='text' required />
        <TextArea label='Description' name='description' rows={5} disableResizing />
      </View>
    </FormModal>
  );

  const deleteParListModal = (
    <ConfirmModal title='Delete par list?' onConfirm={handleDeleteParList} confirmLabel='Delete' destructive>
      <StyledParagraph>This will permanently delete this par list.</StyledParagraph>
    </ConfirmModal>
  );

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', '@media (max-width: 767px)': { flexDirection: 'column', gap: '16px' } }}>
          <View style={{ gap: '8px' }}>
            <StyledHeading tag='h3'>Par Lists</StyledHeading>
            <StyledParagraph>Create multiple par lists based on varying levels of sales activity.</StyledParagraph>
          </View>
          <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
            <ModalLauncher modal={createParListModal}>
              {({ openModal }) => (
                <Button label='Create Par List' variant='primary' role='button' action={openModal} />
              )}
            </ModalLauncher>
          </HasProductRole>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <Card size='medium'>
          {loading && <AtomSpinner size='large' />}
          {!loading &&
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell style={{ minWidth: '250px', width: '250px' }}>Name</TableHeaderCell>
                  <TableHeaderCell style={{ minWidth: '250px' }}>Description</TableHeaderCell>
                  <TableHeaderCell style={{ margin: '0', padding: '0', width: '16px' }}></TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {data?.itemParLists.map((l, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell><Link href={'/par-lists/' + l.id}>{l.name}</Link>{l.isDefault && <span className={css(styles.defaultLabel)}>Default</span>}</TableCell>
                      <TableCell>{l.description}</TableCell>
                      <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
                        <TableCell style={{ margin: '0', padding: '0', width: '16px' }}>
                          <ModalLauncher modal={changeDefaultListModal}>
                            {({ openModal: openSetDefaultModal }) => (
                              <ModalLauncher modal={editParListModal}>
                                {({ openModal: openEditModal }) => (
                                  <ModalLauncher modal={deleteParListModal}>
                                    {({ openModal: openDeleteModal }) => (
                                      <ActionMenu alignment='right'>
                                        {!l.isDefault ? <ActionItem label='Set as default' onClick={() => { openSetDefaultModal(l.id); }} /> : <></>}
                                        <ActionItem label='Edit' onClick={() => { openEditModal({ id: l.id, name: l.name, description: l.description }); }} />
                                        <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
                                          {!l.isDefault && <ActionItem label='Delete' onClick={() => { openDeleteModal(l.id); }} />}
                                        </HasProductRole>
                                      </ActionMenu>
                                    )}
                                  </ModalLauncher>
                                )}
                              </ModalLauncher>
                            )}
                          </ModalLauncher>
                        </TableCell>
                      </HasProductRole>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          }
        </Card>
      </Cell>
    </StandardGrid>
  );
}