export function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

/**
 * Formats a DATE string to the form of MM/DD/YYYY
 * 
 * Note: This only works for dates---the string cannot include a time or timezone.
 */
export function formatDate(date: Date) {  
  return [
    padTo2Digits(date.getUTCMonth() + 1),
    padTo2Digits(date.getUTCDate()),
    date.getUTCFullYear(),
  ].join('/');
}