import { gql, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Choice, CircularSpinner, Colors, ConfirmModal, DatePicker, ErrorPage, FormModal, FormModalValueProvider, HasProductRole, InventoryRoles, Link, ModalLauncher, NotFound, PageButtons, Products, Row, SingleSelect, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextArea, TextField, View } from "@barscience/global-components";
import currency from "currency.js";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { parseVolumeUnits, parseWeightUnits } from "../../util/measurements";

/* Item Query */
const GET_ITEM_QUERY = gql`
query getInventoryItem($id: ID!) {
  item: inventoryItem(id: $id) {
    id
    name
    description
    isArchived
    unitEmptyWeight
    unitVolume
    unitVolumeType
    unitWeight
    unitWeightType
    unitsPerCase
    vendorItemId
    unitDeposit
    vendor {
      id
      name
    }
    category {
      id
      name
    }
  }
}
`;

type GetItemQueryResponse = {
  item: Item;
}

type Item = {
  id: string;
  name: string;
  description: string | null;
  isArchived: boolean;
  unitEmptyWeight: number | null;
  unitVolume: number | null;
  unitVolumeType: string | null;
  unitWeight: number | null;
  unitWeightType: string | null;
  unitsPerCase: number;
  vendorItemId: string | null;
  unitDeposit: string | null;
  vendor: {
    id: string;
    name: string;
  };
  category: {
    id: string;
    name: string;
  };
}

/* Get Price History Query */
const GET_PRICE_HISTORY = gql`
query getInventoryItemPriceHistory($id: ID!, $priceHistoryPage: Int!) {
  item: inventoryItem(id: $id) {
    id
    priceHistory(page: $priceHistoryPage) {
      pages
      prices {
        casePrice
        unitPrice
        startDate
      }
    }
  }
}
`;

type GetPriceHistoryQueryResponse = {
  item: {
    id: string;
    priceHistory: {
      pages: number;
      prices: ItemPrice[];
    };
  };
}

type ItemPrice = {
  casePrice: string;
  unitPrice: string;
  startDate: string;
}

/* Get Purchase History Query */
const GET_PURCHASE_HISTORY = gql`
query getInventoryItemPurchaseHistory($id: ID!, $purchaseLogPage: Int!) {
  item: inventoryItem(id: $id) {
    id
    purchaseHistory(page: $purchaseLogPage) {
      logs {
        date
        cases
        casePrice
        invoice {
          id
        }
      }
      pages
    }
  }
}
`;

type GetPurchaseHistoryQueryResponse = {
  item: {
    id: string;
    purchaseHistory: {
      logs: ItemPurchaseLog[];
      pages: number;
    };
  };
}

type ItemPurchaseLog = {
  date: string;
  cases: number;
  casePrice: string;
  invoice: {
    id: string;
  };
}

/* Create Price Change Mutation */
const CREATE_PRICE_CHANGE_MUTATION = gql`
mutation createInventoryItemPriceChange($itemId: ID!, $input: CreateInventoryItemPriceChangeInput!) {
  priceChange: createInventoryItemPriceChange(itemId: $itemId, input: $input) {
    casePrice
    unitPrice
    startDate
  }
}
`;

type CreatePriceChangeMutationResponse = {
  priceChange: ItemPrice;
}

type CreatePriceChangeInput = {
  enterBy: string;
  casePrice: string;
  unitPrice: string;
  startDate: string;
}

const createPriceChangeInitialValues = {
  enterBy: 'CASE',
  casePrice: '',
  unitPrice: '',
  startDate: '',
}

/* Edit Price Change Mutation */
const EDIT_PRICE_CHANGE = gql`
mutation editInventoryItemPriceChange($itemId: ID!, $startDate: Date!, $input: EditInventoryItemPriceChangeInput!) {
  priceChange: editInventoryItemPriceChange(itemId: $itemId, startDate: $startDate, input: $input) {
    casePrice
    startDate
    unitPrice
  }
}
`;

type EditPriceChangeMutationResponse = {
  priceChange: ItemPrice;
}

type EditPriceChangeInput = {
  enterBy: string;
  casePrice: string;
  unitPrice: string;
  startDate: string;
  initialStartDate: string;
}

const editPriceChangeInitialValues: EditPriceChangeInput = {
  enterBy: 'CASE',
  casePrice: '',
  unitPrice: '',
  startDate: '',
  initialStartDate: '',
}

/* Delete Price Change Mutation */
const DELETE_PRICE_CHANGE = gql`
mutation deleteInventoryItemPriceChange($itemId: ID!, $startDate: Date!) {
  priceChange: deleteInventoryItemPriceChange(itemId: $itemId, startDate: $startDate) {
    casePrice
    unitPrice
    startDate
  }
}
`;

type DeletePriceChangeMutationResponse = {
  priceChange: ItemPrice;
}

/* Edit Item Mutation */
const EDIT_ITEM_MUTATION = gql`
mutation editInventoryItem($id: ID!, $input: EditInventoryItemInput!) {
  editInventoryItem(id: $id, input: $input) {
    id
    name
    description
    vendorItemId
    unitDeposit
  }
}
`;

type EditItemMutationResponse = {
  editInventoryItem: {
    id: string;
    name: string;
    description: string;
    vendorItemId: string;
    unitDeposit: number;
  };
}

type EditItemInput = {
  name: string;
  description: string;
  vendorItemId: string;
  unitDeposit: string;
}

/* Archive Item Mutation */
const ARCHIVE_ITEM_MUTATION = gql`
mutation archiveInventoryItem($id: ID!) {
  archiveInventoryItem(id: $id) {
    id
    isArchived
  }
}
`;

type ArchiveItemMutationResponse = {
  archiveInventoryItem: {
    id: string;
    isArchived: boolean;
  };
}

export default function ItemDetails() {
  const { itemId } = useParams();
  const [priceHistoryPage, setPriceHistoryPage] = useState<number>(0);
  const [purchaseLogPage, setPurchaseLogPage] = useState<number>(0);
  const { loading: itemIsLoading, data: itemData, error: itemError, refetch: refetchItem } = useQuery<GetItemQueryResponse>(GET_ITEM_QUERY, {
    variables: {
      id: itemId,
    },
  });
  const { loading: priceHistoryIsLoading, data: priceHistoryData, error: priceHistoryError } = useQuery<GetPriceHistoryQueryResponse>(GET_PRICE_HISTORY, {
    variables: {
      id: itemId,
      priceHistoryPage: priceHistoryPage,
    },
  });
  const { loading: purchaseHistoryIsLoading, data: purchaseHistoryData, error: purchaseHistoryError } = useQuery<GetPurchaseHistoryQueryResponse>(GET_PURCHASE_HISTORY, {
    variables: {
      id: itemId,
      purchaseLogPage: purchaseLogPage,
    },
  });
  const [createPriceChange] = useMutation<CreatePriceChangeMutationResponse>(CREATE_PRICE_CHANGE_MUTATION);
  const [editPriceChange] = useMutation<EditPriceChangeMutationResponse>(EDIT_PRICE_CHANGE);
  const [deletePriceChange] = useMutation<DeletePriceChangeMutationResponse>(DELETE_PRICE_CHANGE);
  const [editItem] = useMutation<EditItemMutationResponse>(EDIT_ITEM_MUTATION);
  const [archiveItem] = useMutation<ArchiveItemMutationResponse>(ARCHIVE_ITEM_MUTATION);

  if (itemIsLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (!itemId || itemError?.graphQLErrors[0].extensions.status === 404) {
    return (
      <StandardGrid>
        <NotFound />
      </StandardGrid>
    );
  }

  if (!itemData || priceHistoryError || purchaseHistoryError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  const handleCreatePriceChange = async (values: CreatePriceChangeInput) => {
    if (values.enterBy === 'CASE') {
      await createPriceChange({
        variables: {
          itemId: itemId,
          input: {
            casePrice: values.casePrice,
            startDate: values.startDate,
            unitPrice: (currency(values.casePrice).divide(itemData.item.unitsPerCase)).format(),
          },
        },
      });

    } else {
      await createPriceChange({
        variables: {
          itemId: itemId,
          input: {
            casePrice: (currency(values.unitPrice).multiply(itemData.item.unitsPerCase)).format(),
            startDate: values.startDate,
            unitPrice: values.unitPrice,
          },
        },
      });
    }

    await refetchItem();
  }

  const handleEditPriceChange = async (values: EditPriceChangeInput) => {
    if (values.enterBy === 'CASE') {
      await editPriceChange({
        variables: {
          itemId: itemId,
          startDate: values.initialStartDate,
          input: {
            casePrice: values.casePrice,
            startDate: values.startDate,
            unitPrice: (currency(values.casePrice).divide(itemData.item.unitsPerCase)).format(),
          },
        },
      });

    } else {
      await editPriceChange({
        variables: {
          itemId: itemId,
          startDate: values.initialStartDate,
          input: {
            casePrice: (currency(values.unitPrice).multiply(itemData.item.unitsPerCase)).format(),
            startDate: values.startDate,
            unitPrice: values.unitPrice,
          },
        },
      });
    }

    await refetchItem();
  }

  const handleDeletePriceChange = async (startDate: string) => {
    await deletePriceChange({
      variables: {
        itemId: itemId,
        startDate: startDate,
      },
    });

    await refetchItem();
  }

  const handleEditItem = async (values: EditItemInput) => {
    let unitDeposit = null;
    if (values.unitDeposit !== '') {
      unitDeposit = values.unitDeposit;
    }

    await editItem({
      variables: {
        id: itemId,
        input: {
          name: values.name,
          description: values.description,
          vendorItemId: values.vendorItemId,
          unitDeposit: unitDeposit,
        },
      },
    });
  }

  const handleArchiveItem = async () => {
    await archiveItem({
      variables: {
        id: itemId,
        priceHistoryPage: priceHistoryPage,
      },
    });
  }

  const createPriceChangeModal = (
    <FormModal<CreatePriceChangeInput> title='Create Price Change' submitLabel='Create' onSubmit={handleCreatePriceChange} initialValues={createPriceChangeInitialValues}>
      <FormModalValueProvider>
        {({ getValue, setError }) => (
          <View style={{ gap: '32px' }}>
            <SingleSelect label='Enter By' name='enterBy' validate={(_, value) => {
              if (value === 'CASE') {
                setError && setError('unitPrice', null);
              } else {
                setError && setError('casePrice', null);
              }

              return null;
            }}>
              <Choice label='Case Price' value='CASE' />
              <Choice label='Unit Price' value='UNITS' />
            </SingleSelect>
            {(getValue && getValue('enterBy') === 'CASE') &&
              <View style={{ gap: '16px' }}>
                <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 1151px)': { flexDirection: 'column' } }}>
                  <TextField label='Case Price' name='casePrice' type='currency' inputMode='decimal' style={{ flex: '1 1 0px', '@media (max-width: 1151px)': { flex: '0' } }} required />
                  <DatePicker label='Effective Date' name='startDate' style={{ flex: '1 1 0px', '@media (max-width: 1151px)': { flex: '0' } }} required />
                </View>
                <View style={{ flexDirection: 'row', gap: '16px' }}>
                  <View style={{ flex: '1 1 0px' }}>
                    <StyledParagraph bold>Unit Price</StyledParagraph>
                    <StyledParagraph>{getValue('casePrice') ? currency(getValue('casePrice')).divide(itemData.item.unitsPerCase).format() : '---'}</StyledParagraph>
                  </View>
                  <View style={{ flex: '1 1 0px' }}>
                    <StyledParagraph bold>Units Per Case</StyledParagraph>
                    <StyledParagraph>{itemData.item.unitsPerCase}</StyledParagraph>
                  </View>
                </View>
              </View>
            }
            {(getValue && getValue('enterBy') === 'UNITS') &&
              <View style={{ gap: '16px' }}>
                <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 1151px)': { flexDirection: 'column' } }}>
                  <TextField label='Unit Price' name='unitPrice' type='currency' inputMode='decimal' style={{ flex: '1 1 0px', '@media (max-width: 1151px)': { flex: '0' } }} required />
                  <DatePicker label='Effective Date' name='startDate' style={{ flex: '1 1 0px', '@media (max-width: 1151px)': { flex: '0' } }} required />
                </View>
                <View style={{ flexDirection: 'row', gap: '16px' }}>
                  <View style={{ flex: '1 1 0px' }}>
                    <StyledParagraph bold>Case Price</StyledParagraph>
                    <StyledParagraph>{getValue('unitPrice') ? currency(getValue('unitPrice')).multiply(itemData.item.unitsPerCase).format() : '---'}</StyledParagraph>
                  </View>
                  <View style={{ flex: '1 1 0px' }}>
                    <StyledParagraph bold>Units Per Case</StyledParagraph>
                    <StyledParagraph>{itemData.item.unitsPerCase}</StyledParagraph>
                  </View>
                </View>
              </View>
            }
          </View>
        )}
      </FormModalValueProvider>
    </FormModal>
  );

  const editPriceChangeModal = (
    <FormModal<EditPriceChangeInput> title='Edit Price Change' submitLabel='Save' onSubmit={handleEditPriceChange} initialValues={editPriceChangeInitialValues}>
      <FormModalValueProvider>
        {({ getValue, setError }) => (
          <View style={{ gap: '32px' }}>
            <SingleSelect label='Enter By' name='enterBy' validate={(_, value) => {
              if (value === 'CASE') {
                setError && setError('unitPrice', null);
              } else {
                setError && setError('casePrice', null);
              }

              return null;
            }}>
              <Choice label='Case Price' value='CASE' />
              <Choice label='Unit Price' value='UNITS' />
            </SingleSelect>
            {(getValue && getValue('enterBy') === 'CASE') &&
              <View style={{ gap: '16px' }}>
                <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 1151px)': { flexDirection: 'column' } }}>
                  <TextField label='Case Price' name='casePrice' type='currency' inputMode='decimal' style={{ flex: '1 1 0px', '@media (max-width: 1151px)': { flex: '0' } }} required />
                  <DatePicker label='Effective Date' name='startDate' style={{ flex: '1 1 0px', '@media (max-width: 1151px)': { flex: '0' } }} required />
                </View>
                <View style={{ flexDirection: 'row', gap: '16px' }}>
                  <View style={{ flex: '1 1 0px' }}>
                    <StyledParagraph bold>Unit Price</StyledParagraph>
                    <StyledParagraph>{getValue('casePrice') ? (currency(getValue('casePrice')).divide(itemData.item.unitsPerCase)).format() : '---'}</StyledParagraph>
                  </View>
                  <View style={{ flex: '1 1 0px' }}>
                    <StyledParagraph bold>Units Per Case</StyledParagraph>
                    <StyledParagraph>{itemData.item.unitsPerCase}</StyledParagraph>
                  </View>
                </View>
              </View>
            }
            {(getValue && getValue('enterBy') === 'UNITS') &&
              <View style={{ gap: '16px' }}>
                <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 1151px)': { flexDirection: 'column' } }}>
                  <TextField label='Unit Price' name='unitPrice' type='currency' inputMode='decimal' style={{ flex: '1 1 0px', '@media (max-width: 1151px)': { flex: '0' } }} required />
                  <DatePicker label='Effective Date' name='startDate' style={{ flex: '1 1 0px', '@media (max-width: 1151px)': { flex: '0' } }} required />
                </View>
                <View style={{ flexDirection: 'row', gap: '16px' }}>
                  <View style={{ flex: '1 1 0px' }}>
                    <StyledParagraph bold>Case Price</StyledParagraph>
                    <StyledParagraph>{getValue('unitPrice') ? (currency(getValue('unitPrice')).multiply(itemData.item.unitsPerCase)).format() : '---'}</StyledParagraph>
                  </View>
                  <View style={{ flex: '1 1 0px' }}>
                    <StyledParagraph bold>Units Per Case</StyledParagraph>
                    <StyledParagraph>{itemData.item.unitsPerCase}</StyledParagraph>
                  </View>
                </View>
              </View>
            }
          </View>
        )}
      </FormModalValueProvider>
    </FormModal>
  );

  const deletePriceChangeModal = (
    <ConfirmModal title='Delete item price?' onConfirm={handleDeletePriceChange} confirmLabel='Delete' destructive>
      <StyledParagraph>Are you sure you want to delete this price change?</StyledParagraph>
    </ConfirmModal>
  );

  const editItemModal = (
    <FormModal<EditItemInput> title='Edit Item' initialValues={{ name: '', description: '', vendorItemId: '', unitDeposit: '' }} onSubmit={handleEditItem}>
      <View style={{ gap: '16px' }}>
        <TextField label='Name' name='name' />
        <TextArea label='Description' name='description' disableResizing />
        <TextField label='Vendor Item ID' name='vendorItemId' />
        <TextField label='Unit Deposit' type='currency' inputMode='decimal' name='unitDeposit' />
      </View>
    </FormModal>
  );

  const archiveItemModal = (
    <ConfirmModal title='Archive item?' confirmLabel='Archive' onConfirm={handleArchiveItem} destructive>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>Are you sure you want to archive this item? This action cannot be undone.</StyledParagraph>
        <StyledParagraph>This will remove the item from all item count and invoice screens. The data associated with this item will not be deleted.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ gap: '16px' }}>
          <BreadcrumbGroup>
            <Breadcrumb label='Items' to='/items' />
            <Breadcrumb label={itemData.item.name} />
          </BreadcrumbGroup>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
              <StyledHeading tag='h3'>{itemData.item.name}</StyledHeading>
              {itemData.item.isArchived && <StyledParagraph bold style={{ backgroundColor: Colors.error600, borderRadius: '4px', color: Colors.shades0, fontSize: '12px', padding: '4px 8px' }}>ARCHIVED</StyledParagraph>}
            </View>
            <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
              <ModalLauncher modal={editItemModal}>
                {({ openModal: openEditModal }) => (
                  <ModalLauncher modal={archiveItemModal}>
                    {({ openModal: openArchiveModal }) => (
                      <ActionMenu alignment='right'>
                        <ActionItem label='Edit' onClick={() => {
                          openEditModal({
                            name: itemData.item.name,
                            description: itemData.item.description || '',
                            vendorItemId: itemData.item.vendorItemId || '',
                            unitDeposit: itemData.item.unitDeposit || '',
                          });
                        }} />
                        <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Admin]}>
                          {!itemData.item.isArchived &&
                            <ActionItem label='Archive' onClick={openArchiveModal} />
                          }
                        </HasProductRole>
                      </ActionMenu>
                    )}
                  </ModalLauncher>
                )}
              </ModalLauncher>
            </HasProductRole>
          </View>
        </View>
      </Cell>
      <Row>
        <Cell lg={6} md={8} sm={4}>
          <View style={{ gap: '16px' }}>
            <Card size='medium'>
              <View style={{ gap: '16px' }}>
                <StyledHeading tag='h5'>Item Information</StyledHeading>
                <View>
                  <StyledParagraph bold>Description</StyledParagraph>
                  <StyledParagraph>{itemData.item.description || 'None'}</StyledParagraph>
                </View>
                <View>
                  <StyledParagraph bold>Category</StyledParagraph>
                  <StyledParagraph>{itemData.item.category.name}</StyledParagraph>
                </View>
                <View>
                  <StyledParagraph bold>Vendor</StyledParagraph>
                  <Link href={'/vendors/' + itemData.item.vendor.id}><StyledParagraph>{itemData.item.vendor.name}</StyledParagraph></Link>
                </View>
                <View>
                  <StyledParagraph bold>Vendor Item ID</StyledParagraph>
                  <StyledParagraph>{itemData.item.vendorItemId || 'None'}</StyledParagraph>
                </View>
                <View>
                  <StyledParagraph bold>Unit Deposit</StyledParagraph>
                  <StyledParagraph>{itemData.item.unitDeposit ? itemData.item.unitDeposit : 'None'}</StyledParagraph>
                </View>
              </View>
            </Card>
            <Card size='medium'>
              <View style={{ gap: '16px' }}>
                <StyledHeading tag='h5'>Item Measurements</StyledHeading>
                <View>
                  <StyledParagraph bold>Units Per Case</StyledParagraph>
                  <StyledParagraph>{itemData.item.unitsPerCase}</StyledParagraph>
                </View>
                <View>
                  <StyledParagraph bold>Unit Volume</StyledParagraph>
                  <StyledParagraph>{itemData.item.unitVolume ? (itemData.item.unitVolume + ' ' + parseVolumeUnits(itemData.item.unitVolumeType, itemData.item.unitVolume)) : 'Not Enabled'}</StyledParagraph>
                </View>
                <View>
                  <StyledParagraph bold>Unit Weight</StyledParagraph>
                  <StyledParagraph>{itemData.item.unitWeight ? (itemData.item.unitWeight + ' ' + parseWeightUnits(itemData.item.unitWeightType, itemData.item.unitWeight)) : 'Not Enabled'}</StyledParagraph>
                </View>
                <View>
                  <StyledParagraph bold>Empty Weight</StyledParagraph>
                  <StyledParagraph>{itemData.item.unitEmptyWeight ? (itemData.item.unitEmptyWeight + ' ' + parseWeightUnits(itemData.item.unitWeightType, itemData.item.unitEmptyWeight)) : 'Not Enabled'}</StyledParagraph>
                </View>
              </View>
            </Card>
            <Card size='medium'>
              <View style={{ gap: '16px' }}>
                <View style={{ flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
                  <StyledHeading tag='h5'>Price History</StyledHeading>
                  <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
                    <ModalLauncher modal={createPriceChangeModal}>
                      {({ openModal }) => (
                        <Button label='Create Price Change' variant='tertiary' role='button' action={openModal} />
                      )}
                    </ModalLauncher>
                  </HasProductRole>
                </View>
                {
                  priceHistoryIsLoading ?
                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                      <CircularSpinner size='medium' />
                    </View>
                    :
                    <>
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHeaderCell>Effective Date</TableHeaderCell>
                            <TableHeaderCell>Case Price</TableHeaderCell>
                            <TableHeaderCell>Unit Price</TableHeaderCell>
                            <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
                              <TableHeaderCell style={{ padding: '0', width: '16px' }}></TableHeaderCell>
                            </HasProductRole>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {priceHistoryData?.item.priceHistory.prices.map((p, i) => {
                            let date = p.startDate;

                            if (date === '01/01/1900') {
                              date = 'Default Price'
                            }

                            return (
                              <TableRow key={i}>
                                <TableCell>{date}</TableCell>
                                <TableCell>{p.casePrice}</TableCell>
                                <TableCell>{p.unitPrice}</TableCell>
                                <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
                                  <ModalLauncher modal={editPriceChangeModal}>
                                    {({ openModal: openEditModal }) => (
                                      <ModalLauncher modal={deletePriceChangeModal}>
                                        {({ openModal: openDeleteModal }) => (
                                          <TableCell style={{ padding: '0', width: '16px' }}>
                                            {p.startDate !== '01/01/1900' &&
                                              <ActionMenu alignment='right'>
                                                <ActionItem label='Edit' onClick={() => {
                                                  openEditModal({
                                                    enterBy: 'CASE',
                                                    casePrice: p.casePrice,
                                                    unitPrice: p.unitPrice,
                                                    startDate: p.startDate,
                                                    initialStartDate: p.startDate,
                                                  });
                                                }} />
                                                <ActionItem label='Delete' onClick={() => {
                                                  openDeleteModal(p.startDate);
                                                }} />
                                              </ActionMenu>
                                            }
                                          </TableCell>
                                        )}
                                      </ModalLauncher>
                                    )}
                                  </ModalLauncher>
                                </HasProductRole>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                      {(priceHistoryData?.item.priceHistory.pages || 0) > 1 &&
                        <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                          <PageButtons currentPage={priceHistoryPage} numPages={priceHistoryData?.item.priceHistory.pages || 0} onPageChange={setPriceHistoryPage} />
                        </View>
                      }
                    </>
                }
              </View>
            </Card>
          </View>
        </Cell>
        <Cell lg={6} md={8} sm={4}>
          <View style={{ gap: '16px' }}>
            <Card size='medium'>
              <View style={{ gap: '16px' }}>
                <StyledHeading tag='h5'>Purchase History</StyledHeading>
                {purchaseHistoryIsLoading ?
                  <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    <CircularSpinner size='medium' />
                  </View>
                  :
                  <>
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHeaderCell>Delivery Date</TableHeaderCell>
                          <TableHeaderCell># Cases</TableHeaderCell>
                          <TableHeaderCell>Total Cost</TableHeaderCell>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {purchaseHistoryData?.item.purchaseHistory.logs.map((p, index) => (
                          <TableRow key={index}>
                            <TableCell><Link href={'/invoices/' + p.invoice.id}>{p.date}</Link></TableCell>
                            <TableCell>{p.cases}</TableCell>
                            <TableCell>{currency(p.casePrice).multiply(p.cases).format()}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {(purchaseHistoryData?.item.purchaseHistory.pages || 0) > 1 &&
                      <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <PageButtons currentPage={purchaseLogPage} numPages={purchaseHistoryData?.item.purchaseHistory.pages || 0} onPageChange={setPurchaseLogPage} />
                      </View>
                    }
                  </>
                }
              </View>
            </Card>
          </View>
        </Cell>
      </Row>
    </StandardGrid>
  );
}