import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Choice, Colors, ErrorPage, Form, Icon, Icons, InventoryRoles, Link, NoPermission, NotFound, PageButtons, SingleSelect, StandardAlert, StandardGrid, Strut, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, View, generateId, useAlertState, useAuthState, useForm } from "@barscience/global-components";
import { CSSProperties } from "aphrodite";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { parseVolumeUnits, parseWeightUnits } from "../../util/measurements";
import { formatDecimalAmount } from "../../util/formatters";
import { GraphQLError } from "graphql";
import currency from "currency.js";

/* Get Locations Query */
const GET_LOCATIONS_QUERY = gql`
query getItemLocationsForItemCounts {
  inventoryItemLocations(forOrders: false) {
    id
    name
    sublocations(forOrders: false) {
      id
      name
    }
  }
}
`;

type GetLocationsQueryResponse = {
  inventoryItemLocations: ItemLocation[];
}

type ItemLocation = {
  id: string;
  name: string;
  sublocations: ItemSublocationSummary[];
}

type ItemSublocationSummary = {
  id: string;
  name: string;
}

/* Get Sublocation Items Query */
const GET_SUBLOCATION_QUERY = gql`
query getSublocationForItemCounts($sublocationId: ID!) {
  inventoryItemSublocation(id: $sublocationId) {
    id
    name
    items(listType: DEFAULT) {
      ... on RecipeLocationAssignment {
        recipe {
          id
          name
          recipeCategory {
            id
          }
          unitsPerBatch
          unitVolume
          unitVolumeType
          unitWeight
          unitWeightType
        }
      }
      ... on InventoryItemLocationAssignment {
        item {
          id
          name
          unitsPerCase
          unitVolume
          unitVolumeType
          unitWeight
          unitWeightType
        }
      }
    }
  }
}
`;

type GetSublocationQueryResponse = {
  inventoryItemSublocation: ItemSublocation;
}

type ItemSublocation = {
  id: string;
  name: string;
  items: LocationItem[];
}

type LocationItem = ItemLocationAssignment | RecipeLocationAssignment;

type ItemLocationAssignment = {
  item: Item;
  __typename: 'InventoryItemLocationAssignment';
}

type Item = {
  id: string;
  name: string;
  unitsPerCase: number;
  unitVolume: number | null;
  unitVolumeType: string | null;
  unitWeight: number | null;
  unitWeightType: string | null;
}

type RecipeLocationAssignment = {
  recipe: Recipe;
  __typename: 'RecipeLocationAssignment';
}

type Recipe = {
  id: string;
  name: string;
  recipeCategory: {
    id: string;
  };
  unitsPerBatch: number;
  unitVolume: number | null;
  unitVolumeType: string | null;
  unitWeight: number | null;
  unitWeightType: string | null;
}


/* Get Item Count List */
const GET_ITEM_COUNT = gql`
query getItemCountListBasicInfo($id: ID!) {
  itemCountList(id: $id) {
    id
    name
    date
    isLocked
  }
}
`;

type GetItemCountResponse = {
  itemCountList: {
    id: string;
    name: string;
    date: string;
    isLocked: boolean;
  } | null;
}

/* Get Item Counts */
const GET_ITEM_COUNTS_FOR_SUBLOCATION = gql`
query getItemCountsForSublocation($itemCountListId: ID!, $sublocationId: ID!) {
  getItemCountsForSublocation(itemCountListId: $itemCountListId, sublocationId: $sublocationId) {
    item {
      ... on InventoryItem {
        id
        currentPrice {
          casePrice
        }
        unitsPerCase
      }
      ... on Recipe {
        id
        batchCost
        unitsPerBatch
      }
    }
    unitAmount
  }
}
`;

type GetItemCountsForSublocationResponse = {
  getItemCountsForSublocation: ItemCount[] | null;
}

type ItemCount = {
  item: {
    id: string;
    currentPrice?: {
      casePrice: string;
    };
    unitsPerCase?: number;
    batchCost?: string;
    unitsPerBatch?: number;
  };
  unitAmount: number;
}

/* Add Item Amount Mutation */
const ADD_TO_ITEM_COUNT = gql`
mutation addToItemCount($itemCountListId: ID!, $itemId: ID!, $amount: Float!, $sublocationId: ID!, $volumeType: VolumeType, $weightType: WeightType) {
  addToItemCount(itemCountListId: $itemCountListId, itemId: $itemId, amount: $amount, sublocationId: $sublocationId, volumeType: $volumeType, weightType: $weightType) {
    item {
      ... on InventoryItem {
        id
        currentPrice {
          casePrice
        }
        unitsPerCase
      }
    }
    unitAmount
  }
}
`;

type AddToItemCountResponse = {
  addToItemCount: {
    item: {
      id: string;
      currentPrice: {
        casePrice: string;
      };
      unitsPerCase: number;
    };
    unitAmount: number;
  } | null;
}

/* Remove Item Amount Mutation */
const REMOVE_FROM_ITEM_COUNT = gql`
mutation removeFromItemCount($itemCountListId: ID!, $sublocationId: ID!, $itemId: ID!, $amount: Float!, $volumeType: VolumeType, $weightType: WeightType) {
  removeFromItemCount(itemCountListId: $itemCountListId, sublocationId: $sublocationId, itemId: $itemId, amount: $amount, volumeType: $volumeType, weightType: $weightType) {
    item {
      ... on InventoryItem {
        id
        currentPrice {
          casePrice
        }
        unitsPerCase
      }
    }
    unitAmount
  }
}
`;

type RemoveFromItemCountResponse = {
  removeFromItemCount: {
    item: {
      id: string;
      currentPrice: {
        casePrice: string;
      };
      unitsPerCase: number;
    };
    unitAmount: number;
  } | null;
}

/* Clear Item Amount Mutation */
const CLEAR_ITEM_COUNT = gql`
mutation clearItemCount($itemCountListId: ID!, $sublocationId: ID!, $itemId: ID!) {
  clearItemCount(itemCountListId: $itemCountListId, sublocationId: $sublocationId, itemId: $itemId) {
    item {
      ... on InventoryItem {
        id
      }
    }
  }
}
`;

type ClearItemCountResponse = {
  clearItemCount: {
    item: {
      id: string;
    };
  } | null;
}

/* Add Recipe Amount Mutation */
const ADD_TO_RECIPE_COUNT = gql`
mutation addToRecipeCount($itemCountListId: ID!, $sublocationId: ID!, $recipeId: ID!, $amount: Float!, $volumeType: VolumeType, $weightType: WeightType) {
  addToRecipeCount(itemCountListId: $itemCountListId, sublocationId: $sublocationId, recipeId: $recipeId, amount: $amount, volumeType: $volumeType, weightType: $weightType) {
    item {
      ... on Recipe {
        id
        batchCost
        unitsPerBatch
      }
    }
    unitAmount
  }
}
`;

type AddToRecipeCountResponse = {
  addToRecipeCount: {
    item: {
      id: string;
      batchCost: string;
      unitsPerBatch: number;
    };
    unitAmount: number;
  } | null;
}

/* Remove Recipe Amount Mutation */
const REMOVE_FROM_RECIPE_COUNT = gql`
mutation removeFromRecipeCount($itemCountListId: ID!, $sublocationId: ID!, $recipeId: ID!, $amount: Float!, $volumeType: VolumeType, $weightType: WeightType) {
  removeFromRecipeCount(itemCountListId: $itemCountListId, sublocationId: $sublocationId, recipeId: $recipeId, amount: $amount, volumeType: $volumeType, weightType: $weightType) {
    item {
      ... on Recipe {
        id
        batchCost
        unitsPerBatch
      }
    }
    unitAmount
  }
}
`;

type RemoveFromRecipeCountResponse = {
  removeFromRecipeCount: {
    item: {
      id: string;
      batchCost: string;
      unitsPerBatch: number;
    };
    unitAmount: number;
  } | null;
}

/* Clear Recipe Amount Mutation */
const CLEAR_RECIPE_COUNT = gql`
mutation clearRecipeCount($itemCountListId: ID!, $sublocationId: ID!, $recipeId: ID!) {
  clearRecipeCount(itemCountListId: $itemCountListId, sublocationId: $sublocationId, recipeId: $recipeId) {
    item {
      ... on Recipe {
        id
      }
    }
  }
}
`;

type ClearRecipeCountResponse = {
  clearRecipeCount: {
    item: {
      id: string;
    };
  } | null;
}

/* Form Inputs */
type AddItemByUnitsInput = {
  units: string;
  cases: string;
}

type AddItemByVolumeInput = {
  volume: string;
  volumeUnits: string;
}

type AddItemByWeightInput = {
  weight: string;
  weightUnits: string;
}

const styles: { [name: string]: CSSProperties } = {
  hideOverFlowText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  cardContentContainer: {
    flexDirection: 'row',
    gap: '16px',
    height: '100%',
    '@media (max-width: 767px)': {
      flexDirection: 'column',
      overflowY: 'scroll'
    },
  },
  locationsListContainer: {
    borderRight: `1px solid ${Colors.neutral200}`,
    maxWidth: '350px',
    minWidth: '300px',
    overflowY: 'scroll',
    paddingRight: '16px',
    '@media (min-width: 768px)': {
      height: '100%'
    },
    '@media (max-width: 1151px)': {
      display: 'none',
    },
  },
  sublocationContainer: {
    alignItems: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: '56px',
    padding: '8px 16px'
  },
  leftFixedColumn: {
    maxWidth: '200px',
    minWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    zIndex: 2,
  },
}

export default function EditItemCount() {
  const { state } = useAuthState();
  const { addAlert } = useAlertState();
  const { itemCountId } = useParams();
  const [queryParams, setQueryParams] = useSearchParams();
  const locationId = queryParams.get('locationId');
  const sublocationId = queryParams.get('sublocationId');
  const [itemCounts, setItemCounts] = useState<{ [itemId: string]: ItemCount }>({});
  const [openItemIndex, setOpenItemIndex] = useState<number | null>(null);
  const [isFocusMode, setIsFocusMode] = useState<boolean>(false);
  const { data: locationsData, loading: locationsAreLoading } = useQuery<GetLocationsQueryResponse>(GET_LOCATIONS_QUERY, {
    onCompleted: (data) => {
      if (data.inventoryItemLocations.length > 0 && !queryParams.get('locationId')) {
        setQueryParams({
          locationId: data.inventoryItemLocations[0].id,
        });

        if (data.inventoryItemLocations[0].sublocations.length > 0) {
          setQueryParams({
            locationId: data.inventoryItemLocations[0].id,
            sublocationId: data.inventoryItemLocations[0].sublocations[0].id,
          });
        }
      }
    },
  });
  const [getSublocation, { data: sublocationData, loading: sublocationIsLoading }] = useLazyQuery<GetSublocationQueryResponse>(GET_SUBLOCATION_QUERY, {
    onCompleted(data) {
      if (isFocusMode && data.inventoryItemSublocation.items.length > 0) {
        setOpenItemIndex(0);
      }
    },
  });
  const { data: itemCountListData, loading: itemCountListIsLoading, error: itemCountError } = useQuery<GetItemCountResponse>(GET_ITEM_COUNT, {
    variables: {
      id: itemCountId,
    },
  });
  const [lastInputType, setLastInputType] = useState<string>('');
  const [getSublocationItemCounts, { data: itemCountData, loading: itemCountsAreLoading }] = useLazyQuery<GetItemCountsForSublocationResponse>(GET_ITEM_COUNTS_FOR_SUBLOCATION, { fetchPolicy: 'network-only' });
  const [addToItemCount, { loading: addToItemCountIsLoading }] = useMutation<AddToItemCountResponse>(ADD_TO_ITEM_COUNT, {
    update(cache, { data }, { variables }) {
      if (!itemCountData || !variables?.itemId || !data || !data.addToItemCount) {
        return;
      }

      const newData = { ...itemCountData };

      if (!newData.getItemCountsForSublocation) {
        return;
      }

      newData.getItemCountsForSublocation = newData.getItemCountsForSublocation.map((itemCount) => {
        if (itemCount.item.id === variables.itemId) {
          return data.addToItemCount as ItemCount;
        }

        return itemCount;
      });

      cache.writeQuery({
        query: GET_ITEM_COUNTS_FOR_SUBLOCATION,
        variables: {
          itemCountListId: itemCountId,
          sublocationId: sublocationId,
        },
        data: newData,
      });

      itemCounts[variables.itemId] = data.addToItemCount;
    },
  });
  const [removeFromItemCount, { loading: removeFromItemCountIsLoading }] = useMutation<RemoveFromItemCountResponse>(REMOVE_FROM_ITEM_COUNT, {
    update(cache, { data }, { variables }) {
      if (!itemCountData || !variables?.itemId || !data || !data.removeFromItemCount) {
        return;
      }

      const newData = { ...itemCountData };

      if (!newData.getItemCountsForSublocation) {
        return;
      }

      newData.getItemCountsForSublocation = newData.getItemCountsForSublocation.map((itemCount) => {
        if (itemCount.item.id === variables.itemId) {
          return data.removeFromItemCount as ItemCount;
        }

        return itemCount;
      });

      cache.writeQuery({
        query: GET_ITEM_COUNTS_FOR_SUBLOCATION,
        variables: {
          itemCountListId: itemCountId,
          sublocationId: sublocationId,
        },
        data: newData,
      });

      itemCounts[variables.itemId] = data.removeFromItemCount;
    },
  });
  const [clearItemCount, { loading: clearItemCountIsLoading }] = useMutation<ClearItemCountResponse>(CLEAR_ITEM_COUNT, {
    update(cache, { data }, { variables }) {
      if (!itemCountData || !variables?.itemId || !data) {
        return;
      }

      const newData = { ...itemCountData };

      if (!newData.getItemCountsForSublocation) {
        return;
      }

      newData.getItemCountsForSublocation = newData.getItemCountsForSublocation.filter((itemCount) => {
        return itemCount.item.id !== variables.itemId;
      });

      cache.writeQuery({
        query: GET_ITEM_COUNTS_FOR_SUBLOCATION,
        variables: {
          itemCountListId: itemCountId,
          sublocationId: sublocationId,
        },
        data: newData,
      });

      delete itemCounts[variables.itemId];
    },
  });
  const [addToRecipeCount, { loading: addToRecipeCountIsLoading }] = useMutation<AddToRecipeCountResponse>(ADD_TO_RECIPE_COUNT, {
    update(cache, { data }, { variables }) {
      if (!itemCountData || !variables?.recipeId || !data || !data.addToRecipeCount) {
        return;
      }

      const newData = { ...itemCountData };

      if (!newData.getItemCountsForSublocation) {
        return;
      }

      newData.getItemCountsForSublocation = newData.getItemCountsForSublocation.map((itemCount) => {
        if (itemCount.item.id === variables.recipeId) {
          return data.addToRecipeCount as ItemCount;
        }

        return itemCount;
      });

      cache.writeQuery({
        query: GET_ITEM_COUNTS_FOR_SUBLOCATION,
        variables: {
          itemCountListId: itemCountId,
          sublocationId: sublocationId,
        },
        data: newData,
      });

      itemCounts[variables.recipeId] = data.addToRecipeCount;
    },
  });
  const [removeFromRecipeCount, { loading: removeFromRecipeCountIsLoading }] = useMutation<RemoveFromRecipeCountResponse>(REMOVE_FROM_RECIPE_COUNT, {
    update(cache, { data }, { variables }) {
      if (!itemCountData || !variables?.recipeId || !data || !data.removeFromRecipeCount) {
        return;
      }

      const newData = { ...itemCountData };

      if (!newData.getItemCountsForSublocation) {
        return;
      }

      newData.getItemCountsForSublocation = newData.getItemCountsForSublocation.map((itemCount) => {
        if (itemCount.item.id === variables.recipeId) {
          return data.removeFromRecipeCount as ItemCount;
        }

        return itemCount;
      });

      cache.writeQuery({
        query: GET_ITEM_COUNTS_FOR_SUBLOCATION,
        variables: {
          itemCountListId: itemCountId,
          sublocationId: sublocationId,
        },
        data: newData,
      });

      itemCounts[variables.recipeId] = data.removeFromRecipeCount;
    },
  });
  const [clearRecipeCount, { loading: clearRecipeCountIsLoading }] = useMutation<ClearRecipeCountResponse>(CLEAR_RECIPE_COUNT, {
    update(cache, { data }, { variables }) {
      if (!itemCountData || !variables?.recipeId || !data) {
        return;
      }

      const newData = { ...itemCountData };

      if (!newData.getItemCountsForSublocation) {
        return;
      }

      newData.getItemCountsForSublocation = newData.getItemCountsForSublocation.filter((itemCount) => {
        return itemCount.item.id !== variables.recipeId;
      });

      cache.writeQuery({
        query: GET_ITEM_COUNTS_FOR_SUBLOCATION,
        variables: {
          itemCountListId: itemCountId,
          sublocationId: sublocationId,
        },
        data: newData,
      });

      delete itemCounts[variables.recipeId];
    },
  });

  useEffect(() => {
    if (sublocationId !== null) {
      getSublocation({
        variables: {
          sublocationId: sublocationId,
        },
      });

      getSublocationItemCounts({
        variables: {
          itemCountListId: itemCountId,
          sublocationId: sublocationId,
        },
      });
    }
  }, [sublocationId, getSublocation, getSublocationItemCounts, itemCountId]);

  useEffect(() => {
    const counts: { [itemId: string]: ItemCount } = {};

    itemCountData?.getItemCountsForSublocation?.forEach((item) => {
      counts[item.item.id] = item;
    });

    setItemCounts(counts);
  }, [itemCountData]);

  const handleError = (errors: readonly GraphQLError[] | undefined) => {
    if (errors === undefined || errors.length === 0) {
      return;
    }

    const id = generateId();
    const alert = (
      <StandardAlert title='Failed to update item count' description={errors[0].message} type='error' id={id} />
    );

    addAlert(id, alert);
  }

  const handleLocationClick = (id: string) => {
    if (id === locationId) {
      return;
    }

    setQueryParams({
      locationId: id,
    });
  }

  const handleSublocationClick = (id: string) => {
    if (id === sublocationId || !locationId) {
      return;
    }

    setQueryParams({
      locationId: locationId,
      sublocationId: id,
    });

    setOpenItemIndex(null);
  }

  const handleAddItemByUnits = async (values: AddItemByUnitsInput) => {
    if (openItemIndex === null || !sublocationData) {
      return;
    }

    setLastInputType('UNITS');

    const item = sublocationData.inventoryItemSublocation.items[openItemIndex];
    if (item.__typename === 'InventoryItemLocationAssignment') {
      let amount = 0;

      const cases = parseFloat(values.cases);
      const units = parseFloat(values.units);

      if (cases) {
        amount += item.item.unitsPerCase * cases;
      }

      if (units) {
        amount += units;
      }

      const { errors } = await addToItemCount({
        variables: {
          itemCountListId: itemCountListData?.itemCountList?.id,
          sublocationId: sublocationId,
          itemId: item.item.id,
          amount: amount,
          volumeType: null,
          weightType: null,
        },
      });

      if (errors) {
        handleError(errors);
      }
    } else if (item.__typename === 'RecipeLocationAssignment') {
      let amount = 0;

      const cases = parseFloat(values.cases);
      const units = parseFloat(values.units);

      if (cases) {
        amount += item.recipe.unitsPerBatch * cases;
      }

      if (units) {
        amount += units;
      }

      const { errors } = await addToRecipeCount({
        variables: {
          itemCountListId: itemCountListData?.itemCountList?.id,
          sublocationId: sublocationId,
          recipeId: item.recipe.id,
          amount: amount,
          volumeType: null,
          weightType: null,
        },
      });

      if (errors) {
        handleError(errors);
      }
    }

    addByUnitsForm.resetValues();
    if (isFocusMode) {
      goToNextItem();
    }
  }

  const handleRemoveItemByUnits = async (values: AddItemByUnitsInput) => {
    if (openItemIndex === null || !sublocationData) {
      return;
    }

    setLastInputType('UNITS');

    const item = sublocationData.inventoryItemSublocation.items[openItemIndex];
    if (item.__typename === 'InventoryItemLocationAssignment') {
      let amount = 0;

      const cases = parseFloat(values.cases);
      const units = parseFloat(values.units);

      if (cases) {
        amount += item.item.unitsPerCase * cases;
      }

      if (units) {
        amount += units;
      }

      const { errors } = await removeFromItemCount({
        variables: {
          itemCountListId: itemCountListData?.itemCountList?.id,
          sublocationId: sublocationId,
          itemId: item.item.id,
          amount: amount,
          volumeType: null,
          weightType: null,
        },
      });

      if (errors) {
        handleError(errors);
      }
    } else if (item.__typename === 'RecipeLocationAssignment') {
      let amount = 0;

      const cases = parseFloat(values.cases);
      const units = parseFloat(values.units);

      if (cases) {
        amount += item.recipe.unitsPerBatch * cases;
      }

      if (units) {
        amount += units;
      }

      const { errors } = await removeFromRecipeCount({
        variables: {
          itemCountListId: itemCountListData?.itemCountList?.id,
          sublocationId: sublocationId,
          recipeId: item.recipe.id,
          amount: amount,
          volumeType: null,
          weightType: null,
        },
      });

      if (errors) {
        handleError(errors);
      }
    }

    addByUnitsForm.resetValues();
  }

  const handleAddItemByVolume = async (values: AddItemByVolumeInput) => {
    if (openItemIndex === null || !sublocationData) {
      return;
    }

    setLastInputType('VOLUME');

    const item = sublocationData.inventoryItemSublocation.items[openItemIndex];
    if (item.__typename === 'InventoryItemLocationAssignment') {
      const { errors } = await addToItemCount({
        variables: {
          itemCountListId: itemCountListData?.itemCountList?.id,
          sublocationId: sublocationId,
          itemId: item.item.id,
          amount: parseFloat(values.volume),
          volumeType: values.volumeUnits,
          weightType: null,
        },
      });

      if (errors) {
        handleError(errors);
      }
    } else if (item.__typename === 'RecipeLocationAssignment') {
      const { errors } = await addToRecipeCount({
        variables: {
          itemCountListId: itemCountListData?.itemCountList?.id,
          sublocationId: sublocationId,
          recipeId: item.recipe.id,
          amount: parseFloat(values.volume),
          volumeType: values.volumeUnits,
          weightType: null,
        },
      });

      if (errors) {
        handleError(errors);
      }
    }

    addByVolumeForm.resetValues();
    if (isFocusMode) {
      goToNextItem();
    }
  }

  const handleRemoveItemByVolume = async (values: AddItemByVolumeInput) => {
    if (openItemIndex === null || !sublocationData) {
      return;
    }

    setLastInputType('VOLUME');

    const item = sublocationData.inventoryItemSublocation.items[openItemIndex];
    if (item.__typename === 'InventoryItemLocationAssignment') {
      const { errors } = await removeFromItemCount({
        variables: {
          itemCountListId: itemCountListData?.itemCountList?.id,
          sublocationId: sublocationId,
          itemId: item.item.id,
          amount: parseFloat(values.volume),
          volumeType: values.volumeUnits,
          weightType: null,
        },
      });

      if (errors) {
        handleError(errors);
      }
    } else if (item.__typename === 'RecipeLocationAssignment') {
      const { errors } = await removeFromRecipeCount({
        variables: {
          itemCountListId: itemCountListData?.itemCountList?.id,
          sublocationId: sublocationId,
          recipeId: item.recipe.id,
          amount: parseFloat(values.volume),
          volumeType: values.volumeUnits,
          weightType: null,
        },
      });

      if (errors) {
        handleError(errors);
      }
    }

    addByVolumeForm.resetValues();
  }

  const handleAddItemByWeight = async (values: AddItemByWeightInput) => {
    if (openItemIndex === null || !sublocationData) {
      return;
    }

    setLastInputType('WEIGHT');

    const item = sublocationData.inventoryItemSublocation.items[openItemIndex];
    if (item.__typename === 'InventoryItemLocationAssignment') {
      const { errors } = await addToItemCount({
        variables: {
          itemCountListId: itemCountListData?.itemCountList?.id,
          sublocationId: sublocationId,
          itemId: item.item.id,
          amount: parseFloat(values.weight),
          volumeType: null,
          weightType: values.weightUnits,
        },
      });

      if (errors) {
        handleError(errors);
      }
    } else if (item.__typename === 'RecipeLocationAssignment') {
      const { errors } = await addToRecipeCount({
        variables: {
          itemCountListId: itemCountListData?.itemCountList?.id,
          sublocationId: sublocationId,
          recipeId: item.recipe.id,
          amount: parseFloat(values.weight),
          volumeType: null,
          weightType: values.weightUnits,
        },
      });

      if (errors) {
        handleError(errors);
      }
    }

    addByWeightForm.resetValues();

    if (isFocusMode) {
      goToNextItem();
    }
  }

  const handleRemoveItemByWeight = async (values: AddItemByWeightInput) => {
    if (openItemIndex === null || !sublocationData) {
      return;
    }

    setLastInputType('WEIGHT');

    const item = sublocationData.inventoryItemSublocation.items[openItemIndex];
    if (item.__typename === 'InventoryItemLocationAssignment') {
      const { errors } = await removeFromItemCount({
        variables: {
          itemCountListId: itemCountListData?.itemCountList?.id,
          sublocationId: sublocationId,
          itemId: item.item.id,
          amount: parseFloat(values.weight),
          volumeType: null,
          weightType: values.weightUnits,
        },
      });

      if (errors) {
        handleError(errors);
      }
    } else if (item.__typename === 'RecipeLocationAssignment') {
      const { errors } = await removeFromRecipeCount({
        variables: {
          itemCountListId: itemCountListData?.itemCountList?.id,
          sublocationId: sublocationId,
          recipeId: item.recipe.id,
          amount: parseFloat(values.weight),
          volumeType: null,
          weightType: values.weightUnits,
        },
      });

      if (errors) {
        handleError(errors);
      }
    }
    addByWeightForm.resetValues();
  }

  const handleClearItem = async (itemId: string, type: 'ITEM' | 'RECIPE') => {
    if (type === 'ITEM') {
      const { errors } = await clearItemCount({
        variables: {
          itemCountListId: itemCountListData?.itemCountList?.id,
          sublocationId: sublocationId,
          itemId: itemId,
        },
      });

      if (errors) {
        handleError(errors);
      }
    } else if (type === 'RECIPE') {
      const { errors } = await clearRecipeCount({
        variables: {
          itemCountListId: itemCountListData?.itemCountList?.id,
          sublocationId: sublocationId,
          recipeId: itemId,
        },
      });

      if (errors) {
        handleError(errors);
      }
    }
  }

  const addByUnitsForm = useForm<AddItemByUnitsInput>({
    initialValues: { units: '', cases: '' },
    onSubmit: handleAddItemByUnits,
  });

  const addByVolumeForm = useForm<AddItemByVolumeInput>({
    initialValues: { volume: '', volumeUnits: '' },
    onSubmit: handleAddItemByVolume,
  });

  const addByWeightForm = useForm<AddItemByWeightInput>({
    initialValues: { weight: '', weightUnits: '' },
    onSubmit: handleAddItemByWeight,
  });

  if (!(state.user?.roles.Inventory === InventoryRoles.Employee || state.user?.roles.Inventory === InventoryRoles.Manager || state.user?.roles.Inventory === InventoryRoles.Admin)) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <NoPermission />
        </Cell>
      </StandardGrid>
    );
  }

  if (itemCountListIsLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (!itemCountId || itemCountError?.graphQLErrors[0].extensions.status === 404) {
    return (
      <StandardGrid>
        <NotFound />
      </StandardGrid>
    );
  }

  if (!itemCountListData) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  const getUnitSizes = (item: Item | Recipe) => {
    if (!item.unitVolume && !item.unitWeight) {
      return '----';
    }

    let unitSizes = '';

    if (item.unitVolume) {
      const label = parseVolumeUnits(item.unitVolumeType, item.unitVolume);
      unitSizes += `${item.unitVolume} ${label}`;
    }

    if (item.unitWeight) {
      const label = parseWeightUnits(item.unitWeightType, item.unitWeight);
      if (unitSizes) {
        unitSizes += ' | ';
      }

      unitSizes += `${item.unitWeight} ${label}`;
    }

    return unitSizes;
  }

  const anyRequestIsLoading = () => {
    return (addToItemCountIsLoading || removeFromItemCountIsLoading || clearItemCountIsLoading || addToRecipeCountIsLoading || removeFromRecipeCountIsLoading || clearRecipeCountIsLoading);
  }

  const getRowStyle = (rowIndex: number): CSSProperties => {
    if (rowIndex === openItemIndex) {
      return {
        backgroundColor: Colors.primary50,
      };
    }

    return {};
  }

  const goToNextItem = () => {
    if (openItemIndex === null || !sublocationData) {
      return;
    }

    if (openItemIndex < sublocationData.inventoryItemSublocation.items.length - 1) {
      setOpenItemIndex(openItemIndex + 1);
    }
  }

  const handleEnterFocusMode = () => {
    if (openItemIndex === null && sublocationData && sublocationData.inventoryItemSublocation.items.length > 0) {
      setOpenItemIndex(0);
    }

    setIsFocusMode(true);
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Item Counts' to='/item-counts' />
          <Breadcrumb label={itemCountListData.itemCountList?.name || ''} to={'/item-counts/' + itemCountListData.itemCountList?.id} />
          <Breadcrumb label={itemCountListData.itemCountList?.isLocked ? 'Location Counts' : 'Edit'} />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'flex-start', flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
          <View style={{ gap: '8px' }}>
            {itemCountListData.itemCountList?.isLocked ?
              <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                <StyledHeading tag='h3'>Location Counts</StyledHeading>
                <Icon icon={Icons.Lock} size='medium' />
              </View>
              :
              <StyledHeading tag='h3'>Edit Item Counts</StyledHeading>
            }
            <View style={{ flexDirection: 'row', gap: '8px' }}>
              <StyledParagraph>{itemCountListData.itemCountList?.name}</StyledParagraph>
              <StyledParagraph>|</StyledParagraph>
              <StyledParagraph>{itemCountListData.itemCountList?.date}</StyledParagraph>
            </View>
          </View>
          {!itemCountListData.itemCountList?.isLocked && (
            isFocusMode ?
              <Button label='Exit Focus Mode' role='button' action={() => { setIsFocusMode(false) }} variant='secondary' />
              :
              <Button label='Focus Mode' role='button' action={handleEnterFocusMode} variant='secondary' />
          )}
        </View>
      </Cell>
      <Cell lg={0} md={8} sm={4}>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '16px' }}>
          <SingleSelect label='Location' name='locationId' placeholder='Choose a location' value={locationId || ''} onChange={(_, value) => { handleLocationClick(value || ''); }} style={{ maxWidth: '300px', minWidth: '250px', width: 'calc(50% - 16px)' }}>
            {locationsData?.inventoryItemLocations.map((l, index) => {
              return (
                <Choice label={l.name} value={l.id} key={index} />
              );
            })}
          </SingleSelect>
          {locationId &&
            <SingleSelect label='Sublocation' name='sublocationId' placeholder='Choose a sublocation' value={sublocationId || ''} onChange={(_, value) => { handleSublocationClick(value || ''); }} style={{ maxWidth: '300px', minWidth: '250px', width: 'calc(50% - 16px)' }}>
              {(() => {
                if (!locationsData) {
                  return null;
                }

                const openLocation = locationsData.inventoryItemLocations.find((loc) => loc.id === locationId);
                if (!openLocation) {
                  return null;
                }

                if (openLocation.sublocations.length === 0) {
                  return (
                    <View style={{ maxWidth: '300px', minWidth: '250px', padding: '16px' }}>
                      <StyledParagraph>There are no sublocations in this location.</StyledParagraph>
                    </View>
                  );
                } else {
                  return (
                    <>
                      {openLocation.sublocations.map((s, index) => {
                        return (
                          <Choice label={s.name} value={s.id} key={index} />
                        );
                      })}
                    </>
                  );
                }
              })()}
            </SingleSelect>
          }
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {(locationsAreLoading) ?
          <AtomSpinner size='large' />
          :
          (
            <Card size='medium' style={{ height: 'calc(100vh - 272px)', minHeight: '300px' }}>
              {(locationsData?.inventoryItemLocations.length === 0) ?
                <StyledHeading tag='h6'>No item locations exist.</StyledHeading>
                :
                <View style={styles.cardContentContainer}>
                  <View style={styles.locationsListContainer}>
                    {locationsData?.inventoryItemLocations.map((l, index) => {
                      return (
                        <View key={index} style={{ minHeight: 'fit-content' }}>
                          <View style={{ alignItems: 'center', borderBottom: `1px solid ${Colors.neutral200}`, flexDirection: 'row', justifyContent: 'space-between', minHeight: '72px', padding: '16px 16px 16px 0px' }}>
                            <View style={{ alignItems: 'center', cursor: 'pointer', flexDirection: 'row', gap: '16px' }} onClick={() => { handleLocationClick(l.id); }}>
                              {locationId === l.id &&
                                <Icon size='small' icon={Icons.ChevronUp} />
                              }
                              {locationId !== l.id &&
                                <Icon size='small' icon={Icons.ChevronDown} />
                              }
                              <StyledHeading tag='h5' style={{ maxWidth: '200px', ...styles.hideOverFlowText }}>{l.name}</StyledHeading>
                            </View>
                          </View>
                          {locationId === l.id &&
                            <View style={{ padding: '8px 0' }}>
                              {l.sublocations.map((s, index) => {
                                return (
                                  <View key={index} style={{ ...styles.sublocationContainer, ...(s.id === sublocationId ? { backgroundColor: Colors.primary50 } : {}) }} onClick={() => { handleSublocationClick(s.id); }}>
                                    <StyledHeading tag='h6' style={{ maxWidth: '200px', ...styles.hideOverflowText }}>{s.name}</StyledHeading>
                                  </View>
                                );
                              })}
                              {l.sublocations.length === 0 &&
                                <StyledParagraph style={{ margin: '16px 0' }}>There are no sublocations in this location.</StyledParagraph>
                              }
                            </View>
                          }
                        </View>
                      );
                    })}
                  </View>
                  <View style={{ height: '100%', overflow: 'hidden', width: '100%' }}>
                    {sublocationId === null ?
                      <View style={{ alignContent: 'center', height: '100%', justifyContent: 'center', textAlign: 'center', width: '100%' }} >
                        <StyledHeading tag='h6' style={{ color: Colors.neutral700 }}>Select a sublocation to view recipes.</StyledHeading>
                      </View>
                      :
                      ((sublocationIsLoading || itemCountsAreLoading || !sublocationData) ?
                        <View style={{ alignContent: 'center', height: '100%', justifyContent: 'center', textAlign: 'center', width: '100%' }} >
                          <AtomSpinner size='medium' />
                        </View>
                        :
                        <View style={{ height: '100%', justifyContent: 'space-between', overflowX: 'auto' }}>
                          <View style={{ overflow: 'scroll', ...(isFocusMode ? { display: 'none' } : {}) }}>
                            <Table>
                              <TableHeader>
                                <TableRow>
                                  <TableHeaderCell style={styles.leftFixedColumn}>Item Name</TableHeaderCell>
                                  <TableHeaderCell style={{ maxWidth: '100px', minWidth: '100px', width: '100px' }}>Units Per Case</TableHeaderCell>
                                  <TableHeaderCell style={{ maxWidth: '100px', minWidth: '100px', width: '100px' }}>Unit Size</TableHeaderCell>
                                  <TableHeaderCell>Amount On Hand</TableHeaderCell>
                                  <TableHeaderCell>Est. Value</TableHeaderCell>
                                </TableRow>
                              </TableHeader>
                              <TableBody>
                                {sublocationData?.inventoryItemSublocation.items.map((item, index) => {
                                  if (item.__typename === 'InventoryItemLocationAssignment') {
                                    return (
                                      <TableRow key={index} style={{ cursor: 'pointer', ...getRowStyle(index) }} onClick={() => { setOpenItemIndex(index); addByUnitsForm.resetValues(); addByVolumeForm.resetValues(); addByWeightForm.resetValues(); }}>
                                        <TableCell style={styles.leftFixedColumn}><Link href={`/items/${item.item.id}`} linkStyle={{ maxWidth: '200px', textOverflow: 'ellipsis', overflow: 'hidden' }}>{item.item.name}</Link></TableCell>
                                        <TableCell>{item.item.unitsPerCase}</TableCell>
                                        <TableCell>{getUnitSizes(item.item)}</TableCell>
                                        <TableCell>{itemCounts[item.item.id] ? getAmountLabel(itemCounts[item.item.id].unitAmount, item.item.unitsPerCase) : '----'}</TableCell>
                                        <TableCell>{itemCounts[item.item.id] ? currency(itemCounts[item.item.id].item.currentPrice?.casePrice || 0).multiply(itemCounts[item.item.id].unitAmount / (itemCounts[item.item.id].item.unitsPerCase || 1)).format() : '----'}</TableCell>
                                      </TableRow>
                                    );
                                  } else if (item.__typename === 'RecipeLocationAssignment') {
                                    return (
                                      <TableRow key={index} style={{ cursor: 'pointer', ...getRowStyle(index) }} onClick={() => { setOpenItemIndex(index); addByUnitsForm.resetValues(); addByVolumeForm.resetValues(); addByWeightForm.resetValues(); }}>
                                        <TableCell style={styles.leftFixedColumn}><Link href={`/recipes/${item.recipe.recipeCategory.id}/${item.recipe.id}`} linkStyle={{ maxWidth: '200px', textOverflow: 'ellipsis', overflow: 'hidden' }}>{item.recipe.name}</Link></TableCell>
                                        <TableCell>{item.recipe.unitsPerBatch}</TableCell>
                                        <TableCell>{getUnitSizes(item.recipe)}</TableCell>
                                        <TableCell>{itemCounts[item.recipe.id] ? getAmountLabel(itemCounts[item.recipe.id].unitAmount, item.recipe.unitsPerBatch) : '----'}</TableCell>
                                        <TableCell>{itemCounts[item.recipe.id] ? currency((itemCounts[item.recipe.id].item.batchCost || 0)).multiply(itemCounts[item.recipe.id].unitAmount / (itemCounts[item.recipe.id].item.unitsPerBatch || 1)).format() : '----'}</TableCell>
                                      </TableRow>
                                    );
                                  } else {
                                    return null;
                                  }
                                })}
                              </TableBody>
                            </Table>
                          </View>
                          {!itemCountListData?.itemCountList?.isLocked &&
                            <View style={{ borderTop: `1px solid ${Colors.neutral200}`, minHeight: '280px', padding: '24px 0' }}>
                              {(() => {
                                if (sublocationData?.inventoryItemSublocation.items.length === 0) {
                                  return (
                                    <View style={{ alignContent: 'center', height: '100%', justifyContent: 'center', textAlign: 'center', width: '100%' }} >
                                      <StyledHeading tag='h6' style={{ color: Colors.neutral700 }}>There are no items in this sublocation.</StyledHeading>
                                    </View>
                                  );
                                }

                                if (openItemIndex === null || !sublocationData?.inventoryItemSublocation.items[openItemIndex || 0]) {
                                  return (
                                    <View style={{ alignContent: 'center', height: '100%', justifyContent: 'center', textAlign: 'center', width: '100%' }} >
                                      <StyledHeading tag='h6' style={{ color: Colors.neutral700 }}>Select an item above to edit.</StyledHeading>
                                    </View>
                                  );
                                }

                                const validateAddByUnits = (): boolean => {
                                  if (addByUnitsForm.values.cases === '' && addByUnitsForm.values.units === '') {
                                    return false;
                                  } else if (addByUnitsForm.values.cases === '') {
                                    const units = parseFloat(addByUnitsForm.values.units);
                                    if (units <= 0) {
                                      return false;
                                    }
                                  } else if (addByUnitsForm.values.units === '') {
                                    const cases = parseFloat(addByUnitsForm.values.cases);
                                    if (cases <= 0) {
                                      return false;
                                    }
                                  } else {
                                    const units = parseFloat(addByUnitsForm.values.units);
                                    const cases = parseFloat(addByUnitsForm.values.cases);

                                    if (units < 0 || cases < 0) {
                                      return false;
                                    }

                                    if (units === 0 && cases === 0) {
                                      return false;
                                    }
                                  }

                                  return true;
                                }

                                const validateAddByVolume = (): boolean => {
                                  if (addByVolumeForm.values.volume === '' || addByVolumeForm.values.volumeUnits === '') {
                                    return false;
                                  }

                                  const volume = parseFloat(addByVolumeForm.values.volume)
                                  if (volume <= 0) {
                                    return false;
                                  }

                                  return true;
                                }

                                const validateAddByWeight = (): boolean => {
                                  if (addByWeightForm.values.weight === '' || addByWeightForm.values.weightUnits === '') {
                                    return false;
                                  }

                                  const weight = parseFloat(addByWeightForm.values.weight)
                                  if (weight <= 0) {
                                    return false;
                                  }

                                  return true;
                                }

                                const item = sublocationData.inventoryItemSublocation.items[openItemIndex || 0];

                                const getAmountLabelSecondary = () => {
                                  let unitSizes = '';

                                  if (item?.__typename === 'InventoryItemLocationAssignment') {
                                    if (item.item.unitVolume) {
                                      const volume = item.item.unitVolume * itemCounts[item.item.id].unitAmount;
                                      const label = parseVolumeUnits(item.item.unitVolumeType, volume);

                                      unitSizes += `${formatDecimalAmount(volume)} ${label}`;
                                    }

                                    if (item.item.unitWeight) {
                                      const weight = item.item.unitWeight * itemCounts[item.item.id].unitAmount;
                                      const label = parseWeightUnits(item.item.unitWeightType, weight);
                                      if (unitSizes) {
                                        unitSizes += ' | ';
                                      }

                                      unitSizes += `${formatDecimalAmount(weight)} ${label}`;
                                    }
                                  } else if (item?.__typename === 'RecipeLocationAssignment') {
                                    if (item.recipe.unitVolume) {
                                      const volume = item.recipe.unitVolume * itemCounts[item.recipe.id].unitAmount;
                                      const label = parseVolumeUnits(item.recipe.unitVolumeType, volume);

                                      unitSizes += `${formatDecimalAmount(volume)} ${label}`;
                                    }

                                    if (item.recipe.unitWeight) {
                                      const weight = item.recipe.unitWeight * itemCounts[item.recipe.id].unitAmount;
                                      const label = parseWeightUnits(item.recipe.unitWeightType, weight);
                                      if (unitSizes) {
                                        unitSizes += ' | ';
                                      }

                                      unitSizes += `${formatDecimalAmount(weight)} ${label}`;
                                    }
                                  }

                                  return unitSizes;
                                }

                                if (item.__typename === 'InventoryItemLocationAssignment') {
                                  return (
                                    <View style={{ flexDirection: 'row', gap: '16px', justifyContent: 'space-between', overflowX: 'auto', width: '100%', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
                                      <View style={{ flexGrow: 1, gap: '8px', minHeight: 'fit-content', minWidth: 'fit-content', width: '50%' }}>
                                        <StyledHeading tag='h6'>{item?.item.name}</StyledHeading>
                                        <StyledParagraph>{item.item.unitsPerCase} unit{item.item.unitsPerCase > 1 && 's'} per case</StyledParagraph>
                                        {getUnitSizes(item.item) !== '----' ? <StyledParagraph>{getUnitSizes(item.item)}</StyledParagraph> : <Strut size='24px' />}
                                        <View>
                                          <StyledHeading tag='h5' style={{ marginTop: '16px' }}>{getAmountLabel(itemCounts[item.item.id]?.unitAmount || 0, item.item.unitsPerCase)}</StyledHeading>
                                          {((item.item.unitVolume || item.item.unitWeight) && itemCounts[item.item.id]) ? <StyledParagraph>{getAmountLabelSecondary()}</StyledParagraph> : <Strut size='24px' />}
                                        </View>
                                        <Button label='Clear Saved Amount' role='button' action={() => { handleClearItem(item.item.id, 'ITEM'); }} variant='tertiary' destructive disabled={!itemCounts[item.item.id] || anyRequestIsLoading()} loading={clearItemCountIsLoading} />
                                        {isFocusMode && (
                                          <PageButtons currentPage={openItemIndex} numPages={sublocationData.inventoryItemSublocation.items.length} onPageChange={(page) => { setOpenItemIndex(page) }} />
                                        )}
                                      </View>
                                      <View style={{ flexGrow: 1, gap: '8px', minWidth: 'fit-content', width: '50%' }}>
                                        <Form handleSubmit={addByUnitsForm.handleSubmit}>
                                          <View style={{ alignItems: 'flex-end', flexDirection: 'row', gap: '16px' }}>
                                            <View style={{ alignItems: 'flex-end', flexDirection: 'row', gap: '8px' }}>
                                              <TextField label='Cases' name='cases' type='number' inputMode='decimal' value={addByUnitsForm.values.cases} error={addByUnitsForm.errors.cases} onChange={addByUnitsForm.handleChange} style={{ flexGrow: 0, width: '150px' }} />
                                              <TextField label='Units' name='units' type='number' inputMode='decimal' value={addByUnitsForm.values.units} error={addByUnitsForm.errors.units} onChange={addByUnitsForm.handleChange} style={{ flexGrow: 0, width: '150px' }} />
                                            </View>
                                            <View style={{ flexDirection: 'row', gap: '32px' }}>
                                              <Button label='Add' role='button' action={() => { handleAddItemByUnits(addByUnitsForm.values) }} variant='tertiary' disabled={addByUnitsForm.hasError || anyRequestIsLoading() || !validateAddByUnits()} loading={addToItemCountIsLoading && lastInputType === 'UNITS'} />
                                              <Button label='Remove' role='button' action={() => { handleRemoveItemByUnits(addByUnitsForm.values) }} variant='tertiary' destructive disabled={addByUnitsForm.hasError || anyRequestIsLoading() || !validateAddByUnits()} loading={removeFromItemCountIsLoading && lastInputType === 'UNITS'} />
                                            </View>
                                          </View>
                                        </Form>
                                        <Form handleSubmit={addByVolumeForm.handleSubmit}>
                                          <View style={{ alignItems: 'flex-end', flexDirection: 'row', gap: '16px' }}>
                                            <View style={{ alignItems: 'flex-end', flexDirection: 'row', gap: '8px' }}>
                                              <TextField label='Volume' name='volume' type='number' inputMode='decimal' value={addByVolumeForm.values.volume} error={addByVolumeForm.errors.volume} onChange={addByVolumeForm.handleChange} disabled={!item.item.unitVolume} style={{ flexGrow: 0, width: '150px' }} />
                                              <SingleSelect label='Volume Units' name='volumeUnits' placeholder='Choose' value={addByVolumeForm.values.volumeUnits} error={addByVolumeForm.errors.volumeUnits} onChange={addByVolumeForm.handleChange} disabled={!item.item.unitVolume} style={{ maxWidth: '150px', width: '150px' }} >
                                                <Choice label='tsp' value='TSP' />
                                                <Choice label='tbsp' value='TBSP' />
                                                <Choice label='fl oz' value='FLUID_OZ' />
                                                <Choice label='cups' value='CUP' />
                                                <Choice label='pints' value='PINT' />
                                                <Choice label='quarts' value='QUART' />
                                                <Choice label='gallons' value='GALLON' />
                                                <Choice label='liters' value='LITER' />
                                                <Choice label='milliliters' value='MILLILITER' />
                                              </SingleSelect>
                                            </View>
                                            <View style={{ flexDirection: 'row', gap: '32px' }}>
                                              <Button label='Add' role='button' action={() => { handleAddItemByVolume(addByVolumeForm.values) }} variant='tertiary' disabled={addByVolumeForm.hasError || !item.item.unitVolume || anyRequestIsLoading() || !validateAddByVolume()} loading={addToItemCountIsLoading && lastInputType === 'VOLUME'} />
                                              <Button label='Remove' role='button' action={() => { handleRemoveItemByVolume(addByVolumeForm.values) }} variant='tertiary' destructive disabled={addByVolumeForm.hasError || !item.item.unitVolume || anyRequestIsLoading() || !validateAddByVolume()} loading={removeFromItemCountIsLoading && lastInputType === 'VOLUME'} />
                                            </View>
                                          </View>
                                        </Form>
                                        <Form handleSubmit={addByWeightForm.handleSubmit}>
                                          <View style={{ alignItems: 'flex-end', flexDirection: 'row', gap: '16px' }}>
                                            <View style={{ alignItems: 'flex-end', flexDirection: 'row', gap: '8px' }}>
                                              <TextField label='Weight' name='weight' type='number' inputMode='decimal' value={addByWeightForm.values.weight} error={addByWeightForm.errors.weight} onChange={addByWeightForm.handleChange} disabled={!item.item.unitWeight} style={{ flexGrow: 0, width: '150px' }} />
                                              <SingleSelect label='Weight Units' name='weightUnits' placeholder='Choose' value={addByWeightForm.values.weightUnits} error={addByWeightForm.errors.weightUnits} onChange={addByWeightForm.handleChange} disabled={!item.item.unitWeight} style={{ maxWidth: '150px', width: '150px' }} >
                                                <Choice label='oz' value='OUNCE' />
                                                <Choice label='lbs' value='POUND' />
                                                <Choice label='g' value='GRAM' />
                                                <Choice label='kg' value='KILOGRAM' />
                                              </SingleSelect>
                                            </View>
                                            <View style={{ flexDirection: 'row', gap: '32px' }}>
                                              <Button label='Add' role='button' action={() => { handleAddItemByWeight(addByWeightForm.values) }} variant='tertiary' disabled={!item.item.unitWeight || addByWeightForm.hasError || anyRequestIsLoading() || !validateAddByWeight()} loading={addToItemCountIsLoading && lastInputType === 'WEIGHT'} />
                                              <Button label='Remove' role='button' action={() => { handleRemoveItemByWeight(addByWeightForm.values) }} variant='tertiary' destructive disabled={!item.item.unitWeight || addByWeightForm.hasError || anyRequestIsLoading() || !validateAddByWeight()} loading={removeFromItemCountIsLoading && lastInputType === 'WEIGHT'} />
                                            </View>
                                          </View>
                                        </Form>
                                      </View>
                                    </View>
                                  );
                                } else if (item.__typename === 'RecipeLocationAssignment') {
                                  return (
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                      <View style={{ flexGrow: 1, gap: '8px', width: '50%' }}>
                                        <StyledHeading tag='h6'>{item.recipe.name}</StyledHeading>
                                        <StyledParagraph>{item.recipe.unitsPerBatch} unit{item.recipe.unitsPerBatch > 1 && 's'} per batch</StyledParagraph>
                                        {getUnitSizes(item.recipe) !== '----' ? <StyledParagraph>{getUnitSizes(item.recipe)}</StyledParagraph> : <Strut size='24px' />}
                                        <View>
                                          <StyledHeading tag='h5' style={{ marginTop: '16px' }}>{getAmountLabel(itemCounts[item.recipe.id]?.unitAmount || 0, item.recipe.unitsPerBatch)}</StyledHeading>
                                          {((item.recipe.unitVolume || item.recipe.unitWeight) && itemCounts[item.recipe.id]) ? <StyledParagraph>{getAmountLabelSecondary()}</StyledParagraph> : <Strut size='24px' />}
                                        </View>
                                        <Button label='Clear Saved Amount' role='button' action={() => { handleClearItem(item.recipe.id, 'RECIPE'); }} variant='tertiary' destructive disabled={!itemCounts[item.recipe.id] || anyRequestIsLoading()} loading={clearRecipeCountIsLoading} />
                                        {isFocusMode && (
                                          <PageButtons currentPage={openItemIndex} numPages={sublocationData.inventoryItemSublocation.items.length} onPageChange={(page) => { setOpenItemIndex(page) }} />
                                        )}
                                      </View>
                                      <View style={{ flexGrow: 1, gap: '8px', width: '50%' }}>
                                        <Form handleSubmit={addByUnitsForm.handleSubmit}>
                                          <View style={{ alignItems: 'flex-end', flexDirection: 'row', gap: '16px' }}>
                                            <View style={{ alignItems: 'flex-end', flexDirection: 'row', gap: '8px' }}>
                                              <TextField label='Cases' name='cases' type='number' inputMode='decimal' value={addByUnitsForm.values.cases} error={addByUnitsForm.errors.cases} onChange={addByUnitsForm.handleChange} style={{ flexGrow: 0, width: '150px' }} />
                                              <TextField label='Units' name='units' type='number' inputMode='decimal' value={addByUnitsForm.values.units} error={addByUnitsForm.errors.units} onChange={addByUnitsForm.handleChange} style={{ flexGrow: 0, width: '150px' }} />
                                            </View>
                                            <View style={{ flexDirection: 'row', gap: '32px' }}>
                                              <Button label='Add' role='button' action={() => { handleAddItemByUnits(addByUnitsForm.values) }} variant='tertiary' disabled={addByUnitsForm.hasError || anyRequestIsLoading() || !validateAddByUnits()} loading={addToRecipeCountIsLoading && lastInputType === 'UNITS'} />
                                              <Button label='Remove' role='button' action={() => { handleRemoveItemByUnits(addByUnitsForm.values) }} variant='tertiary' destructive disabled={addByUnitsForm.hasError || anyRequestIsLoading() || !validateAddByUnits()} loading={removeFromRecipeCountIsLoading && lastInputType === 'UNITS'} />
                                            </View>
                                          </View>
                                        </Form>
                                        <Form handleSubmit={addByVolumeForm.handleSubmit}>
                                          <View style={{ alignItems: 'flex-end', flexDirection: 'row', gap: '16px' }}>
                                            <View style={{ alignItems: 'flex-end', flexDirection: 'row', gap: '8px' }}>
                                              <TextField label='Volume' name='volume' type='number' inputMode='decimal' value={addByVolumeForm.values.volume} error={addByVolumeForm.errors.volume} onChange={addByVolumeForm.handleChange} disabled={!item.recipe.unitVolume} style={{ flexGrow: 0, width: '150px' }} />
                                              <SingleSelect label='Volume Units' name='volumeUnits' placeholder='Choose' value={addByVolumeForm.values.volumeUnits} error={addByVolumeForm.errors.volumeUnits} onChange={addByVolumeForm.handleChange} disabled={!item.recipe.unitVolume} style={{ maxWidth: '150px', width: '150px' }} >
                                                <Choice label='tsp' value='TSP' />
                                                <Choice label='tbsp' value='TBSP' />
                                                <Choice label='fl oz' value='FLUID_OZ' />
                                                <Choice label='cups' value='CUP' />
                                                <Choice label='pints' value='PINT' />
                                                <Choice label='quarts' value='QUART' />
                                                <Choice label='gallons' value='GALLON' />
                                                <Choice label='liters' value='LITER' />
                                                <Choice label='milliliters' value='MILLILITER' />
                                              </SingleSelect>
                                            </View>
                                            <View style={{ flexDirection: 'row', gap: '32px' }}>
                                              <Button label='Add' role='button' action={() => { handleAddItemByVolume(addByVolumeForm.values) }} variant='tertiary' disabled={addByVolumeForm.hasError || !item.recipe.unitVolume || anyRequestIsLoading() || !validateAddByVolume()} loading={addToRecipeCountIsLoading && lastInputType === 'VOLUME'} />
                                              <Button label='Remove' role='button' action={() => { handleRemoveItemByVolume(addByVolumeForm.values) }} variant='tertiary' destructive disabled={addByVolumeForm.hasError || !item.recipe.unitVolume || anyRequestIsLoading() || !validateAddByVolume()} loading={removeFromRecipeCountIsLoading && lastInputType === 'VOLUME'} />
                                            </View>
                                          </View>
                                        </Form>
                                        <Form handleSubmit={addByWeightForm.handleSubmit}>
                                          <View style={{ alignItems: 'flex-end', flexDirection: 'row', gap: '16px' }}>
                                            <View style={{ alignItems: 'flex-end', flexDirection: 'row', gap: '8px' }}>
                                              <TextField label='Weight' name='weight' type='number' inputMode='decimal' value={addByWeightForm.values.weight} error={addByWeightForm.errors.weight} onChange={addByWeightForm.handleChange} disabled={!item.recipe.unitWeight} style={{ flexGrow: 0, width: '150px' }} />
                                              <SingleSelect label='Weight Units' name='weightUnits' placeholder='Choose' value={addByWeightForm.values.weightUnits} error={addByWeightForm.errors.weightUnits} onChange={addByWeightForm.handleChange} disabled={!item.recipe.unitWeight} style={{ maxWidth: '150px', width: '150px' }} >
                                                <Choice label='oz' value='OUNCE' />
                                                <Choice label='lbs' value='POUND' />
                                                <Choice label='g' value='GRAM' />
                                                <Choice label='kg' value='KILOGRAM' />
                                              </SingleSelect>
                                            </View>
                                            <View style={{ flexDirection: 'row', gap: '32px' }}>
                                              <Button label='Add' role='button' action={() => { handleAddItemByWeight(addByWeightForm.values) }} variant='tertiary' disabled={!item.recipe.unitWeight || addByWeightForm.hasError || anyRequestIsLoading() || !validateAddByWeight()} loading={addToRecipeCountIsLoading && lastInputType === 'WEIGHT'} />
                                              <Button label='Remove' role='button' action={() => { handleRemoveItemByWeight(addByWeightForm.values) }} variant='tertiary' destructive disabled={!item.recipe.unitWeight || addByWeightForm.hasError || anyRequestIsLoading() || !validateAddByWeight()} loading={removeFromRecipeCountIsLoading && lastInputType === 'WEIGHT'} />
                                            </View>
                                          </View>
                                        </Form>
                                      </View>
                                    </View>
                                  );
                                }
                              })()}
                            </View>
                          }
                        </View>
                      )
                    }
                  </View>
                </View>
              }
            </Card>
          )
        }
      </Cell>
    </StandardGrid>
  );
}

export const getAmountLabel = (unitAmount: number, unitsPerCase: number) => {
  if (unitsPerCase === 1) {
    return `${formatDecimalAmount(unitAmount)} unit${(unitAmount !== 1) ? 's' : ''}`;
  }

  if (unitAmount >= unitsPerCase) {
    if (unitAmount % unitsPerCase !== 0) {
      const numCases = Math.floor(unitAmount / unitsPerCase);
      const numUnits = unitAmount % unitsPerCase;

      return `${formatDecimalAmount(numCases)} case${numCases > 1 ? 's' : ''}, ${formatDecimalAmount(numUnits)} unit${(numUnits !== 1) ? 's' : ''}`;
    } else {
      const numCases = Math.floor(unitAmount / unitsPerCase);

      return `${formatDecimalAmount(numCases)} case${numCases > 1 ? 's' : ''}`;
    }
  } else {
    return `${formatDecimalAmount(unitAmount)} unit${(unitAmount !== 1) ? 's' : ''}`;
  }
}