import { ActionItem, ActionMenu, AtomSpinner, Button, Card, Cell, Choice, CircularSpinner, Colors, ConfirmModal, DatePicker, ErrorPage, FormModal, FormModalValueProvider, HasProductRole, Icon, Icons, InventoryRoles, Link, ModalLauncher, Products, SingleSelect, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextArea, TextField, View } from "@barscience/global-components";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { CSSProperties } from "aphrodite";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { formatDate, padTo2Digits } from "../../util/dates";
import currency from "currency.js";
import { parseVolumeUnits, parseWeightUnits } from "../../util/measurements";

/* Get Waste Logs Query */
const GET_WASTE_LOGS = gql`
query getWasteLogsForDate($date: Date!) {
  itemWasteLogs(date: $date) {
    id
    item {
      ... on InventoryItem {
        id
        name
        currentPrice(startDate: $date) {
          casePrice
          unitPrice
        }
        unitsPerCase
        unitVolume
        unitVolumeType
        unitWeight
        unitWeightType
        category {
          id
          name
        }
      }
      ... on Recipe {
        id
        name
        itemCategory {
          id
          name
        }
        recipeCategory {
          id
        }
        unitsPerBatch
        unitVolume
        unitVolumeType
        unitWeight
        unitWeightType
        batchCost(date: $date)
      }
    }
    rawAmount
    amount
    amountType {
      ... on WasteLogWeight {
        weightUnits: units
      }
      ... on WasteLogVolume {
        volumeUnits: units
      }
      ... on WasteLogUnits {
        units
      }
    }
    date
    notes
  }
}
`;

type GetWasteLogsResponse = {
  itemWasteLogs: ItemWasteLog[] | null;
}

type ItemWasteLog = {
  id: string;
  item: InventoryItem | Recipe;
  rawAmount: number;
  amount: number;
  amountType: WasteLogWeight | WasteLogVolume | WasteLogUnits;
  date: string;
  notes: string;
}

type InventoryItem = {
  id: string;
  name: string;
  currentPrice: {
    casePrice: string;
    unitPrice: string;
  };
  unitsPerCase: number;
  unitVolume: number | null;
  unitVolumeType: string | null;
  unitWeight: number | null;
  unitWeightType: string | null;
  category: {
    id: string;
    name: string;
  };
  __typename: 'InventoryItem';
}

type Recipe = {
  id: string;
  name: string;
  itemCategory: {
    id: string;
    name: string;
  };
  recipeCategory: {
    id: string;
  };
  unitsPerBatch: number;
  unitVolume: number | null;
  unitVolumeType: string | null;
  unitWeight: number | null;
  unitWeightType: string | null;
  batchCost: string;
  __typename: 'Recipe';
}

type WasteLogWeight = {
  weightUnits: string;
  __typename: 'WasteLogWeight';
}

type WasteLogVolume = {
  volumeUnits: string;
  __typename: 'WasteLogVolume';
}

type WasteLogUnits = {
  units: string;
  __typename: 'WasteLogUnits';
}

/* Create Waste Log Mutation */
const CREATE_WASTE_LOG = gql`
mutation createItemWasteLog($input: CreateItemWasteLogInput!, $priceDate: Date!) {
  createItemWasteLog(input: $input) {
    id
    item {
      ... on InventoryItem {
        id
        name
        currentPrice(startDate: $priceDate) {
          casePrice
          unitPrice
        }
        unitsPerCase
        unitVolume
        unitVolumeType
        unitWeight
        unitWeightType
        category {
          id
          name
        }
      }
      ... on Recipe {
        id
        name
        itemCategory {
          id
          name
        }
        recipeCategory {
          id
        }
        unitsPerBatch
        unitVolume
        unitVolumeType
        unitWeight
        unitWeightType
        batchCost(date: $priceDate)
      }
    }
    rawAmount
    amount
    amountType {
      ... on WasteLogWeight {
        weightUnits: units
      }
      ... on WasteLogVolume {
        volumeUnits: units
      }
      ... on WasteLogUnits {
        units
      }
    }
    date
    notes
  }
}
`;

type CreateWasteLogResponse = {
  createItemWasteLog: ItemWasteLog;
}

type CreateWasteLogInput = {
  itemId: string;
  amount: string;
  inputType: string;
  volumeUnits: string;
  weightUnits: string;
  notes: string;
}

const CREATE_WASTE_LOG_INPUT_INITIAL_VALUES = {
  itemId: '',
  amount: '',
  inputType: 'UNITS',
  volumeUnits: '',
  weightUnits: '',
  notes: '',
}

/* Edit Waste Log Mutation */
const EDIT_WASTE_LOG = gql`
mutation editItemWasteLog($id: ID!, $input: EditItemWasteLogInput!) {
  editItemWasteLog(id: $id, input: $input) {
    id
    rawAmount
    amount
    amountType {
      ... on WasteLogWeight {
        weightUnits: units
      }
      ... on WasteLogVolume {
        volumeUnits: units
      }
      ... on WasteLogUnits {
        units
      }
    }
    date
    notes
  }
}
`;

type EditWasteLogResponse = {
  editItemWasteLog: ItemWasteLog;
}

type EditWasteLogInput = {
  id: string;
  amount: string;
  inputType: string;
  volumeUnits: string;
  weightUnits: string;
  notes: string;
}

/* Get Items Query */
const GET_ITEMS = gql`
query getItemsForWasteLogCreation($page: Int!, $name: String) {
  inventoryItems(page: $page, name: $name) {
    items {
      id
      name
      unitVolume
      unitVolumeType
      unitWeight
      unitWeightType
    }
  }
}
`;

type GetItemsQueryResponse = {
  inventoryItems: {
    items: {
      id: string;
      name: string;
      unitVolume: number | null;
      unitVolumeType: string | null;
      unitWeight: number | null;
      unitWeightType: string | null;
    }[];
  }
}

/* Get Recipes Query */
const GET_RECIPES = gql`
query getRecipesForWasteLogCreation($name: String!) {
  recipes(name: $name) {
    id
    name
    unitVolume
    unitVolumeType
    unitWeight
    unitWeightType
  }
}
`;

type GetRecipesResponse = {
  recipes: {
    id: string;
    name: string;
    unitVolume: number | null;
    unitVolumeType: string | null;
    unitWeight: number | null;
    unitWeightType: string | null;
  }[];
}

/* Delete Waste Log Mutation */
const DELETE_WASTE_LOG = gql`
mutation deleteItemWasteLog($id: ID!) {
  deleteItemWasteLog(id: $id) {
    id
  }
}
`;

type DeleteWasteLogResponse = {
  deleteItemWasteLog: {
    id: string;
  } | null;
}


/* Styles */
const dateButtonStyle: CSSProperties = {
  alignItems: 'center',
  alignContent: 'center',
  background: 'none',
  border: 'none',
  borderRadius: '4px',
  boxSizing: 'border-box',
  cursor: 'pointer',
  color: Colors.neutral700,
  fill: Colors.neutral700,
  flexDirection: 'row',
  flexWrap: 'nowrap',
  gap: '8px',
  height: '40px',
  justifyContent: 'center',
  padding: '8px',
  textDecoration: 'none',
  width: '40px',
  ':hover': {
    borderColor: Colors.primary500,
    borderStyle: 'solid',
    borderWidth: '2px',
    padding: '6px',
    fill: Colors.primary500,
    height: '40px',
  },
  ':active': {
    borderColor: Colors.primary700,
    fill: Colors.primary700,
  },
}

const dateButtonDisabledStyle: CSSProperties = {
  color: Colors.neutral400,
  fill: Colors.neutral400,
  cursor: 'default',
  ':hover': {
    border: 'none',
  },
}

const getFormattedTodayDate = () => {
  const date = new Date();
  return `${padTo2Digits(date.getMonth() + 1)}/${padTo2Digits(date.getDate())}/${date.getFullYear()}`;
}

export default function WasteLogs() {
  const [queryParams, setQueryParams] = useSearchParams();
  const { data: wasteLogData, loading: wasteLogsAreLoading, error: wasteLogsError } = useQuery<GetWasteLogsResponse>(GET_WASTE_LOGS, {
    variables: {
      date: (queryParams.has('date') && queryParams.get('date') !== '') ? formatDate(new Date(decodeURIComponent(queryParams.get('date') || ''))) : getFormattedTodayDate(),
    },
  });
  const [getItemsToAdd, { data: itemSearchData, loading: itemSearchIsLoading }] = useLazyQuery<GetItemsQueryResponse>(GET_ITEMS);
  const [getRecipesToAdd, { data: recipeSearchData, loading: recipeSearchIsLoading }] = useLazyQuery<GetRecipesResponse>(GET_RECIPES);
  const [createWasteLog] = useMutation<CreateWasteLogResponse>(CREATE_WASTE_LOG, {
    update(cache, { data }) {
      if (data) {
        const wasteLogs = cache.readQuery<GetWasteLogsResponse>({
          query: GET_WASTE_LOGS,
          variables: {
            date: formatDate(new Date(decodeURIComponent(queryParams.get('date') || ''))),
          },
        });

        if (wasteLogs) {
          const newWasteLogs = [
            ...(wasteLogs.itemWasteLogs || []),
            data.createItemWasteLog,
          ];

          // Sort the logs by item name
          newWasteLogs.sort((a, b) => {
            if (a.item.name < b.item.name) {
              return -1;
            } else if (a.item.name > b.item.name) {
              return 1;
            } else {
              return 0;
            }
          });

          cache.writeQuery<GetWasteLogsResponse>({
            query: GET_WASTE_LOGS,
            variables: {
              date: formatDate(new Date(decodeURIComponent(queryParams.get('date') || ''))),
            },
            data: {
              itemWasteLogs: newWasteLogs,
            },
          });
        }
      }
    },
  });
  const [editWasteLog] = useMutation<EditWasteLogResponse>(EDIT_WASTE_LOG);
  const [deleteWasteLog] = useMutation<DeleteWasteLogResponse>(DELETE_WASTE_LOG, {
    update(cache, { data }) {
      cache.evict({
        id: cache.identify({
          __typename: 'ItemWasteLog',
          id: data?.deleteItemWasteLog?.id,
        }),
      });
    },
  });

  useEffect(() => {
    if (!queryParams.has('date') || queryParams.get('date') === '') {
      const date = new Date();
      setQueryParams({ date: `${padTo2Digits(date.getMonth() + 1)}/${padTo2Digits(date.getDate())}/${date.getFullYear()}` });
    }
  }, [queryParams, setQueryParams]);

  const handlePreviousDayClick = () => {
    const date = new Date(queryParams.get('date') || '');
    date.setDate(date.getDate() - 1);
    setQueryParams({ date: `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}` });
  }

  const handleNextDayClick = () => {
    if (isTodaySelected()) {
      return;
    }

    const date = new Date(queryParams.get('date') || '');
    date.setDate(date.getDate() + 1);
    setQueryParams({ date: `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}` });
  }

  const isTodaySelected = () => {
    const today = new Date();
    const selectedDate = new Date(decodeURIComponent(queryParams.get('date') || ''));
    return today.getMonth() === selectedDate.getMonth() && today.getDate() === selectedDate.getDate() && today.getFullYear() === selectedDate.getFullYear();
  }

  const getItemValue = (wasteLog: ItemWasteLog): currency | null => {
    if (wasteLog.item.__typename === 'InventoryItem') {
      const price = currency(wasteLog.item.currentPrice.unitPrice);
      return price.multiply(wasteLog.rawAmount);
    } else if (wasteLog.item.__typename === 'Recipe') {
      const price = currency(wasteLog.item.batchCost).divide(wasteLog.item.unitsPerBatch);
      return price.multiply(wasteLog.rawAmount);
    } else {
      return null;
    }
  }

  const getItemAmountLabel = (wasteLog: ItemWasteLog): string | null => {
    let label = '';
    if (wasteLog.amountType.__typename === 'WasteLogUnits') {
      if (wasteLog.amount !== 1) {
        label = 'units';
      } else {
        label = 'unit';
      }
    } else if (wasteLog.amountType.__typename === 'WasteLogVolume') {
      label = parseVolumeUnits(wasteLog.amountType.volumeUnits, wasteLog.amount);
    } else if (wasteLog.amountType.__typename === 'WasteLogWeight') {
      label = parseWeightUnits(wasteLog.amountType.weightUnits, wasteLog.amount);
    }

    return `${wasteLog.amount} ${label}`;
  }

  const getSelectedItem = (itemId: string) => {
    let foundItem = null;

    foundItem = itemSearchData?.inventoryItems.items.find((item) => (
      item.id === itemId
    ));

    if (!foundItem) {
      foundItem = recipeSearchData?.recipes.find((recipe) => (
        recipe.id === itemId
      ));
    }

    return foundItem;
  }

  const getEditingItem = (logId: string) => {
    let foundItem = null;

    foundItem = wasteLogData?.itemWasteLogs?.find((log) => (
      log.id === logId
    ));

    return foundItem;
  }

  const getCategoryTotals = () => {
    const categoryTotals: { [key: string]: { name: string; total: currency; } } = {};

    wasteLogData?.itemWasteLogs?.forEach((wasteLog) => {
      if (wasteLog.item.__typename === 'InventoryItem') {
        if (categoryTotals[wasteLog.item.category.id]) {
          categoryTotals[wasteLog.item.category.id].total = categoryTotals[wasteLog.item.category.id].total.add(getItemValue(wasteLog) || 0);
        } else {
          categoryTotals[wasteLog.item.category.id] = {
            name: wasteLog.item.category.name,
            total: getItemValue(wasteLog) || currency(0),
          };
        }
      } else if (wasteLog.item.__typename === 'Recipe') {
        if (categoryTotals[wasteLog.item.itemCategory.id]) {
          categoryTotals[wasteLog.item.itemCategory.id].total = categoryTotals[wasteLog.item.itemCategory.id].total.add(getItemValue(wasteLog) || 0);
        } else {
          categoryTotals[wasteLog.item.itemCategory.id] = {
            name: wasteLog.item.itemCategory.name,
            total: getItemValue(wasteLog) || currency(0),
          };
        }
      }
    });

    const totals = Object.keys(categoryTotals).map((key) => categoryTotals[key]);
    totals.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }

      if (a.name > b.name) {
        return 1;
      }

      return 0;
    });

    return totals;
  }

  const getTotalValue = () => {
    let total = currency(0);

    wasteLogData?.itemWasteLogs?.forEach((wasteLog) => {
      total = total.add(getItemValue(wasteLog) || 0);
    });

    return total;
  }

  const handleCreateWasteLog = async (values: CreateWasteLogInput) => {
    await createWasteLog({
      variables: {
        input: {
          itemId: values.itemId,
          date: formatDate(new Date(decodeURIComponent(queryParams.get('date') || ''))),
          amount: parseFloat(values.amount),
          volumeUnits: values.inputType === 'VOLUME' ? values.volumeUnits : null,
          weightUnits: values.inputType === 'WEIGHT' ? values.weightUnits : null,
          notes: values.notes,
        },
        priceDate: formatDate(new Date(decodeURIComponent(queryParams.get('date') || ''))),
      },
    });
  }

  const handleEditWasteLog = async (values: EditWasteLogInput) => {
    await editWasteLog({
      variables: {
        id: values.id,
        input: {
          amount: parseFloat(values.amount),
          volumeUnits: values.inputType === 'VOLUME' ? values.volumeUnits : null,
          weightUnits: values.inputType === 'WEIGHT' ? values.weightUnits : null,
          notes: values.notes,
        },
      },
    });
  }

  const handleDeleteWasteLog = async (wasteLogId: string) => {
    await deleteWasteLog({
      variables: {
        id: wasteLogId,
      },
    });
  }

  if (!wasteLogsAreLoading && wasteLogsError) {
    return <ErrorPage />;
  }

  const createWasteLogModal = (
    <FormModal<CreateWasteLogInput> title='Create Waste Log' onSubmit={handleCreateWasteLog} initialValues={CREATE_WASTE_LOG_INPUT_INITIAL_VALUES} submitLabel='Create'>
      <FormModalValueProvider>
        {({ getValue, setError }) => (
          <View style={{ gap: '16px' }}>
            <SingleSelect
              label='Item or Recipe'
              name='itemId'
              placeholder='Choose an item or recipe'
              onFilter={(_: string, value: string) => {
                getItemsToAdd({
                  variables: {
                    name: value,
                    page: 0,
                  },
                });
                getRecipesToAdd({
                  variables: {
                    name: value,
                  },
                });
              }}
              filterable
              filterPlaceholder='Enter item or recipe name'
              required
              autoFocusSearch
            >
              {(itemSearchIsLoading || recipeSearchIsLoading) ?
                <View style={{ alignItems: 'center', padding: '16px' }}>
                  <CircularSpinner size='medium' />
                </View>
                :
                <>
                  {((itemSearchData && itemSearchData.inventoryItems.items.length > 0) || (recipeSearchData && recipeSearchData.recipes.length > 0)) ?
                    (
                      <View style={{ gap: '16px' }}>
                        {(() => {
                          const items = itemSearchData?.inventoryItems.items;
                          if (!items || items.length === 0) {
                            return null;
                          }

                          return (
                            <View>
                              <StyledHeading tag='h6' style={{ paddingBottom: '4px', paddingLeft: '32px' }}>Items</StyledHeading>
                              {items.map((item, index) =>
                                <Choice label={item.name} value={item.id} key={index} />
                              )
                              }
                            </View>
                          );
                        })()}

                        {(() => {
                          const recipes = recipeSearchData?.recipes;
                          if (!recipes || recipes.length === 0) {
                            return null;
                          }

                          return (
                            <View>
                              <StyledHeading tag='h6' style={{ paddingBottom: '4px', paddingLeft: '32px' }}>Recipes</StyledHeading>
                              {recipes.map((recipe, index) =>
                                <Choice label={recipe.name} value={recipe.id} key={index} />
                              )}
                            </View>
                          );

                        })()}
                      </View>
                    )
                    :
                    <View style={{ padding: '16px' }}>
                      <StyledParagraph>No items or recipes found.</StyledParagraph>
                    </View>
                  }
                </>
              }
            </SingleSelect>
            <View>
              <SingleSelect label='Enter By' name='inputType' style={{ width: '50%', '@media (max-width: 767px)': { width: '100%' } }} required validate={(_, value) => {
                if (value === 'UNITS') {
                  setError && setError('volumeUnits', null);
                  setError && setError('weightUnits', null);
                } else if (value === 'VOLUME') {
                  setError && setError('weightUnits', null);
                } else if (value === 'WEIGHT') {
                  setError && setError('volumeUnits', null);
                }

                return null;
              }}>
                <Choice label='Units' value='UNITS' />

                {(getValue && getSelectedItem(getValue('itemId'))?.unitVolume) && <Choice label='Volume' value='VOLUME' />}
                {(getValue && getSelectedItem(getValue('itemId'))?.unitWeight) && <Choice label='Weight' value='WEIGHT' />}
              </SingleSelect>
            </View>
            <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
              <TextField label='Amount' name='amount' type='number' inputMode='decimal' required style={{ flexGrow: 0, width: '50%', '@media (max-width: 767px)': { width: '100%' } }} />
              {(getValue && getValue('inputType') === 'VOLUME') &&
                <SingleSelect label='Units' name='volumeUnits' required style={{ width: '50%', '@media (max-width: 767px)': { width: '100%' } }} >
                  <Choice label='tsp' value='TSP' />
                  <Choice label='tbsp' value='TBSP' />
                  <Choice label='fl oz' value='FLUID_OZ' />
                  <Choice label='cups' value='CUP' />
                  <Choice label='pints' value='PINT' />
                  <Choice label='quarts' value='QUART' />
                  <Choice label='gallons' value='GALLON' />
                  <Choice label='liters' value='LITER' />
                  <Choice label='milliliters' value='MILLILITER' />
                </SingleSelect>
              }
              {(getValue && getValue('inputType') === 'WEIGHT') &&
                <SingleSelect label='Units' name='weightUnits' required style={{ width: '50%', '@media (max-width: 767px)': { width: '100%' } }} >
                  <Choice label='oz' value='OUNCE' />
                  <Choice label='lbs' value='POUND' />
                  <Choice label='g' value='GRAM' />
                  <Choice label='kg' value='KILOGRAM' />
                </SingleSelect>
              }
            </View>
            <TextArea label='Notes' description='Provide an explanation of why the item was wasted' name='notes' />
          </View>
        )}
      </FormModalValueProvider>
    </FormModal>
  );

  const editWasteLogModal = (
    <FormModal<EditWasteLogInput> title='Edit Waste Log' onSubmit={handleEditWasteLog} initialValues={{ id: '', amount: '', inputType: 'UNITS', volumeUnits: '', weightUnits: '', notes: '' }}>
      <FormModalValueProvider>
        {({ getValue, setError }) => (
          <View style={{ gap: '16px' }}>
            <View>
              <SingleSelect label='Enter By' name='inputType' style={{ width: '50%', '@media (max-width: 767px)': { width: '100%' } }} required validate={(_, value) => {
                if (value === 'UNITS') {
                  setError && setError('volumeUnits', null);
                  setError && setError('weightUnits', null);
                } else if (value === 'VOLUME') {
                  setError && setError('weightUnits', null);
                } else if (value === 'WEIGHT') {
                  setError && setError('volumeUnits', null);
                }

                return null;
              }}>
                <Choice label='Units' value='UNITS' />

                {(getValue && getEditingItem(getValue('id'))?.item.unitVolume) && <Choice label='Volume' value='VOLUME' />}
                {(getValue && getEditingItem(getValue('id'))?.item.unitWeight) && <Choice label='Weight' value='WEIGHT' />}
              </SingleSelect>
            </View>
            <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
              <TextField label='Amount' name='amount' type='number' inputMode='decimal' required style={{ flexGrow: 0, width: '50%', '@media (max-width: 767px)': { width: '100%' } }} />
              {(getValue && getValue('inputType') === 'VOLUME') &&
                <SingleSelect label='Units' name='volumeUnits' required style={{ width: '50%', '@media (max-width: 767px)': { width: '100%' } }} >
                  <Choice label='tsp' value='TSP' />
                  <Choice label='tbsp' value='TBSP' />
                  <Choice label='fl oz' value='FLUID_OZ' />
                  <Choice label='cups' value='CUP' />
                  <Choice label='pints' value='PINT' />
                  <Choice label='quarts' value='QUART' />
                  <Choice label='gallons' value='GALLON' />
                  <Choice label='liters' value='LITER' />
                  <Choice label='milliliters' value='MILLILITER' />
                </SingleSelect>
              }
              {(getValue && getValue('inputType') === 'WEIGHT') &&
                <SingleSelect label='Units' name='weightUnits' required style={{ width: '50%', '@media (max-width: 767px)': { width: '100%' } }} >
                  <Choice label='oz' value='OUNCE' />
                  <Choice label='lbs' value='POUND' />
                  <Choice label='g' value='GRAM' />
                  <Choice label='kg' value='KILOGRAM' />
                </SingleSelect>
              }
            </View>
            <TextArea label='Notes' description='Provide an explanation of why the item was wasted' name='notes' />
          </View>
        )}
      </FormModalValueProvider>
    </FormModal>
  );

  const confirmDeleteModal = (
    <ConfirmModal title='Delete Waste Log?' onConfirm={handleDeleteWasteLog} confirmLabel='Delete' destructive>
      <View>
        <StyledParagraph>This waste log will be permanently deleted.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '64px', width: '100%', '@media (max-width: 767px)': { gap: '16px' } }}>
            <StyledHeading tag='h3' style={{ minWidth: 'fit-content' }}>Waste Logs</StyledHeading>
            <View style={{ alignItems: 'flex-end', flexDirection: 'row', gap: '8px', minWidth: 'fit-content' }}>
              <Icon size='medium' icon={Icons.ChevronLeft} style={dateButtonStyle} onClick={handlePreviousDayClick} />
              <DatePicker name='viewDate' value={decodeURIComponent(queryParams.get('date') || '')} required disableClear onChange={(_, value) => {
                setQueryParams({ date: value });
              }} style={{ flexGrow: 0, width: 'fit-content' }} />
              <Icon size='medium' icon={Icons.ChevronRight} style={{ ...dateButtonStyle, ...(isTodaySelected() ? dateButtonDisabledStyle : {}) }} onClick={handleNextDayClick} />
            </View>
          </View>

          <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Employee, InventoryRoles.Manager, InventoryRoles.Admin]}>
            <ModalLauncher modal={createWasteLogModal}>
              {({ openModal }) => (
                <Button label='Create Waste Log' variant='primary' role='button' action={openModal} />
              )}
            </ModalLauncher>
          </HasProductRole>
        </View>
      </Cell>
      {wasteLogData?.itemWasteLogs?.length ?
        <Cell lg={6} md={4} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '32px' }}>
              <View style={{ gap: '12px' }}>
                <StyledParagraph bold>Total Waste Cost</StyledParagraph>
                <StyledParagraph style={{ fontSize: '32px', fontWeight: 600 }}>{getTotalValue().format()}</StyledParagraph>
              </View>
              <View>
                <StyledParagraph bold>Totals By Category</StyledParagraph>
                <Table style={{ padding: '0' }}>
                  <TableHeader>
                    <TableRow>
                      <TableHeaderCell>Category</TableHeaderCell>
                      <TableHeaderCell>Total Waste Cost</TableHeaderCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {getCategoryTotals().map((c, index) => (
                      <TableRow key={index}>
                        <TableCell>{c.name}</TableCell>
                        <TableCell>{c.total.format()}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </View>
            </View>
          </Card>
        </Cell>
        :
        <></>
      }
      <Cell lg={12} md={8} sm={4}>
        {(!queryParams.get('date') || queryParams.get('date') === '') ?
          <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'center', minHeight: '200px' }}>
            <StyledParagraph>Select a date to view waste logs.</StyledParagraph>
          </View>
          :
          <View style={{ gap: '16px' }}>
            {wasteLogsAreLoading ?
              <AtomSpinner size='medium' />
              :
              wasteLogData?.itemWasteLogs?.length ?
                wasteLogData?.itemWasteLogs.map((w, index) => {
                  let amountType = 'UNITS';
                  let volumeUnits = '';
                  let weightUnits = '';
                  if (w.amountType.__typename === 'WasteLogVolume') {
                    amountType = 'VOLUME';
                    volumeUnits = w.amountType.volumeUnits;
                  } else if (w.amountType.__typename === 'WasteLogWeight') {
                    amountType = 'WEIGHT';
                    weightUnits = w.amountType.weightUnits;
                  }

                  if (w.item.__typename === 'InventoryItem') {
                    return (
                      <Card size='medium' key={index}>
                        <View style={{ flexDirection: 'row', gap: '32px' }}>
                          <View style={{ flexGrow: 1, gap: '16px', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
                            <View style={{ flexDirection: 'row', flexGrow: 1, '@media (max-width: 1151px)': { flexDirection: 'column' } }}>
                              <View style={{ maxWidth: '350px', minWidth: '350px', width: '350px', '@media (max-width: 767px)': { minWidth: '0px', width: 'fit-content' } }}>
                                <Link href={`/items/${w.item.id}`} linkStyle={{ color: Colors.neutral900, width: 'fit-content' }}><StyledHeading tag='h6'>{w.item.name}</StyledHeading></Link>
                                <StyledParagraph>{getItemAmountLabel(w)}</StyledParagraph>
                              </View>
                              <View style={{ flexGrow: 2 }}>
                                <StyledParagraph style={{ color: Colors.neutral700 }}>{w.notes}</StyledParagraph>
                              </View>
                            </View>
                            <View style={{ justifyContent: 'center', maxWidth: '150px', minWidth: '150px', width: '150px' }}>
                              <StyledParagraph bold style={{ fontSize: '18px' }}>{getItemValue(w)?.format()}</StyledParagraph>
                            </View>
                          </View>
                          <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Employee, InventoryRoles.Manager, InventoryRoles.Admin]}>
                            <View style={{ height: '100%', justifyContent: 'flex-start', width: '16px' }}>
                              <ModalLauncher modal={confirmDeleteModal}>
                                {({ openModal: openDeleteModal }) => (
                                  <ModalLauncher modal={editWasteLogModal}>
                                    {({ openModal: openEditModal }) => (
                                      <ActionMenu alignment='right'>
                                        <ActionItem label='Edit' onClick={() => { openEditModal({ id: w.id, amount: w.amount, inputType: amountType, volumeUnits: volumeUnits, weightUnits: weightUnits, notes: w.notes }); }} />
                                        <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
                                          <ActionItem label='Delete' onClick={() => { openDeleteModal(w.id); }} />
                                        </HasProductRole>
                                      </ActionMenu>
                                    )}
                                  </ModalLauncher>
                                )}
                              </ModalLauncher>
                            </View>
                          </HasProductRole>
                        </View>
                      </Card>
                    );
                  } else if (w.item.__typename === 'Recipe') {
                    return (
                      <Card size='medium' key={index}>
                        <View style={{ flexDirection: 'row', gap: '32px' }}>
                          <View style={{ flexGrow: 1, gap: '16px', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
                            <View style={{ flexDirection: 'row', flexGrow: 1, '@media (max-width: 1151px)': { flexDirection: 'column' } }}>
                              <View style={{ maxWidth: '350px', minWidth: '350px', width: '350px', '@media (max-width: 767px)': { minWidth: '0px', width: 'fit-content' } }}>
                                <Link href={`/recipes/${w.item}/${w.item.id}`} linkStyle={{ color: Colors.neutral900, width: 'fit-content' }}><StyledHeading tag='h6'>{w.item.name}</StyledHeading></Link>
                                <StyledParagraph>{getItemAmountLabel(w)}</StyledParagraph>
                              </View>
                              <View style={{ flexGrow: 2 }}>
                                <StyledParagraph style={{ color: Colors.neutral700 }}>{w.notes}</StyledParagraph>
                              </View>
                            </View>
                            <View style={{ justifyContent: 'center', maxWidth: '150px', minWidth: '150px', width: '150px' }}>
                              <StyledParagraph bold style={{ fontSize: '18px' }}>{getItemValue(w)?.format()}</StyledParagraph>
                            </View>
                          </View>
                          <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Employee, InventoryRoles.Manager, InventoryRoles.Admin]}>
                            <View style={{ height: '100%', justifyContent: 'flex-start', width: '16px' }}>
                              <ModalLauncher modal={confirmDeleteModal}>
                                {({ openModal: openDeleteModal }) => (
                                  <ModalLauncher modal={editWasteLogModal}>
                                    {({ openModal: openEditModal }) => (
                                      <ActionMenu alignment='right'>
                                        <ActionItem label='Edit' onClick={() => { openEditModal({ id: w.id, amount: w.amount, inputType: amountType, volumeUnits: volumeUnits, weightUnits: weightUnits, notes: w.notes }); }} />
                                        <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
                                          <ActionItem label='Delete' onClick={() => { openDeleteModal(w.id); }} />
                                        </HasProductRole>
                                      </ActionMenu>
                                    )}
                                  </ModalLauncher>
                                )}
                              </ModalLauncher>
                            </View>
                          </HasProductRole>
                        </View>
                      </Card>
                    );
                  } else {
                    return null;
                  }
                })
                :
                <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'center', minHeight: '200px' }}>
                  <StyledParagraph>Woohoo! There is no recorded waste for this day.</StyledParagraph>
                </View>
            }
          </View>
        }
      </Cell>
    </StandardGrid>
  );
}