export const parseVolumeUnits = (type: string | null, amount: number) => {
  switch (type) {
    case 'CUP':
      return amount > 1 ? 'cups' : 'cup';
    case 'FLUID_OZ':
      return 'fl oz';
    case 'GALLON':
      return amount > 1 ? 'gallons' : 'gallon';
    case 'LITER':
      return 'L';
    case 'MILLILITER':
      return 'mL';
    case 'PINT':
      return amount > 1 ? 'pints' : 'pint';
    case 'QUART':
      return amount > 1 ? 'quarts' : 'quart';
    case 'TBSP':
      return 'tbsp';
    case 'TSP':
      return 'tsp';
    default:
      return 'Unkown Type';
  }
}

export const parseWeightUnits = (type: string | null, amount: number) => {
  switch (type) {
    case 'GRAM':
      return 'g';
    case 'KILOGRAM':
      return 'kg';
    case 'OUNCE':
      return 'oz';
    case 'POUND':
      return 'lb';
    default:
      return 'Unknown Type'
  }
}