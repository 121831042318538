import { gql, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, Breadcrumb, BreadcrumbGroup, Card, Cell, CircularSpinner, Colors, ErrorPage, NotFound, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, View } from "@barscience/global-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

/* Get Sales Period Query */
const GET_SALES_PERIOD = gql`
query getSalesPeriodCategoryDetails($id: ID!) {
  inventorySalesPeriod(id: $id) {
    id
    name
    endDate
    salesByCategory {
      category {
        id
        name
      }
      totalRevenue
    }
  }
}
`;

type GetSalesPeriodResponse = {
  inventorySalesPeriod: SalesPeriod | null;
}

type SalesPeriod = {
  id: string;
  name: string;
  endDate: string;
  salesByCategory: SalesByCategory[];
}

type SalesByCategory = {
  category: Category;
  totalRevenue: string;
}

type Category = {
  id: string;
  name: string;
}

/* Get Item Categories Query */
const GET_ITEM_CATEGORIES = gql`
query getAllItemCategories {
  itemCategories {
    id
    name
    isArchived
  }
}
`;

type GetItemCategoriesResponse = {
  itemCategories: ItemCategory[] | null;
}

type ItemCategory = {
  id: string;
  name: string;
  isArchived: boolean;
}

/* Edit Category Sales Mutation */
const EDIT_CATEGORY_SALES = gql`
mutation setInventoryCategorySales($salesPeriodId: ID!, $categoryId: ID!, $totalRevenue: Decimal!) {
  setInventoryCategorySales(salesPeriodId: $salesPeriodId, categoryId: $categoryId, totalRevenue: $totalRevenue) {
    category {
      id
      name
    }
    totalRevenue
  }
}
`;

type EditCategorySalesResponse = {
  setInventoryCategorySales: SalesByCategory | null;
}

export default function EditCategorySales() {
  const { periodId } = useParams<{ periodId: string }>();
  const [salesValues, setSalesValues] = useState<{ [id: string]: string }>({});
  const { data: salesData, loading: salesAreLoading, error: salesError } = useQuery<GetSalesPeriodResponse>(GET_SALES_PERIOD, {
    variables: {
      id: periodId,
    },
  });
  const { data: categoriesData, loading: categoriesAreLoading, error: categoriesError } = useQuery<GetItemCategoriesResponse>(GET_ITEM_CATEGORIES);
  const [editCategorySales, { loading: editCategoryIsLoading }] = useMutation<EditCategorySalesResponse>(EDIT_CATEGORY_SALES, {
    update(cache, { data }, { variables }) {
      if (!variables || !variables.salesPeriodId) {
        return;
      }

      const { inventorySalesPeriod } = cache.readQuery<GetSalesPeriodResponse>({
        query: GET_SALES_PERIOD,
        variables: {
          id: variables.salesPeriodId,
        },
      }) || { inventorySalesPeriod: null };

      if (inventorySalesPeriod) {
        // If the new amount is 0, remove the category from the sales period
        if (variables?.totalRevenue === '$0.00') {
          cache.writeQuery({
            query: GET_SALES_PERIOD,
            variables: {
              id: variables.salesPeriodId,
            },
            data: {
              inventorySalesPeriod: {
                ...inventorySalesPeriod,
                salesByCategory: inventorySalesPeriod.salesByCategory.filter(s => s.category.id !== variables.categoryId),
              },
            },
          });
          return;
        }

        // If the category is already in the sales period, update it
        const newSalesByCategory = inventorySalesPeriod.salesByCategory.map(s => {
          if (s.category.id === variables.categoryId) {
            return data?.setInventoryCategorySales || s;
          }
          return s;
        });

        // If the category is not in the sales period, add it
        if (newSalesByCategory.length === inventorySalesPeriod.salesByCategory.length && data?.setInventoryCategorySales) {
          newSalesByCategory.push(data?.setInventoryCategorySales);
        }

        // Update the cache
        cache.writeQuery({
          query: GET_SALES_PERIOD,
          variables: {
            id: variables.salesPeriodId,
          },
          data: {
            inventorySalesPeriod: {
              ...inventorySalesPeriod,
              salesByCategory: newSalesByCategory,
            },
          },
        });
      }
    },
  });

  useEffect(() => {
    salesData?.inventorySalesPeriod?.salesByCategory.forEach(c => {
      setSalesValues(prev => ({ ...prev, [c.category.id]: c.totalRevenue }));
    });
  }, [salesData]);

  const handleEditCategory = async (id: string, value: string) => {
    await editCategorySales({
      variables: {
        salesPeriodId: periodId,
        categoryId: id,
        totalRevenue: value === '' ? '$0.00' : value,
      },
    });
  }

  const handleSalesChange = (id: string, value: string) => {
    setSalesValues(prev => ({ ...prev, [id]: value }));
  }

  if (salesAreLoading || categoriesAreLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (!periodId || salesError?.graphQLErrors[0]?.extensions.status === 404 || categoriesError?.graphQLErrors[0]?.extensions.status === 404) {
    return (
      <StandardGrid>
        <NotFound />
      </StandardGrid>
    );
  }

  if (!salesData?.inventorySalesPeriod || !categoriesData?.itemCategories) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Sales Periods' to='/sales' />
          <Breadcrumb label={salesData.inventorySalesPeriod.name} to={`/sales/${salesData.inventorySalesPeriod.id}`} />
          <Breadcrumb label='Edit Category Sales' />
        </BreadcrumbGroup>
      </Cell>

      <Cell lg={6} md={6} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
          <StyledHeading tag='h3'>Edit Category Sales</StyledHeading>
          {editCategoryIsLoading ?
            <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
              <CircularSpinner size='xsmall' />
              <StyledParagraph style={{ color: Colors.neutral700 }}>Saving changes...</StyledParagraph>
            </View>
            :
            <View>
              <StyledParagraph style={{ color: Colors.neutral700 }}>All changes saved</StyledParagraph>
            </View>
          }
        </View>
      </Cell>

      <Cell lg={6} md={6} sm={4}>
        <Card size='medium'>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Category</TableHeaderCell>
                <TableHeaderCell>Total Revenue</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {categoriesData.itemCategories.map((c, index) => (
                <TableRow key={index}>
                  <TableCell>{c.name}</TableCell>
                  <TableCell style={{ padding: '8px 0' }}>
                    <TextField type='currency' inputMode='decimal' name={c.id} value={salesValues[c.id] || ''} error={''} onChange={handleSalesChange} onBlur={handleEditCategory} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </Cell>
    </StandardGrid>
  );
}