import { gql, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, AutoOpen, Button, Card, Cell, Choice, Colors, DatePicker, FormModal, HasProductRole, InventoryRoles, Link, ModalLauncher, PageButtons, Products, SingleSelect, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, View } from "@barscience/global-components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

/* Get All Item Counts Query */
const GET_ITEM_COUNTS = gql`
query getAllItemCountLists($dateRange: DateRange, $page: Int!) {
  itemCountLists(dateRange: $dateRange, page: $page) {
    itemCountLists {
      id
      name
      date
    }
    pages
  }
}
`;

type GetItemCountsResponse = {
  itemCountLists: {
    itemCountLists: {
      id: string;
      name: string;
      date: string;
    }[];
    pages: number;
  } | null;
}

/* Create Item Count Mutation */
const CREATE_ITEM_COUNT = gql`
mutation createItemCountList($input: CreateItemCountListInput!) {
  createItemCountList(input: $input) {
    id
    name
    date
  }
}
`;

type CreateItemCountResponse = {
  createItemCountList: {
    id: string;
    name: string;
    date: string;
  };
}

type CreateItemCountInput = {
  name: string;
  date: string;
}

/* Util Types */
type DateRange = {
  startDate: string;
  endDate: string;
}

export default function AllItemCounts() {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);
  const [dateRange, setDateRange] = useState<DateRange | null>(null);
  const [selectedRange, setSelectedRange] = useState<string | null>('ALL_TIME');
  const { data: itemCountData, loading: itemCountsAreLoading } = useQuery<GetItemCountsResponse>(GET_ITEM_COUNTS, {
    variables: {
      page: page,
      dateRange: selectedRange === 'CUSTOM' ? dateRange : null,
    },
    fetchPolicy: 'network-only',
  });
  const [createItemCountList] = useMutation<CreateItemCountResponse>(CREATE_ITEM_COUNT);

  const handleCreateItemCount = async (values: CreateItemCountInput) => {
    const { data } = await createItemCountList({
      variables: {
        input: {
          name: values.name,
          date: values.date,
        },
      },
    });

    navigate('/item-counts/' + data?.createItemCountList.id + '/edit');
  }

  const handleDateFilterChange = (_: string, value: string | null) => {
    setSelectedRange(value);
  }

  const handleDateRangePick = async (values: DateRange) => {
    setDateRange({
      startDate: values.startDate,
      endDate: values.endDate,
    });

    setSelectedRange('CUSTOM');
  }

  const handleCancelDateRangePick = () => {
    setSelectedRange('ALL_TIME');
  }

  const getDateLabel = (): string => {
    if (!dateRange) {
      return '';
    }

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const start = new Date(dateRange.startDate);
    const end = new Date(dateRange.endDate);

    const label = `${months[start.getMonth()]} ${start.getDate()}${getDayLabel(start.getDate())} - ${months[end.getMonth()]} ${end.getDate()}${getDayLabel(end.getDate())}`;

    return label;
  }

  const getDayLabel = (day: number) => {
    const str = day.toString();
    if (str.charAt(str.length - 1) === '1') {
      return 'st';
    } else if (str.charAt(str.length - 1) === '2') {
      return 'nd';
    } if (str.charAt(str.length - 1) === '3') {
      return 'rd';
    } else {
      return 'th';
    }
  }

  const customRangeModal = (
    <FormModal<DateRange> title='Select date range' onSubmit={handleDateRangePick} onCancel={handleCancelDateRangePick} showCloseButton={false} submitLabel='Confirm' initialValues={{ startDate: dateRange?.startDate || '', endDate: dateRange?.endDate || '' }}>
      <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 1151px)': { flexDirection: 'column' } }}>
        <DatePicker label='Start Date' name='startDate' required />
        <DatePicker label='End Date' name='endDate' required />
      </View>
    </FormModal>
  );

  const createItemCountModal = (
    <FormModal<CreateItemCountInput> title='Create Item Count' onSubmit={handleCreateItemCount} submitLabel='Create' initialValues={{ name: '', date: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Name' description='A descriptive name for the item count' name='name' required />
        <DatePicker label='Date' description='The date the item count is performed' name='date' required style={{ width: '100%' }} />
      </View>
    </FormModal>
  );

  return (
    <StandardGrid>
      <ModalLauncher modal={customRangeModal}>
        {({ openModal: openPickDateModal }) => {
          if (selectedRange === 'SET_CUSTOM') {
            return <AutoOpen openModal={openPickDateModal} />
          }

          return <></>
        }}
      </ModalLauncher>

      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', justifyContent: 'space-between', '@media (max-width: 767px)': { alignItems: 'flex-start', flexDirection: 'column' } }}>
          <View style={{ gap: '8px' }}>
            <StyledHeading tag='h3'>Item Counts</StyledHeading>
            <StyledParagraph>Item counts are used to perform inventory counts at the end of a period, or throughout a period to check for missing items.</StyledParagraph>
          </View>
          <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
            <ModalLauncher modal={createItemCountModal}>
              {({ openModal }) => (
                <Button label='Create Item Count' role='button' action={openModal} variant='primary' />
              )}
            </ModalLauncher>
          </HasProductRole>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <SingleSelect name='dateRange' value={selectedRange} onChange={handleDateFilterChange} style={{ maxWidth: '255px', width: '225px' }}>
            <Choice label='All Time' value='ALL_TIME' />
            {dateRange && <Choice label={getDateLabel()} value='CUSTOM' />}
            <View style={{ borderTop: `1px solid ${Colors.neutral200}`, marginTop: '4px', paddingTop: '4px' }}>
              <Choice label='Custom Range' value='SET_CUSTOM' />
            </View>
          </SingleSelect>
          <PageButtons currentPage={page} numPages={itemCountData?.itemCountLists?.pages || 0} onPageChange={setPage} />
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <Card size='medium'>
          {itemCountsAreLoading ?
            <AtomSpinner size='large' />
            :
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Name</TableHeaderCell>
                  <TableHeaderCell>Date</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {itemCountData?.itemCountLists?.itemCountLists.map((count, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell><Link href={'/item-counts/' + count.id}>{count.name}</Link></TableCell>
                      <TableCell>{count.date}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          }
        </Card>
      </Cell>
    </StandardGrid>
  );
}