import { gql, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Checkbox, Choice, CircularSpinner, FormModal, FormModalValueProvider, HasProductRole, InventoryRoles, Link, ModalLauncher, NotFound, Products, SingleSelect, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, View } from "@barscience/global-components";
import { useNavigate, useParams } from "react-router-dom";

/* Get Recipe Query */
export const GET_RECIPE_CATEGORY = gql`
query getRecipeCategory($id: ID!) {
  recipeCategory(id: $id) {
    id
    name
    description
    recipes {
      id
      name
      itemCategory {
        id
        name
      }
    }
  }
}
`;

export type GetRecipeCategoryResponse = {
  recipeCategory: RecipeCategory | null;
}

type RecipeCategory = {
  id: string;
  name: string;
  description: string;
  recipes: Recipe[];
}

type Recipe = {
  id: string;
  name: string;
  itemCategory: {
    id: string;
    name: string;
  };
}

/* Get Recipe Categories Query */
const GET_RECIPE_CATEGORIES = gql`
query getAllRecipeCategories {
  recipeCategories {
    id
    name
    description
  }
}
`;

type GetAllRecipeCategoriesResponse = {
  recipeCategories: {
    id: string;
    name: string;
    description: string;
  }[];
}


/* Get Item Categories Query */
const GET_ITEM_CATEGORIES = gql`
query getItemCategoriesForRecipes {
  itemCategories {
    id
    name
  }
}
`;

type GetItemCategoriesResponse = {
  itemCategories: {
    id: string;
    name: string;
  }[];
}

/* Create Recipe Mutation */
const CREATE_RECIPE = gql`
mutation createRecipe($input: CreateRecipeInput!) {
  createRecipe(input: $input) {
    id
    name
    itemCategory {
      id
      name
    }
  }
}
`;

type CreateRecipeResponse = {
  createRecipe: {
    id: string;
    name: string;
    itemCategory: {
      id: string;
      name: string;
    };
  };
}

type CreateRecipeInput = {
  name: string;
  recipeCategoryId: string;
  itemCategoryId: string;
  unitsPerBatch: string;
  unitVolumeEnabled: boolean;
  unitVolume: string;
  unitVolumeType: string;
  unitWeightEnabled: boolean;
  unitWeight: string;
  unitWeightType: string;
}

const createRecipeInitialValues = {
  name: '',
  recipeCategoryId: '',
  itemCategoryId: '',
  unitsPerBatch: '',
  unitVolumeEnabled: false,
  unitVolume: '',
  unitVolumeType: '',
  unitWeightEnabled: false,
  unitWeight: '',
  unitWeightType: '',
}

export default function RecipeCategoryDetail() {
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const { data: categoryData, loading: categoryIsLoading, error: categoryError } = useQuery<GetRecipeCategoryResponse>(GET_RECIPE_CATEGORY, {
    variables: {
      id: categoryId,
    },
  });
  const { data: itemCategoriesData, loading: itemCategoriesAreLoading } = useQuery<GetItemCategoriesResponse>(GET_ITEM_CATEGORIES);
  const { data: recipeCategoriesData, loading: recipeCategoriesAreLoading } = useQuery<GetAllRecipeCategoriesResponse>(GET_RECIPE_CATEGORIES);
  const [createRecipe] = useMutation<CreateRecipeResponse>(CREATE_RECIPE, {
    update(cache, { data }) {
      const newRecipe = data?.createRecipe;
      
      const categoryData = cache.readQuery<GetRecipeCategoryResponse>({
        query: GET_RECIPE_CATEGORY,
        variables: {
          id: categoryId,
        },
      });

      if (!categoryData?.recipeCategory || !newRecipe) {
        return;
      }

      const newRecipes = [
        ...categoryData.recipeCategory.recipes,
        newRecipe,
      ];

      // Sort recipes by name
      newRecipes.sort((a, b) => a.name.localeCompare(b.name));

      cache.writeQuery({
        query: GET_RECIPE_CATEGORY,
        variables: {
          id: categoryId,
        },
        data: {
          recipeCategory: {
            ...categoryData.recipeCategory,
            recipes: newRecipes,
          },
        },
      });
    },
  });

  if (!categoryId || categoryError?.graphQLErrors[0].extensions.status === 404) {
    return <NotFound />;
  }

  const handleCreateRecipe = async (values: CreateRecipeInput) => {
    const { data } = await createRecipe({
      variables: {
        input: {
          name: values.name,
          recipeCategoryId: values.recipeCategoryId,
          itemCategoryId: values.itemCategoryId,
          unitsPerBatch: parseFloat(values.unitsPerBatch),
          unitVolume: values.unitVolumeEnabled ? parseFloat(values.unitVolume) : null,
          unitVolumeType: values.unitVolumeEnabled ? values.unitVolumeType : null,
          unitWeight: values.unitWeightEnabled ? parseFloat(values.unitWeight) : null,
          unitWeightType: values.unitWeightEnabled ? values.unitWeightType : null,
        },
      },
    });

    if (data?.createRecipe.id) {
      navigate(`/recipes/${categoryId}/${data.createRecipe.id}`);
    }
  }

  const createRecipeModal = (
    <FormModal<CreateRecipeInput> title='Create Recipe' onSubmit={handleCreateRecipe} submitLabel='Create' initialValues={{ ...createRecipeInitialValues, recipeCategoryId: categoryId || '' }}>
      <FormModalValueProvider>
        {({ getValue, setError }) => {
          if (recipeCategoriesAreLoading || itemCategoriesAreLoading) {
            return <CircularSpinner size='medium' />;
          }

          return (
            <View style={{ gap: '16px' }}>
              <TextField label='Recipe Name' name='name' type='text' required />
              <SingleSelect label='Recipe Category' name='recipeCategoryId' required>
                {recipeCategoriesData?.recipeCategories.map((c) => {
                  return (
                    <Choice label={c.name} value={c.id} />
                  );
                })}
              </SingleSelect>
              <SingleSelect label='Item Category' name='itemCategoryId' required>
                {itemCategoriesData?.itemCategories.map((c) => {
                  return (
                    <Choice label={c.name} value={c.id} />
                  );
                })}
              </SingleSelect>
              <TextField label='Units Per Batch' name='unitsPerBatch' type='number' inputMode='decimal' required />

              <View style={{ paddingTop: '32px', gap: '48px' }}>
                <View style={{ gap: '16px' }}>
                  <View style={{ gap: '8px' }}>
                    <StyledHeading tag='h6'>Volume Measurements</StyledHeading>
                  </View>
                  <Checkbox name='unitVolumeEnabled' label='Enable volume measurements' description='Allows this recipe to be input by unit volume for item counts' validate={(_, value) => {
                    if (!value && setError) {
                      setError('unitVolume', null);
                      setError('unitVolumeType', null);
                    }

                    return null;
                  }} />
                  {(getValue && getValue('unitVolumeEnabled')) && (
                    <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
                      <TextField name='unitVolume' label='Unit Volume' type='number' inputMode='decimal' style={{ flex: '1 1 0px', '@media (max-width: 767px)': { flex: '0' } }} required validate={(_, value) => {
                        const num = parseFloat(value);
                        if (num < 0) {
                          return 'Unit volume must be greater than 0'
                        }
                        return null;
                      }} />
                      <SingleSelect name='unitVolumeType' label='Volume Type' style={{ flex: '1 1 0px', '@media (max-width: 767px)': { flex: '0' } }} required >
                        <Choice label='Fluid Ounce' value='FLUID_OZ' />
                        <Choice label='Cup' value='CUP' />
                        <Choice label='Gallon' value='GALLON' />
                        <Choice label='Liter' value='LITER' />
                        <Choice label='Milliliter' value='MILLILITER' />
                        <Choice label='Pint' value='PINT' />
                        <Choice label='Quart' value='QUART' />
                        <Choice label='Tablespoon' value='TBSP' />
                        <Choice label='Teaspoon' value='TSP' />
                      </SingleSelect>
                    </View>
                  )}
                </View>
                <View style={{ gap: '16px' }}>
                  <View style={{ gap: '8px' }}>
                    <StyledHeading tag='h6'>Weight Measurements</StyledHeading>
                  </View>
                  <Checkbox name='unitWeightEnabled' label='Enable weight measurements' description='Allows this item to be input by unit weight for item counts' validate={(_, value) => {
                    if (!value && setError) {
                      setError('unitWeight', null);
                      setError('unitWeightType', null);
                    }

                    return null;
                  }} />
                  {(getValue && getValue('unitWeightEnabled')) && (
                    <View style={{ gap: '16px' }}>
                      <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
                        <TextField name='unitWeight' label='Unit Weight' type='number' inputMode='decimal' style={{ flex: '1 1 0px', '@media (max-width: 767px)': { flex: '0' } }} required validate={(_, value) => {
                          const num = parseFloat(value);
                          if (num < 0) {
                            return 'Unit weight must be greater than 0'
                          }
                          return null;
                        }} />
                        <SingleSelect name='unitWeightType' label='Weight Type' style={{ flex: '1 1 0px', '@media (max-width: 767px)': { flex: '0' } }} required >
                          <Choice label='Ounce' value='OUNCE' />
                          <Choice label='Pound' value='POUND' />
                          <Choice label='Gram' value='GRAM' />
                          <Choice label='Kilogram' value='KILOGRAM' />
                        </SingleSelect>
                      </View>
                    </View>
                  )}
                </View>
              </View>
            </View>
          );
        }}
      </FormModalValueProvider>
    </FormModal>
  );

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Recipes' to='/recipes' />
          <Breadcrumb label={categoryData?.recipeCategory?.name || ''} />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', gap: '16px', justifyContent: 'space-between', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
          <View style={{ gap: '8px' }}>
            <StyledHeading tag='h3'>{categoryData?.recipeCategory?.name}</StyledHeading>
            <StyledParagraph>{categoryData?.recipeCategory?.description}</StyledParagraph>
          </View>
          <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
            <ModalLauncher modal={createRecipeModal}>
              {({ openModal }) => (
                <Button label='Create Recipe' variant='primary' role='button' action={openModal} />
              )}
            </ModalLauncher>
          </HasProductRole>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {categoryIsLoading && <AtomSpinner size='large' />}
        {!categoryIsLoading &&
          <Card size='medium'>
            <Table>
              <TableHeader>
                <TableHeaderCell style={{ minWidth: '80px' }}>Name</TableHeaderCell>
                <TableHeaderCell>Item Category</TableHeaderCell>
              </TableHeader>
              <TableBody>
                {categoryData?.recipeCategory?.recipes.map((r) => {
                  return (
                    <TableRow>
                      <TableCell><Link href={`/recipes/${categoryId}/${r.id}`}>{r.name}</Link></TableCell>
                      <TableCell>{r.itemCategory.name}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Card>
        }
      </Cell>
    </StandardGrid>
  );
}