import { gql, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Button, Card, Cell, Choice, FormModal, FormModalValueProvider, HasProductRole, InventoryRoles, Link, ModalLauncher, Products, SingleSelect, StandardGrid, StyledHeading, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextArea, TextField, View } from "@barscience/global-components";

/* Get All Categories Query */
const GET_ALL_RECIPE_CATEGORIES = gql`
query getAllRecipeCategories {
  recipeCategories {
    id
    name
    description
  }
}
`;

type GetAllRecipeCategoriesResponse = {
  recipeCategories: RecipeCategory[] | null;
}

type RecipeCategory = {
  id: string;
  name: string;
  description: string | null;
}

/* Create Category Mutation */
const CREATE_RECIPE_CATEGORY = gql`
mutation createRecipeCategory($input: CreateRecipeCategoryInput!) {
  createRecipeCategory(input: $input) {
    id
    name
    description
  }
}
`;

type CreateRecipeCategoryResponse = {
  createRecipeCategory: RecipeCategory;
}

type CreateRecipeCategoryInput = {
  name: string;
  description: string;
}

/* Edit Category Mutation */
const EDIT_RECIPE_CATEGORY = gql`
mutation editRecipeCategory($id: ID!, $input: EditRecipeCategoryInput!) {
  editRecipeCategory(id: $id, input: $input) {
    id
    name
    description
  }
}
`;

type EditRecipeCategoryResponse = {
  editRecipeCategory: RecipeCategory;
}

type EditRecipeCategoryInput = {
  id: string;
  name: string;
  description: string;
}

/* Delete Category Mutation */
const DELETE_CATEGORY = gql`
mutation deleteRecipeCategory($id: ID!, $moveToCategoryId: ID!) {
  deleteRecipeCategory(id: $id, moveToCategoryId: $moveToCategoryId) {
    id
  }
}
`;

type DeleteCategoryResponse = {
  deleteRecipeCategory: {
    id: string;
  };
}

type DeleteCategoryInput = {
  categoryId: string;
  alternateCategoryId: string;
}

export default function AllRecipeCategories() {
  const { data: categoriesData, loading: categoriesAreLoading, refetch: refetchRecipeCategories } = useQuery<GetAllRecipeCategoriesResponse>(GET_ALL_RECIPE_CATEGORIES);
  const [createRecipeCategory] = useMutation<CreateRecipeCategoryResponse>(CREATE_RECIPE_CATEGORY);
  const [editRecipeCategory] = useMutation<EditRecipeCategoryResponse>(EDIT_RECIPE_CATEGORY);
  const [deleteCategory] = useMutation<DeleteCategoryResponse>(DELETE_CATEGORY);

  const handleCreateRecipeCategory = async (values: CreateRecipeCategoryInput) => {
    await createRecipeCategory({
      variables: {
        input: {
          name: values.name,
          description: values.description,
        },
      },
    });

    await refetchRecipeCategories();
  }

  const handleEditRecipeCategory = async (values: EditRecipeCategoryInput) => {
    await editRecipeCategory({
      variables: {
        id: values.id,
        input: {
          name: values.name,
          description: values.description,
        },
      },
    });
  }

  const handleDeleteCategory = async (values: DeleteCategoryInput) => {
    await deleteCategory({
      variables: {
        id: values.categoryId,
        moveToCategoryId: values.alternateCategoryId,
      },
    });

    refetchRecipeCategories();
  }

  const createRecipeCategoryModal = (
    <FormModal<CreateRecipeCategoryInput> title='Create Recipe Category' initialValues={{ name: '', description: '' }} onSubmit={handleCreateRecipeCategory} submitLabel='Create'>
      <View style={{ gap: '16px' }}>
        <TextField label='Name' name='name' required />
        <TextArea label='Description' name='description' />
      </View>
    </FormModal>
  );

  const editRecipeCategoryModal = (
    <FormModal<EditRecipeCategoryInput> title='Edit Recipe Category' initialValues={{ id: '', name: '', description: '' }} onSubmit={handleEditRecipeCategory}>
      <View style={{ gap: '16px' }}>
        <TextField label='Name' name='name' required />
        <TextArea label='Description' name='description' />
      </View>
    </FormModal>
  );

  const deleteCategoryModal = (
    <FormModal<DeleteCategoryInput> title='Delete Category?' onSubmit={handleDeleteCategory} submitLabel='Delete' initialValues={{ categoryId: '', alternateCategoryId: '' }} destructive>
      <FormModalValueProvider>
        {({ getValue }) => (
          <View>
            <SingleSelect label='Alternate Category' name='alternateCategoryId' description='Any recipes assigned to the deleted category (including archived recipes) will be moved to this category.' required>
              {categoriesData?.recipeCategories?.filter((c) => c.id !== (getValue && getValue('categoryId'))).map((c) => {
                return (
                  <Choice label={c.name} value={c.id} />
                );
              })}
            </SingleSelect>
          </View>
        )}
      </FormModalValueProvider>
    </FormModal>
  );

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', gap: '16px', justifyContent: 'space-between', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
          <StyledHeading tag='h3'>Recipe Categories</StyledHeading>
          <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
            <ModalLauncher modal={createRecipeCategoryModal}>
              {({ openModal }) => (
                <Button label='Create Category' variant='primary' role='button' action={openModal} />
              )}
            </ModalLauncher>
          </HasProductRole>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {categoriesAreLoading && <AtomSpinner size='large' />}
        {!categoriesAreLoading &&
          <Card size='medium'>
            <Table>
              <TableHeader>
                <TableHeaderCell style={{ minWidth: '80px' }}>Name</TableHeaderCell>
                <TableHeaderCell>Description</TableHeaderCell>
                <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
                  <TableHeaderCell style={{ margin: '0', padding: '0', width: '16px' }}></TableHeaderCell>
                </HasProductRole>
              </TableHeader>
              <TableBody>
                {categoriesData?.recipeCategories?.map((c, index) => {
                  return (
                    <TableRow key={index} >
                      <TableCell><Link href={'/recipes/' + c.id}>{c.name}</Link></TableCell>
                      <TableCell>{c.description}</TableCell>
                      <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
                        <TableCell style={{ margin: '0', padding: '0', width: '16px' }}>
                          <ModalLauncher modal={editRecipeCategoryModal}>
                            {({ openModal: openEditModal }) => (
                              <ModalLauncher modal={deleteCategoryModal}>
                                {({ openModal: openDeleteModal }) => (
                                  <ActionMenu alignment='right'>
                                    <ActionItem label='Edit' onClick={() => { openEditModal({ id: c.id, name: c.name, description: c.description }); }} />
                                    <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Admin]}>
                                      <ActionItem label='Delete' onClick={() => { openDeleteModal({ categoryId: c.id }); }} />
                                    </HasProductRole>
                                  </ActionMenu>
                                )}
                              </ModalLauncher>
                            )}
                          </ModalLauncher>
                        </TableCell>
                      </HasProductRole>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Card>
        }
      </Cell>
    </StandardGrid>
  );
}