import { gql, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Button, Card, Cell, ConfirmModal, FormModal, HasProductRole, InventoryRoles, Link, ModalLauncher, OptionBar, OptionItem, Products, StandardGrid, Strut, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, View } from "@barscience/global-components";
import { useState } from "react";

const GET_ALL_VENDORS = gql`
query getAllVendors($filter: VendorFilter) {
  vendors(filter: $filter) {
    id
    name
    representativeName
    representativeEmail
    representativePhone
    orderWebsite
    accountNumber
    isArchived
  }
}
`;

type GetAllVendorsResponse = {
  vendors: Vendor[];
}

type Vendor = {
  id: string;
  name: string;
  representativeName: string | null;
  representativeEmail: string | null;
  representativePhone: string | null;
  orderWebsite: string | null;
  accountNumber: string | null;
  isArchived: boolean;
}

const CREATE_VENDOR = gql`
mutation createVendor($input: CreateVendorInput!) {
  vendor: createVendor(input: $input) {
    id
    name
    representativeName
    representativeEmail
    representativePhone
    orderWebsite
    accountNumber
    isArchived
  }
}
`;

type CreateVendorResponse = {
  vendor: Vendor
}

type CreateVendorFormType = {
  name: string;
  accountNumber: string;
  orderWebsite: string;
  repName: string;
  repEmail: string;
  repPhone: string;
}

const createVendorFormInitialValues: CreateVendorFormType = {
  name: '',
  accountNumber: '',
  orderWebsite: '',
  repName: '',
  repEmail: '',
  repPhone: '',
}

const ARCHIVE_VENDOR = gql`
mutation archiveVendor($id: ID!) {
  vendor: archiveVendor(id: $id) {
    id
    name
    representativeName
    representativeEmail
    representativePhone
    orderWebsite
    accountNumber
    isArchived
  }
}
`;

type ArchiveVendorResponse = {
  vendor: Vendor;
}

export default function AllVendors() {
  const [filter, setFilter] = useState<string>('ACTIVE');
  const { data, loading } = useQuery<GetAllVendorsResponse>(GET_ALL_VENDORS, {
    variables: {
      filter: filter
    }
  });
  const [createVendor] = useMutation<CreateVendorResponse>(CREATE_VENDOR, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          vendors(existingVendors = []) {
            const newVendorRef = cache.writeFragment({
              data: data?.vendor,
              fragment: gql`
                fragment NewVendor on Vendor {
                  id
                  name
                  representativeName
                  representativeEmail
                  representativePhone
                  orderWebsite
                  accountNumber
                  isArchived
                }
              `
            });
            return [...existingVendors, newVendorRef];
          }
        }
      });
    }
  });
  const [archiveVendor] = useMutation<ArchiveVendorResponse>(ARCHIVE_VENDOR, {
    update(cache, { data }) {
      const updatedVendor = data?.vendor;
      const activeVendors = cache.readQuery<GetAllVendorsResponse>({
        query: GET_ALL_VENDORS,
        variables: {
          filter: 'ACTIVE',
        },
      });
      const inactiveVendors = cache.readQuery<GetAllVendorsResponse>({
        query: GET_ALL_VENDORS,
        variables: {
          filter: 'INACTIVE',
        },
      });

      if (updatedVendor?.isArchived && activeVendors) {
        cache.writeQuery({
          query: GET_ALL_VENDORS,
          variables: {
            filter: 'ACTIVE',
          },
          data: {
            vendors: activeVendors.vendors.filter((vendor) => {
              return vendor.id !== updatedVendor.id;
            }),
          },
        });
      }

      if (updatedVendor?.isArchived && inactiveVendors) {
        cache.writeQuery({
          query: GET_ALL_VENDORS,
          variables: {
            filter: 'INACTIVE',
          },
          data: {
            vendors: [
              ...inactiveVendors.vendors,
              updatedVendor,
            ],
          },
        });
      }
    },
  });

  const handleCreateVendor = async (values: CreateVendorFormType) => {
    await createVendor({
      variables: {
        input: {
          name: values.name,
          representativeName: values.repName,
          representativeEmail: values.repEmail,
          representativePhone: values.repPhone,
          orderWebsite: values.orderWebsite,
          accountNumber: values.accountNumber,
        }
      },
    });
  }

  const handleArchiveVendor = async (id?: string) => {
    await archiveVendor({
      variables: {
        id: id,
      }
    });
  }

  const createVendorModal = (
    <FormModal<CreateVendorFormType>
      title='Create Vendor'
      onCancel={() => { }}
      onSubmit={handleCreateVendor}
      initialValues={createVendorFormInitialValues}
      submitLabel='Create'
    >
      <View style={{ gap: '16px' }}>
        <TextField type='text' name='name' label='Vendor Name' required />
        <TextField type='text' name='accountNumber' label='Account #' />
        <TextField type='url' name='orderWebsite' label='Order Website' />
        <TextField type='text' name='repName' label='Representative Name' />
        <TextField type='email' name='repEmail' label='Representative Email' />
        <TextField type='text' name='repPhone' label='Representative Phone' />
      </View>
    </FormModal>
  );


  const confirmArchiveModal = (
    <ConfirmModal title='Archive vendor?' onCancel={() => { }} onConfirm={handleArchiveVendor} confirmLabel='Yes, archive' destructive>
      <StyledParagraph>
        Are you sure you want to archive this vendor?
      </StyledParagraph>
      <Strut size='16px' />
      <StyledParagraph>
        <span style={{ fontWeight: 'bold' }}>IMPORTANT: </span>This will also archive all items associated with this vendor.
      </StyledParagraph>
    </ConfirmModal>
  );

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <StyledHeading tag='h4'>Vendors</StyledHeading>
          <View style={{ flexDirection: 'row', gap: '16px' }}>
            <OptionBar selectedValue={filter} onChange={setFilter}>
              <OptionItem label='Active' value='ACTIVE' />
              <OptionItem label='Inactive' value='INACTIVE' />
            </OptionBar>
            <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
              <ModalLauncher modal={createVendorModal}>
                {({ openModal }) => (
                  <Button variant='primary' role='button' action={openModal} label='Create Vendor' />
                )}
              </ModalLauncher>
            </HasProductRole>
          </View>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4} style={{ overflowX: 'auto' }}>
        {loading && <AtomSpinner size='large' />}
        {!loading &&
          <Card size='medium'>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell style={{ minWidth: '200px' }}>Name</TableHeaderCell>
                  <TableHeaderCell style={{ minWidth: '200px' }}>Rep Name</TableHeaderCell>
                  <TableHeaderCell style={{ minWidth: '200px' }}>Rep Email</TableHeaderCell>
                  <TableHeaderCell style={{ minWidth: '200px' }}>Rep Phone</TableHeaderCell>
                  <TableHeaderCell style={{ minWidth: '100px' }}>Account #</TableHeaderCell>
                  {filter !== 'INACTIVE' && <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Admin]}>
                    <TableHeaderCell style={{ padding: '0', margin: '0', width: '8px' }}></TableHeaderCell>
                  </HasProductRole>}
                </TableRow>
              </TableHeader>
              <TableBody>
                {data?.vendors.map((v) => {
                  return (
                    <TableRow key={v.id}>
                      <TableCell><Link href={'/vendors/' + v.id}>{v.name}</Link></TableCell>
                      <TableCell>{v.representativeName}</TableCell>
                      <TableCell>{v.representativeEmail}</TableCell>
                      <TableCell>{v.representativePhone}</TableCell>
                      <TableCell>{v.accountNumber}</TableCell>
                      {filter !== 'INACTIVE' && <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Admin]}>
                        <TableCell style={{ padding: '0', margin: '0', width: '8px' }}>
                          <ModalLauncher modal={confirmArchiveModal}>
                            {({ openModal }) => (
                              <ActionMenu alignment='right'>
                                <ActionItem label='Archive' onClick={openModal} data={v.id} />
                              </ActionMenu>
                            )}
                          </ModalLauncher>
                        </TableCell>
                      </HasProductRole>}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            {data?.vendors.length === 0 ?
              <StyledParagraph>Your organization does not have any {filter === 'ACTIVE' ? 'active' : 'inactive'} vendors.</StyledParagraph> : ''}
          </Card>}
      </Cell >
    </StandardGrid>
  );
}