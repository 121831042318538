import { Reference, gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Choice, CircularSpinner, Colors, ConfirmModal, DatePicker, FormModal, FormModalValueProvider, HasProductRole, Icon, Icons, InfoPanel, InventoryRoles, Link, ModalLauncher, NotFound, Products, Row, SingleSelect, StandardAlert, StandardGrid, Strut, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, Typography, View, generateId, useAlertState } from "@barscience/global-components";
import { css } from "aphrodite";
import { useParams, useNavigate } from "react-router-dom";
import useErrorHandler from "../../util/useErrorHandler";
import currency from "currency.js";
import { formatDecimalAmount } from "../../util/formatters";

/* Get Order Query - This must be the same as the query in the EditOrder page */
const GET_INVOICE_DETAILS_QUERY = gql`
query getInventoryInvoiceDetails($id: ID!) {
  inventoryInvoice(id: $id) {
    id
    vendor {
      id
      name
    }
    orderDate
    deliveryDate
    items {
      item {
        id
        name
        currentPrice {
          casePrice
        }
        unitDeposit
        unitsPerCase
        category {
          id
          name
        }
      }
      casesOrdered
    }
    itemCount
    caseCount
    subtotal
    totalDeposits
    paidDate
    order {
      id
      deliveryDate
      orderDate
    }
    statementTotal
  }
}
`;

type GetInventoryInvoiceDetails = {
  inventoryInvoice: InventoryInvoice;
}

type InventoryInvoice = {
  id: string;
  vendor: {
    id: string;
    name: string;
  };
  orderDate: string;
  deliveryDate: string;
  items: InvoiceItem[];
  itemCount: number;
  caseCount: number;
  subtotal: string;
  totalDeposits: string;
  paidDate: string | null;
  order: {
    id: string;
    deliveryDate: string;
    orderDate: string;
  }
  statementTotal: string;
}

type InvoiceItem = {
  item: Item;
  casesOrdered: number;
  locationName: string;
  sublocationName: string;
}

type Item = {
  id: string;
  name: string;
  category: {
    id: string;
    name: string;
  };
  currentPrice: {
    casePrice: string;
  }
  unitDeposit: string | null;
  unitsPerCase: number;
}

/* Edit Invoice Mutation */
const EDIT_INVOICE_DATES = gql`
mutation editInventoryInvoice($id: ID!, $input: EditInventoryInvoiceInput!) {
  editInventoryInvoice(id: $id, input: $input) {
    id
    orderDate
    deliveryDate
    statementTotal
  }
}
`;

type EditInvoiceDatesResponse = {
  editInventoryInvoice: {
    id: string;
    orderDate: string;
    deliveryDate: string;
    statementTotal: number;
  };
}

type EditInvoiceInput = {
  orderDate: string;
  deliveryDate: string;
  statementTotal: string;
}

/* Delete Inventory Invoice Details */
const DELETE_INVOICE_MUTATION = gql`
mutation deleteInventoryInvoice($id: ID!) {
  deleteInventoryInvoice(id: $id) {
    id
  }
}
`;

type DeleteInvoiceMutationResponse = {
  deleteInventoryInvoice: {
    id: string;
  };
}

/* Get Items Query */
const GET_ITEMS = gql`
query getItemsToAddToInvoice($page: Int!, $name: String, $vendors: [ID!], $startDate: Date) {
  inventoryItems(page: $page, name: $name, vendors: $vendors) {
    items {
      id
      name
      vendorItemId
      currentPrice(startDate: $startDate) {
        casePrice
        startDate
      }
      unitDeposit
      unitsPerCase
    }
  }
}
`;

type GetItemsQueryResponse = {
  inventoryItems: {
    items: {
      id: string;
      name: string;
      vendorItemId: string;
      currentPrice: {
        casePrice: string;
        startDate: string;
      }
      unitDeposit: string | null;
      unitsPerCase: number;
    }[];
  }
}

/* Add Item Mutation */
const ADD_ITEM = gql`
mutation addInventoryInvoiceItem($invoiceId: ID!, $input: AddInventoryInvoiceItemInput!) {
  addInventoryInvoiceItem(invoiceId: $invoiceId, input: $input) {
    id
    items {
      item {
        id
        name
      }
      casesOrdered
    }
    itemCount
    caseCount
    subtotal
    totalDeposits
  }
}
`;

type AddItemMutationResponse = {
  addInventoryInvoiceItem: {
    id: string;
    items: Item[];
    itemCount: number;
    caseCount: number;
    subtotal: number;
    totalDeposits: number;
  }
}

type AddItemInput = {
  itemId: string;
  casesOrdered: string;
  casePrice: string;
}

/* Edit Item Mutation */
const EDIT_ITEM = gql`
mutation editInventoryInvoiceItem($invoiceId: ID!, $input: EditInventoryInvoiceItemInput!) {
  editInventoryInvoiceItem(invoiceId: $invoiceId, input: $input) {
    id
    itemCount
    caseCount
    subtotal
    totalDeposits
  }
}
`;

type EditItemResponse = {
  editInventoryInvoiceItem: {
    id: string;
    itemCount: number;
    caseCount: number;
    subtotal: number;
    totalDeposits: number;
  }
}

type EditItemInput = {
  itemId: string;
  casesOrdered: string;
}

/* Mark As Paid Mutation */
const MARK_AS_PAID = gql`
mutation markInventoryInvoiceAsPaid($id: ID!, $paidDate: Date!) {
  markInventoryInvoiceAsPaid(id: $id, paidDate: $paidDate) {
    id
    paidDate
  }
}
`;

type MarkAsPaidResponse = {
  markInventoryInvoiceAsPaid: {
    id: string;
    paidDate: string;
  }
}

type MarkAsPaidInput = {
  paidDate: string;
}

/* Mark As Unpaid */
const MARK_AS_UNPAID = gql`
mutation markInventoryInvoiceAsUnpaid($id: ID!) {
  markInventoryInvoiceAsUnpaid(id: $id) {
    id
    paidDate
  }
}
`;

type MarkAsUnpaidResponse = {
  markInventoryInvoiceAsUnpaid: {
    id: string;
    paidDate: string;
  };
}

/* Create Price Change Modal */
type CreatePriceChangeInput = {
  casePrice: string;
  itemId: string;
  unitsPerCase: number;
}

/* Create Price Change Mutation */
const CREATE_PRICE_CHANGE_MUTATION = gql`
mutation createInventoryItemPriceChange($itemId: ID!, $input: CreateInventoryItemPriceChangeInput!) {
  priceChange: createInventoryItemPriceChange(itemId: $itemId, input: $input) {
    casePrice
    unitPrice
    startDate
  }
}
`;

type CreatePriceChangeMutationResponse = {
  priceChange: ItemPrice;
}

type ItemPrice = {
  casePrice: string;
  unitPrice: string;
  startDate: string;
}

export default function InvoiceDetail() {
  const navigate = useNavigate();
  const { addAlert } = useAlertState();
  const { handleGQLError } = useErrorHandler();
  const { invoiceId } = useParams();
  const { data: invoiceData, loading: invoiceIsLoading, error: invoiceError } = useQuery<GetInventoryInvoiceDetails>(GET_INVOICE_DETAILS_QUERY, {
    variables: {
      id: invoiceId,
    },
    errorPolicy: 'all',
  });
  const [getItemsToAdd, { data: itemsData, loading: itemsAreLoading, refetch: refetchItemsToAdd }] = useLazyQuery<GetItemsQueryResponse>(GET_ITEMS);
  const [editInvoiceDates] = useMutation<EditInvoiceDatesResponse>(EDIT_INVOICE_DATES, { onError: handleGQLError });
  const [deleteInvoice] = useMutation<DeleteInvoiceMutationResponse>(DELETE_INVOICE_MUTATION, {
    onError: handleGQLError,
  });
  const [addItem] = useMutation<AddItemMutationResponse>(ADD_ITEM, { onError: handleGQLError });
  const [editItem] = useMutation<EditItemResponse>(EDIT_ITEM, {
    onError: handleGQLError,
    update(cache, { data }, { variables }) {
      if (!invoiceData || !variables) {
        return;
      }

      cache.modify({
        id: cache.identify(invoiceData.inventoryInvoice),
        fields: {
          items(existingItemRefs = [], { readField, DELETE }) {
            return existingItemRefs.map((itemRef: Reference) => {
              const item: Reference | undefined = readField('item', itemRef);
              if (readField('id', item) === variables.input.itemId) {
                if (variables.input.casesOrdered === 0) {
                  return DELETE;
                }

                return {
                  ...itemRef,
                  casesOrdered: variables.input.casesOrdered,
                };
              }

              return itemRef;
            });
          }
        },
      });
    },
  });
  const [markAsPaid] = useMutation<MarkAsPaidResponse>(MARK_AS_PAID);
  const [markAsUnpaid] = useMutation<MarkAsUnpaidResponse>(MARK_AS_UNPAID);
  const [createPriceChange] = useMutation<CreatePriceChangeMutationResponse>(CREATE_PRICE_CHANGE_MUTATION);

  const handleDeleteInvoice = async () => {
    await deleteInvoice({
      variables: {
        id: invoiceId,
      },
    });

    const alertId = generateId();
    const alert = (
      <StandardAlert title='Invoice deleted' type='success' id={alertId} />
    );
    addAlert(alertId, alert);

    navigate('/invoices');
  }

  const handleAddItem = async (values: AddItemInput) => {
    const { errors } = await addItem({
      variables: {
        invoiceId: invoiceId,
        input: {
          itemId: values.itemId,
          casesOrdered: parseFloat(values.casesOrdered),
        },
      },
    });

    if (!errors) {
      const alertId = generateId();
      const alert = (
        <StandardAlert title='Item added' type='success' id={alertId} />
      );

      addAlert(alertId, alert);
    }
  }

  const handleEditItem = async (values: EditItemInput) => {
    await editItem({
      variables: {
        invoiceId: invoiceId,
        input: {
          itemId: values.itemId,
          casesOrdered: parseFloat(values.casesOrdered),
        },
      },
    });
  }

  const handleRemoveItem = async (itemId: string) => {
    await editItem({
      variables: {
        invoiceId: invoiceId,
        input: {
          itemId: itemId,
          casesOrdered: 0,
        },
      },
    });
  }

  const validateCasesOrdered = (_: string, value: string) => {
    if (parseFloat(value) <= 0) {
      return 'Must be greater than 0'
    }

    return null;
  }

  const handleEditDates = async (values: EditInvoiceInput) => {
    await editInvoiceDates({
      variables: {
        id: invoiceId,
        input: {
          orderDate: values.orderDate,
          deliveryDate: values.deliveryDate,
          statementTotal: values.statementTotal,
        },
      },
    });
  }

  const handleMarkAsPaid = async (values: MarkAsPaidInput) => {
    await markAsPaid({
      variables: {
        id: invoiceId,
        paidDate: values.paidDate,
      },
    });
  }

  const handleMarkAsUnpaid = async () => {
    await markAsUnpaid({
      variables: {
        id: invoiceId,
      },
    });
  }

  const handleCreatePriceChange = async (values: CreatePriceChangeInput) => {
    await createPriceChange({
      variables: {
        itemId: values.itemId,
        input: {
          casePrice: values.casePrice,
          unitPrice: currency(values.casePrice).divide(values.unitsPerCase).format(),
          startDate: invoiceData?.inventoryInvoice.deliveryDate,
        },
      },
    });

    await refetchItemsToAdd();
  }

  if (invoiceIsLoading || !invoiceData) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (!invoiceId || invoiceError?.graphQLErrors[0]?.extensions.status === 404) {
    return (
      <StandardGrid>
        <NotFound />
      </StandardGrid>
    );
  }

  const categoryTotals: { [categoryId: string]: { name: string; cases: number; subtotal: string; deposits: string; } } = {};
  invoiceData.inventoryInvoice.items.forEach((item) => {
    if (!categoryTotals[item.item.category.id]) {
      categoryTotals[item.item.category.id] = {
        name: item.item.category.name,
        cases: item.casesOrdered,
        deposits: item.item.unitDeposit ? currency(item.item.unitDeposit).multiply(item.item.unitsPerCase).multiply(item.casesOrdered).format() : '$0.00',
        subtotal: currency(item.item.currentPrice.casePrice).multiply(item.casesOrdered).format(),
      }
    } else {
      categoryTotals[item.item.category.id].deposits = item.item.unitDeposit ? (currency(categoryTotals[item.item.category.id].deposits).add(currency(item.item.unitDeposit).multiply(item.item.unitsPerCase).multiply(item.casesOrdered)).format()) : categoryTotals[item.item.category.id].deposits;
      categoryTotals[item.item.category.id].subtotal = currency(categoryTotals[item.item.category.id].subtotal).add(currency(item.item.currentPrice.casePrice).multiply(item.casesOrdered)).format();
      categoryTotals[item.item.category.id].cases += item.casesOrdered;
    }
  });

  const createPriceChangeModal = (
    <FormModal<CreatePriceChangeInput> title='Create Price Change' onSubmit={handleCreatePriceChange} initialValues={{ casePrice: '', itemId: '', unitsPerCase: 1 }}>
      <TextField label='Case Price' name='casePrice' type='currency' required />
    </FormModal>
  );

  const addItemModal = (
    <FormModal<AddItemInput> title='Add Item' keepOpenLabel='Add another item' onSubmit={handleAddItem} initialValues={{ itemId: '', casesOrdered: '', casePrice: '' }} submitLabel='Add Item'>
      <FormModalValueProvider>
        {({ getValue, setValue }) => (
          <View style={{ gap: '16px' }}>
            <SingleSelect
              label='Item'
              name='itemId'
              placeholder='Choose an item'
              onFilter={(_: string, value: string) => {
                getItemsToAdd({
                  variables: { name: value, page: 0, vendors: [invoiceData.inventoryInvoice.vendor.id], startDate: invoiceData.inventoryInvoice.deliveryDate }
                });
              }}
              filterable
              filterPlaceholder='Enter item name'
              required
              autoFocusSearch
            >
              {itemsAreLoading ?
                <View style={{ alignItems: 'center', padding: '16px' }}>
                  <CircularSpinner size='medium' />
                </View>
                :
                <>
                  {(itemsData && itemsData.inventoryItems.items.length > 0) ?
                    itemsData.inventoryItems.items.filter((item) => {
                      // Filter out items that are already assigned to the invoice
                      let found = false;
                      invoiceData.inventoryInvoice.items.forEach((invoiceItem) => {
                        if (item.id === invoiceItem.item.id) {
                          found = true;
                        }
                      });

                      return !found;
                    }).map((item, index) =>
                      <Choice label={`${item.name} ${item.vendorItemId ? `(${item.vendorItemId})` : ''}`} value={item.id} key={index} />
                    )
                    :
                    <View style={{ padding: '16px' }}>
                      <StyledParagraph>No items found.</StyledParagraph>
                    </View>
                  }
                </>
              }
            </SingleSelect>
            <TextField label='Cases Received' type='number' name='casesOrdered' style={{ width: '50%' }} validate={validateCasesOrdered} required />
            {(() => {
              if (!getValue) {
                return;
              }

              const selectedItem = itemsData?.inventoryItems.items.find((item) => (getValue && item.id === getValue('itemId')));
              const unitsPerCase = selectedItem?.unitsPerCase || 1;
              const unitDeposit = selectedItem?.unitDeposit || 0;
              const casePrice = selectedItem?.currentPrice.casePrice || 0;

              const subtotal = currency(casePrice).multiply(getValue('casesOrdered'))
              const deposits = currency(unitDeposit).multiply(unitsPerCase).multiply(getValue('casesOrdered'))

              return (
                <View style={{ flexDirection: 'row', gap: '32px' }}>
                  <View>
                    <StyledParagraph>Case Price</StyledParagraph>
                    <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                      <StyledParagraph>{itemsData?.inventoryItems.items.find((item) => (getValue && item.id === getValue('itemId')))?.currentPrice.casePrice || '----'}</StyledParagraph>
                      {(getValue && getValue('itemId') && selectedItem?.currentPrice.startDate !== invoiceData.inventoryInvoice.deliveryDate) && <ModalLauncher modal={createPriceChangeModal}>
                        {({ openModal }) => (
                          <Button label='Edit' variant='tertiary' role='button' action={() => {
                            const selectedItem = itemsData?.inventoryItems.items.find((item) => (getValue && item.id === getValue('itemId')));
                            openModal({ casePrice: selectedItem?.currentPrice.casePrice, itemId: selectedItem?.id, unitsPerCase: selectedItem?.unitsPerCase });
                          }} style={{ height: '' }} />
                        )}
                      </ModalLauncher>}
                    </View>
                  </View>
                  <View>
                    <StyledParagraph>Subtotal</StyledParagraph>
                    <StyledParagraph>{subtotal.format()}</StyledParagraph>
                  </View>
                  <View>
                    <StyledParagraph>Deposits</StyledParagraph>
                    <StyledParagraph>{deposits.format()}</StyledParagraph>
                  </View>
                  <View>
                    <StyledParagraph>Total Cost</StyledParagraph>
                    <StyledParagraph>{subtotal.add(deposits).format()}</StyledParagraph>
                  </View>
                </View>
              );
            })()}
          </View>
        )}
      </FormModalValueProvider>
    </FormModal>
  );

  const editItemModal = (
    <FormModal<EditItemInput> title='Edit Item' onSubmit={handleEditItem} initialValues={{ itemId: '', casesOrdered: '' }}>
      <View>
        <TextField label='Cases Received' name='casesOrdered' type='number' inputMode='decimal' style={{ width: '50%' }} validate={validateCasesOrdered} required />
      </View>
    </FormModal>
  );

  const confirmRemoveItem = (
    <ConfirmModal title='Remove item?' onConfirm={handleRemoveItem} confirmLabel='Remove' >
      <StyledParagraph>The item will be removed from this invoice. You can always add it back later.</StyledParagraph>
    </ConfirmModal>
  );

  const editInvoiceModal = (
    <FormModal<EditInvoiceInput> title='Edit Invoice' onSubmit={handleEditDates} initialValues={{ orderDate: invoiceData.inventoryInvoice.orderDate, deliveryDate: invoiceData.inventoryInvoice.deliveryDate, statementTotal: currency(invoiceData.inventoryInvoice.statementTotal).toString() }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Statement Total' type='currency' inputMode='decimal' description='Enter the total cost as shown on your invoice' name='statementTotal' required />
        <Strut size='8px' />
        <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 1151px)': { flexDirection: 'column' } }}>
          <DatePicker label='Order Date' name='orderDate' required />
          <DatePicker label='Delivery Date' name='deliveryDate' required />
        </View>
        {invoiceData.inventoryInvoice.order !== null &&
          <InfoPanel type='info'>
            <StyledParagraph>Changing these dates will also update the order linked to this invoice.</StyledParagraph>
          </InfoPanel>
        }
      </View>
    </FormModal>
  );

  const confirmDeleteModal = (
    <ConfirmModal title='Delete invoice?' onConfirm={handleDeleteInvoice} confirmLabel='Delete' destructive>
      <StyledParagraph>Are you sure you want to delete this invoice? This will permanently delete the invoice.</StyledParagraph>
    </ConfirmModal>
  );

  const markAsPaidModal = (
    <FormModal<MarkAsPaidInput> title='Mark As Paid' submitLabel='Mark as paid' onSubmit={handleMarkAsPaid} initialValues={{ paidDate: '' }}>
      <DatePicker label='Date Paid' name='paidDate' required />
    </FormModal>
  );

  const markAsUnpaidModal = (
    <ConfirmModal title='Mark as unpaid?' confirmLabel='Mark as unpaid' onConfirm={handleMarkAsUnpaid} >
      <StyledParagraph>This will remove the payment date for this invoice and mark it as unpaid.</StyledParagraph>
    </ConfirmModal>
  );

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Invoices' to='/invoices' />
          <Breadcrumb label={invoiceData.inventoryInvoice.vendor.name + ' (' + invoiceData.inventoryInvoice.deliveryDate + ')'} />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', justifyContent: 'space-between', '@media (max-width: 767px)': { alignItems: 'flex-start', flexDirection: 'column' } }}>
          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', '@media (max-width: 767px)': { alignItems: 'flex-start', flexDirection: 'column' } }}>
            <StyledHeading tag='h3'>{invoiceData.inventoryInvoice.vendor.name + ' (' + invoiceData.inventoryInvoice.deliveryDate + ')'}</StyledHeading>
            {invoiceData.inventoryInvoice.paidDate ?
              <StyledParagraph size='small' style={{ backgroundColor: Colors.primary500, borderRadius: '4px', color: '#ffffff', padding: '4px 12px' }} bold>PAID - {invoiceData.inventoryInvoice.paidDate}</StyledParagraph>
              :
              <StyledParagraph size='small' style={{ backgroundColor: Colors.error500, borderRadius: '4px', color: '#ffffff', padding: '4px 12px' }} bold>UNPAID</StyledParagraph>
            }
          </View>
          <View style={{ flexDirection: 'row', gap: '16px' }}>
            <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
              <ModalLauncher modal={addItemModal}>
                {({ openModal }) => (
                  <Button label='Add Item' variant='secondary' role='button' action={openModal} />
                )}
              </ModalLauncher>
            </HasProductRole>
            <ModalLauncher modal={confirmDeleteModal}>
              {({ openModal: openDeleteModal }) => (
                <ModalLauncher modal={editInvoiceModal}>
                  {({ openModal: openEditDatesModal }) => (
                    <ModalLauncher modal={markAsPaidModal}>
                      {({ openModal: openMarkAsPaidModal }) => (
                        <ModalLauncher modal={markAsUnpaidModal}>
                          {({ openModal: openMarkAsUnpaidModal }) => (
                            <ActionMenu alignment='right'>
                              {invoiceData.inventoryInvoice.order && <ActionItem label='View Order' onClick={() => { navigate('/orders/' + invoiceData.inventoryInvoice.order.id); }} />}
                              <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
                                {invoiceData.inventoryInvoice.paidDate ?
                                  <ActionItem label='Mark as unpaid' onClick={openMarkAsUnpaidModal} />
                                  :
                                  <ActionItem label='Mark as paid' onClick={openMarkAsPaidModal} />
                                }
                              </HasProductRole>
                              <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
                                <ActionItem label='Edit' onClick={openEditDatesModal} />
                              </HasProductRole>
                              <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Admin]}>
                                <ActionItem label='Delete' onClick={openDeleteModal} />
                              </HasProductRole>
                            </ActionMenu>
                          )}
                        </ModalLauncher>
                      )}
                    </ModalLauncher>
                  )}
                </ModalLauncher>
              )}
            </ModalLauncher>
          </View>
        </View>
      </Cell>
      <Row>
        <Cell lg={4} md={4} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '16px' }}>
              <StyledHeading tag='h6'>Total Cost</StyledHeading>
              <p className={css(Typography.heading4SemiBold)}>{(currency(invoiceData.inventoryInvoice.subtotal).add(currency(invoiceData.inventoryInvoice.totalDeposits))).format()}</p>
              {invoiceData.inventoryInvoice.statementTotal !== (currency(invoiceData.inventoryInvoice.subtotal).add(currency(invoiceData.inventoryInvoice.totalDeposits))).format() &&
                <View style={{ flexDirection: 'row', gap: '8px' }}>
                  <Icon size='medium' icon={Icons.CircleX} style={{ color: Colors.error500 }} />
                  <StyledParagraph style={{ color: Colors.error500 }}>Does not match statement ({invoiceData.inventoryInvoice.statementTotal})</StyledParagraph>
                </View>
              }
            </View>
          </Card>
        </Cell>
        <Cell lg={4} md={4} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '16px' }}>
              <StyledHeading tag='h6'>Items Ordered</StyledHeading>
              <p className={css(Typography.heading4SemiBold)}>{invoiceData.inventoryInvoice.itemCount}</p>
            </View>
          </Card>
        </Cell>
        <Cell lg={4} md={4} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '16px' }}>
              <StyledHeading tag='h6'>Cases Ordered</StyledHeading>
              <p className={css(Typography.heading4SemiBold)}>{formatDecimalAmount(invoiceData.inventoryInvoice.caseCount)}</p>
            </View>
          </Card>
        </Cell>
      </Row>
      <Cell lg={12} md={8} sm={4}>
        <Card size='medium'>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Item Name</TableHeaderCell>
                <TableHeaderCell>Category</TableHeaderCell>
                <TableHeaderCell style={{ minWidth: '40px' }}># Cases</TableHeaderCell>
                <TableHeaderCell style={{ minWidth: '80px' }}>Subtotal</TableHeaderCell>
                <TableHeaderCell style={{ minWidth: '80px' }}>Deposit</TableHeaderCell>
                <TableHeaderCell style={{ minWidth: '80px' }}>Total</TableHeaderCell>
                <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
                  <TableHeaderCell style={{ padding: '0', margin: '0', width: '16px' }}></TableHeaderCell>
                </HasProductRole>
              </TableRow>
            </TableHeader>
            <TableBody>
              {invoiceData.inventoryInvoice.items.map((item, index) => {
                const cost = currency(item.item.currentPrice.casePrice).multiply(item.casesOrdered);
                const deposit = currency(item.item.unitDeposit || 0).multiply(item.casesOrdered).multiply(item.item.unitsPerCase);

                return (
                  <TableRow key={index}>
                    <TableCell style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}><Link href={'/items/' + item.item.id}>{item.item.name}</Link></TableCell>
                    <TableCell style={{ maxWidth: '140px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.item.category.name}</TableCell>
                    <TableCell>{item.casesOrdered}</TableCell>
                    <TableCell>{cost.format()}</TableCell>
                    <TableCell>{deposit.format()}</TableCell>
                    <TableCell>{cost.add(deposit).format()}</TableCell>
                    <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
                      <TableCell style={{ padding: '0', margin: '0', width: '16px' }}>
                        <ModalLauncher modal={editItemModal}>
                          {({ openModal: openEditItemModal }) => (
                            <ModalLauncher modal={confirmRemoveItem}>
                              {({ openModal: openRemoveItemModal }) => (
                                <ActionMenu alignment='right'>
                                  <ActionItem label='Edit' onClick={() => { openEditItemModal({ itemId: item.item.id, casesOrdered: item.casesOrdered }); }} />
                                  <ActionItem label='Remove' onClick={() => { openRemoveItemModal(item.item.id); }} />
                                </ActionMenu>
                              )}
                            </ModalLauncher>
                          )}
                        </ModalLauncher>
                      </TableCell>
                    </HasProductRole>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Card>
      </Cell>
      <Cell lg={8} md={8} sm={4}>
        <Card>
          <View style={{ gap: '16px' }}>
            <StyledHeading tag='h6'>Totals By Category</StyledHeading>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Category</TableHeaderCell>
                  <TableHeaderCell style={{ minWidth: '80px' }}># Cases</TableHeaderCell>
                  <TableHeaderCell style={{ minWidth: '100px' }}>Subtotal</TableHeaderCell>
                  <TableHeaderCell style={{ minWidth: '100px' }}>Deposits</TableHeaderCell>
                  <TableHeaderCell style={{ minWidth: '100px' }}>Total</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {Object.keys(categoryTotals).map((id, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{categoryTotals[id].name}</TableCell>
                      <TableCell>{formatDecimalAmount(categoryTotals[id].cases)}</TableCell>
                      <TableCell>{categoryTotals[id].subtotal}</TableCell>
                      <TableCell>{categoryTotals[id].deposits}</TableCell>
                      <TableCell>{currency(categoryTotals[id].deposits).add(categoryTotals[id].subtotal).format()}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </View>
        </Card>
      </Cell>
    </StandardGrid>
  );
}