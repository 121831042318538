import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Button, Choice, CircularSpinner, Colors, DatePicker, Form, Modal, ModalBody, ModalFooter, ModalHeader, SingleSelect, StandardAlert, StyledCaption, StyledHeading, StyledParagraph, TextField, View, generateId, useAlertState, useForm } from "@barscience/global-components";
import { CSSProperties } from "aphrodite";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

/* Get Orders Query */
const GET_ORDERS_WITHOUT_INVOICES = gql`
query getInventoryOrdersWithoutInvoices {
  inventoryOrdersWithoutInvoices {
    id
    vendor {
      name
    }
    deliveryDate
  }
}
`;

type GetOrdersWithoutInvoicesResponse = {
  inventoryOrdersWithoutInvoices: Order[];
}

type Order = {
  id: string;
  vendor: {
    name: string;
  };
  deliveryDate: string;
}

/* Import Order Mutation */
export const IMPORT_ORDER = gql`
mutation createInventoryInvoiceFromOrder($orderId: ID!, $input: CreateInventoryInvoiceFromOrderInput!) {
  createInventoryInvoiceFromOrder(orderId: $orderId, input: $input) {
    id
  }
}
`;

export type ImportOrderResponse = {
  createInventoryInvoiceFromOrder: {
    id: string;
  };
}

export type ImportOrderInput = {
  orderId: string;
  statementTotal: string;
}

/* Get Vendors Query */
const GET_ALL_VENDORS = gql`
query getVendorsForCreateInvoice {
  vendors {
    id
    name
  }
}
`;

type GetAllVendorsResponse = {
  vendors: Vendor[];
}

type Vendor = {
  id: string;
  name: string;
}

/* Manually Create Invoice Mutation */
const MANUALLY_CREATE_INVOICE = gql`
mutation createInventoryInvoiceManually($input: CreateInventoryInvoiceManuallyInput!) {
  createInventoryInvoiceManually(input: $input) {
    id
  }
}
`;

type ManuallyCreateInvoiceResponse = {
  createInventoryInvoiceManually: {
    id: string;
  };
}

type ManuallyCreateInvoiceInput = {
  vendorId: string;
  orderDate: string;
  deliveryDate: string;
  statementTotal: string;
}

/* Styles */
const createInvoiceCardStyles: CSSProperties = {
  border: `1px solid ${Colors.neutral200}`,
  borderRadius: '4px',
  boxSizing: 'border-box',
  cursor: 'pointer',
  gap: '16px',
  minHeight: 'fit-content',
  padding: '16px',
  width: '100%',
  ':hover': {
    borderColor: Colors.primary500,
  },
  ':active': {
    backgroundColor: Colors.primary50,
  },
}

type CreateInvoiceModalProps = {
  handleClose?: () => void;
}

enum Pages {
  ChooseMethod,
  ImportOrder,
  CreateManually
}

export default function CreateInvoiceModal(props: CreateInvoiceModalProps) {
  const navigate = useNavigate();
  const { addAlert } = useAlertState();
  const client = useApolloClient();
  const [page, setPage] = useState<Pages>(Pages.ChooseMethod);
  const { data: ordersData, loading: ordersAreLoading } = useQuery<GetOrdersWithoutInvoicesResponse>(GET_ORDERS_WITHOUT_INVOICES);
  const [importOrder, { loading: importOrderIsLoading }] = useMutation<ImportOrderResponse>(IMPORT_ORDER);
  const { data: vendorsData, loading: vendorsAreLoading } = useQuery<GetAllVendorsResponse>(GET_ALL_VENDORS);
  const [manuallyCreateInvoice, { loading: manuallyCreateInvoiceIsLoading }] = useMutation<ManuallyCreateInvoiceResponse>(MANUALLY_CREATE_INVOICE);

  const handleImportOrder = async (values: ImportOrderInput) => {
    const { data, errors } = await importOrder({
      variables: {
        orderId: values.orderId,
        input: {
          statementTotal: values.statementTotal,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to create invoice' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }

    if (data?.createInventoryInvoiceFromOrder) {
      // Update the cache
      await client.refetchQueries({
        updateCache(cache) {
          cache.modify({
            fields: {
              inventoryInvoices(_, { INVALIDATE }) {
                return INVALIDATE;
              },
              inventoryOrdersWithoutInvoices(_, { INVALIDATE }) {
                return INVALIDATE;
              },
            },
          });
        },
      });

      navigate('/invoices/' + data?.createInventoryInvoiceFromOrder.id)
    }
  }

  const handleManuallyCreateInvoice = async (values: ManuallyCreateInvoiceInput) => {
    const { data, errors } = await manuallyCreateInvoice({
      variables: {
        input: {
          vendorId: values.vendorId,
          orderDate: values.orderDate,
          deliveryDate: values.deliveryDate,
          statementTotal: values.statementTotal,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to create invoice' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }

    if (data?.createInventoryInvoiceManually) {
      // Update the cache
      await client.refetchQueries({
        updateCache(cache) {
          cache.modify({
            fields: {
              inventoryInvoices(_, { INVALIDATE }) {
                return INVALIDATE;
              },
            },
          });
        },
      });

      navigate('/invoices/' + data?.createInventoryInvoiceManually.id);
    }
  }

  const importOrderForm = useForm<ImportOrderInput>({
    initialValues: { orderId: '', statementTotal: '' },
    onSubmit: handleImportOrder,
  });

  const createInvoiceForm = useForm<ManuallyCreateInvoiceInput>({
    initialValues: {
      vendorId: '',
      orderDate: '',
      deliveryDate: '',
      statementTotal: '',
    },
    onSubmit: handleManuallyCreateInvoice,
  });

  const getPageBody = () => {
    switch (page) {
      case Pages.ImportOrder: {
        return (
          [
            (
              <ModalBody>
                <View>
                  {ordersAreLoading ?
                    <CircularSpinner size='medium' />
                    :
                    <Form handleSubmit={importOrderForm.handleSubmit}>
                      <View style={{ gap: '16px' }}>
                        <SingleSelect label='Choose an order to import' placeholder='Choose an order' name='orderId' value={importOrderForm.values.orderId} error={importOrderForm.errors.orderId} onChange={importOrderForm.handleChange} onValidate={importOrderForm.handleValidate} required>
                          {ordersData?.inventoryOrdersWithoutInvoices.map((order, index) => {
                            return (
                              <Choice label={order.vendor.name + ' (' + order.deliveryDate + ')'} value={order.id} key={index} />
                            );
                          })}
                          {ordersData?.inventoryOrdersWithoutInvoices.length === 0 &&
                            <StyledParagraph style={{ padding: '16px' }}>There are no orders available to import.</StyledParagraph>
                          }
                        </SingleSelect>
                        <TextField label='Statement Total' type='currency' inputMode='decimal' description='Enter the total cost as shown on your invoice' name='statementTotal' required value={importOrderForm.values.statementTotal} error={importOrderForm.errors.statementTotal} onChange={importOrderForm.handleChange} onValidate={importOrderForm.handleValidate} />
                      </View>
                    </Form>
                  }
                </View>
              </ModalBody>
            ),
            (
              <ModalFooter>
                {({ closeModal }) => (
                  <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', justifyContent: 'space-between', width: '100%' }}>
                    <Button label='Back' variant='tertiary' role='button' action={() => { setPage(Pages.ChooseMethod); }} />
                    <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                      <Button label='Cancel' variant='tertiary' role='button' action={closeModal} />
                      <Button label='Import' variant='primary' role='button' type='submit' action={async () => {
                        await handleImportOrder(importOrderForm.values);
                        closeModal();
                      }} disabled={importOrderForm.hasError} loading={importOrderIsLoading} />
                    </View>
                  </View>
                )}
              </ModalFooter>
            )
          ]
        );
      }
      case Pages.CreateManually: {
        return [
          (
            <ModalBody>
              <View>
                {vendorsAreLoading ?
                  <CircularSpinner size='medium' />
                  :
                  <Form handleSubmit={importOrderForm.handleSubmit}>
                    <View style={{ gap: '16px' }}>
                      <SingleSelect label='Vendor' placeholder='Choose a vendor' name='vendorId' value={createInvoiceForm.values.vendorId} error={createInvoiceForm.errors.vendorId} onChange={createInvoiceForm.handleChange} onValidate={createInvoiceForm.handleValidate} filterable autoFocusSearch required>
                        {vendorsData?.vendors.map((vendor, index) => {
                          return (
                            <Choice label={vendor.name} value={vendor.id} key={index} />
                          );
                        })}
                      </SingleSelect>
                      <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 1151px)': { flexDirection: 'column' } }}>
                        <DatePicker label='Order Date' name='orderDate' value={createInvoiceForm.values.orderDate} error={createInvoiceForm.errors.orderDate} onChange={createInvoiceForm.handleChange} onValidate={createInvoiceForm.handleValidate} required />
                        <DatePicker label='Delivery Date' name='deliveryDate' value={createInvoiceForm.values.deliveryDate} error={createInvoiceForm.errors.deliveryDate} onChange={createInvoiceForm.handleChange} onValidate={createInvoiceForm.handleValidate} required />
                      </View>
                      <TextField label='Statement Total' type='currency' inputMode='decimal' description='Enter the total cost as shown on your invoice' name='statementTotal' required value={createInvoiceForm.values.statementTotal} error={createInvoiceForm.errors.statementTotal} onChange={createInvoiceForm.handleChange} onValidate={createInvoiceForm.handleValidate} />
                    </View>
                  </Form>
                }
              </View>
            </ModalBody>
          ),
          (
            <ModalFooter>
              {({ closeModal }) => (
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', justifyContent: 'space-between', width: '100%' }}>
                  <Button label='Back' variant='tertiary' role='button' action={() => { setPage(Pages.ChooseMethod); }} />
                  <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                    <Button label='Cancel' variant='tertiary' role='button' action={closeModal} />
                    <Button label='Create' variant='primary' role='button' type='submit' action={async () => {
                      await handleManuallyCreateInvoice(createInvoiceForm.values);
                      closeModal();
                    }} disabled={createInvoiceForm.hasError} loading={manuallyCreateInvoiceIsLoading} />
                  </View>
                </View>
              )}
            </ModalFooter>
          )
        ];
      }
      default: {
        return (
          [
            (
              <ModalBody>
                <View style={{ alignItems: 'center', gap: '24px' }}>
                  <StyledParagraph bold>How would you like to create this invoice?</StyledParagraph>
                  <View style={createInvoiceCardStyles} onClick={() => { setPage(Pages.ImportOrder); }}>
                    <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                      <StyledHeading tag='h6'>Import From Order</StyledHeading>
                      <StyledCaption bold><span style={{ color: Colors.primary500 }}>Recommended</span></StyledCaption>
                    </View>
                    <StyledParagraph style={{ color: Colors.neutral700 }}>Automatically create an invoice by importing items from a previous order.</StyledParagraph>
                  </View>
                  <View style={createInvoiceCardStyles} onClick={() => { setPage(Pages.CreateManually); }}>
                    <StyledHeading tag='h6'>Do It Myself</StyledHeading>
                    <StyledParagraph style={{ color: Colors.neutral700 }}>Manually add items to the invoice.</StyledParagraph>
                  </View>
                </View>
              </ModalBody>
            ),
            (
              <ModalFooter>

              </ModalFooter>
            )
          ]
        );
      }
    }
  }

  return (
    <Modal header={<ModalHeader title='Create Invoice' />}
      body={getPageBody()[0]}
      footer={getPageBody()[1]}
      handleClose={props.handleClose}
      onClose={() => { setPage(Pages.ChooseMethod); }}
    />
  );
}