import { Reference, gql, useApolloClient, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Card, Cell, Choice, CircularSpinner, Colors, ConfirmModal, FormModal, FormModalValueProvider, HasProductRole, Icon, Icons, InventoryRoles, Link, ModalLauncher, Products, SingleSelect, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, TextField, View, generateId, useAlertState } from "@barscience/global-components";
import { CSSProperties, StyleSheet, css } from "aphrodite";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";


/* Get Locations Query */
const GET_LOCATIONS_QUERY = gql`
query getActiveInventoryItemLocations {
  inventoryItemLocations {
    id
    name
    sublocations {
      id
      name
      isHiddenFromOrders
    }
    isHiddenFromOrders
  }
}
`;

type GetLocationsQueryResponse = {
  inventoryItemLocations: ItemLocation[];
}

type ItemLocation = {
  id: string;
  name: string;
  sublocations: ItemSublocationSummary[];
  isHiddenFromOrders: boolean;
}

type ItemSublocationSummary = {
  id: string;
  name: string;
  isHiddenFromOrders: boolean;
}

/* Get Sublocation Items Query */
const GET_SUBLOCATION_QUERY = gql`
query getInventoryItemSublocation($id: ID!) {
  inventoryItemSublocation(id: $id) {
    id
    name
    items {
      ... on InventoryItemLocationAssignment {
        item {
          id
          name
          vendor {
            id
            name
          }
          category {
            name
          }
        }
        isHiddenFromOrders
      }
      ... on RecipeLocationAssignment {
        recipe {
          id
          name
          itemCategory {
            name
          }
          recipeCategory {
            id
            name
          }
        }
      }
    }
  }
}

`;

type GetSublocationQueryResponse = {
  inventoryItemSublocation: ItemSublocation;
}

type ItemSublocation = {
  id: string;
  name: string;
  items: LocationItem[];
}

type LocationItem = ItemLocationAssignment | RecipeLocationAssignment;

type ItemLocationAssignment = {
  item: Item;
  isHiddenFromOrders: boolean;
  __typename: 'InventoryItemLocationAssignment';
}

type Item = {
  id: string;
  name: string;
  category: {
    name: string;
  }
  vendor: {
    id: string;
    name: string;
  }
}

type RecipeLocationAssignment = {
  recipe: Recipe;
  __typename: 'RecipeLocationAssignment';
}

type Recipe = {
  id: string;
  name: string;
  itemCategory: {
    name: string;
  };
  recipeCategory: {
    id: string;
    name: string;
  };
}

/* Create Location Mutation */
const CREATE_LOCATION_MUTATION = gql`
mutation createInventoryItemLocation($input: CreateInventoryItemLocationInput!) {
  createInventoryItemLocation(input: $input) {
    id
    name
    sublocations {
      id
      name
    }
  }
}
`;

type CreateLocationMutationResponse = {
  createInventoryItemLocation: ItemLocation;
}

type CreateLocationInput = {
  name: string;
}

/* Create Sublocation Mutation */
const CREATE_SUBLOCATION_MUTATION = gql`
mutation createInventoryItemSublocation($input: CreateInventoryItemSublocationInput!) {
  createInventoryItemSublocation(input: $input) {
    id
    name
  }
}
`;

type CreateSublocationMutationResponse = {
  createInventoryItemSublocation: ItemSublocationSummary;
}

type CreateSublocationInput = {
  name: string;
  parentLocation: string;
}

/* Edit Location Mutation */
const EDIT_LOCATION_MUTATION = gql`
mutation editInventoryItemLocation($id: ID!, $input: EditInventoryItemLocationInput!) {
  location: editInventoryItemLocation(id: $id, input: $input) {
    id
    name
  }
}
`;

type EditLocationResponse = {
  location: {
    id: string;
    name: string;
  };
}

type EditLocationInput = {
  id: string;
  name: string;
}

/* Edit Sublocation Mutation */
const EDIT_SUBLOCATION_MUTATION = gql`
mutation editInventoryItemSublocation($id: ID!, $input: EditInventoryItemSublocationInput!) {
  sublocation: editInventoryItemSublocation(id: $id, input: $input) {
    id
    name
  }
}
`;

type EditSublocationResponse = {
  sublocation: ItemSublocationSummary;
}

type EditSublocationInput = {
  id: string;
  name: string;
}

/* Archive Sublocation Mutation */
const ARCHIVE_SUBLOCATION_MUTATION = gql`
mutation archiveInventoryItemSublocation($id: ID!) {
  archiveInventoryItemSublocation(id: $id) {
    id
    name
    isArchived
  }
}
`;

type ArchiveSublocationMutationResponse = {
  archiveInventoryItemSublocation: {
    id: string;
    name: string;
    isArchived: boolean;
  }
}

/* Archive Location Mutation */
const ARCHIVE_LOCATION_MUTATION = gql`
mutation archiveInventoryItemLocation($id: ID!) {
  archiveInventoryItemLocation(id: $id) {
    id
    name
    isArchived
  }
}
`;

type ArchiveLocationMutationResponse = {
  archiveInventoryItemLocation: {
    id: string;
    name: string;
    isArchived: string;
  }
}

/* Get Items Query */
const GET_ITEMS = gql`
query getItemsToAddToItemLocations($page: Int!, $name: String) {
  inventoryItems(page: $page, name: $name) {
    items {
      id
      name
    }
    pages
  }
}
`;

type GetItemsQueryResponse = {
  inventoryItems: {
    items: {
      id: string;
      name: string;
    }[];
    pages: number;
  }
}

/* Add Item Mutation */
const ADD_ITEM_MUTATION = gql`
mutation assignInventoryItemToSublocation($itemId: ID!, $locationId: ID!) {
  item: assignInventoryItemToSublocation(itemId: $itemId, locationId: $locationId) {
    id
    name
    vendor {
      id
      name
    }
    category {
      name
    }
  }
}
`;

type AddItemMutationResponse = {
  item: Item;
}

type AddItemInput = {
  itemId: string;
  locationId: string;
  sublocationId: string;
}

/* Remove Item Mutation */
const REMOVE_ITEM_MUTATION = gql`
mutation removeInventoryItemFromSublocation($itemId: ID!, $locationId: ID!) {
  success: removeInventoryItemFromSublocation(itemId: $itemId, locationId: $locationId)
}
`;

type RemoveItemMutationResponse = {
  success: boolean;
}

type RemoveItemInput = {
  itemId: string;
  locationId: string;
}

/* Move Item Mutation */
const MOVE_ITEM_MUTATION = gql`
mutation updateSublocationItemSortOrder($itemId: ID!, $locationId: ID!, $sortOrder: Int!) {
  success: updateSublocationItemSortOrder(itemId: $itemId, locationId: $locationId, sortOrder: $sortOrder)
}
`;

type MoveItemMutationResponse = {
  success: boolean;
}

const classStyles = StyleSheet.create({
  dragHoverTop: {
    borderTop: `2px solid ${Colors.primary500}`
  },
  dragHoverBottom: {
    borderBottom: `2px solid ${Colors.primary500}`
  },
});

/* Set Location Visibility Mutation */
const SET_LOCATION_VISIBILITY = gql`
mutation setInventoryItemLocationVisibilityForOrders($locationId: ID!, $isVisible: Boolean!) {
  setInventoryItemLocationVisibilityForOrders(locationId: $locationId, isVisible: $isVisible) {
    id
    isHiddenFromOrders
  }
}
`;

type SetLocationVisibilityResponse = {
  setInventoryItemLocationVisibilityForOrders: {
    id: string;
    isHiddenFromOrders: boolean;
  };
}

/* Set Sublocation Visibility Mutation */
const SET_SUBLOCATION_VISIBILITY = gql`
mutation setInventoryItemSublocationVisibilityForOrders($sublocationId: ID!, $isVisible: Boolean!) {
  setInventoryItemSublocationVisibilityForOrders(sublocationId: $sublocationId, isVisible: $isVisible) {
    id
    isHiddenFromOrders
  }
}
`;

type SetSublocationVisibilityResponse = {
  setInventoryItemSublocationVisibilityForOrders: {
    id: string;
    isHiddenFromOrders: boolean;
  };
}

/* Set Item Visibility Mutation */
const SET_ITEM_VISIBILITY = gql`
mutation setInventoryItemLocationAssignmentVisibilityForOrders($sublocationId: ID!, $itemId: ID!, $isVisible: Boolean!) {
  setInventoryItemLocationAssignmentVisibilityForOrders(sublocationId: $sublocationId, itemId: $itemId, isVisible: $isVisible) {
    item {
      id
    }
    isHiddenFromOrders
  }
}
`;

type SetItemVisibilityResponse = {
  setInventoryItemLocationAssignmentVisibilityForOrders: {
    item: {
      id: string;
    };
    isHiddenFromOrders: boolean;
  };
}

/* Get Recipes Query */
const GET_RECIPES = gql`
query getRecipesToAddToItemLocations($name: String!) {
  recipes(name: $name) {
    id
    name
  }
}
`;

type GetRecipesResponse = {
  recipes: {
    id: string;
    name: string;
  }[];
}

type AddRecipeInput = {
  recipeId: string;
  locationId: string;
  sublocationId: string;
}

/* Add Recipe Mutation */
const ADD_RECIPE = gql`
mutation assignRecipeToSublocation($recipeId: ID!, $locationId: ID!) {
  recipe: assignRecipeToSublocation(recipeId: $recipeId, locationId: $locationId) {
    id
    name
    itemCategory {
      name
    }
  }
}
`;

type AddRecipeResponse = {
  recipe: {
    id: string;
    name: string;
    itemCategory: {
      name: string;
    };
  } | null;
}

/* Remove Recipe Mutation */
const REMOVE_RECIPE = gql`
mutation removeRecipeFromSublocation($recipeId: ID!, $locationId: ID!) {
  success: removeRecipeFromSublocation(recipeId: $recipeId, locationId: $locationId)
}
`;

type RemoveRecipeResponse = {
  success: boolean;
}

type RemoveRecipeInput = {
  recipeId: string;
  locationId: string;
}

const styles: { [name: string]: CSSProperties } = {
  hideOverFlowText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  cardContentContainer: {
    flexDirection: 'row',
    gap: '16px',
    height: '100%',
    '@media (max-width: 767px)': {
      flexDirection: 'column',
      overflowY: 'scroll',
    },
  },
  locationsListContainer: {
    borderRight: `1px solid ${Colors.neutral200}`,
    maxWidth: '450px',
    minWidth: '400px',
    overflowY: 'scroll',
    paddingRight: '16px',
    '@media (min-width: 768px)': {
      height: '100%',
    },
    '@media (min-width: 768px) and (max-width: 1151px)': {
      maxWidth: '300px',
      minWidth: '300px',
    },
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  sublocationContainer: {
    alignItems: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: '56px',
    padding: '8px 16px',
  },
}

export default function ItemLocations() {
  const { addAlert } = useAlertState();
  const { cache } = useApolloClient();
  const [queryParams, setQueryParams] = useSearchParams();
  const locationId = queryParams.get('locationId');
  const sublocationId = queryParams.get('sublocationId');
  const { data: locationsData, loading: locationsAreLoading } = useQuery<GetLocationsQueryResponse>(GET_LOCATIONS_QUERY, {
    onCompleted: (data) => {
      if (data.inventoryItemLocations.length > 0 && !queryParams.get('locationId')) {
        setQueryParams({
          locationId: data.inventoryItemLocations[0].id,
        });

        if (data.inventoryItemLocations[0].sublocations.length > 0) {
          setQueryParams({
            locationId: data.inventoryItemLocations[0].id,
            sublocationId: data.inventoryItemLocations[0].sublocations[0].id,
          });
        }
      }
    },
  });
  const [getSublocation, { data: sublocationData, loading: sublocationIsLoading }] = useLazyQuery<GetSublocationQueryResponse>(GET_SUBLOCATION_QUERY);
  const [getItemsToAdd, { data: itemsData, loading: itemsAreLoading }] = useLazyQuery<GetItemsQueryResponse>(GET_ITEMS);
  const [getRecipesToAdd, { data: recipesData, loading: recipesAreLoading }] = useLazyQuery<GetRecipesResponse>(GET_RECIPES);
  const [createLocation] = useMutation<CreateLocationMutationResponse>(CREATE_LOCATION_MUTATION, {
    update(cache, { data: createLocationData }) {
      cache.updateQuery<GetLocationsQueryResponse, GetLocationsQueryResponse>({ query: GET_LOCATIONS_QUERY }, (data) => {
        if (!data || !createLocationData) {
          return undefined;
        }

        const newLocations = [...data.inventoryItemLocations, createLocationData.createInventoryItemLocation];
        newLocations.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });

        return {
          inventoryItemLocations: newLocations,
        };
      });
    },
    onCompleted(data) {
      handleLocationClick(data.createInventoryItemLocation.id);
    },
  });
  const [createSublocation] = useMutation<CreateSublocationMutationResponse>(CREATE_SUBLOCATION_MUTATION, {
    update(cache, { data }, { variables }) {
      if (!data || !locationId) {
        return;
      }

      const parentLocation = locationsData?.inventoryItemLocations.find((l) => l.id === variables?.input.parentLocationId);
      if (!parentLocation) {
        return;
      }

      cache.modify({
        id: cache.identify(parentLocation),
        fields: {
          sublocations(existingSublocationRefs = [], { readField }) {
            const newSublocationRef = cache.writeFragment({
              data: data.createInventoryItemSublocation,
              fragment: gql`
                fragment NewInventoryItemSublocation on InventoryItemSublocation {
                  id
                  name
                }
              `,
            });

            const newSublocations = [...existingSublocationRefs, newSublocationRef];
            newSublocations.sort((a, b) => {
              return readField('name', a)?.toLocaleString().localeCompare(readField('name', b)?.toLocaleString() || '') || 0;
            });

            return newSublocations;
          },
        },
      });
    }
  });
  const [editLocation] = useMutation<EditLocationResponse>(EDIT_LOCATION_MUTATION);
  const [editSublocation] = useMutation<EditSublocationResponse>(EDIT_SUBLOCATION_MUTATION);
  const [archiveSublocation] = useMutation<ArchiveSublocationMutationResponse>(ARCHIVE_SUBLOCATION_MUTATION, {
    update(cache, { data }) {
      if (!data?.archiveInventoryItemSublocation.isArchived) {
        return;
      }

      const sublocationId = data?.archiveInventoryItemSublocation.id;
      const parentLocation = locationsData?.inventoryItemLocations.find((l) => {
        const found = l.sublocations.find((s) => {
          return s.id === sublocationId;
        });

        if (found) {
          return true;
        }

        return false;
      });

      if (!parentLocation) {
        return;
      }

      cache.modify({
        id: cache.identify(parentLocation),
        fields: {
          sublocations(existingSublocationRefs, { readField }) {
            return existingSublocationRefs.filter((ref: any) => {
              return sublocationId !== readField('id', ref);
            });
          },
        },
      });
    },
  });
  const [archiveLocation] = useMutation<ArchiveLocationMutationResponse>(ARCHIVE_LOCATION_MUTATION, {
    update(cache, { data }) {
      if (!data?.archiveInventoryItemLocation.isArchived) {
        return;
      }

      cache.evict({
        id: cache.identify(data.archiveInventoryItemLocation)
      });
    }
  });
  const [addItem] = useMutation<AddItemMutationResponse>(ADD_ITEM_MUTATION, {
    refetchQueries: [GET_SUBLOCATION_QUERY],
  });
  const [removeItem] = useMutation<RemoveItemMutationResponse>(REMOVE_ITEM_MUTATION, {
    update(cache, { data }, { variables }) {
      if (!data?.success || !variables || !variables.itemId || !variables.locationId || !locationsData) {
        return;
      }

      let sublocation = null;
      locationsData.inventoryItemLocations.forEach((loc) => {
        loc.sublocations.forEach((s) => {
          if (s.id === variables.locationId) {
            sublocation = s;
          }
        });
      });

      if (!sublocation) {
        return;
      }

      cache.modify({
        id: cache.identify(sublocation),
        fields: {
          items(existingItemRefs, { readField }) {
            return existingItemRefs.filter((ref: any) => {
              const item = readField('item', ref);
              if (item) {
                const id = readField('id', item as Reference);
                if (id === variables.itemId) {
                  return false;
                }
              }

              return true;
            });
          },
        }
      });
    },
  });
  const [moveItem] = useMutation<MoveItemMutationResponse>(MOVE_ITEM_MUTATION);
  const [addRecipe] = useMutation<AddRecipeResponse>(ADD_RECIPE, {
    refetchQueries: [GET_SUBLOCATION_QUERY],
  });
  const [removeRecipe] = useMutation<RemoveRecipeResponse>(REMOVE_RECIPE, {
    update(cache, { data }, { variables }) {
      if (!data?.success || !variables || !variables.recipeId || !variables.locationId || !locationsData) {
        return;
      }

      let sublocation = null;
      locationsData.inventoryItemLocations.forEach((loc) => {
        loc.sublocations.forEach((s) => {
          if (s.id === variables.locationId) {
            sublocation = s;
          }
        });
      });

      if (!sublocation) {
        return;
      }

      cache.modify({
        id: cache.identify(sublocation),
        fields: {
          items(existingItemRefs, { readField }) {
            return existingItemRefs.filter((ref: any) => {
              const recipe = readField('recipe', ref);
              if (recipe) {
                const id = readField('id', recipe as Reference);
                if (id === variables.recipeId) {
                  return false;
                }
              }

              return true;
            });
          },
        }
      });
    },
  });
  const [setLocationVisibility] = useMutation<SetLocationVisibilityResponse>(SET_LOCATION_VISIBILITY);
  const [setSublocationVisibility] = useMutation<SetSublocationVisibilityResponse>(SET_SUBLOCATION_VISIBILITY);
  const [setItemVisibility] = useMutation<SetItemVisibilityResponse>(SET_ITEM_VISIBILITY, {
    update(cache, { data }) {
      if (!sublocationData || !data) {
        return;
      }

      cache.modify({
        id: cache.identify(sublocationData.inventoryItemSublocation),
        fields: {
          items(itemRefs = [], { readField }) {
            return itemRefs.map((itemRef: Reference) => {
              const item: Reference | undefined = readField('item', itemRef);

              if (readField('id', item) === data.setInventoryItemLocationAssignmentVisibilityForOrders.item.id) {
                return {
                  ...itemRef,
                  isHiddenFromOrders: data.setInventoryItemLocationAssignmentVisibilityForOrders.isHiddenFromOrders,
                };
              }

              return itemRef;
            });
          }
        },
      });
    },
  });
  const [draggedItem, setDraggedItem] = useState<number>(-1);
  const [draggedOverItem, setDraggedOverItem] = useState<number>(-1);
  const [dragEnabledIndex, setDragEnabledIndex] = useState<number>(-1);

  const dragStart = (position: number) => {
    setDraggedItem(position);
  };

  const dragEnter = (position: number) => {
    setDraggedOverItem(position);
  };

  const dragEnd = () => {
    if (draggedItem === -1 || draggedOverItem === -1 || draggedItem === draggedOverItem) {
      return;
    }


    const item = sublocationData?.inventoryItemSublocation.items[draggedItem];
    let itemId;
    if (item?.__typename === 'InventoryItemLocationAssignment') {
      itemId = item.item.id;
    } else if (item?.__typename === 'RecipeLocationAssignment') {
      itemId = item.recipe.id;
    }

    moveItem({
      variables: {
        itemId: itemId,
        locationId: sublocationId,
        sortOrder: draggedOverItem,
      },
    }).then(data => {
      if (data.errors) {
        const id = generateId();
        const errorAlert = (
          <StandardAlert title='Error adding item' description={data.errors[0].message} type='error' id={id} />
        );

        addAlert(id, errorAlert, 10);
        return;
      }
    });

    // Update the item order in the cache
    let sublocation = null;
    locationsData?.inventoryItemLocations.forEach((loc) => {
      loc.sublocations.forEach((s) => {
        if (s.id === sublocationId) {
          sublocation = s;
        }
      });
    });

    if (!sublocation) {
      return;
    }

    cache.modify({
      id: cache.identify(sublocation),
      fields: {
        items(existingItemRefs = []) {
          const newItemRefs = [...existingItemRefs];

          const movedItemRef = newItemRefs.splice(draggedItem, 1)[0];
          newItemRefs.splice(draggedOverItem, 0, movedItemRef);

          return newItemRefs;
        },
      }
    });

    // Clear drag states
    setDraggedItem(-1);
    setDraggedOverItem(-1);
    setDragEnabledIndex(-1);
  }

  useEffect(() => {
    if (sublocationId !== null) {
      getSublocation({
        variables: {
          id: sublocationId,
        },
      });
    }
  }, [sublocationId, getSublocation]);

  const handleLocationClick = (id: string) => {
    if (id === locationId) {
      return;
    }

    setQueryParams({
      locationId: id,
    });
  }

  const handleSublocationClick = (id: string) => {
    if (id === sublocationId || !locationId) {
      return;
    }

    setQueryParams({
      locationId: locationId,
      sublocationId: id,
    });
  }

  const handleEditLocation = async (values: EditLocationInput) => {
    await editLocation({
      variables: {
        id: values.id,
        input: {
          name: values.name,
        },
      },
    });
  }

  const handleEditSublocation = async (values: EditSublocationInput) => {
    await editSublocation({
      variables: {
        id: values.id,
        input: {
          name: values.name,
        },
      },
    });
  }

  const handleArchiveSublocation = async (id: string) => {
    await archiveSublocation({
      variables: {
        id: id,
      },
    });
  }

  const handleArchiveLocation = async (id: string) => {
    await archiveLocation({
      variables: {
        id: id,
      },
    });
  }

  const handleCreateLocation = async (values: CreateLocationInput) => {
    await createLocation({
      variables: {
        input: {
          name: values.name,
        },
      },
    });
  }

  const handleCreateSublocation = async (values: CreateSublocationInput) => {
    await createSublocation({
      variables: {
        input: {
          name: values.name,
          parentLocationId: values.parentLocation,
        },
      },
    });
  }

  const handleAddItem = async (values: AddItemInput) => {
    const { errors } = await addItem({
      variables: {
        itemId: values.itemId,
        locationId: values.sublocationId,
      },
    });

    if (errors) {
      const id = generateId();
      const errorAlert = (
        <StandardAlert title='Error adding item' description={errors[0].message} type='error' id={id} />
      );

      addAlert(id, errorAlert, 10);
      return;
    }

    if (sublocationId === values.sublocationId) {
      await getSublocation({
        variables: {
          id: sublocationId,
        },
      });
    }
  }

  const handleRemoveItem = async (data: RemoveItemInput) => {
    await removeItem({
      variables: {
        itemId: data.itemId,
        locationId: data.locationId,
      },
    });
  }

  const handleAddRecipe = async (values: AddRecipeInput) => {
    const { errors } = await addRecipe({
      variables: {
        recipeId: values.recipeId,
        locationId: values.sublocationId,
      },
    });

    if (errors) {
      const id = generateId();
      const errorAlert = (
        <StandardAlert title='Error adding recipe' description={errors[0].message} type='error' id={id} />
      );

      addAlert(id, errorAlert, 10);
      return;
    }

    if (sublocationId === values.sublocationId) {
      await getSublocation({
        variables: {
          id: sublocationId,
        },
      });
    }
  }

  const handleRemoveRecipe = async (data: RemoveRecipeInput) => {
    await removeRecipe({
      variables: {
        recipeId: data.recipeId,
        locationId: data.locationId,
      },
    });
  }

  const createLocationModal = (
    <FormModal<CreateLocationInput> title='Create Location' submitLabel='Create' onSubmit={handleCreateLocation} initialValues={{ name: '' }}>
      <TextField label='Location Name' name='name' type='text' required />
    </FormModal>
  );

  const createSublocationModal = (
    <FormModal<CreateSublocationInput> title='Create Sublocation' submitLabel='Create' onSubmit={handleCreateSublocation} initialValues={{ name: '', parentLocation: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Sublocation Name' name='name' type='text' required />
        <SingleSelect label='Parent Location' name='parentLocation' required >
          {locationsData?.inventoryItemLocations.map((l, i) => (
            <Choice label={l.name} value={l.id} key={i} />
          ))}
        </SingleSelect>
      </View>
    </FormModal>
  );

  const editLocationModal = (
    <FormModal<EditLocationInput> title='Edit Location' onSubmit={handleEditLocation} initialValues={{ id: '', name: '' }}>
      <TextField label='Name' name='name' type='text' required />
    </FormModal>
  );

  const editSublocationModal = (
    <FormModal<EditSublocationInput> title='Edit Sublocation' onSubmit={handleEditSublocation} initialValues={{ id: '', name: '' }}>
      <TextField label='Name' name='name' type='text' required />
    </FormModal>
  );

  const archiveLocationModal = (
    <ConfirmModal title='Archive location?' confirmLabel='Archive' onConfirm={handleArchiveLocation} destructive >
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This will permanently archive this location and all sublocations associated with it. You will no longer see this location on order or item count screens.</StyledParagraph>
        <StyledParagraph>The items associated with this location will not be effected.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  const archiveSublocationModal = (
    <ConfirmModal title='Archive sublocation?' confirmLabel='Archive' onConfirm={handleArchiveSublocation} destructive >
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This will permanently archive this sublocation. You will not longer see this sublocation on order or item count screens.</StyledParagraph>
        <StyledParagraph>The items associated with this sublocation will not be effected.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  const addItemModal = (
    <FormModal<AddItemInput> title='Add Item' onSubmit={handleAddItem} initialValues={{ itemId: '', locationId: locationId || '', sublocationId: sublocationId || '' }} submitLabel='Add Item'>
      <FormModalValueProvider>
        {({ getValue, setValue }) => (
          <View style={{ gap: '16px' }}>
            <SingleSelect label='Item' name='itemId' placeholder='Choose an item' onFilter={(_: string, value: string) => { getItemsToAdd({ variables: { name: value, page: 0 } }); }} filterable filterPlaceholder='Enter item name' required autoFocusSearch >
              {itemsAreLoading ?
                <View style={{ alignItems: 'center', padding: '16px' }}>
                  <CircularSpinner size='medium' />
                </View>
                :
                <>
                  {(itemsData && itemsData.inventoryItems.items.length > 0) ?
                    itemsData.inventoryItems.items.map((item, index) => (
                      <Choice label={item.name} value={item.id} key={index} />
                    ))
                    :
                    <View style={{ padding: '16px' }}>
                      <StyledParagraph>No items found.</StyledParagraph>
                    </View>
                  }
                </>
              }
            </SingleSelect>
            <SingleSelect label='Location' name='locationId' validate={(_, value) => {
              if (getValue && value !== getValue('locationId')) {
                setValue && setValue('sublocationId', '');
              }

              return null;
            }} required>
              {locationsData?.inventoryItemLocations.map((l, index) => (
                <Choice label={l.name} value={l.id} key={index} />
              ))}
            </SingleSelect>
            {(getValue && getValue('locationId') && locationsData) ?
              <SingleSelect label='Sublocation' name='sublocationId' required>
                {locationsData.inventoryItemLocations.find((l) => l.id === getValue('locationId'))?.sublocations.map((s, index) => (
                  <Choice label={s.name} value={s.id} key={index} />
                ))}
              </SingleSelect>
              :
              <></>
            }
          </View>
        )}
      </FormModalValueProvider>
    </FormModal>
  );

  const removeItemModal = (
    <ConfirmModal title='Remove item?' confirmLabel='Remove' onConfirm={handleRemoveItem} destructive>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This item will be removed from this sublocation, along with any pars for this item.</StyledParagraph>
        <StyledParagraph>The item can always be added back to the sublocation and no item count data will be lost.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  const addRecipeModal = (
    <FormModal<AddRecipeInput> title='Add Recipe' onSubmit={handleAddRecipe} initialValues={{ recipeId: '', locationId: locationId || '', sublocationId: sublocationId || '' }} submitLabel='Add Recipe'>
      <FormModalValueProvider>
        {({ getValue, setValue }) => (
          <View style={{ gap: '16px' }}>
            <SingleSelect label='Recipe' name='recipeId' placeholder='Choose a recipe' onFilter={(_: string, value: string) => { getRecipesToAdd({ variables: { name: value } }); }} filterable filterPlaceholder='Enter recipe name' required autoFocusSearch >
              {recipesAreLoading ?
                <View style={{ alignItems: 'center', padding: '16px' }}>
                  <CircularSpinner size='medium' />
                </View>
                :
                <>
                  {(recipesData && recipesData.recipes.length > 0) ?
                    recipesData.recipes.map((recipe, index) => (
                      <Choice label={recipe.name} value={recipe.id} key={index} />
                    ))
                    :
                    <View style={{ padding: '16px' }}>
                      <StyledParagraph>No recipes found.</StyledParagraph>
                    </View>
                  }
                </>
              }
            </SingleSelect>
            <SingleSelect label='Location' name='locationId' validate={(_, value) => {
              if (getValue && value !== getValue('locationId')) {
                setValue && setValue('sublocationId', '');
              }

              return null;
            }} required>
              {locationsData?.inventoryItemLocations.map((l, index) => (
                <Choice label={l.name} value={l.id} key={index} />
              ))}
            </SingleSelect>
            {(getValue && getValue('locationId') && locationsData) ?
              <SingleSelect label='Sublocation' name='sublocationId' required>
                {locationsData.inventoryItemLocations.find((l) => l.id === getValue('locationId'))?.sublocations.map((s, index) => (
                  <Choice label={s.name} value={s.id} key={index} />
                ))}
              </SingleSelect>
              :
              <></>
            }
          </View>
        )}
      </FormModalValueProvider>
    </FormModal>
  );

  const removeRecipeModal = (
    <ConfirmModal title='Remove recipe?' confirmLabel='Remove' onConfirm={handleRemoveRecipe} destructive>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This recipe will be removed from this sublocation, along with any pars for this recipe.</StyledParagraph>
        <StyledParagraph>The recipe can always be added back to the sublocation and no item count data will be lost.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <StyledHeading tag='h3'>Item Locations</StyledHeading>
          <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
            <ModalLauncher modal={createLocationModal}>
              {({ openModal: openCreateLocationModal }) => (
                <ModalLauncher modal={createSublocationModal}>
                  {({ openModal: openCreateSublocationModal }) => (
                    <ModalLauncher modal={addItemModal}>
                      {({ openModal: openAddItemModal }) => (
                        <ModalLauncher modal={addRecipeModal}>
                          {({ openModal: openAddRecipeModal }) => (
                            <ActionMenu label='Add' alignment='right'>
                              <ActionItem label='Item' onClick={openAddItemModal} />
                              <ActionItem label='Recipe' onClick={openAddRecipeModal} />
                              <ActionItem label='Location' onClick={openCreateLocationModal} />
                              <ActionItem label='Sublocation' onClick={openCreateSublocationModal} />
                            </ActionMenu>
                          )}
                        </ModalLauncher>
                      )}
                    </ModalLauncher>
                  )}
                </ModalLauncher>
              )}
            </ModalLauncher>
          </HasProductRole>
        </View>
      </Cell>
      <Cell lg={0} md={0} sm={4}>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '16px' }}>
          <SingleSelect label='Location' name='locationId' placeholder='Choose a location' value={locationId || ''} onChange={(_, value) => { handleLocationClick(value || ''); }} style={{ maxWidth: '300px', minWidth: '250px', width: 'calc(50% - 16px)' }}>
            {locationsData?.inventoryItemLocations.map((l, index) => {
              return (
                <Choice label={l.name} value={l.id} key={index} />
              );
            })}
          </SingleSelect>
          {locationId &&
            <SingleSelect label='Sublocation' name='sublocationId' placeholder='Choose a sublocation' value={sublocationId || ''} onChange={(_, value) => { handleSublocationClick(value || ''); }} style={{ maxWidth: '300px', minWidth: '250px', width: 'calc(50% - 16px)' }}>
              {(() => {
                if (!locationsData) {
                  return null;
                }

                const openLocation = locationsData.inventoryItemLocations.find((loc) => loc.id === locationId);
                if (!openLocation) {
                  return null;
                }

                if (openLocation.sublocations.length === 0) {
                  return (
                    <View style={{ maxWidth: '300px', minWidth: '250px', padding: '16px' }}>
                      <StyledParagraph>There are no sublocations in this location.</StyledParagraph>
                    </View>
                  );
                } else {
                  return (
                    <>
                      {openLocation.sublocations.map((s, index) => {
                        return (
                          <Choice label={s.name} value={s.id} key={index} />
                        );
                      })}
                    </>
                  );
                }
              })()}
            </SingleSelect>
          }
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {(locationsAreLoading) ?
          <AtomSpinner size='large' />
          :
          (
            <Card size='medium' style={{ height: 'calc(100vh - 232px)' }}>
              {(locationsData?.inventoryItemLocations.length === 0) ?
                <StyledHeading tag='h6'>No item locations exist.</StyledHeading>
                :
                <View style={styles.cardContentContainer}>
                  <View style={styles.locationsListContainer}>
                    {locationsData?.inventoryItemLocations.map((l, index) => {
                      return (
                        <View key={index} style={{ minHeight: 'fit-content' }}>
                          <View style={{ alignItems: 'center', borderBottom: `1px solid ${Colors.neutral200}`, flexDirection: 'row', justifyContent: 'space-between', minHeight: '72px', padding: '16px 16px 16px 0px' }}>
                            <View style={{ alignItems: 'center', cursor: 'pointer', flexDirection: 'row', gap: '16px' }} onClick={() => { handleLocationClick(l.id); }}>
                              {locationId === l.id &&
                                <Icon size='small' icon={Icons.ChevronUp} />
                              }
                              {locationId !== l.id &&
                                <Icon size='small' icon={Icons.ChevronDown} />
                              }
                              <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                                <StyledHeading tag='h5' style={{ maxWidth: '200px', ...styles.hideOverFlowText }}>{l.name}</StyledHeading>
                                {l.isHiddenFromOrders && <Icon size='medium' icon={Icons.Hidden} style={{ color: Colors.neutral600, cursor: 'default' }} />}
                              </View>
                            </View>
                            <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
                              <ModalLauncher modal={editLocationModal}>
                                {({ openModal: openEditModal }) => (
                                  <ModalLauncher modal={archiveLocationModal}>
                                    {({ openModal: openArchiveModal }) => (
                                      <ActionMenu alignment='right'>
                                        {l.isHiddenFromOrders ?
                                          <ActionItem label='Show on orders' onClick={() => { setLocationVisibility({ variables: { locationId: l.id, isVisible: true } }); }} />
                                          :
                                          <ActionItem label='Hide on orders' onClick={() => { setLocationVisibility({ variables: { locationId: l.id, isVisible: false } }); }} />
                                        }
                                        <ActionItem label='Edit' onClick={() => { openEditModal({ id: l.id, name: l.name }); }} />
                                        <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Admin]}>
                                          <ActionItem label='Archive' onClick={() => { openArchiveModal(l.id); }} />
                                        </HasProductRole>
                                      </ActionMenu>
                                    )}
                                  </ModalLauncher>
                                )}
                              </ModalLauncher>
                            </HasProductRole>
                          </View>
                          {locationId === l.id &&
                            <View style={{ padding: '8px 0' }}>
                              {l.sublocations.map((s, index) => {
                                return (
                                  <View key={index} style={{ ...styles.sublocationContainer, ...(s.id === sublocationId ? { backgroundColor: Colors.primary50 } : {}) }} onClick={() => { handleSublocationClick(s.id); }}>
                                    <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                                      <StyledHeading tag='h6' style={{ maxWidth: '200px', ...styles.hideOverflowText }}>{s.name}</StyledHeading>
                                      {s.isHiddenFromOrders && <Icon size='medium' icon={Icons.Hidden} style={{ color: Colors.neutral600, cursor: 'default' }} />}
                                    </View>

                                    <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
                                      <ModalLauncher modal={editSublocationModal}>
                                        {({ openModal: openEditModal }) => (
                                          <ModalLauncher modal={archiveSublocationModal}>
                                            {({ openModal: openArchiveModal }) => (
                                              <ActionMenu alignment='right'>
                                                {s.isHiddenFromOrders ?
                                                  <ActionItem label='Show on orders' onClick={() => { setSublocationVisibility({ variables: { sublocationId: s.id, isVisible: true } }); }} />
                                                  :
                                                  <ActionItem label='Hide on orders' onClick={() => { setSublocationVisibility({ variables: { sublocationId: s.id, isVisible: false } }); }} />
                                                }
                                                <ActionItem label='Edit' onClick={() => { openEditModal({ id: s.id, name: s.name }); }} />
                                                <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Admin]}>
                                                  <ActionItem label='Archive' onClick={() => { openArchiveModal(s.id); }} />
                                                </HasProductRole>
                                              </ActionMenu>
                                            )}
                                          </ModalLauncher>
                                        )}
                                      </ModalLauncher>
                                    </HasProductRole>
                                  </View>
                                );
                              })}
                              {l.sublocations.length === 0 &&
                                <StyledParagraph style={{ margin: '16px 0' }}>There are no sublocations in this location.</StyledParagraph>
                              }
                            </View>
                          }
                        </View>
                      );
                    })}
                  </View>
                  <View style={{ height: '100%', width: '100%' }}>
                    {sublocationId === null ?
                      <View style={{ alignContent: 'center', height: '100%', justifyContent: 'center', textAlign: 'center', width: '100%' }} >
                        <StyledHeading tag='h6' style={{ color: Colors.neutral700 }}>Select a sublocation to view items.</StyledHeading>
                      </View>
                      :
                      (sublocationIsLoading ?
                        <View style={{ alignContent: 'center', height: '100%', justifyContent: 'center', textAlign: 'center', width: '100%' }} >
                          <AtomSpinner size='medium' />
                        </View>
                        :
                        <View style={{ height: '100%', overflowY: 'scroll' }}>
                          {sublocationData?.inventoryItemSublocation.items.map((itemAssignment, index) => {
                            if (itemAssignment.__typename === 'InventoryItemLocationAssignment') {
                              return (
                                <div key={index} onDragStart={(_) => dragStart(index)} onDragEnter={(_) => dragEnter(index)} onDragEnd={dragEnd} draggable={dragEnabledIndex === index} className={css((draggedOverItem === index && draggedItem > draggedOverItem) && classStyles.dragHoverTop, (draggedOverItem === index && draggedItem < draggedOverItem) && classStyles.dragHoverBottom)}>
                                  <View style={{ alignItems: 'center', borderBottom: `1px solid ${Colors.neutral200}`, flexDirection: 'row', justifyContent: 'space-between', padding: '16px' }}>
                                    <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', width: '300px' }}>
                                      <Icon size='small' icon={Icons.Grip} style={{ cursor: 'pointer', '@media (max-width: 1151px)': { display: 'none' } }} onMouseDown={() => { setDragEnabledIndex(index); }} />
                                      <Link href={'/items/' + itemAssignment.item.id}><StyledParagraph>{itemAssignment.item.name}</StyledParagraph></Link>
                                      {itemAssignment.isHiddenFromOrders && <Icon size='medium' icon={Icons.Hidden} style={{ color: Colors.neutral600, cursor: 'default' }} />}
                                    </View>
                                    <View style={{ flexDirection: 'row', gap: '16px', width: '416px', '@media (max-width: 1530px)': { display: 'none' } }}>
                                      <StyledParagraph style={{ flexGrow: 1, maxWidth: '200px', ...styles.hideOverFlowText }}><span style={{ fontWeight: 600 }}>Vendor:</span> {itemAssignment.item.vendor.name}</StyledParagraph>
                                      <StyledParagraph style={{ flexGrow: 1, maxWidth: '200px', ...styles.hideOverFlowText }}><span style={{ fontWeight: 600 }}>Category:</span> {itemAssignment.item.category.name}</StyledParagraph>
                                    </View>
                                    <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
                                      <ModalLauncher modal={removeItemModal}>
                                        {({ openModal }) => (
                                          <ActionMenu alignment='right'>
                                            {itemAssignment.isHiddenFromOrders ?
                                              <ActionItem label='Show on orders' onClick={() => { setItemVisibility({ variables: { sublocationId: sublocationId, itemId: itemAssignment.item.id, isVisible: true } }); }} />
                                              :
                                              <ActionItem label='Hide on orders' onClick={() => { setItemVisibility({ variables: { sublocationId: sublocationId, itemId: itemAssignment.item.id, isVisible: false } }); }} />
                                            }
                                            <ActionItem label='Remove' onClick={() => { openModal({ itemId: itemAssignment.item.id, locationId: sublocationId }); }} />
                                          </ActionMenu>
                                        )}
                                      </ModalLauncher>
                                    </HasProductRole>
                                  </View>
                                </div>
                              );
                            } else if (itemAssignment.__typename === 'RecipeLocationAssignment') {
                              return (
                                <div key={index} onDragStart={(_) => dragStart(index)} onDragEnter={(_) => dragEnter(index)} onDragEnd={dragEnd} draggable={dragEnabledIndex === index} className={css((draggedOverItem === index && draggedItem > draggedOverItem) && classStyles.dragHoverTop, (draggedOverItem === index && draggedItem < draggedOverItem) && classStyles.dragHoverBottom)}>
                                  <View style={{ alignItems: 'center', borderBottom: `1px solid ${Colors.neutral200}`, flexDirection: 'row', justifyContent: 'space-between', padding: '16px' }}>
                                    <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', width: '300px' }}>
                                      <Icon size='small' icon={Icons.Grip} style={{ cursor: 'pointer', '@media (max-width: 1151px)': { display: 'none' } }} onMouseDown={() => { setDragEnabledIndex(index); }} />
                                      <Link href={`/recipes/${itemAssignment.recipe.recipeCategory.id}/${itemAssignment.recipe.id}`}><StyledParagraph>{itemAssignment.recipe.name}</StyledParagraph></Link>
                                    </View>
                                    <View style={{ flexDirection: 'row', gap: '16px', width: '416px', '@media (max-width: 1530px)': { display: 'none' } }}>
                                    <StyledParagraph style={{ flexGrow: 1, maxWidth: '200px', ...styles.hideOverFlowText }}><span style={{ fontWeight: 600 }}></span></StyledParagraph>
                                      <StyledParagraph style={{ flexGrow: 1, maxWidth: '200px', ...styles.hideOverFlowText }}><span style={{ fontWeight: 600 }}>Category:</span> {itemAssignment.recipe.itemCategory.name}</StyledParagraph>
                                    </View>
                                    <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
                                      <ModalLauncher modal={removeRecipeModal}>
                                        {({ openModal }) => (
                                          <ActionMenu alignment='right'>
                                            <ActionItem label='Remove' onClick={() => { openModal({ recipeId: itemAssignment.recipe.id, locationId: sublocationId }); }} />
                                          </ActionMenu>
                                        )}
                                      </ModalLauncher>
                                    </HasProductRole>
                                  </View>
                                </div>
                              );
                            } else {
                              return null;
                            }
                          })}
                          {sublocationData?.inventoryItemSublocation.items.length === 0 &&
                            <View style={{ alignContent: 'center', height: '100%', justifyContent: 'center', textAlign: 'center', width: '100%' }} >
                              <StyledHeading tag='h6' style={{ color: Colors.neutral700 }}>There are no items in this sublocation.</StyledHeading>
                            </View>
                          }
                        </View>
                      )
                    }
                  </View>
                </View>
              }
            </Card>
          )
        }
      </Cell>
    </StandardGrid>
  );
}