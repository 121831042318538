import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionItemSection, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Card, Cell, ConfirmModal, DatePicker, FormModal, FormModalValueProvider, HasProductRole, InventoryRoles, Link, ModalLauncher, NotFound, Products, Row, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, Typography, View, generateId, useAlertState } from "@barscience/global-components";
import { css } from "aphrodite";
import { useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { IMPORT_ORDER, ImportOrderInput, ImportOrderResponse } from "../invoices/CreateInvoiceModal";
import currency from "currency.js";
import { formatDecimalAmount } from "../../util/formatters";

/* Get Order Query - This must be the same as the query in the EditOrder page */
const GET_ORDER_DETAILS_QUERY = gql`
query getInventoryOrderDetails($id: ID!) {
  inventoryOrder(id: $id) {
    id
    vendor {
      id
      name
    }
    orderDate
    deliveryDate
    items {
      item {
        id
        name
        category {
          id
          name
        }
        unitsPerCase
        currentPrice {
          casePrice
        }
        unitDeposit
      }
      locationName
      sublocationName
      casesOrdered
    }
    itemCount
    caseCount
    estimatedTotalCost
    invoice {
      id
    }
  }
}
`;

type GetOrderDetailsQueryResponse = {
  inventoryOrder: InventoryOrder;
}

type InventoryOrder = {
  id: string;
  vendor: {
    id: string;
    name: string;
  };
  orderDate: string;
  deliveryDate: string;
  items: OrderItem[];
  itemCount: number;
  caseCount: number;
  estimatedTotalCost: string;
  invoice: {
    id: string;
  } | null;
}

type OrderItem = {
  item: Item;
  casesOrdered: number;
  locationName: string;
  sublocationName: string;
}

type Item = {
  id: string;
  name: string;
  category: {
    id: string;
    name: string;
  };
  unitsPerCase: number;
  currentPrice: {
    casePrice: string;
  }
  unitDeposit: string | null;
}

/* Edit Order Mutation */
const EDIT_ORDER_MUTATION = gql`
mutation editInventoryOrder($id: ID!, $input: EditInventoryOrderInput!) {
  editInventoryOrder(id: $id, input: $input) {
    id
    orderDate
    deliveryDate
  }
}
`;

type EditOrderMutationResponse = {
  editInventoryOrder: {
    id: string;
    orderDate: string;
    deliveryDate: string;
  }
}

type EditOrderInput = {
  orderDate: string;
  deliveryDate: string;
}

/* Delete Order Mutation */
const DELETE_ORDER_MUTATION = gql`
mutation deleteInventoryOrder($id: ID!) {
  deleteInventoryOrder(id: $id) {
    id
  }
}
`;

type DeleteOrderMutationResponse = {
  deleteInventoryOrder: {
    id: string;
  };
}

/* Update Order Item Mutation */
const UPDATE_ORDER_ITEM_MUTATION = gql`
mutation updateInventoryOrderItemAmount($orderId: ID!, $itemId: ID!, $input: UpdateOrderItemInput!) {
  orderItem: updateInventoryOrderItemAmount(orderId: $orderId, itemId: $itemId, input: $input) {
    item {
      id
    }
    casesOrdered
  }
}
`;

type UpdateOrderItemMutationResponse = {
  orderItem: {
    item: {
      id: string;
    }
    casesOrdered: number;
  }
}

type UpdateOrderItemInput = {
  itemId: '',
  casesOrdered: '',
}

export default function OrderDetail() {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const client = useApolloClient();
  const { addAlert } = useAlertState();
  const [shouldEditItems, setShouldEditItems] = useState<boolean>(false);
  const [wasDeleted, setWasDeleted] = useState<boolean>(false);
  const { data: orderData, loading: orderIsLoading, error: orderError, refetch: refetchOrder } = useQuery<GetOrderDetailsQueryResponse>(GET_ORDER_DETAILS_QUERY, {
    variables: {
      id: orderId,
    },
    fetchPolicy: 'network-only',
  });
  const [editOrderDates] = useMutation<EditOrderMutationResponse>(EDIT_ORDER_MUTATION);
  const [deleteOrder] = useMutation<DeleteOrderMutationResponse>(DELETE_ORDER_MUTATION);
  const [updateOrderItem] = useMutation<UpdateOrderItemMutationResponse>(UPDATE_ORDER_ITEM_MUTATION);
  const [createInvoice] = useMutation<ImportOrderResponse>(IMPORT_ORDER);

  const handleEditOrderDates = async (values: EditOrderInput) => {
    await editOrderDates({
      variables: {
        id: orderId,
        input: {
          orderDate: values.orderDate,
          deliveryDate: values.deliveryDate,
        },
      },
    });
  }

  const handleDeleteOrder = async () => {
    await deleteOrder({
      variables: {
        id: orderId,
      },
    });

    setWasDeleted(true);
  }

  const handleUpdateItem = async (values: UpdateOrderItemInput) => {
    await updateOrderItem({
      variables: {
        orderId: orderId,
        itemId: values.itemId,
        input: {
          casesOrdered: parseFloat(values.casesOrdered),
        },
      },
    });

    await refetchOrder();
  }

  const handleCreateInvoice = async (values: ImportOrderInput) => {
    const { data } = await createInvoice({
      variables: {
        orderId: orderId,
        input: {
          statementTotal: values.statementTotal,
        }
      },
    });

    // Update the cache
    await client.refetchQueries({
      updateCache(cache) {
        cache.modify({
          fields: {
            inventoryInvoices(_, { INVALIDATE }) {
              return INVALIDATE;
            },
            inventoryOrdersWithoutInvoices(_, { INVALIDATE }) {
              return INVALIDATE;
            },
          },
        });
      },
    });

    await refetchOrder();

    const id = generateId();
    const alert = (
      <StandardAlert title='Invoice successfully created' type='success' id={id}>
        <Link href={'/invoices/' + data?.createInventoryInvoiceFromOrder.id}>View Invoice</Link>
      </StandardAlert>
    );

    addAlert(id, alert);
  }

  if (wasDeleted) {
    return (
      <Navigate to='/orders' />
    );
  }

  if (orderIsLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (!orderId || orderError?.graphQLErrors[0].extensions.status === 404) {
    return (
      <StandardGrid>
        <NotFound />
      </StandardGrid>
    );
  }

  if (shouldEditItems) {
    return <Navigate to={'/orders/' + orderId + '/edit'} />
  }

  const categoryTotals: { [categoryId: string]: { name: string; cases: number; cost: string; } } = {};
  orderData?.inventoryOrder.items.forEach((item) => {
    if (!categoryTotals[item.item.category.id]) {
      categoryTotals[item.item.category.id] = {
        name: item.item.category.name,
        cases: item.casesOrdered,
        cost: currency(item.item.currentPrice.casePrice).multiply(currency(item.casesOrdered)).format(),
      }
    } else {
      categoryTotals[item.item.category.id].cost = currency(categoryTotals[item.item.category.id].cost).add(currency(item.item.currentPrice.casePrice).multiply(item.casesOrdered)).format();
      categoryTotals[item.item.category.id].cases += item.casesOrdered;
    }
  });

  const editOrderDatesModal = (
    <FormModal<EditOrderInput> title='Edit Order Dates' onSubmit={handleEditOrderDates} initialValues={{ orderDate: orderData?.inventoryOrder.orderDate || '', deliveryDate: orderData?.inventoryOrder.deliveryDate || '' }}>
      <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 1151px)': { flexDirection: 'column' } }}>
        <DatePicker label='Order Date' name='orderDate' />
        <DatePicker label='Delivery Date' name='deliveryDate' />
      </View>
    </FormModal>
  );

  const deleteOrderModal = (
    <ConfirmModal title='Delete Order?' confirmLabel='Delete' onConfirm={handleDeleteOrder} destructive>
      <StyledParagraph>This will permanently delete this order.</StyledParagraph>
    </ConfirmModal>
  );

  const editItemModal = (
    <FormModal<UpdateOrderItemInput> title='Edit Item Amount' onSubmit={handleUpdateItem} initialValues={{ itemId: '', casesOrdered: '' }}>
      <FormModalValueProvider>
        {({ setValue }) => (
          <TextField label='Cases To Order' name='casesOrdered' type='number' inputMode='decimal' validate={(name: string, value: string) => {
            let newValue = parseFloat(value);
            if (newValue < 0 || Number.isNaN(newValue)) {
              setValue && setValue(name, 0);
            }

            return null;
          }} />
        )}
      </FormModalValueProvider>
    </FormModal>
  );

  const createInvoiceModal = (
    <FormModal<ImportOrderInput> title='Create Invoice' onSubmit={handleCreateInvoice} initialValues={{ orderId: orderId || '', statementTotal: orderData?.inventoryOrder.estimatedTotalCost.toString() || '' }} submitLabel='Create'>
      <TextField label='Statement Total' type='currency' inputMode='decimal' description='Enter the total cost as shown on your invoice' name='statementTotal' required />
    </FormModal>
  );

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Orders' to='/orders' />
          <Breadcrumb label={orderData?.inventoryOrder.vendor.name + ' (' + orderData?.inventoryOrder.deliveryDate + ')'} />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
          <StyledHeading tag='h3'>{orderData?.inventoryOrder.vendor.name + ' (' + orderData?.inventoryOrder.deliveryDate + ')'}</StyledHeading>
          <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
            <ModalLauncher modal={editOrderDatesModal}>
              {({ openModal: openEditDatesModal }) => (
                <ModalLauncher modal={deleteOrderModal}>
                  {({ openModal: openDeleteOrderModal }) => (
                    <ModalLauncher modal={createInvoiceModal}>
                      {({ openModal: openCreateInvoiceModal }) => (
                        <ActionMenu alignment='right'>
                          <ActionItemSection>
                            <ActionItem label='Edit Items' onClick={() => { setShouldEditItems(true); }} />
                            <ActionItem label='Edit Dates' onClick={openEditDatesModal} />
                          </ActionItemSection>
                          {orderData?.inventoryOrder.invoice === null ?
                            <ActionItemSection>
                              <ActionItem label='Create Invoice' onClick={openCreateInvoiceModal} />
                            </ActionItemSection>
                            :
                            <ActionItemSection>
                              <ActionItem label='View Invoice' onClick={() => { navigate('/invoices/' + orderData?.inventoryOrder.invoice?.id); }} />
                            </ActionItemSection>
                          }
                          <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Admin]}>
                            <ActionItemSection>
                              <ActionItem label='Delete' onClick={openDeleteOrderModal} />
                            </ActionItemSection>
                          </HasProductRole>
                        </ActionMenu>
                      )}
                    </ModalLauncher>
                  )}
                </ModalLauncher>
              )}
            </ModalLauncher>
          </HasProductRole>
        </View>
      </Cell>
      <Row>
        <Cell lg={4} md={4} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '16px' }}>
              <StyledHeading tag='h6'>Estimated Total</StyledHeading>
              <p className={css(Typography.heading4SemiBold)}>{orderData?.inventoryOrder.estimatedTotalCost}</p>
            </View>
          </Card>
        </Cell>
        <Cell lg={4} md={4} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '16px' }}>
              <StyledHeading tag='h6'>Items Ordered</StyledHeading>
              <p className={css(Typography.heading4SemiBold)}>{orderData?.inventoryOrder.itemCount}</p>
            </View>
          </Card>
        </Cell>
        <Cell lg={4} md={4} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '16px' }}>
              <StyledHeading tag='h6'>Cases Ordered</StyledHeading>
              <p className={css(Typography.heading4SemiBold)}>{formatDecimalAmount(orderData?.inventoryOrder.caseCount || 0)}</p>
            </View>
          </Card>
        </Cell>
      </Row>
      <Cell lg={12} md={8} sm={4}>
        <Card size='medium'>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Item Name</TableHeaderCell>
                <TableHeaderCell>Location</TableHeaderCell>
                <TableHeaderCell>Sublocation</TableHeaderCell>
                <TableHeaderCell>Category</TableHeaderCell>
                <TableHeaderCell style={{ minWidth: '40px' }}>Cases Ordered</TableHeaderCell>
                <TableHeaderCell style={{ minWidth: '80px' }}>Estimated Total</TableHeaderCell>
                <TableHeaderCell style={{ margin: '0', padding: '0', width: '16px' }}></TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {orderData?.inventoryOrder.items.map((item, index) => {
                let cost = currency(item.item.currentPrice.casePrice).multiply(item.casesOrdered);
                if (item.item.unitDeposit) {
                  cost = cost.add(currency(item.item.unitDeposit).multiply(item.casesOrdered).multiply(item.item.unitsPerCase));
                }

                return (
                  <TableRow key={index}>
                    <TableCell style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}><Link href={'/items/' + item.item.id}>{item.item.name}</Link></TableCell>
                    <TableCell style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.locationName}</TableCell>
                    <TableCell style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.sublocationName}</TableCell>
                    <TableCell style={{ maxWidth: '140px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.item.category.name}</TableCell>
                    <TableCell>{item.casesOrdered}</TableCell>
                    <TableCell>{cost.format()}</TableCell>
                    <TableCell style={{ margin: '0', padding: '0', width: '16px' }}>
                      <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
                        <ModalLauncher modal={editItemModal}>
                          {({ openModal }) => (
                            <ActionMenu alignment='right'>
                              <ActionItem label='Edit' onClick={() => { openModal({ itemId: item.item.id, casesOrdered: item.casesOrdered }); }} />
                            </ActionMenu>
                          )}
                        </ModalLauncher>
                      </HasProductRole>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Card>
      </Cell>
      <Cell lg={6} md={4} sm={4}>
        <Card>
          <View style={{ gap: '16px' }}>
            <StyledHeading tag='h6'>Totals By Category</StyledHeading>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Category</TableHeaderCell>
                  <TableHeaderCell style={{ minWidth: '80px' }}># Cases</TableHeaderCell>
                  <TableHeaderCell style={{ minWidth: '100px' }}>Total</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {Object.keys(categoryTotals).map((id, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{categoryTotals[id].name}</TableCell>
                      <TableCell>{categoryTotals[id].cases}</TableCell>
                      <TableCell>{categoryTotals[id].cost}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </View>
        </Card>
      </Cell>
    </StandardGrid>
  );
}