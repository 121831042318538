import { Card, Cell, Colors, StandardGrid, StyledHeading, StyledParagraph, View } from "@barscience/global-components";
import { CSSProperties } from "aphrodite";
import { Link } from "react-router-dom";

const cardStyle: CSSProperties = {
  flexGrow: 1,
  height: '80px',
  maxWidth: '400px',
  minWidth: '400px',
  width: '400px',
  ':hover': {
    backgroundColor: Colors.neutral100,
  },
  ':active': {
    backgroundColor: Colors.neutral300,
  },
  '@media (max-width: 767px)': {
    maxWidth: '100%',
    minWidth: 'fit-content',
    width: 'fit-content',
  }
}

const linkStyle: React.CSSProperties = {
  textDecoration: 'none',
  maxWidth: '100%',
}

export default function Reports() {
  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <StyledHeading tag='h3'>Reports</StyledHeading>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '16px' }}>
          <Link to='/reports/item-count-valuation' style={linkStyle}>
            <Card size='medium' style={cardStyle}>
              <View style={{ gap: '8px' }}>
                <StyledHeading tag='h6' style={{ color: Colors.neutral900 }}>Item Count Valuation</StyledHeading>
                <StyledParagraph style={{ color: Colors.neutral700 }}>View the total value of the items on hand.</StyledParagraph>
              </View>
            </Card>
          </Link>

          <Link to='/reports/period-pc' style={linkStyle}>
            <Card size='medium' style={cardStyle}>
              <View style={{ gap: '8px' }}>
                <StyledHeading tag='h6' style={{ color: Colors.neutral900 }}>Period Percent Cost</StyledHeading>
                <StyledParagraph style={{ color: Colors.neutral700 }}>View the overall and category PCs for a period.</StyledParagraph>
              </View>
            </Card>
          </Link>

          <Link to='/reports/shrinkage' style={linkStyle}>
            <Card size='medium' style={cardStyle}>
              <View style={{ gap: '8px' }}>
                <StyledHeading tag='h6' style={{ color: Colors.neutral900 }}>Period Shrinkage</StyledHeading>
                <StyledParagraph style={{ color: Colors.neutral700 }}>View the inventory shrinkage for a period.</StyledParagraph>
              </View>
            </Card>
          </Link>

          <Link to='/reports/waste' style={linkStyle}>
            <Card size='medium' style={cardStyle}>
              <View style={{ gap: '8px' }}>
                <StyledHeading tag='h6' style={{ color: Colors.neutral900 }}>Waste</StyledHeading>
                <StyledParagraph style={{ color: Colors.neutral700 }}>View a summary of waste logs for a date range.</StyledParagraph>
              </View>
            </Card>
          </Link>

        </View>
      </Cell>
    </StandardGrid>
  );
}