import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { ActionItem, ActionMenu, Checkbox, Choice, CircularSpinner, DatePicker, FormModal, FormModalValueProvider, Link, ModalLauncher, SingleSelect, StandardAlert, StyledHeading, TextArea, TextField, View, generateId, useAlertState } from "@barscience/global-components";
import { UNITS_PER_CASE_MODAL, VOLUME_MEASUREMENTS_MODAL, WEIGHT_MEASUREMENTS_MODAL } from "../../pages/items/AllItems";
import { formatDate } from "../../util/dates";
import CreateInvoiceModal from "../../pages/invoices/CreateInvoiceModal";
import { useNavigate } from "react-router-dom";

/* Create Item */
const CREATE_ITEM = gql`
mutation createInventoryItem($input: CreateInventoryItemInput!) {
  item: createInventoryItem(input: $input) {
    id
    name
    category {
      id
      name
    }
    vendor {
      id
      name
    }
    currentPrice {
      casePrice
      unitPrice
    }
  }
}
`;

type CreateItemMutationResponse = {
  item: Item
}

type Item = {
  id: string;
  name: string;
  category: {
    id: string;
    name: string;
  };
  vendor: {
    id: string;
    name: string;
  };
  currentPrice: {
    casePrice: string;
    unitPrice: string;
  };
}

type CreateItemInput = {
  name: string;
  description: string;
  vendorId: string;
  categoryId: string;
  casePrice: string;
  unitsPerCase: string;
  unitVolumeEnabled: boolean;
  unitVolume: string;
  unitVolumeType: string;
  unitWeightEnabled: boolean;
  unitWeight: string;
  unitWeightType: string;
  unitEmptyWeight: string;
  vendorItemId: string;
  unitDeposit: string;
}

const CreateItemInitialValues: CreateItemInput = {
  name: '',
  description: '',
  vendorId: '',
  categoryId: '',
  casePrice: '',
  unitsPerCase: '',
  unitVolumeEnabled: false,
  unitVolume: '',
  unitVolumeType: '',
  unitWeightEnabled: false,
  unitWeight: '',
  unitWeightType: '',
  unitEmptyWeight: '',
  vendorItemId: '',
  unitDeposit: '',
}

/* Get Categories Query */
const GET_ALL_CATEGORIES = gql`
query getItemCategoriesForItemFilter {
  itemCategories {
    id
    name
  }
}
`;

type GetAllCategoriesResponse = {
  itemCategories: ItemCategory[];
}

type ItemCategory = {
  id: string;
  name: string;
}

/* Get Vendors Query */
const GET_ALL_VENDORS = gql`
query getVendorsForItemFilter($filter: VendorFilter) {
  vendors(filter: $filter) {
    id
    name
  }
}
`;

type GetAllVendorsResponse = {
  vendors: Vendor[];
}

type Vendor = {
  id: string;
  name: string;
}

/* Create Order Mutation */
const CREATE_ORDER_MUTATION = gql`
mutation createInventoryOrder($input: CreateInventoryOrderInput!) {
  order: createInventoryOrder(input: $input) {
    id
    vendor {
      id
      name
    }
    orderDate
    deliveryDate
    itemCount
    caseCount
    estimatedTotalCost
  }
}
`;

type CreateOrderMutationResponse = {
  order: InventoryOrder;
}

type CreateOrderInput = {
  vendorId: string;
  orderDate: string;
  deliveryDate: string;
}

type InventoryOrder = {
  id: string;
  vendor: {
    id: string;
    name: string;
  };
  orderDate: string;
  deliveryDate: string;
  itemCount: number;
  caseCount: number;
  estimatedTotalCost: string;
}

export default function HeaderNewActions() {
  const navigate = useNavigate();
  const { addAlert } = useAlertState();
  const [createItem] = useMutation<CreateItemMutationResponse>(CREATE_ITEM);
  const [fetchCategories, { loading: categoriesAreLoading, data: categoriesData }] = useLazyQuery<GetAllCategoriesResponse>(GET_ALL_CATEGORIES);
  const [fetchVendors, { loading: vendorsAreLoading, data: vendorsData }] = useLazyQuery<GetAllVendorsResponse>(GET_ALL_VENDORS);
  const [createOrder] = useMutation<CreateOrderMutationResponse>(CREATE_ORDER_MUTATION);

  /* Create Order */
  const handleCreateOrder = async (values: CreateOrderInput) => {
    const date = new Date();
    const orderDate = formatDate(date);

    const { data, errors } = await createOrder({
      variables: {
        input: {
          vendorId: values.vendorId,
          orderDate: orderDate,
          deliveryDate: values.deliveryDate,
        },
      },
    });

    if (data?.order) {
      navigate('/orders/' + data.order.id + '/edit');
    }

    if (errors) {
      const alertId = generateId();
      const alert = (
        <StandardAlert title='Error creating order' description={errors[0].message} type='error' id={alertId} />
      );

      addAlert(alertId, alert);
    }
  }

  const createOrderModal = (
    <FormModal<CreateOrderInput> title='Create Order' onSubmit={handleCreateOrder} submitLabel='Create' initialValues={{ vendorId: '', orderDate: '', deliveryDate: '' }}>
      {vendorsAreLoading ?
        <CircularSpinner size='large' style={{ margin: 'auto' }} />
        :
        <View style={{ gap: '16px' }}>
          <SingleSelect label='Vendor' name='vendorId' required>
            {vendorsData?.vendors.map((v, index) => {
              return (
                <Choice label={v.name} value={v.id} key={index} />
              );
            })}
          </SingleSelect>
          <DatePicker label='Delivery Date' name='deliveryDate' required />
        </View>
      }
    </FormModal>
  );

  /* Create Item */
  const handleCreateItem = async (values: CreateItemInput) => {
    let unitDeposit = null;
    if (values.unitDeposit !== '') {
      unitDeposit = values.unitDeposit;
    }

    const { data, errors } = await createItem({
      variables: {
        input: {
          name: values.name,
          description: values.description,
          categoryId: values.categoryId,
          vendorId: values.vendorId,
          casePrice: values.casePrice,
          unitsPerCase: parseFloat(values.unitsPerCase),
          unitVolume: values.unitVolumeEnabled ? parseFloat(values.unitVolume) : null,
          unitVolumeType: values.unitVolumeEnabled ? values.unitVolumeType : null,
          unitWeight: values.unitWeightEnabled ? parseFloat(values.unitWeight) : null,
          unitWeightType: values.unitWeightEnabled ? values.unitWeightType : null,
          unitEmptyWeight: values.unitWeightEnabled ? parseFloat(values.unitEmptyWeight) : null,
          vendorItemId: values.vendorItemId,
          unitDeposit: unitDeposit,
        },
      },
    });

    if (data?.item) {
      const alertId = generateId();
      const alert = (
        <StandardAlert id={alertId} title='Item successfully created' type='success' description={data?.item.name + ' was successfully added as an item.'}>
          <Link href={'/items/' + data?.item.id}>View item</Link>
        </StandardAlert>
      );

      addAlert(alertId, alert);
    }

    if (errors) {
      const alertId = generateId();
      const alert = (
        <StandardAlert title='Error creating item' description={errors[0].message} type='error' id={alertId} />
      );

      addAlert(alertId, alert);
    }
  }

  const createItemModal = (
    <FormModal<CreateItemInput> title='Create Item' onSubmit={handleCreateItem} initialValues={CreateItemInitialValues} submitLabel='Create Item' >
      {(categoriesAreLoading || vendorsAreLoading) ?
        <CircularSpinner size='large' style={{ margin: 'auto' }} />
        :
        <FormModalValueProvider>
          {({ getValue, setError }) => (
            <View style={{ gap: '16px' }}>
              <TextField name='name' label='Item Name' required />
              <TextArea name='description' label='Item Description' disableResizing />
              <SingleSelect name='vendorId' label='Vendor' required>
                {vendorsData?.vendors.map((vendor) => {
                  return (
                    <Choice label={vendor.name} value={vendor.id} key={vendor.id} />
                  );
                })}
              </SingleSelect>
              <SingleSelect name='categoryId' label='Category' required>
                {categoriesData?.itemCategories.map((category) => {
                  return (
                    <Choice label={category.name} value={category.id} key={category.id} />
                  );
                })}
              </SingleSelect>
              <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
                <TextField name='casePrice' label='Case Price' type='currency' required />
                <TextField name='unitsPerCase' label='Units Per Case' type='number' helpModal={UNITS_PER_CASE_MODAL} required />
              </View>
              <View style={{ paddingTop: '32px', gap: '48px' }}>
                <View style={{ gap: '16px' }}>
                  <View style={{ gap: '8px' }}>
                    <StyledHeading tag='h6'>Volume Measurements</StyledHeading>
                  </View>
                  <Checkbox name='unitVolumeEnabled' label='Enable volume measurements' description='Allows this item to be input by unit volume for item counts and recipes' helpModal={VOLUME_MEASUREMENTS_MODAL} validate={(_, value) => {
                    if (!value && setError) {
                      setError('unitVolume', null);
                      setError('unitVolumeType', null);
                    }

                    return null;
                  }} />
                  {(getValue && getValue('unitVolumeEnabled')) && (
                    <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
                      <TextField name='unitVolume' label='Unit Volume' type='number' style={{ flex: '1 1 0px', '@media (max-width: 767px)': { flex: '0' } }} required validate={(_, value) => {
                        const num = parseFloat(value);
                        if (num < 0) {
                          return 'Unit volume must be greater than 0'
                        }
                        return null;
                      }} />
                      <SingleSelect name='unitVolumeType' label='Volume Type' style={{ flex: '1 1 0px', '@media (max-width: 767px)': { flex: '0' } }} required >
                        <Choice label='Fluid Ounce' value='FLUID_OZ' />
                        <Choice label='Cup' value='CUP' />
                        <Choice label='Gallon' value='GALLON' />
                        <Choice label='Liter' value='LITER' />
                        <Choice label='Milliliter' value='MILLILITER' />
                        <Choice label='Pint' value='PINT' />
                        <Choice label='Quart' value='QUART' />
                        <Choice label='Tablespoon' value='TBSP' />
                        <Choice label='Teaspoon' value='TSP' />
                      </SingleSelect>
                    </View>
                  )}
                </View>
                <View style={{ gap: '16px' }}>
                  <View style={{ gap: '8px' }}>
                    <StyledHeading tag='h6'>Weight Measurements</StyledHeading>
                  </View>
                  <Checkbox name='unitWeightEnabled' label='Enable weight measurements' description='Allows this item to be input by unit weight for item counts and recipes' helpModal={WEIGHT_MEASUREMENTS_MODAL} validate={(_, value) => {
                    if (!value && setError) {
                      setError('unitWeight', null);
                      setError('unitWeightType', null);
                    }

                    return null;
                  }} />
                  {(getValue && getValue('unitWeightEnabled')) && (
                    <View style={{ gap: '16px' }}>
                      <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
                        <TextField name='unitWeight' label='Unit Weight' type='number' style={{ flex: '1 1 0px', '@media (max-width: 767px)': { flex: '0' } }} required validate={(_, value) => {
                          const num = parseFloat(value);
                          if (num < 0) {
                            return 'Unit weight must be greater than 0'
                          }
                          return null;
                        }} />
                        <SingleSelect name='unitWeightType' label='Weight Type' style={{ flex: '1 1 0px', '@media (max-width: 767px)': { flex: '0' } }} required >
                          <Choice label='Ounce' value='OUNCE' />
                          <Choice label='Pound' value='POUND' />
                          <Choice label='Gram' value='GRAM' />
                          <Choice label='Kilogram' value='KILOGRAM' />
                        </SingleSelect>
                      </View>
                      <TextField name='unitEmptyWeight' label='Unit Empty Weight' type='number' style={{ maxWidth: 'calc(50% - 8px)', '@media (max-width: 767px)': { maxWidth: '100%' } }} validate={(_, value) => {
                        const num = parseFloat(value);
                        if (num < 0) {
                          return 'Empty weight must be greater than 0'
                        }
                        return null;
                      }} />
                    </View>
                  )}
                </View>
                <View style={{ gap: '16px' }}>
                  <StyledHeading tag='h6'>Other Information</StyledHeading>
                  <TextField label='Vendor Item ID' name='vendorItemId' />
                  <TextField label='Unit Deposit' type='currency' name='unitDeposit' />
                </View>
              </View>
            </View>
          )}
        </FormModalValueProvider>
      }
    </FormModal>
  );

  return (
    <>
      <ModalLauncher modal={createItemModal}>
        {({ openModal: openCreateItemModal }) => (
          <ModalLauncher modal={createOrderModal}>
            {({ openModal: openCreateOrderModal }) => (
              <ModalLauncher modal={<CreateInvoiceModal />}>
                {({ openModal: openCreateInvoiceModal }) => (
                  <ActionMenu label='New' >
                    <ActionItem label='Item' onClick={() => {
                      fetchCategories();
                      fetchVendors();
                      openCreateItemModal();
                    }} />
                    <ActionItem label='Order' onClick={() => {
                      fetchVendors();
                      openCreateOrderModal();
                    }} />
                    <ActionItem label='Invoice' onClick={openCreateInvoiceModal} />
                  </ActionMenu>
                )}
              </ModalLauncher>
            )}
          </ModalLauncher>

        )}
      </ModalLauncher>
    </>
  );
}