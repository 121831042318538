import { ApolloCache, ApolloError, BaseMutationOptions, DefaultContext, OperationVariables } from "@apollo/client";
import { StandardAlert, generateId, useAlertState } from "@barscience/global-components";

export default function useErrorHandler() {
  const { addAlert } = useAlertState();

  const handleGQLError = (error: ApolloError, clientOptions?: BaseMutationOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined): void => {    
    const id = generateId();
    const errorAlert = (
      <StandardAlert title='An error has occurred' type='error' id={id} description={error.message} />
    );
    addAlert(id, errorAlert, 10);
  }
  
  return {
    handleGQLError
  }
}