import { gql, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, ConfirmModal, DatePicker, ErrorPage, FormModal, HasProductRole, InventoryRoles, Link, ModalLauncher, NotFound, PageButtons, Products, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, View } from "@barscience/global-components";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Get Sales Period Query */
const GET_SALES_PERIOD = gql`
query getSalesPeriodDetails($id: ID!) {
  inventorySalesPeriod(id: $id) {
    id
    name
    startDate
    endDate
    salesByCategory {
      category {
        id
        name
      }
      totalRevenue
    }
    salesByItemPageCount
  }
}
`;

type GetSalesPeriodResponse = {
  inventorySalesPeriod: SalesPeriod | null;
}

type SalesPeriod = {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  salesByCategory: SalesByCategory[];
  salesByItemPageCount: number;
}

type SalesByCategory = {
  category: Category;
  totalRevenue: string;
}

type Category = {
  id: string;
  name: string;
}

type SalesByItem = {
  item: Item | Recipe;
  unitsSold: number | null;
  totalRevenue: number;
}

type Item = {
  id: string;
  name: string;
  __typename: 'InventoryItem'
}

type Recipe = {
  id: string;
  name: string;
  recipeCategory: {
    id: string;
  }
  __typename: 'Recipe'
}

/* Get Sales Period Items Query */
const GET_SALES_PERIOD_ITEMS = gql`
query getSalesPeriodItems($id: ID!, $page: Int!) {
  inventorySalesPeriod(id: $id) {
    id
    salesByItem(page: $page) {
      item {
        ... on InventoryItem {
          id
          name
        }
        ... on Recipe {
          id
          name
          recipeCategory {
            id
          }
        }
      }
      unitsSold
      totalRevenue
    }
  }
}
`;

type GetSalesPeriodItemsResponse = {
  inventorySalesPeriod: {
    id: string;
    salesByItem: SalesByItem[];
  } | null;
}

/* Edit Sales Period Mutation */
const EDIT_SALES_PERIOD = gql`
mutation editInventorySalesPeriod($id: ID!, $input: EditInventorySalesPeriodInput!) {
  editInventorySalesPeriod(id: $id, input: $input) {
    id
    name
    startDate
    endDate
  }
}
`;

type EditSalesPeriodResponse = {
  editInventorySalesPeriod: SalesPeriod | null;
}

type EditSalesPeriodInput = {
  name: string;
  startDate: string;
  endDate: string;
}

/* Delete Sales Period Mutation */
const DELETE_SALES_PERIOD = gql`
mutation deleteInventorySalesPeriod($id: ID!) {
  deleteInventorySalesPeriod(id: $id) {
    id
  }
}
`;

type DeleteSalesPeriodResponse = {
  deleteInventorySalesPeriod: {
    id: string;
  } | null;
}

export default function SalesPeriodDetails() {
  const navigate = useNavigate();
  const { periodId } = useParams();
  const [itemPage, setItemPage] = useState<number>(0);
  const { data: salesData, loading: salesAreLoading, error: salesError } = useQuery<GetSalesPeriodResponse>(GET_SALES_PERIOD, {
    variables: {
      id: periodId,
    },
  });
  const { data: salesItemsData, loading: salesItemsAreLoading } = useQuery<GetSalesPeriodItemsResponse>(GET_SALES_PERIOD_ITEMS, {
    variables: {
      id: periodId,
      page: itemPage,
    },
    fetchPolicy: 'network-only',
  });
  const [editSalesPeriod] = useMutation<EditSalesPeriodResponse>(EDIT_SALES_PERIOD);
  const [deleteSalesPeriod] = useMutation<DeleteSalesPeriodResponse>(DELETE_SALES_PERIOD, {
    update(cache) {
      if (!salesData?.inventorySalesPeriod) {
        return;
      }

      cache.evict({
        id: cache.identify(salesData.inventorySalesPeriod),
      });

      navigate('/sales');
    }
  });

  if (salesAreLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (!periodId || salesError?.graphQLErrors[0].extensions.status === 404) {
    return (
      <StandardGrid>
        <NotFound />
      </StandardGrid>
    );
  }

  if (!salesData?.inventorySalesPeriod) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  const handleEditSalesPeriod = async (values: EditSalesPeriodInput) => {
    await editSalesPeriod({
      variables: {
        id: periodId,
        input: {
          name: values.name,
          startDate: values.startDate,
          endDate: values.endDate,
        },
      },
    });
  }

  const handleDeleteSalesPeriod = async () => {
    await deleteSalesPeriod({
      variables: {
        id: periodId,
      },
    });
  }

  const editSalesPeriodModal = (
    <FormModal<EditSalesPeriodInput> title='Edit Sales Period' onSubmit={handleEditSalesPeriod} initialValues={{ name: salesData.inventorySalesPeriod.name, startDate: salesData.inventorySalesPeriod.startDate, endDate: salesData.inventorySalesPeriod.endDate }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Period Name' name='name' required />
        <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 1151px)': { flexDirection: 'column' } }}>
          <DatePicker label='Start Date' name='startDate' required />
          <DatePicker label='End Date' name='endDate' required />
        </View>
      </View>
    </FormModal>
  );

  const deleteSalesPeriodModal = (
    <ConfirmModal title='Delete Period?' onConfirm={handleDeleteSalesPeriod} confirmLabel='Delete' destructive>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>Are you sure you want to delete this sales period? All item sales associated with this period will be permanently deleted.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Sales Periods' to='/sales' />
          <Breadcrumb label={salesData.inventorySalesPeriod.name} />
        </BreadcrumbGroup>
      </Cell>

      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <StyledHeading tag='h3'>{salesData.inventorySalesPeriod.name}</StyledHeading>
          <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
            <ModalLauncher modal={editSalesPeriodModal}>
              {({ openModal: openEditModal }) => (
                <ModalLauncher modal={deleteSalesPeriodModal}>
                  {({ openModal: openDeleteModal }) => (
                    <ActionMenu alignment='right'>
                      <ActionItem label='Edit' onClick={openEditModal} />
                      <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Admin]}>
                        <ActionItem label='Delete' onClick={openDeleteModal} />
                      </HasProductRole>
                    </ActionMenu>
                  )}
                </ModalLauncher>
              )}
            </ModalLauncher>
          </HasProductRole>
        </View>
      </Cell>

      <Cell lg={6} md={8} sm={4}>
        <Card size='medium'>
          <View style={{ flexDirection: 'row', gap: '16px' }}>
            <StyledHeading tag='h5'>Sales By Category</StyledHeading>
            <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
              <Button label='Edit' variant='tertiary' role='link' href={`/sales/${periodId}/edit-categories`} />
            </HasProductRole>
          </View>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Sales Category</TableHeaderCell>
                <TableHeaderCell>Total Revenue</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {salesData.inventorySalesPeriod.salesByCategory.map((category, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell style={{ maxWidth: '400px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{category.category.name}</TableCell>
                    <TableCell>{category.totalRevenue}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Card>
      </Cell>

      <Cell lg={12} md={8} sm={4}>
        <Card size='medium'>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={{ flexDirection: 'row', gap: '16px' }}>
              <StyledHeading tag='h5'>Sales By Item</StyledHeading>
              <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
                <Button label='Edit' variant='tertiary' role='link' href={`/sales/${periodId}/edit-items`} />
              </HasProductRole>
            </View>
            <PageButtons currentPage={itemPage} numPages={salesData.inventorySalesPeriod.salesByItemPageCount} onPageChange={setItemPage} />
          </View>
          {salesItemsAreLoading ?
            <AtomSpinner size='medium' />
            :
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Item</TableHeaderCell>
                  <TableHeaderCell>Units Sold</TableHeaderCell>
                  <TableHeaderCell>Total Revenue</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {salesItemsData?.inventorySalesPeriod?.salesByItem.map((itemSales, index) => {
                  if (itemSales.item.__typename === 'InventoryItem') {
                    return (
                      <TableRow key={index}>
                        <TableCell><Link href={`/items/${itemSales.item.id}`} linkStyle={{ maxWidth: '400px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{itemSales.item.name}</Link></TableCell>
                        <TableCell>{itemSales.unitsSold !== null ? itemSales.unitsSold : '----'}</TableCell>
                        <TableCell>{itemSales.totalRevenue}</TableCell>
                      </TableRow>
                    );
                  } else if (itemSales.item.__typename === 'Recipe') {
                    return (
                      <TableRow key={index}>
                        <TableCell><Link href={`/recipes/${itemSales.item.recipeCategory.id}/${itemSales.item.id}`} linkStyle={{ maxWidth: '400px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{itemSales.item.name}</Link></TableCell>
                        <TableCell>{itemSales.unitsSold !== null ? itemSales.unitsSold : '----'}</TableCell>
                        <TableCell>{itemSales.totalRevenue}</TableCell>
                      </TableRow>
                    );
                  } else {
                    return null;
                  }
                })}
              </TableBody>
            </Table>
          }
        </Card>
      </Cell>
    </StandardGrid>
  );
}