import { gql, Reference, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Button, Card, Cell, ConfirmModal, FormModal, HasProductRole, InventoryRoles, ModalLauncher, Products, StandardGrid, Strut, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, View } from "@barscience/global-components";

/* Object Types */
type ItemCategory = {
  id: string;
  name: string;
  isArchived: boolean;
}

/* Queries and Mutations */
const GET_ALL_CATEGORIES = gql`
query getAllItemCategories {
  itemCategories {
    id
    name
    isArchived
  }
}
`;

type GetAllCategoriesResponse = {
  itemCategories: ItemCategory[];
}

const CREATE_CATEGORY = gql`
mutation createItemCategory($input: CreateItemCategoryInput!) {
  category: createItemCategory(input: $input) {
    id
    name
    isArchived
  }
}
`;

type CreateCategoryMutationResponse = {
  category: ItemCategory;
}

const EDIT_CATEGORY = gql`
mutation editItemCategory($id: ID!, $input: EditItemCategoryInput!) {
  category: editItemCategory(id: $id, input: $input) {
    id
    name
    isArchived
  }
}
`;

type EditCategoryMutationResponse = {
  category: ItemCategory;
}

const ARCHIVE_CATEGORY = gql`
mutation archiveItemCategory($id: ID!) {
  category: archiveItemCategory(id: $id) {
    id
    name
    isArchived
  }
}
`;

type ArchiveCategoryMutationResponse = {
  category: ItemCategory;
}

/* Form Types */
type CreateCategoryFormType = {
  name: string;
}

const createCategoryFormInitialValues: CreateCategoryFormType = {
  name: '',
}

type EditCategoryFormType = {
  id: string;
  name: string;
}

export default function AllItemCategories() {
  const { data, loading, refetch: refetchCategories } = useQuery<GetAllCategoriesResponse>(GET_ALL_CATEGORIES);
  const [createCategory] = useMutation<CreateCategoryMutationResponse>(CREATE_CATEGORY);
  const [editCategory] = useMutation<EditCategoryMutationResponse>(EDIT_CATEGORY);
  const [archiveCategory] = useMutation<ArchiveCategoryMutationResponse>(ARCHIVE_CATEGORY, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          itemCategories(exitingCategories = [], { readField }) {
            return exitingCategories.filter((v: Reference) => {
              return data?.category.id !== readField('id', v);
            });
          },
        },
      });
    },
  });

  const handleCreateCategory = async (values: CreateCategoryFormType) => {
    await createCategory({
      variables: {
        input: {
          name: values.name,
        }
      }
    });

    await refetchCategories();
  }

  const handleEditCategory = async (values: EditCategoryFormType) => {
    await editCategory({
      variables: {
        id: values.id,
        input: {
          name: values.name,
        },
      },
    });
  }

  const handleArchiveCategory = async (id: string) => {
    await archiveCategory({
      variables: {
        id: id,
      },
    });
  }



  const createCategoryModal = (
    <FormModal<CreateCategoryFormType>
      title='Create Item Category'
      onCancel={() => { }}
      onSubmit={handleCreateCategory}
      initialValues={createCategoryFormInitialValues}
      submitLabel='Create'
    >
      <View style={{ gap: '16px' }}>
        <TextField type='text' name='name' label='Category Name' required />
      </View>
    </FormModal>
  );

  const editCategoryModal = (
    <FormModal<EditCategoryFormType>
      title='Edit Category'
      onCancel={() => { }}
      onSubmit={handleEditCategory}
      initialValues={{
        id: '',
        name: '',
      }}
      submitLabel='Save'
    >
      <View style={{ gap: '16px' }}>
        <TextField type='text' name='name' label='Category Name' required />
      </View>
    </FormModal>
  );

  const confirmArchiveModal = (
    <ConfirmModal title='Archive category?' onCancel={() => { }} onConfirm={handleArchiveCategory} confirmLabel='Yes, archive' destructive>
      <StyledParagraph>Are you sure you want to archive this category?</StyledParagraph>
      <Strut size='16px' />
      <StyledParagraph>
        <span style={{ fontWeight: 'bold' }}>IMPORTANT: </span>This will also permanently archive all items associated with this category.
      </StyledParagraph>
    </ConfirmModal>
  );

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', '@media (max-width: 767px)': { flexDirection: 'column', gap: '16px' } }}>
          <View style={{ gap: '8px' }}>
            <StyledHeading tag='h3'>Item Categories</StyledHeading>
            <StyledParagraph>Item categories group inventory items into distinct categories for tracking and reporting purposes.</StyledParagraph>
          </View>
          <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
            <ModalLauncher modal={createCategoryModal}>
              {({ openModal }) => (
                <Button label='Create Category' variant='primary' role='button' action={openModal} />
              )}
            </ModalLauncher>
          </HasProductRole>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {loading && <AtomSpinner size='large' />}
        {!loading && (
          <Card style={{ padding: '16px' }} size='medium'>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Name</TableHeaderCell>
                  <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
                    <TableHeaderCell style={{ margin: '0', padding: '0', width: '8px' }}></TableHeaderCell>
                  </HasProductRole>
                </TableRow>
              </TableHeader>
              <TableBody>
                {data?.itemCategories.map((c) => {
                  return (
                    <TableRow key={c.id}>
                      <TableCell>{c.name}</TableCell>
                      <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
                        <TableCell style={{ padding: '0', margin: '0', width: '8px' }}>
                          <ModalLauncher modal={editCategoryModal}>
                            {({ openModal: openEditCategoryModal }) => (
                              <ModalLauncher modal={confirmArchiveModal}>
                                {({ openModal: openArchiveModal }) => (
                                  <ActionMenu alignment='right'>
                                    <ActionItem label='Edit' onClick={openEditCategoryModal} data={{ id: c.id, name: c.name }} />
                                    <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Admin]}>
                                      <ActionItem label='Archive' onClick={openArchiveModal} data={c.id} />
                                    </HasProductRole>
                                  </ActionMenu>
                                )}
                              </ModalLauncher>
                            )}
                          </ModalLauncher>
                        </TableCell>
                      </HasProductRole>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            {data?.itemCategories.length === 0 && <StyledParagraph>Your organization does not have any item categories.</StyledParagraph>}
          </Card>
        )}
      </Cell>
    </StandardGrid>
  );
}