import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, Breadcrumb, BreadcrumbGroup, Card, Cell, Choice, Colors, Icon, Icons, InventoryRoles, Link, NoPermission, NotFound, OptionBar, OptionItem, SingleSelect, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, View, useAuthState } from "@barscience/global-components";
import { CSSProperties } from "aphrodite";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

/* Get Locations Query */
const GET_LOCATIONS_QUERY = gql`
query getItemLocationsForOrders {
  inventoryItemLocations(forOrders: true) {
    id
    name
    sublocations(forOrders: true) {
      id
      name
    }
  }
}
`;

type GetLocationsQueryResponse = {
  inventoryItemLocations: ItemLocation[];
}

type ItemLocation = {
  id: string;
  name: string;
  sublocations: ItemSublocationSummary[];
}

type ItemSublocationSummary = {
  id: string;
  name: string;
}

/* Get Sublocation Items Query */
const GET_SUBLOCATION_QUERY = gql`
query getSublocationForPrepLists($sublocationId: ID!) {
  inventoryItemSublocation(id: $sublocationId) {
    id
    name
    items(listType: PREP_LISTS) {
      ... on RecipeLocationAssignment {
        recipe {
          id
          name
          par(sublocationId: $sublocationId) {
            casePar
            unitPar
          }
          recipeCategory {
            id
          }
        }
      }
    }
  }
}
`;

type GetSublocationQueryResponse = {
  inventoryItemSublocation: ItemSublocation;
}

type ItemSublocation = {
  id: string;
  name: string;
  items: SublocationItem[];
}

type SublocationItem = {
  recipe: {
    id: string;
    name: string;
    par: {
      casePar: number;
      unitPar: number;
    };
    recipeCategory: {
      id: string;
    };
  };
}

/* Get Prep List Query */
const GET_PREP_LIST = gql`
query getPrepListItems($id: ID!) {
  prepList(id: $id) {
    id
    name
    date
    items {
      recipe {
        id
        name
      }
      casesToMake
    }
  }
}
`;

type GetPrepListResponse = {
  prepList: PrepList | null;
}

type PrepList = {
  id: string;
  name: string;
  date: string;
  items: PrepListItem[];
}

type PrepListItem = {
  recipe: {
    id: string;
    name: string;
  };
  casesToMake: number;
}

/* Set Cases To Make Mutation */
const SET_CASES_TO_MAKE = gql`
mutation setPrepListItemCasesToMake($prepListId: ID!, $recipeId: ID!, $casesToMake: Float!) {
  setPrepListItemCasesToMake(prepListId: $prepListId, recipeId: $recipeId, casesToMake: $casesToMake) {
    recipe {
      id
      name
    }
    casesToMake
  }
}
`;

type SetCasesToMakeResponse = {
  setPrepListItemCasesToMake: PrepListItem | null;
}

const styles: { [name: string]: CSSProperties } = {
  hideOverFlowText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  cardContentContainer: {
    flexDirection: 'row',
    gap: '16px',
    height: '100%',
    '@media (max-width: 767px)': {
      flexDirection: 'column',
      overflowY: 'scroll'
    },
  },
  locationsListContainer: {
    borderRight: `1px solid ${Colors.neutral200}`,
    maxWidth: '350px',
    minWidth: '300px',
    overflowY: 'scroll',
    paddingRight: '16px',
    '@media (min-width: 768px)': {
      height: '100%'
    },
    '@media (max-width: 1151px)': {
      display: 'none',
    },
  },
  sublocationContainer: {
    alignItems: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: '56px',
    padding: '8px 16px'
  },
  leftFixedColumn: {
    backgroundColor: '#ffffff',
    maxWidth: '100px',
    minWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    zIndex: 2,
  },
  rightFixedColumn: {
    backgroundColor: '#ffffff',
    borderLeft: `1px solid ${Colors.neutral200}`,
    minWidth: '180px',
    padding: '16px 0px 16px 16px',
    position: 'sticky',
    right: '168px',
    width: '180px',
    '@media (max-width: 1151px)': {
      position: 'relative',
      right: 0,
    },
  },
  rightOuterFixedColumn: {
    backgroundColor: '#ffffff',
    minWidth: '120px',
    position: 'sticky',
    right: 0,
    width: '120px',
    '@media (max-width: 1151px)': {
      position: 'relative',
    },
  },
}

export default function EditPrepList() {
  const { state } = useAuthState();
  const { prepListId } = useParams();
  const [queryParams, setQueryParams] = useSearchParams();
  const locationId = queryParams.get('locationId');
  const sublocationId = queryParams.get('sublocationId');
  const [recipeBatches, setRecipeBatches] = useState<{ [recipeId: string]: number }>({});
  const [recipeMakeChoice, setRecipeMakeChoice] = useState<{ [recipeId: string]: string }>({});
  const { data: prepListData, loading: prepListIsLoading, error: prepListError } = useQuery<GetPrepListResponse>(GET_PREP_LIST, {
    variables: {
      id: prepListId,
    },
  });
  const { data: locationsData, loading: locationsAreLoading } = useQuery<GetLocationsQueryResponse>(GET_LOCATIONS_QUERY, {
    onCompleted: (data) => {
      if (data.inventoryItemLocations.length > 0 && !queryParams.get('locationId')) {
        setQueryParams({
          locationId: data.inventoryItemLocations[0].id,
        });

        if (data.inventoryItemLocations[0].sublocations.length > 0) {
          setQueryParams({
            locationId: data.inventoryItemLocations[0].id,
            sublocationId: data.inventoryItemLocations[0].sublocations[0].id,
          });
        }
      }
    },
  });
  const [getSublocation, { data: sublocationData, loading: sublocationIsLoading }] = useLazyQuery<GetSublocationQueryResponse>(GET_SUBLOCATION_QUERY);
  const [setCasesToMake] = useMutation<SetCasesToMakeResponse>(SET_CASES_TO_MAKE);

  useEffect(() => {
    if (sublocationId !== null) {
      getSublocation({
        variables: {
          sublocationId: sublocationId,
        },
      });
    }
  }, [sublocationId, getSublocation]);

  useEffect(() => {
    const recipeBatchData: { [recipeId: string]: number } = {};
    const recipeMakeChoiceData: { [recipeId: string]: string } = {};

    prepListData?.prepList?.items.forEach((recipe) => {
      recipeBatchData[recipe.recipe.id] = recipe.casesToMake;

      if (recipe.casesToMake > 0) {
        recipeMakeChoiceData[recipe.recipe.id] = 'MAKE';
      }
    });

    setRecipeBatches(recipeBatchData);
    setRecipeMakeChoice(recipeMakeChoiceData);
  }, [prepListData]);

  const handleLocationClick = (id: string) => {
    if (id === locationId) {
      return;
    }

    setQueryParams({
      locationId: id,
    });
  }

  const handleSublocationClick = (id: string) => {
    if (id === sublocationId || !locationId) {
      return;
    }

    setQueryParams({
      locationId: locationId,
      sublocationId: id,
    });
  }

  const handleBatchesToMakeChange = (recipeId: string, value: string) => {
    let newValue = parseFloat(value);
    if (newValue < 0) {
      return;
    }

    let valueToSet = newValue;
    if (Number.isNaN(newValue)) {
      valueToSet = 0;
    }

    setRecipeBatches({
      ...recipeBatches,
      [recipeId]: valueToSet,
    });

    if (valueToSet === 0) {
      setRecipeMakeChoice({
        ...recipeMakeChoice,
        [recipeId]: 'DONT_MAKE'
      });
    } else {
      setRecipeMakeChoice({
        ...recipeMakeChoice,
        [recipeId]: 'MAKE'
      });
    }
  }

  const updateBatchesToMake = (recipeId: string, value: string) => {
    let newValue = parseFloat(value);
    if (newValue < 0) {
      return;
    }

    let valueToSet = newValue;
    if (Number.isNaN(newValue)) {
      valueToSet = 0;
    }

    setCasesToMake({
      variables: {
        prepListId: prepListId,
        recipeId: recipeId,
        casesToMake: valueToSet,
      },
    })
  }

  if (!(state.user?.roles.Inventory === InventoryRoles.Employee || state.user?.roles.Inventory === InventoryRoles.Manager || state.user?.roles.Inventory === InventoryRoles.Admin)) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <NoPermission />
        </Cell>
      </StandardGrid>
    );
  }

  if (prepListIsLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (!prepListId || prepListError?.graphQLErrors[0].extensions.status === 404) {
    return (
      <StandardGrid>
        <NotFound />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Prep Lists' to='/prep-lists' />
          <Breadcrumb label={prepListData?.prepList?.name || ''} to={'/prep-lists/' + prepListData?.prepList?.id} />
          <Breadcrumb label='Edit' />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
          <View style={{ gap: '8px' }}>
            <StyledHeading tag='h3'>Edit Prep List</StyledHeading>
            <View style={{ flexDirection: 'row', gap: '8px' }}>
              <StyledParagraph>{prepListData?.prepList?.name}</StyledParagraph>
              <StyledParagraph>|</StyledParagraph>
              <StyledParagraph>{prepListData?.prepList?.date}</StyledParagraph>
            </View>
          </View>
        </View>
      </Cell>
      <Cell lg={0} md={8} sm={4}>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '16px' }}>
          <SingleSelect label='Location' name='locationId' placeholder='Choose a location' value={locationId || ''} onChange={(_, value) => { handleLocationClick(value || ''); }} style={{ maxWidth: '300px', minWidth: '250px', width: 'calc(50% - 16px)' }}>
            {locationsData?.inventoryItemLocations.map((l, index) => {
              return (
                <Choice label={l.name} value={l.id} key={index} />
              );
            })}
          </SingleSelect>
          {locationId &&
            <SingleSelect label='Sublocation' name='sublocationId' placeholder='Choose a sublocation' value={sublocationId || ''} onChange={(_, value) => { handleSublocationClick(value || ''); }} style={{ maxWidth: '300px', minWidth: '250px', width: 'calc(50% - 16px)' }}>
              {(() => {
                if (!locationsData) {
                  return null;
                }

                const openLocation = locationsData.inventoryItemLocations.find((loc) => loc.id === locationId);
                if (!openLocation) {
                  return null;
                }

                if (openLocation.sublocations.length === 0) {
                  return (
                    <View style={{ maxWidth: '300px', minWidth: '250px', padding: '16px' }}>
                      <StyledParagraph>There are no sublocations in this location.</StyledParagraph>
                    </View>
                  );
                } else {
                  return (
                    <>
                      {openLocation.sublocations.map((s, index) => {
                        return (
                          <Choice label={s.name} value={s.id} key={index} />
                        );
                      })}
                    </>
                  );
                }
              })()}
            </SingleSelect>
          }
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {(locationsAreLoading) ?
          <AtomSpinner size='large' />
          :
          (
            <Card size='medium' style={{ height: 'calc(100vh - 272px)', minHeight: '300px' }}>
              {(locationsData?.inventoryItemLocations.length === 0) ?
                <StyledHeading tag='h6'>No item locations exist.</StyledHeading>
                :
                <View style={styles.cardContentContainer}>
                  <View style={styles.locationsListContainer}>
                    {locationsData?.inventoryItemLocations.map((l, index) => {
                      return (
                        <View key={index} style={{ minHeight: 'fit-content' }}>
                          <View style={{ alignItems: 'center', borderBottom: `1px solid ${Colors.neutral200}`, flexDirection: 'row', justifyContent: 'space-between', minHeight: '72px', padding: '16px 16px 16px 0px' }}>
                            <View style={{ alignItems: 'center', cursor: 'pointer', flexDirection: 'row', gap: '16px' }} onClick={() => { handleLocationClick(l.id); }}>
                              {locationId === l.id &&
                                <Icon size='small' icon={Icons.ChevronUp} />
                              }
                              {locationId !== l.id &&
                                <Icon size='small' icon={Icons.ChevronDown} />
                              }
                              <StyledHeading tag='h5' style={{ maxWidth: '200px', ...styles.hideOverFlowText }}>{l.name}</StyledHeading>
                            </View>
                          </View>
                          {locationId === l.id &&
                            <View style={{ padding: '8px 0' }}>
                              {l.sublocations.map((s, index) => {
                                return (
                                  <View key={index} style={{ ...styles.sublocationContainer, ...(s.id === sublocationId ? { backgroundColor: Colors.primary50 } : {}) }} onClick={() => { handleSublocationClick(s.id); }}>
                                    <StyledHeading tag='h6' style={{ maxWidth: '200px', ...styles.hideOverflowText }}>{s.name}</StyledHeading>
                                  </View>
                                );
                              })}
                              {l.sublocations.length === 0 &&
                                <StyledParagraph style={{ margin: '16px 0' }}>There are no sublocations in this location.</StyledParagraph>
                              }
                            </View>
                          }
                        </View>
                      );
                    })}
                  </View>
                  <View style={{ height: '100%', overflow: 'hidden', width: '100%' }}>
                    {sublocationId === null ?
                      <View style={{ alignContent: 'center', height: '100%', justifyContent: 'center', textAlign: 'center', width: '100%' }} >
                        <StyledHeading tag='h6' style={{ color: Colors.neutral700 }}>Select a sublocation to view recipes.</StyledHeading>
                      </View>
                      :
                      (sublocationIsLoading ?
                        <View style={{ alignContent: 'center', height: '100%', justifyContent: 'center', textAlign: 'center', width: '100%' }} >
                          <AtomSpinner size='medium' />
                        </View>
                        :
                        <View style={{ height: '100%', overflowX: 'auto' }}>
                          <Table>
                            <TableHeader>
                              <TableRow>
                                <TableHeaderCell style={styles.leftFixedColumn}>Recipe Name</TableHeaderCell>
                                <TableHeaderCell style={{ width: '65px' }}>Case Par</TableHeaderCell>
                                <TableHeaderCell style={{ width: '65px' }}>Unit Par</TableHeaderCell>
                                <TableHeaderCell style={styles.rightFixedColumn}></TableHeaderCell>
                                <TableHeaderCell style={styles.rightOuterFixedColumn}>Batches To Make</TableHeaderCell>
                              </TableRow>
                            </TableHeader>
                            <TableBody>
                              {sublocationData?.inventoryItemSublocation.items.map((recipe, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell style={styles.leftFixedColumn}><Link href={`/recipes/${recipe.recipe.recipeCategory.id}/${recipe.recipe.id}`}>{recipe.recipe.name}</Link></TableCell>
                                    <TableCell>{recipe.recipe.par.casePar}</TableCell>
                                    <TableCell>{recipe.recipe.par.unitPar}</TableCell>
                                    <TableCell style={styles.rightFixedColumn}>
                                      <OptionBar selectedValue={recipeMakeChoice[recipe.recipe.id] || ''} onChange={(value) => {
                                        const valueToSet = value === 'MAKE' ? '1' : '0';
                                        handleBatchesToMakeChange(recipe.recipe.id, valueToSet);
                                        updateBatchesToMake(recipe.recipe.id, valueToSet);
                                        setRecipeMakeChoice({
                                          ...recipeMakeChoice,
                                          [recipe.recipe.id]: value,
                                        });
                                      }}>
                                        <OptionItem label='Make' value='MAKE' />
                                        <OptionItem label={'Don\'t Make'} value='DONT_MAKE' />
                                      </OptionBar>
                                    </TableCell>
                                    <TableCell style={styles.rightOuterFixedColumn}>
                                      <TextField name={recipe.recipe.id} type='number' inputMode='decimal' value={recipeBatches[recipe.recipe.id]?.toString() || ''} onChange={handleBatchesToMakeChange} onBlur={updateBatchesToMake} />
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                          {sublocationData?.inventoryItemSublocation.items.length === 0 &&
                            <View style={{ alignContent: 'center', height: '100%', justifyContent: 'center', textAlign: 'center', width: '100%' }} >
                              <StyledHeading tag='h6' style={{ color: Colors.neutral700 }}>There are no recipes in this sublocation.</StyledHeading>
                            </View>
                          }
                        </View>
                      )
                    }
                  </View>
                </View>
              }
            </Card>
          )
        }
      </Cell>
    </StandardGrid>
  );
}