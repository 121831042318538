import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, Breadcrumb, BreadcrumbGroup, Card, Cell, Choice, CircularSpinner, Colors, Icon, Icons, InventoryRoles, Link, NotFound, Products, SingleSelect, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, View, generateId, useAlertState, useAuthState } from "@barscience/global-components";
import { CSSProperties } from "aphrodite";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

/* Get Locations Query */
const GET_LOCATIONS_QUERY = gql`
query getItemLocationsForParLists {
  inventoryItemLocations {
    id
    name
    sublocations {
      id
      name
    }
  }
}
`;

type GetLocationsQueryResponse = {
  inventoryItemLocations: ItemLocation[];
}

type ItemLocation = {
  id: string;
  name: string;
  sublocations: ItemSublocationSummary[];
}

type ItemSublocationSummary = {
  id: string;
  name: string;
}

/* Get Sublocation Items Query */
const GET_SUBLOCATION_QUERY = gql`
query getInventoryItemSublocationWithItemPars(
  $sublocationId: ID!
  $parListId: ID
) {
  inventoryItemSublocation(id: $sublocationId) {
    id
    name
    items(listType: PARS) {
      ... on InventoryItemLocationAssignment {
        item {
          id
          name
          unitsPerCase
          vendor {
            id
            name
          }
          category {
            name
          }
          par(sublocationId: $sublocationId, parListId: $parListId) {
            casePar
            unitPar
          }
        }
      }
      ... on RecipeLocationAssignment {
        recipe {
          id
          name
          unitsPerBatch
          recipeCategory {
            id
            name
          }
          itemCategory {
            name
          }
          par(sublocationId: $sublocationId, parListId: $parListId) {
            casePar
            unitPar
          }
        }
      }
    }
  }
}
`;

type GetSublocationQueryResponse = {
  inventoryItemSublocation: ItemSublocation;
}

type ItemSublocation = {
  id: string;
  name: string;
  items: LocationAssignment[];
}

type LocationAssignment = ItemLocationAssignment | RecipeLocationAssignment;

type ItemLocationAssignment = {
  item: Item;
  __typename: 'InventoryItemLocationAssignment';
}

type RecipeLocationAssignment = {
  recipe: Recipe;
  __typename: 'RecipeLocationAssignment';
}

type Item = {
  id: string;
  name: string;
  unitsPerCase: number;
  category: {
    name: string;
  }
  vendor: {
    id: string;
    name: string;
  }
  par: {
    casePar: number;
    unitPar: number;
  }
}

type Recipe = {
  id: string;
  name: string;
  unitsPerBatch: number;
  recipeCategory: {
    id: string;
    name: string;
  };
  itemCategory: {
    name: string;
  };
  par: {
    casePar: number;
    unitPar: number;
  };
}

/* Get Par List Query */
const GET_PAR_LIST = gql`
query getItemParList($itemParListId: ID!) {
  itemParList(id: $itemParListId) {
    id
    name
    description
    isDefault
  }
}
`;

type GetParListQueryResponse = {
  itemParList: ItemParList;
}

type ItemParList = {
  id: string;
  name: string;
  description: string | null;
  isDefault: boolean;
}

type ItemPar = {
  casePar: number;
  unitPar: number;
}

/* Set Item Par Mutation */
const SET_ITEM_PAR = gql`
mutation setItemPar($itemId: ID!, $sublocationId: ID!, $parListId: ID!, $casePar: Float, $unitPar: Float) {
  setItemPar(itemId: $itemId, sublocationId: $sublocationId, parListId: $parListId, casePar: $casePar, unitPar: $unitPar) {
    id
    par(sublocationId: $sublocationId) {
      casePar
      unitPar
    }
  }
}
`;

type SetItemParMutationResponse = {
  setItemPar: {
    id: string;
    par: {
      casePar: number;
      unitPar: number;
    };
  };
}

/* Set Recipe Par Mutation */
const SET_RECIPE_PAR = gql`
mutation setRecipePar($recipeId: ID!, $sublocationId: ID!, $parListId: ID!, $casePar: Float, $unitPar: Float) {
  setRecipePar(recipeId: $recipeId, sublocationId: $sublocationId, parListId: $parListId, casePar: $casePar, unitPar: $unitPar) {
    id
    par(sublocationId: $sublocationId) {
      casePar
      unitPar
    }
  }
}
`;

type SetRecipeParResponse = {
  setRecipePar: {
    id: string;
    par: {
      casePar: number;
      unitPar: number;
    };
  } | null;
}

/* Styles */
const styles: { [name: string]: CSSProperties } = {
  hideOverFlowText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  cardContentContainer: {
    flexDirection: 'row',
    gap: '16px',
    height: '100%',
    '@media (max-width: 767px)': {
      flexDirection: 'column',
      overflowY: 'scroll'
    },
  },
  locationsListContainer: {
    borderRight: `1px solid ${Colors.neutral200}`,
    maxWidth: '450px',
    minWidth: '400px',
    overflowY: 'scroll',
    paddingRight: '16px',
    '@media (min-width: 768px)': {
      height: '100%'
    },
    '@media (min-width: 768px) and (max-width: 1151px)': {
      maxWidth: '300px',
      minWidth: '300px'
    },
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  sublocationContainer: {
    alignItems: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: '56px',
    padding: '8px 16px'
  },
}

export default function ParDetail() {
  const { state } = useAuthState();
  const { addAlert } = useAlertState();
  const { parListId } = useParams();
  const [queryParams, setQueryParams] = useSearchParams();
  const locationId = queryParams.get('locationId');
  const sublocationId = queryParams.get('sublocationId');
  const [pars, setPars] = useState<{ [itemId: string]: ItemPar }>({});
  const { data: locationsData, loading: locationsAreLoading } = useQuery<GetLocationsQueryResponse>(GET_LOCATIONS_QUERY, {
    onCompleted: (data) => {
      if (data.inventoryItemLocations.length > 0 && !queryParams.get('locationId')) {
        setQueryParams({
          locationId: data.inventoryItemLocations[0].id,
        });

        if (data.inventoryItemLocations[0].sublocations.length > 0) {
          setQueryParams({
            locationId: data.inventoryItemLocations[0].id,
            sublocationId: data.inventoryItemLocations[0].sublocations[0].id,
          });
        }
      }
    },
  });
  const { data: parListData, loading: parListIsLoading, error: parListError } = useQuery<GetParListQueryResponse>(GET_PAR_LIST, {
    variables: {
      itemParListId: parListId,
    },
  });
  const [getSublocation, { data: sublocationData, loading: sublocationIsLoading }] = useLazyQuery<GetSublocationQueryResponse>(GET_SUBLOCATION_QUERY);
  const [setItemPar, { loading: setItemParIsLoading }] = useMutation<SetItemParMutationResponse>(SET_ITEM_PAR);
  const [setRecipePar, { loading: setRecipeParIsLoading }] = useMutation<SetRecipeParResponse>(SET_RECIPE_PAR);

  useEffect(() => {
    if (sublocationId !== null) {
      getSublocation({
        variables: {
          sublocationId: sublocationId,
          parListId: parListId,
        },
      });
    }
  }, [sublocationId, getSublocation, parListId]);

  useEffect(() => {
    const itemPars: { [itemId: string]: ItemPar } = {};

    sublocationData?.inventoryItemSublocation.items.forEach((item) => {
      if (item.__typename === 'InventoryItemLocationAssignment') {
        itemPars[item.item.id] = {
          casePar: item.item.par.casePar,
          unitPar: item.item.par.unitPar,
        }
      } else if (item.__typename === 'RecipeLocationAssignment') {
        itemPars[item.recipe.id] = {
          casePar: item.recipe.par.casePar,
          unitPar: item.recipe.par.unitPar,
        }
      }
    });

    setPars(itemPars);
  }, [sublocationData]);

  const handleLocationClick = (id: string) => {
    if (id === locationId) {
      return;
    }

    setQueryParams({
      locationId: id,
    });
  }

  const handleSublocationClick = (id: string) => {
    if (id === sublocationId || !locationId) {
      return;
    }

    setQueryParams({
      locationId: locationId,
      sublocationId: id,
    });
  }

  const handleCaseParChangeForItem = (name: string, value: string) => {
    const newPars = {
      ...pars
    }

    newPars[name].casePar = parseFloat(value);

    setPars(newPars);
  }

  const updateCaseParForItem = (name: string, value: string) => {
    setItemPar({
      variables: {
        itemId: name,
        sublocationId: sublocationId,
        parListId: parListId,
        casePar: parseFloat(value),
        unitPar: pars[name].unitPar,
      },
    }).then(({ errors }) => {
      if (errors && errors.length > 0) {
        const id = generateId();
        const alert = (
          <StandardAlert title='Error saving item par' description={errors[0].message} type='error' id={id} />
        );

        addAlert(id, alert);
      }
    });
  }

  const handleUnitParChangeForItem = (name: string, value: string) => {
    const newPars = {
      ...pars
    }

    newPars[name].unitPar = parseFloat(value);

    setPars(newPars);
  }

  const updateUnitParForItem = (name: string, value: string) => {
    setItemPar({
      variables: {
        itemId: name,
        sublocationId: sublocationId,
        parListId: parListId,
        casePar: pars[name].casePar,
        unitPar: parseFloat(value),
      },
    }).then(({ errors }) => {
      if (errors && errors.length > 0) {
        const id = generateId();
        const alert = (
          <StandardAlert title='Error saving item par' description={errors[0].message} type='error' id={id} />
        );

        addAlert(id, alert);
      }
    });
  }

  const handleCaseParChangeForRecipe = (name: string, value: string) => {
    const newPars = {
      ...pars
    }

    newPars[name].casePar = parseFloat(value);

    setPars(newPars);
  }

  const updateCaseParForRecipe = (name: string, value: string) => {
    setRecipePar({
      variables: {
        recipeId: name,
        sublocationId: sublocationId,
        parListId: parListId,
        casePar: parseFloat(value),
        unitPar: pars[name].unitPar,
      },
    }).then(({ errors }) => {
      if (errors && errors.length > 0) {
        const id = generateId();
        const alert = (
          <StandardAlert title='Error saving item par' description={errors[0].message} type='error' id={id} />
        );

        addAlert(id, alert);
      }
    });
  }

  const handleUnitParChangeForRecipe = (name: string, value: string) => {
    const newPars = {
      ...pars
    }

    newPars[name].unitPar = parseFloat(value);

    setPars(newPars);
  }

  const updateUnitParForRecipe = (name: string, value: string) => {
    setRecipePar({
      variables: {
        recipeId: name,
        sublocationId: sublocationId,
        parListId: parListId,
        casePar: pars[name].casePar,
        unitPar: parseFloat(value),
      },
    }).then(({ errors }) => {
      if (errors && errors.length > 0) {
        const id = generateId();
        const alert = (
          <StandardAlert title='Error saving item par' description={errors[0].message} type='error' id={id} />
        );

        addAlert(id, alert);
      }
    });
  }

  if (!parListId || parListError?.graphQLErrors[0].extensions.status === 404) {
    return (
      <StandardGrid>
        <NotFound />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ gap: '16px' }}>
          <BreadcrumbGroup>
            <Breadcrumb label='Item Pars' to='/par-lists' />
            <Breadcrumb label={parListData?.itemParList.name || 'Par List'} />
          </BreadcrumbGroup>
          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '32px', '@media (max-width: 1151px)': { alignItems: 'flex-start', flexDirection: 'column' } }}>
            <View style={{ gap: '8px' }}>
              <StyledHeading tag='h3'>{parListData?.itemParList.name}</StyledHeading>
              {parListData?.itemParList.description && <StyledParagraph>{parListData.itemParList.description}</StyledParagraph>}
            </View>
            {(setItemParIsLoading || setRecipeParIsLoading) ?
              <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                <CircularSpinner size='xsmall' />
                <StyledParagraph style={{ color: Colors.neutral700 }}>Saving changes...</StyledParagraph>
              </View>
              :
              <View>
                <StyledParagraph style={{ color: Colors.neutral700 }}>All changes saved</StyledParagraph>
              </View>
            }
          </View>
        </View>
      </Cell>
      <Cell lg={0} md={0} sm={4}>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '16px' }}>
          <SingleSelect label='Location' name='locationId' placeholder='Choose a location' value={locationId || ''} onChange={(_, value) => { handleLocationClick(value || ''); }} style={{ maxWidth: '300px', minWidth: '250px', width: 'calc(50% - 16px)' }}>
            {locationsData?.inventoryItemLocations.map((l, index) => {
              return (
                <Choice label={l.name} value={l.id} key={index} />
              );
            })}
          </SingleSelect>
          {locationId &&
            <SingleSelect label='Sublocation' name='sublocationId' placeholder='Choose a sublocation' value={sublocationId || ''} onChange={(_, value) => { handleSublocationClick(value || ''); }} style={{ maxWidth: '300px', minWidth: '250px', width: 'calc(50% - 16px)' }}>
              {(() => {
                if (!locationsData) {
                  return null;
                }

                const openLocation = locationsData.inventoryItemLocations.find((loc) => loc.id === locationId);
                if (!openLocation) {
                  return null;
                }

                if (openLocation.sublocations.length === 0) {
                  return (
                    <View style={{ maxWidth: '300px', minWidth: '250px', padding: '16px' }}>
                      <StyledParagraph>There are no sublocations in this location.</StyledParagraph>
                    </View>
                  );
                } else {
                  return (
                    <>
                      {openLocation.sublocations.map((s, index) => {
                        return (
                          <Choice label={s.name} value={s.id} key={index} />
                        );
                      })}
                    </>
                  );
                }
              })()}
            </SingleSelect>
          }
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {(locationsAreLoading || parListIsLoading) ?
          <AtomSpinner size='large' />
          :
          (
            <Card size='medium' style={{ height: 'calc(100vh - 232px)' }}>
              {(locationsData?.inventoryItemLocations.length === 0) ?
                <StyledHeading tag='h6'>No item locations exist. <Link href='/item-locations'>Create one here!</Link></StyledHeading>
                :
                <View style={styles.cardContentContainer}>
                  <View style={styles.locationsListContainer}>
                    {locationsData?.inventoryItemLocations.map((l, index) => {
                      return (
                        <View key={index} style={{ minHeight: 'fit-content' }}>
                          <View style={{ alignItems: 'center', borderBottom: `1px solid ${Colors.neutral200}`, flexDirection: 'row', justifyContent: 'space-between', minHeight: '72px', padding: '16px 16px 16px 0px' }}>
                            <View style={{ alignItems: 'center', cursor: 'pointer', flexDirection: 'row', gap: '16px' }} onClick={() => { handleLocationClick(l.id); }}>
                              {locationId === l.id &&
                                <Icon size='small' icon={Icons.ChevronUp} />
                              }
                              {locationId !== l.id &&
                                <Icon size='small' icon={Icons.ChevronDown} />
                              }
                              <StyledHeading tag='h5' style={{ maxWidth: '200px', ...styles.hideOverFlowText }}>{l.name}</StyledHeading>
                            </View>
                          </View>
                          {locationId === l.id &&
                            <View style={{ padding: '8px 0' }}>
                              {l.sublocations.map((s, index) => {
                                return (
                                  <View key={index} style={{ ...styles.sublocationContainer, ...(s.id === sublocationId ? { backgroundColor: Colors.primary50 } : {}) }} onClick={() => { handleSublocationClick(s.id); }}>
                                    <StyledHeading tag='h6' style={{ maxWidth: '200px', ...styles.hideOverflowText }}>{s.name}</StyledHeading>
                                  </View>
                                );
                              })}
                              {l.sublocations.length === 0 &&
                                <StyledParagraph style={{ margin: '16px 0' }}>There are no sublocations in this location.</StyledParagraph>
                              }
                            </View>
                          }
                        </View>
                      );
                    })}
                  </View>
                  <View style={{ height: '100%', width: '100%' }}>
                    {sublocationId === null ?
                      <View style={{ alignContent: 'center', height: '100%', justifyContent: 'center', textAlign: 'center', width: '100%' }} >
                        <StyledHeading tag='h6' style={{ color: Colors.neutral700 }}>Select a sublocation to view items pars.</StyledHeading>
                      </View>
                      :
                      (sublocationIsLoading ?
                        <View style={{ alignContent: 'center', height: '100%', justifyContent: 'center', textAlign: 'center', width: '100%' }} >
                          <AtomSpinner size='medium' />
                        </View>
                        :
                        <View style={{ height: '100%', overflowY: 'scroll', overflowX: 'auto' }}>
                          <Table style={{ tableLayout: 'fixed' }}>
                            <TableHeader>
                              <TableRow>
                                <TableHeaderCell style={{ width: '250px' }}>Item Name</TableHeaderCell>
                                <TableHeaderCell style={{ width: '100px' }}>Units Per Case</TableHeaderCell>
                                <TableHeaderCell style={{ width: '88px' }}>Case Par</TableHeaderCell>
                                <TableHeaderCell style={{ width: '88px' }}>Unit Par</TableHeaderCell>
                              </TableRow>
                            </TableHeader>
                            <TableBody>
                              {sublocationData?.inventoryItemSublocation.items.map((item, index) => {
                                if (item.__typename === 'InventoryItemLocationAssignment') {
                                  return (
                                    <TableRow key={index} style={{ height: '72px' }}>
                                      <TableCell><Link linkStyle={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '250px' }} href={'/items/' + item.item.id}>{item.item.name}</Link></TableCell>
                                      <TableCell>{item.item.unitsPerCase}</TableCell>
                                      <TableCell style={{ width: '88px' }}>
                                        {(state.user?.roles[Products.Inventory] === InventoryRoles.Manager || state.user?.roles[Products.Inventory] === InventoryRoles.Admin) ?
                                          <TextField name={item.item.id} type='number' inputMode='decimal' value={pars[item.item.id]?.casePar.toString() || '0.0'} style={{ marginTop: '-4px', width: '88px' }} onChange={handleCaseParChangeForItem} onBlur={updateCaseParForItem} />
                                          :
                                          item.item.par.casePar
                                        }
                                      </TableCell>
                                      <TableCell style={{ width: '88px' }}>
                                        {(state.user?.roles[Products.Inventory] === InventoryRoles.Manager || state.user?.roles[Products.Inventory] === InventoryRoles.Admin) ?
                                          <TextField name={item.item.id} type='number' inputMode='decimal' value={pars[item.item.id]?.unitPar.toString() || '0.0'} style={{ marginTop: '-4px', width: '88px' }} onChange={handleUnitParChangeForItem} onBlur={updateUnitParForItem} />
                                          :
                                          item.item.par.unitPar
                                        }
                                      </TableCell>
                                    </TableRow>
                                  );
                                } else if (item.__typename === 'RecipeLocationAssignment') {
                                  return (
                                    <TableRow key={index} style={{ height: '72px' }}>
                                      <TableCell><Link linkStyle={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '250px' }} href={`/recipes/${item.recipe.recipeCategory.id}/${item.recipe.id}`}>{item.recipe.name}</Link></TableCell>
                                      <TableCell>{item.recipe.unitsPerBatch}</TableCell>
                                      <TableCell style={{ width: '88px' }}>
                                        {(state.user?.roles[Products.Inventory] === InventoryRoles.Manager || state.user?.roles[Products.Inventory] === InventoryRoles.Admin) ?
                                          <TextField name={item.recipe.id} type='number' inputMode='decimal' value={pars[item.recipe.id]?.casePar.toString() || '0.0'} style={{ marginTop: '-4px', width: '88px' }} onChange={handleCaseParChangeForRecipe} onBlur={updateCaseParForRecipe} />
                                          :
                                          item.recipe.par.casePar
                                        }
                                      </TableCell>
                                      <TableCell style={{ width: '88px' }}>
                                        {(state.user?.roles[Products.Inventory] === InventoryRoles.Manager || state.user?.roles[Products.Inventory] === InventoryRoles.Admin) ?
                                          <TextField name={item.recipe.id} type='number' inputMode='decimal' value={pars[item.recipe.id]?.unitPar.toString() || '0.0'} style={{ marginTop: '-4px', width: '88px' }} onChange={handleUnitParChangeForRecipe} onBlur={updateUnitParForRecipe} />
                                          :
                                          item.recipe.par.unitPar
                                        }
                                      </TableCell>
                                    </TableRow>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                            </TableBody>
                          </Table>
                          {sublocationData?.inventoryItemSublocation.items.length === 0 &&
                            <View style={{ alignContent: 'center', height: '100%', justifyContent: 'center', textAlign: 'center', width: '100%' }} >
                              <StyledHeading tag='h6' style={{ color: Colors.neutral700 }}>There are no items in this sublocation.</StyledHeading>
                            </View>
                          }
                        </View>
                      )
                    }
                  </View>
                </View>
              }
            </Card>
          )
        }
      </Cell>
    </StandardGrid>
  );
}