import { gql, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Colors, FormModal, HasProductRole, InventoryRoles, Link, ModalLauncher, NotFound, PageButtons, PhoneInputField, Products, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, View } from "@barscience/global-components";
import currency from "currency.js";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { formatDecimalAmount } from "../../util/formatters";

const GET_VENDOR = gql`
query getVendor($vendorId: ID!) {
  vendor(id: $vendorId) {
    id
    name
    representativeName
    representativeEmail
    representativePhone
    orderWebsite
    accountNumber
    isArchived
  }
}
`;

type GetVendorResponse = {
  vendor: Vendor;
}

type Vendor = {
  id: string;
  name: string;
  representativeName: string | null;
  representativeEmail: string | null;
  representativePhone: string | null;
  orderWebsite: string | null;
  accountNumber: string | null;
  isArchived: boolean;
}

type EditVendorResponse = {
  vendor: Vendor;
}

type EditVendorFormType = {
  name: string;
  accountNumber: string;
  orderWebsite: string;
  repName: string;
  repEmail: string;
  repPhone: string;
}

const EDIT_VENDOR = gql`
mutation editVendor($id: ID!, $input: EditVendorInput!) {
  editVendor(id: $id, input: $input) {
    id
    name
    representativeName
    representativeEmail
    representativePhone
    orderWebsite
    accountNumber
    isArchived
  }
}
`;

/* Get Orders Query */
const GET_ORDERS_QUERY = gql`
query getOrdersForVendor($page: Int!, $vendors: [ID!]) {
  inventoryOrders(page: $page, vendors: $vendors) {
    orders {
      id
      vendor {
        id
        name
      }
      orderDate
      deliveryDate
      itemCount
      caseCount
      estimatedTotalCost
    }
    pages
  }
}
`;

type GetOrdersQueryResponse = {
  inventoryOrders: {
    orders: InventoryOrder[];
    pages: number;
  };
}

type InventoryOrder = {
  id: string;
  vendor: {
    id: string;
    name: string;
  };
  orderDate: string;
  deliveryDate: string;
  itemCount: number;
  caseCount: number;
  estimatedTotalCost: string;
}

/* Get Invoices Query */
const GET_INVOICES = gql`
query getInvoicesForVendor($page: Int!, $vendors: [ID!]) {
  inventoryInvoices(page: $page, vendors: $vendors) {
    invoices {
      id
      deliveryDate
      itemCount
      caseCount
      subtotal
      totalDeposits
      paidDate
    }
    pages
  }
}
`;

type GetInvoicesResponse = {
  inventoryInvoices: {
    invoices: Invoice[];
    pages: number;
  };
}

type Invoice = {
  id: string;
  deliveryDate: string;
  itemCount: number;
  caseCount: number;
  subtotal: string;
  totalDeposits: string;
  paidDate: number;
}

const GET_UNPAID_INVOICES = gql`
query getVendorUnpaidInvoices($vendorId: ID!) {
  vendor(id: $vendorId) {
    totalUnpaidInvoices
  }
}
`;

type GetUnpaidInvoicesResponse = {
  vendor: {
    totalUnpaidInvoices: string;
  };
}

export default function VendorDetail() {
  const { vendorId } = useParams();
  const [ordersPage, setOrdersPage] = useState<number>(0);
  const [invoicesPage, setInvoicesPage] = useState<number>(0);
  const { data: vendorData, loading: vendorIsLoading, error: vendorError } = useQuery<GetVendorResponse>(GET_VENDOR, {
    variables: {
      vendorId: vendorId,
    },
  });
  const { data: ordersData, loading: ordersAreLoading } = useQuery<GetOrdersQueryResponse>(GET_ORDERS_QUERY, {
    variables: {
      page: ordersPage,
      vendors: [vendorId],
    },
  });
  const { data: invoicesData, loading: invoicesAreLoading } = useQuery<GetInvoicesResponse>(GET_INVOICES, {
    variables: {
      page: invoicesPage,
      vendors: [vendorId],
    },
  });
  const { data: unpaidInvoicesData, loading: unpaidInvoicesAreLoading } = useQuery<GetUnpaidInvoicesResponse>(GET_UNPAID_INVOICES, {
    variables: {
      vendorId: vendorId,
    },
  });
  const [editVendor] = useMutation<EditVendorResponse>(EDIT_VENDOR, {
    refetchQueries: [GET_VENDOR],
  });

  const handleEditVendor = async (values: EditVendorFormType) => {
    await editVendor({
      variables: {
        id: vendorId,
        input: {
          name: values.name,
          representativeName: values.repName,
          representativeEmail: values.repEmail,
          representativePhone: values.repPhone,
          orderWebsite: values.orderWebsite,
          accountNumber: values.accountNumber,
        }
      }
    });
  }

  const editVendorFormInitialValues: EditVendorFormType = {
    name: vendorData?.vendor.name || '',
    accountNumber: vendorData?.vendor.accountNumber || '',
    orderWebsite: vendorData?.vendor.orderWebsite || '',
    repName: vendorData?.vendor.representativeName || '',
    repEmail: vendorData?.vendor.representativeEmail || '',
    repPhone: vendorData?.vendor.representativePhone || '',
  }

  const editVendorModal = (
    <FormModal<EditVendorFormType>
      title='Edit Vendor'
      onCancel={() => { }}
      onSubmit={handleEditVendor}
      initialValues={editVendorFormInitialValues}
    >
      <View style={{ gap: '16px' }}>
        <TextField type='text' name='name' label='Vendor Name' required />
        <TextField type='text' name='accountNumber' label='Account #' />
        <TextField type='url' name='orderWebsite' label='Order Website' />
        <TextField type='text' name='repName' label='Representative Name' />
        <TextField type='email' name='repEmail' label='Representative Email' />
        <PhoneInputField name='repPhone' label='Representative Phone' />
      </View>
    </FormModal>
  );

  if (vendorIsLoading || !vendorData || ordersAreLoading || invoicesAreLoading || unpaidInvoicesAreLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (!vendorId || vendorError?.graphQLErrors[0].extensions.status === 404) {
    return (
      <StandardGrid>
        <NotFound />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4} style={{ gridRow: 1 }}>
        <BreadcrumbGroup>
          <Breadcrumb label='Vendors' to='/vendors' />
          <Breadcrumb label={vendorData.vendor.name} />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4} style={{ gridRow: 2 }}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
          <View style={{ flexDirection: 'row', gap: '16px', alignItems: 'center', '@media (max-width: 767px)': { alignItems: 'flex-start', flexDirection: 'column' } }}>
            <StyledHeading tag='h3'>{vendorData.vendor.name}</StyledHeading>
            {vendorData.vendor.accountNumber && <StyledParagraph style={{ color: Colors.neutral800 }}>Account #: {vendorData.vendor.accountNumber}</StyledParagraph>}
            {vendorData.vendor.isArchived && <StyledParagraph style={{ backgroundColor: Colors.error600, color: Colors.shades0, padding: '2px 6px', borderRadius: '4px', fontSize: '14px', fontWeight: 'bold', }}>Archived</StyledParagraph>}
          </View>
          <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
            <ModalLauncher modal={editVendorModal}>
              {({ openModal }) => (
                <Button variant='tertiary' role='button' action={openModal} label='Edit Vendor' />
              )}
            </ModalLauncher>
          </HasProductRole>
        </View>
      </Cell>
      <Cell lg={9} md={5} sm={4} style={{ gridRow: 3 }}>
        <View style={{ gap: '32px' }}>
          <Card style={{ padding: '32px' }} size='medium'>
            <View style={{ gap: '36px' }}>
              <StyledHeading tag='h5'>Orders</StyledHeading>
              <View style={{ gap: '32px' }}>
                <View>
                  <StyledHeading tag='h6'>Vendor Website</StyledHeading>
                  <StyledParagraph>{vendorData.vendor.orderWebsite ? <Link href={vendorData.vendor.orderWebsite.includes('http') ? vendorData.vendor.orderWebsite : 'http://' + vendorData.vendor.orderWebsite}>{vendorData.vendor.orderWebsite}</Link> : "-----"}</StyledParagraph>
                </View>
                <View style={{ width: '100%', overflowX: 'auto' }}>
                  <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', '@media (max-width: 767px)': { alignItems: 'flex-start', flexDirection: 'column' } }}>
                    <StyledHeading tag='h6'>Recent Orders</StyledHeading>
                    <PageButtons numPages={ordersData?.inventoryOrders.pages || 0} currentPage={ordersPage} onPageChange={setOrdersPage} />
                  </View>
                  <View style={{ overflowX: 'auto', width: '100%' }}>
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHeaderCell>Delivery Date</TableHeaderCell>
                          <TableHeaderCell>Order Date</TableHeaderCell>
                          <TableHeaderCell># Items</TableHeaderCell>
                          <TableHeaderCell># Cases</TableHeaderCell>
                          <TableHeaderCell>Estimated Cost</TableHeaderCell>
                          <TableHeaderCell></TableHeaderCell>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {ordersData?.inventoryOrders.orders.map((o, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>{o.deliveryDate}</TableCell>
                              <TableCell>{o.orderDate}</TableCell>
                              <TableCell>{o.itemCount}</TableCell>
                              <TableCell>{formatDecimalAmount(o.caseCount)}</TableCell>
                              <TableCell>{o.estimatedTotalCost}</TableCell>
                              <TableCell style={{ margin: 0, padding: 0 }}>
                                <Button label='View' variant='tertiary' role='link' href={'/orders/' + o.id} />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </View>
                </View>
              </View>
            </View>
          </Card>
          <Card style={{ padding: '32px' }} size='medium'>
            <View style={{ gap: '36px' }}>
              <StyledHeading tag='h5'>Invoices</StyledHeading>
              <View style={{ gap: '52px' }}>
                <View style={{ gap: '12px' }}>
                  <StyledHeading tag='h6'>Total Amount Outstanding</StyledHeading>
                  <StyledParagraph bold style={{ fontSize: '36px' }}>{unpaidInvoicesData?.vendor.totalUnpaidInvoices}</StyledParagraph>
                </View>
                <View>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <StyledHeading tag='h6'>Recent Invoices</StyledHeading>
                    <PageButtons currentPage={invoicesPage} numPages={invoicesData?.inventoryInvoices.pages || 0} onPageChange={setInvoicesPage} />
                  </View>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHeaderCell>Delivery Date</TableHeaderCell>
                        <TableHeaderCell># Items</TableHeaderCell>
                        <TableHeaderCell># Cases</TableHeaderCell>
                        <TableHeaderCell>Subtotal</TableHeaderCell>
                        <TableHeaderCell>Deposits</TableHeaderCell>
                        <TableHeaderCell>Total Cost</TableHeaderCell>
                        <TableHeaderCell>Date Paid</TableHeaderCell>
                        <TableHeaderCell></TableHeaderCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {invoicesData?.inventoryInvoices.invoices.map((invoice, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{invoice.deliveryDate}</TableCell>
                            <TableCell>{invoice.itemCount}</TableCell>
                            <TableCell>{formatDecimalAmount(invoice.caseCount)}</TableCell>
                            <TableCell>{invoice.subtotal}</TableCell>
                            <TableCell>{invoice.totalDeposits}</TableCell>
                            <TableCell>{currency(invoice.subtotal).add(invoice.totalDeposits).format()}</TableCell>
                            <TableCell>{invoice.paidDate ? invoice.paidDate : '----'}</TableCell>
                            <TableCell>
                              <Button label='View' variant='tertiary' role='link' href={'/invoices/' + invoice.id} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </View>
              </View>
            </View>
          </Card>
        </View>
      </Cell>
      <Cell lg={3} lgOffset={10} md={3} mdOffset={6} sm={4} style={{ '@media (min-width: 767px)': { gridRow: 3 } }}>
        <View style={{ gap: '32px' }}>
          <Card style={{ padding: '32px' }} size='medium' >
            <View style={{ gap: '36px' }}>
              <StyledHeading tag='h5'>Representative Info</StyledHeading>
              <View style={{ gap: '16px' }}>
                <View>
                  <StyledHeading tag='h6'>Name</StyledHeading>
                  <StyledParagraph>{vendorData.vendor.representativeName ? vendorData.vendor.representativeName : "-----"}</StyledParagraph>
                </View>
                <View>
                  <StyledHeading tag='h6'>Email</StyledHeading>
                  <StyledParagraph>{vendorData.vendor.representativeEmail ? <a href={`mailto:${vendorData.vendor.representativeEmail}`}>{vendorData.vendor.representativeEmail}</a> : "-----"}</StyledParagraph>
                </View>
                <View>
                  <StyledHeading tag='h6'>Phone</StyledHeading>
                  <StyledParagraph>{vendorData.vendor.representativePhone ? vendorData.vendor.representativePhone : "-----"}</StyledParagraph>
                </View>
              </View>
            </View>
          </Card>
        </View>
      </Cell>
    </StandardGrid>
  );
}