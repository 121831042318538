import { gql, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, Button, Card, Cell, Checkbox, Choice, FormModal, FormModalValueProvider, HasProductRole, Icons, InfoModal, InventoryRoles, Link, ModalLauncher, MultiSelect, PageButtons, Products, SingleSelect, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextArea, TextField, View, generateId, useAlertState } from "@barscience/global-components";
import { useEffect, useState } from "react";


/* Get Categories Query */
const GET_ALL_CATEGORIES = gql`
query getItemCategoriesForItemFilter {
  itemCategories {
    id
    name
  }
}
`;

type GetAllCategoriesResponse = {
  itemCategories: ItemCategory[];
}

type ItemCategory = {
  id: string;
  name: string;
  isArchived: boolean;
}

/* Get Vendors Query */
const GET_ALL_VENDORS = gql`
query getVendorsForItemFilter($filter: VendorFilter) {
  vendors(filter: $filter) {
    id
    name
  }
}
`;

type GetAllVendorsResponse = {
  vendors: Vendor[];
}

type Vendor = {
  id: string;
  name: string;
}

/* Get Items Query */
const GET_FILTERED_ITEMS = gql`
query getFilteredInventoryItems($page: Int!, $name: String, $categories: [ID!], $vendors: [ID!]) {
  inventoryItems(page: $page, name: $name, categories: $categories, vendors: $vendors) {
    items {
      id
      name
      category {
        id
        name
      }
      vendor {
        id
        name
      }
      currentPrice {
        casePrice
        unitPrice
      }
    }
    pages
  }
}
`;

type GetFilteredItemsQueryResponse = {
  inventoryItems: {
    items: Item[];
    pages: number;
  };
}

type Item = {
  id: string;
  name: string;
  category: {
    id: string;
    name: string;
  };
  vendor: {
    id: string;
    name: string;
  };
  currentPrice: {
    casePrice: string;
    unitPrice: string;
  };
}

/* Create Item Mutation */
const CREATE_ITEM = gql`
mutation createInventoryItem($input: CreateInventoryItemInput!) {
  item: createInventoryItem(input: $input) {
    id
    name
    category {
      id
      name
    }
    vendor {
      id
      name
    }
    currentPrice {
      casePrice
      unitPrice
    }
  }
}
`;

type CreateItemMutationResponse = {
  item: Item
}

/* Form Types */
type CreateItemInput = {
  name: string;
  description: string;
  vendorId: string;
  categoryId: string;
  casePrice: string;
  unitsPerCase: string;
  unitVolumeEnabled: boolean;
  unitVolume: string;
  unitVolumeType: string;
  unitWeightEnabled: boolean;
  unitWeight: string;
  unitWeightType: string;
  unitEmptyWeight: string;
  vendorItemId: string;
  unitDeposit: string;
}

const CreateItemInitialValues: CreateItemInput = {
  name: '',
  description: '',
  vendorId: '',
  categoryId: '',
  casePrice: '',
  unitsPerCase: '',
  unitVolumeEnabled: false,
  unitVolume: '',
  unitVolumeType: '',
  unitWeightEnabled: false,
  unitWeight: '',
  unitWeightType: '',
  unitEmptyWeight: '',
  vendorItemId: '',
  unitDeposit: '',
}

/* Static Modals */
export const UNITS_PER_CASE_MODAL = (
  <InfoModal title='Units Per Case'>
    <View style={{ gap: '16px' }}>
      <StyledParagraph>
        Enter the number of individual units that come in a case of a product. If you do not order the product "by the case", then you should enter "1".
      </StyledParagraph>
      <StyledParagraph>
        <span style={{ fontWeight: 600 }}>Beverage Example:</span> If a case of beer has 24 bottles, you should enter 24. If a half barrel keg has 124 pints, you should enter 1, because kegs are not typically bought “by the case”, and then use the Unit Volume and Volume Type fields to record the 124 pints per unit (keg).
      </StyledParagraph>
      <StyledParagraph>
        <span style={{ fontWeight: 600 }}>Food Example:</span> If a case of milk has four 1 gallon containers packaged together in a box, you should enter 4 for the number of units. You can then enter 1 gallon under Unit Volume and Volume Type to allow you to input volume measurements (such as 1 cup) in a recipe or item count.
      </StyledParagraph>
      <Link href='https://support.barscience.us/help-center/articles/a5615d1e-88d8-46a2-ac84-0d99f8c772c8' target='_blank'>Learn more here</Link>
    </View>
  </InfoModal>
);

export const VOLUME_MEASUREMENTS_MODAL = (
  <InfoModal title='Volume Measurements'>
    <View style={{ gap: '16px' }}>
      <StyledParagraph>
        Enable volume measurements if you need to input units by volume in item counts or recipes.
      </StyledParagraph>
      <StyledParagraph>
        Common items that are measured by volume:
      </StyledParagraph>
      <ul>
        <li><StyledParagraph>Beer kegs</StyledParagraph></li>
        <li><StyledParagraph>Sauces and dressings</StyledParagraph></li>
        <li><StyledParagraph>Dry goods used in recipes (listed by cups, tbsps, etc.)</StyledParagraph></li>
      </ul>
      <StyledParagraph>
        If the item units are sold or used in its entirety, then it is not recommend to add unit volumes. For example, if you sell and use beer bottles in their entirety, then you should not input the number of ounces per bottle, since bottles will not be counted or sold this way.
      </StyledParagraph>
      <Link href='https://support.barscience.us/help-center/articles/f0742650-dcf5-40f6-9ddb-b6217865b117' target='_blank'>Learn more here</Link>
    </View>
  </InfoModal>
);

export const WEIGHT_MEASUREMENTS_MODAL = (
  <InfoModal title='Weight Measurements'>
    <View style={{ gap: '16px' }}>
      <StyledParagraph>
        Enable weight measurements if you need to input units by weight in item counts or recipes.
      </StyledParagraph>
      <StyledParagraph>
        Common items that are measured by weight:
      </StyledParagraph>
      <ul>
        <li><StyledParagraph>Meats</StyledParagraph></li>
        <li><StyledParagraph>Beer kegs (if weighed for item counts)</StyledParagraph></li>
        <li><StyledParagraph>Dry goods used in recipes (listed by grams, kilograms, etc.)</StyledParagraph></li>
      </ul>
      <StyledParagraph>
        If the container or packaging is weighed with the product during inventory counts, enter the weight of the container when empty in the “Empty Container Weight” field.
      </StyledParagraph>
      <StyledParagraph>
        For example, if a full keg of beer weighs 165 lbs and an empty keg weighs 55 lbs, enter 165 lbs as the Unit Weight and 55 lbs as the Empty Container Weight.
      </StyledParagraph>
      <Link href='https://support.barscience.us/help-center/articles/dcb39c35-613c-458b-b031-5597c8ac3cf7' target='_blank'>Learn more here</Link>
    </View>
  </InfoModal>
);

export default function AllItems() {
  const { addAlert } = useAlertState();
  const [page, setPage] = useState<number>(0);
  const [searchInput, setSearchInput] = useState<string | null>(null);
  const [selectedCategories, setSelectedCategories] = useState<{ [name: string]: boolean }>({});
  const [selectedVendors, setSelectedVendors] = useState<{ [name: string]: boolean }>({});
  const { loading: itemsAreLoading, data: itemsData, refetch: refetchItems } = useQuery<GetFilteredItemsQueryResponse>(GET_FILTERED_ITEMS, {
    variables: {
      page: page,
      name: searchInput,
      categories: Object.keys(selectedCategories).filter((key) => (selectedCategories[key])),
      vendors: Object.keys(selectedVendors).filter((key) => (selectedVendors[key])),
    },
  });
  const { loading: categoriesAreLoading, data: categoriesData } = useQuery<GetAllCategoriesResponse>(GET_ALL_CATEGORIES, {
    onCompleted: (data) => {
      const noneSelected: { [name: string]: boolean } = {};

      data.itemCategories.forEach((category) => {
        noneSelected[category.id] = false;
      });

      setSelectedCategories(noneSelected);
    },
  });
  const { loading: vendorsAreLoading, data: vendorsData } = useQuery<GetAllVendorsResponse>(GET_ALL_VENDORS, {
    onCompleted: (data) => {
      const noneSelected: { [name: string]: boolean } = {};

      data.vendors.forEach((vendor) => {
        noneSelected[vendor.id] = false;
      });

      setSelectedVendors(noneSelected);
    },
  });
  const [createItem] = useMutation<CreateItemMutationResponse>(CREATE_ITEM);

  useEffect(() => {
    refetchItems();
  }, [selectedCategories, selectedVendors, searchInput, refetchItems, page]);

  const handleCreateItem = async (values: CreateItemInput) => {
    let unitDeposit = null;
    if (values.unitDeposit !== '') {
      unitDeposit = values.unitDeposit;
    }

    const { data } = await createItem({
      variables: {
        input: {
          name: values.name,
          description: values.description,
          categoryId: values.categoryId,
          vendorId: values.vendorId,
          casePrice: values.casePrice,
          unitsPerCase: parseFloat(values.unitsPerCase),
          unitVolume: values.unitVolumeEnabled ? parseFloat(values.unitVolume) : null,
          unitVolumeType: values.unitVolumeEnabled ? values.unitVolumeType : null,
          unitWeight: values.unitWeightEnabled ? parseFloat(values.unitWeight) : null,
          unitWeightType: values.unitWeightEnabled ? values.unitWeightType : null,
          unitEmptyWeight: values.unitWeightEnabled ? parseFloat(values.unitEmptyWeight) : null,
          vendorItemId: values.vendorItemId,
          unitDeposit: unitDeposit,
        },
      },
    });

    const alertId = generateId();
    const alert = (
      <StandardAlert id={alertId} title='Item successfully created' type='success' description={data?.item.name + ' was successfully added as an item.'}>
        <Link href={'/items/' + data?.item.id}>View item</Link>
      </StandardAlert>
    );

    addAlert(alertId, alert);

    refetchItems();
  }

  const createItemModal = (
    <FormModal<CreateItemInput> title='Create Item' onSubmit={handleCreateItem} initialValues={CreateItemInitialValues} submitLabel='Create Item' >
      <FormModalValueProvider>
        {({ getValue, setError }) => (
          <View style={{ gap: '16px' }}>
            <TextField name='name' label='Item Name' required />
            <TextArea name='description' label='Item Description' disableResizing />
            <SingleSelect name='vendorId' label='Vendor' required>
              {vendorsData?.vendors.map((vendor) => {
                return (
                  <Choice label={vendor.name} value={vendor.id} key={vendor.id} />
                );
              })}
            </SingleSelect>
            <SingleSelect name='categoryId' label='Category' required>
              {categoriesData?.itemCategories.map((category) => {
                return (
                  <Choice label={category.name} value={category.id} key={category.id} />
                );
              })}
            </SingleSelect>
            <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
              <TextField name='casePrice' label='Case Price' type='currency' inputMode='decimal' required />
              <TextField name='unitsPerCase' label='Units Per Case' type='number' inputMode='decimal' helpModal={UNITS_PER_CASE_MODAL} required />
            </View>
            <View style={{ paddingTop: '32px', gap: '48px' }}>
              <View style={{ gap: '16px' }}>
                <View style={{ gap: '8px' }}>
                  <StyledHeading tag='h6'>Volume Measurements</StyledHeading>
                </View>
                <Checkbox name='unitVolumeEnabled' label='Enable volume measurements' description='Allows this item to be input by unit volume for item counts and recipes' helpModal={VOLUME_MEASUREMENTS_MODAL} validate={(_, value) => {
                  if (!value && setError) {
                    setError('unitVolume', null);
                    setError('unitVolumeType', null);
                  }

                  return null;
                }} />
                {(getValue && getValue('unitVolumeEnabled')) && (
                  <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
                    <TextField name='unitVolume' label='Unit Volume' type='number' inputMode='decimal' style={{ flex: '1 1 0px', '@media (max-width: 767px)': { flex: '0' } }} required validate={(_, value) => {
                      const num = parseFloat(value);
                      if (num < 0) {
                        return 'Unit volume must be greater than 0'
                      }
                      return null;
                    }} />
                    <SingleSelect name='unitVolumeType' label='Volume Type' style={{ flex: '1 1 0px', '@media (max-width: 767px)': { flex: '0' } }} required >
                      <Choice label='Fluid Ounce' value='FLUID_OZ' />
                      <Choice label='Cup' value='CUP' />
                      <Choice label='Gallon' value='GALLON' />
                      <Choice label='Liter' value='LITER' />
                      <Choice label='Milliliter' value='MILLILITER' />
                      <Choice label='Pint' value='PINT' />
                      <Choice label='Quart' value='QUART' />
                      <Choice label='Tablespoon' value='TBSP' />
                      <Choice label='Teaspoon' value='TSP' />
                    </SingleSelect>
                  </View>
                )}
              </View>
              <View style={{ gap: '16px' }}>
                <View style={{ gap: '8px' }}>
                  <StyledHeading tag='h6'>Weight Measurements</StyledHeading>
                </View>
                <Checkbox name='unitWeightEnabled' label='Enable weight measurements' description='Allows this item to be input by unit weight for item counts and recipes' helpModal={WEIGHT_MEASUREMENTS_MODAL} validate={(_, value) => {
                  if (!value && setError) {
                    setError('unitWeight', null);
                    setError('unitWeightType', null);
                  }

                  return null;
                }} />
                {(getValue && getValue('unitWeightEnabled')) && (
                  <View style={{ gap: '16px' }}>
                    <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
                      <TextField name='unitWeight' label='Unit Weight' type='number' inputMode='decimal' style={{ flex: '1 1 0px', '@media (max-width: 767px)': { flex: '0' } }} required validate={(_, value) => {
                        const num = parseFloat(value);
                        if (num < 0) {
                          return 'Unit weight must be greater than 0'
                        }
                        return null;
                      }} />
                      <SingleSelect name='unitWeightType' label='Weight Type' style={{ flex: '1 1 0px', '@media (max-width: 767px)': { flex: '0' } }} required >
                        <Choice label='Ounce' value='OUNCE' />
                        <Choice label='Pound' value='POUND' />
                        <Choice label='Gram' value='GRAM' />
                        <Choice label='Kilogram' value='KILOGRAM' />
                      </SingleSelect>
                    </View>
                    <TextField name='unitEmptyWeight' label='Unit Empty Weight' type='number' inputMode='decimal' style={{ maxWidth: 'calc(50% - 8px)', '@media (max-width: 767px)': { maxWidth: '100%' } }} validate={(_, value) => {
                      const num = parseFloat(value);
                      if (num < 0) {
                        return 'Empty weight must be greater than 0'
                      }
                      return null;
                    }} />
                  </View>
                )}
              </View>
              <View style={{ gap: '16px' }}>
                <StyledHeading tag='h6'>Other Information</StyledHeading>
                <TextField label='Vendor Item ID' type='text' name='vendorItemId' />
                <TextField label='Unit Deposit' type='currency' inputMode='decimal' name='unitDeposit' />
              </View>
            </View>
          </View>
        )}
      </FormModalValueProvider>
    </FormModal>
  );

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ gap: '16px' }}>
          <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', gap: '16px' }}>
            <StyledHeading tag='h3'>Items</StyledHeading>
            <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
              <ModalLauncher modal={createItemModal}>
                {({ openModal }) => (
                  <Button label='Create Item' variant='primary' role='button' action={openModal} />
                )}
              </ModalLauncher>
            </HasProductRole>
          </View>
          <View style={{ flexDirection: 'row', gap: '16px', '@media(max-width: 1151px)': { flexDirection: 'column' } }}>
            <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', '@media (max-width: 767px)': { flexDirection: 'column', alignItems: 'flex-start' } }}>
              <MultiSelect name='categoriesFilter' value={selectedCategories} onChange={(_, value) => { setSelectedCategories(value); }} entityLabel='categories' placeholder='Filter by category' style={{ width: '240px' }}>
                {categoriesData?.itemCategories.map((category) => {
                  return (
                    <Choice label={category.name} value={category.id} key={category.id} />
                  );
                })}
              </MultiSelect>
              <MultiSelect name='vendorsFilter' value={selectedVendors} onChange={(_, value) => { setSelectedVendors(value); }} entityLabel='vendors' placeholder='Filter by vendor' style={{ width: '240px' }}>
                {vendorsData?.vendors.map((vendor) => {
                  return (
                    <Choice label={vendor.name} value={vendor.id} key={vendor.id} />
                  );
                })}
              </MultiSelect>
            </View>
            <View style={{ alignItems: 'center', flexDirection: 'row', flexGrow: 1, gap: '16px', justifyContent: 'space-between', '@media (max-width: 767px)': { alignItems: 'flex-start', flexDirection: 'column', gap: '16px', justifyContent: 'center' } }}>
              <View style={{ flexGrow: 1, maxWidth: '400px' }}>
                <TextField name='searchInput' value={searchInput || ''} onChange={(_, value) => { setSearchInput(value); }} icon={Icons.MagnifyingGlass} placeholder='Search' />
              </View>
              <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                <PageButtons currentPage={page} numPages={itemsData?.inventoryItems.pages || 0} onPageChange={setPage} />
              </View>
            </View>
          </View>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {(categoriesAreLoading || vendorsAreLoading) ?
          <AtomSpinner size='large' />
          :
          (
            <Card size='medium'>
              {itemsAreLoading ?
                <AtomSpinner size='medium' />
                :
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHeaderCell style={{ minWidth: '200px' }}>Name</TableHeaderCell>
                      <TableHeaderCell style={{ minWidth: '200px' }}>Category</TableHeaderCell>
                      <TableHeaderCell style={{ minWidth: '200px' }}>Vendor</TableHeaderCell>
                      <TableHeaderCell style={{ minWidth: '200px' }}>Current Price</TableHeaderCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {itemsData?.inventoryItems.items.map((item) => {
                      return (
                        <TableRow key={item.id}>
                          <TableCell><Link href={'/items/' + item.id}>{item.name}</Link></TableCell>
                          <TableCell>{item.category.name}</TableCell>
                          <TableCell><Link href={'/vendors/' + item.vendor.id}>{item.vendor.name}</Link></TableCell>
                          <TableCell>{item.currentPrice.casePrice} ({item.currentPrice.unitPrice} per unit)</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              }
            </Card>
          )
        }
      </Cell>
    </StandardGrid>
  );
}