import { gql, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, Button, Card, Cell, Colors, DatePicker, FormModal, HasProductRole, InfoModal, InventoryRoles, ModalLauncher, PageButtons, Products, StandardGrid, Strut, StyledHeading, StyledParagraph, TextField, View } from "@barscience/global-components";
import { CSSProperties } from "aphrodite";
import { useState } from "react";
import { Link } from "react-router-dom";

const GET_ALL_PERIODS = gql`
query getInventoryPeriods($page: Int!) {
  inventoryPeriods(page: $page) {
    periods {
      id
      name
      startDate
      endDate
    }
    pageCount
  }
}
`;

type GetAllPeriodsResponse = {
  inventoryPeriods: {
    periods: Period[];
    pageCount: number;
  };
}

const CREATE_PERIOD = gql`
mutation createInventoryPeriod($input: CreateInventoryPeriodInput!) {
  createInventoryPeriod(input: $input) {
    id
    name
    startDate
    endDate
  }
}
`;

type CreatePeriodResponse = {
  createInventoryPeriod: Period | null
}

type Period = {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
}

type CreatePeriodInput = {
  name: string;
  startDate: string;
  endDate: string;
}

const createPeriodInputInitialValues = {
  name: '',
  startDate: '',
  endDate: '',
}

const cardStyles: CSSProperties = {
  overflowX: 'auto',
  padding: '16px',
  ':hover': {
    backgroundColor: Colors.neutral100,
    cursor: 'pointer',
  },
  ':active': {
    backgroundColor: Colors.neutral300,
  },
}

export default function AllPeriods() {
  const [page, setPage] = useState<number>(0);
  const { data, loading, refetch: refetchPeriods } = useQuery<GetAllPeriodsResponse>(GET_ALL_PERIODS, {
    variables: {
      page: page,
    },
  });
  const [createPeriod, { error: createPeriodError, reset: resetCreatePeriod }] = useMutation<CreatePeriodResponse>(CREATE_PERIOD);

  const handleCreatePeriod = async (values: CreatePeriodInput) => {
    await createPeriod({
      variables: {
        input: {
          name: values.name,
          startDate: values.startDate,
          endDate: values.endDate,
        },
      },
    });

    await refetchPeriods();
  }

  const createPeriodModal = (
    <FormModal<CreatePeriodInput> title='Create Period' onSubmit={handleCreatePeriod} initialValues={createPeriodInputInitialValues} submitLabel='Create' >
      <View style={{ gap: '16px' }}>
        <TextField label='Period Name' type='text' name='name' required />
        <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 1151px)': { flexDirection: 'column' } }}>
          <DatePicker label='Start Date' name='startDate' required />
          <DatePicker label='End Date' name='endDate' required />
        </View>
      </View>
    </FormModal>
  );

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4} >
        <View style={{ flexDirection: 'row', gap: '32px', '@media (max-width: 767px)': { flexDirection: 'column', gap: '16px', marginBottom: '16px' } }}>
          <View style={{ flexGrow: 1, gap: '8px' }}>
            <StyledHeading tag='h3'>Periods</StyledHeading>
            <StyledParagraph>An inventory period represents a full cycle of the inventory process to be completed by your business.</StyledParagraph>
          </View>
          <View style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}>
            <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
              <ModalLauncher modal={createPeriodModal}>
                {({ openModal }) => (
                  <Button label='Create Period' variant='primary' role='button' action={openModal} />
                )}
              </ModalLauncher>
            </HasProductRole>
            <PageButtons currentPage={page} numPages={data?.inventoryPeriods.pageCount || 1} onPageChange={setPage} />
          </View>
        </View>
      </Cell>

      {loading &&
        <Cell lg={12} md={8} sm={4} >
          <AtomSpinner size='large' />
        </Cell>
      }

      <Cell lg={12} md={8} sm={4} >
        <View style={{ gap: '18px' }}>
          {data?.inventoryPeriods.periods.map((p) => {
            return (
              <Link to={'/periods/' + p.id} style={{ color: Colors.neutral900, textDecoration: 'none' }} key={p.id}>
                <Card style={cardStyles} size='medium' >
                  <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View>
                      <StyledHeading tag='h5' style={{ fontWeight: 700 }}>{p.name}</StyledHeading>
                      <StyledParagraph style={{ color: Colors.neutral700 }}>{p.startDate} - {p.endDate}</StyledParagraph>
                    </View>
                    <View>

                    </View>
                  </View>
                </Card>
              </Link>
            );
          })}
        </View>
        {data?.inventoryPeriods.pageCount === 0 &&
          <Card size='medium'>
            <StyledParagraph>No periods have been created.</StyledParagraph>
          </Card>
        }
      </Cell>

      {createPeriodError &&
        <InfoModal title='Error creating period' onClose={() => { resetCreatePeriod(); }}>
          <StyledParagraph>
            {createPeriodError.message}
          </StyledParagraph>
          <Strut size='16px' />
          <StyledParagraph>Each inventory period must have a unique date range.</StyledParagraph>
        </InfoModal>
      }
    </StandardGrid>
  );
}