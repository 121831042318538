export const convertCurrencyToFloat = (amount: string) => {
    if (!amount) {
        return null;
    }

    let currency = amount;
    
    currency = currency.replaceAll('$', '')
    currency = currency.replaceAll(',', '');

    return parseFloat(currency);
}