import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Choice, Colors, Icon, Icons, Link, MultiSelect, PageButtons, SingleSelect, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, View, useForm } from "@barscience/global-components";
import currency from "currency.js";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { formatDecimalAmount } from "../../util/formatters";
import { parseVolumeUnits, parseWeightUnits } from "../../util/measurements";

/* Get Periods Query */
const GET_PERIODS = gql`
query getPeriodsWithShrinkageReportAvailable($page: Int!) {
  periodsWithShrinkageReportAvailable(page: $page) {
    id
    name
    startDate
    endDate
  }
}
`;

type GetPeriodsResponse = {
  periodsWithShrinkageReportAvailable: Period[] | null;
}

type Period = {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
}

/* Get Item Categories Query */
const GET_ITEM_CATEGORIES = gql`
query getAllItemCategories {
  itemCategories {
    id
    name
    isArchived
  }
}
`;

type GetItemCategoriesResponse = {
  itemCategories: ItemCategory[] | null;
}

type ItemCategory = {
  id: string;
  name: string;
  isArchived: boolean;
}

/* Get Period Shrinkage Report Query */
const GET_PERIOD_SHRINKAGE_REPORT = gql`
query getPeriodShrinkageReport($periodId: ID!, $priceDate: Date!) {
  periodShrinkageReport(id: $periodId) {
    period {
      id
      name
      startDate
      endDate
    }
    items {
      item {
        id
        name
        currentPrice(startDate: $priceDate) {
          unitPrice
        }
        category {
          id
        }
        unitVolume
        unitVolumeType
        unitWeight
        unitWeightType
        unitEmptyWeight
      }
      expectedUsage
      actualUsage
    }
  }
}
`;

type GetPeriodShrinkageReportResponse = {
  periodShrinkageReport: {
    period: Period;
    items: ItemShrinkage[]
  } | null;
}

type ItemShrinkage = {
  item: Item;
  expectedUsage: number;
  actualUsage: number;
}

type Item = {
  id: string;
  name: string;
  currentPrice: {
    unitPrice: string;
  }
  category: {
    id: string;
  }
  unitVolume: number | null;
  unitVolumeType: string | null;
  unitWeight: number | null;
  unitWeightType: string | null;
  unitEmptyWeight: number | null;
}

const PERIODS_PER_PAGE = 12;

type PeriodFormInput = {
  periodId: string;
}

const ITEMS_PER_PAGE = 100;

export default function Shrinkage() {
  const [queryParams, setQueryParams] = useSearchParams();
  const periodId = queryParams.get('periodId');
  const [periods, setPeriods] = useState<Period[]>([]);
  const [periodsPage, setPeriodsPage] = useState<number>(0);
  const [selectedCategories, setSelectedCategories] = useState<{ [name: string]: boolean }>({});
  const [itemsPage, setItemsPage] = useState<number>(0);
  const [shrinkageData, setShrinkageData] = useState<ItemShrinkage[]>([]);
  const { data: periodsData, loading: periodsAreLoading } = useQuery<GetPeriodsResponse>(GET_PERIODS, {
    variables: {
      page: 0,
    },
    onCompleted(data) {
      if (data.periodsWithShrinkageReportAvailable) {
        setPeriods(data.periodsWithShrinkageReportAvailable);
      }
    },
    fetchPolicy: 'network-only',
  });
  const { data: categoriesData, loading: categoriesAreLoading } = useQuery<GetItemCategoriesResponse>(GET_ITEM_CATEGORIES, {
    onCompleted: (data) => {
      const noneSelected: { [name: string]: boolean } = {};

      data.itemCategories?.forEach((category) => {
        noneSelected[category.id] = false;
      });

      setSelectedCategories(noneSelected);
    },
  });
  const [loadMorePeriods, { data: morePeriodsData, loading: morePeriodsAreLoading }] = useLazyQuery<GetPeriodsResponse>(GET_PERIODS, {
    onCompleted(data) {
      if (data.periodsWithShrinkageReportAvailable) {
        setPeriods([...periods, ...data.periodsWithShrinkageReportAvailable]);
      }
    },
    fetchPolicy: 'network-only',
  });
  const [generateReport, { data: reportData, loading: reportIsLoading }] = useLazyQuery<GetPeriodShrinkageReportResponse>(GET_PERIOD_SHRINKAGE_REPORT);

  useEffect(() => {
    const startDate = periods.find(period => period.id === periodId)?.startDate;

    if (periodId && startDate) {
      generateReport({
        variables: {
          periodId: periodId,
          priceDate: startDate,
        },
      });
    }
  }, [periodId, periods, generateReport]);

  useEffect(() => {
    if (!reportData?.periodShrinkageReport) {
      return;
    }

    let data = [...reportData.periodShrinkageReport.items];

    // Only include items in the selected categories
    if (Object.values(selectedCategories).some((value) => value)) {
      data = data.filter((item) => {
        return selectedCategories[item.item.category.id];
      });
    }

    // Sort the included items
    const sortBy = queryParams.get('sort') || 'NAME';
    if (sortBy === 'NAME') {
      data.sort((a, b) => {
        if (a.item.name < b.item.name) {
          return -1;
        } else if (a.item.name > b.item.name) {
          return 1;
        }

        return 0;
      });
    } else if (sortBy === 'HIGHEST_COST') {
      data.sort((a, b) => {
        const aCost = currency(a.item.currentPrice.unitPrice).multiply(a.actualUsage - a.expectedUsage);
        const bCost = currency(b.item.currentPrice.unitPrice).multiply(b.actualUsage - b.expectedUsage);

        if (aCost.value < bCost.value) {
          return 1;
        } else if (aCost.value > bCost.value) {
          return -1;
        }

        return 0;
      });
    }

    // Set the appropriate page of data
    setShrinkageData(data.slice(itemsPage * ITEMS_PER_PAGE, (itemsPage + 1) * ITEMS_PER_PAGE));
  }, [reportData, queryParams, itemsPage, selectedCategories]);

  const updateSearchParam = (name: string, value: string) => {
    setQueryParams((prev) => {
      prev.set(name, value)
      return prev;
    });
  }

  const hasMorePages = () => {
    // More than one page has been loaded, and the most recent page was full
    if (morePeriodsData?.periodsWithShrinkageReportAvailable && morePeriodsData.periodsWithShrinkageReportAvailable.length === PERIODS_PER_PAGE) {
      return true;
    }

    // The first page was full, and no more pages have been loaded yet
    if (!morePeriodsData && periodsData?.periodsWithShrinkageReportAvailable && periodsData?.periodsWithShrinkageReportAvailable?.length === PERIODS_PER_PAGE) {
      return true;
    }

    return false;
  }

  const handleGenerateReport = (values: PeriodFormInput) => {
    setQueryParams({ periodId: values.periodId, sort: 'NAME' });
  }

  const handleLoadMorePeriods = async () => {
    await loadMorePeriods({
      variables: {
        page: periodsPage + 1,
      },
    });
    setPeriodsPage(periodsPage + 1);
  }

  const periodForm = useForm<PeriodFormInput>({
    initialValues: {
      periodId: '',
    },
    onSubmit: handleGenerateReport,
  });

  const getItemVolumeAndWeightLabels = (unitAmount: number, item: Item): string => {
    if (!item.unitVolume && !item.unitWeight) {
      return '';
    }

    let volumeLabel = '';
    if (item.unitVolume && item.unitVolumeType) {
      const volumeShrinkage = Math.abs(formatDecimalAmount(unitAmount * item.unitVolume));
      volumeLabel = `${volumeShrinkage} ${parseVolumeUnits(item.unitVolumeType, volumeShrinkage)}`;
    }

    let weightLabel = '';
    if (item.unitWeight && item.unitWeightType) {
      const weightShrinkage = Math.abs(formatDecimalAmount(unitAmount * (item.unitWeight - (item.unitEmptyWeight || 0))));
      weightLabel = `${weightShrinkage} ${parseWeightUnits(item.unitWeightType, weightShrinkage)}`;
    }

    if (volumeLabel && weightLabel) {
      return `(${volumeLabel} | ${weightLabel})`;
    } else if (volumeLabel) {
      return `(${volumeLabel})`;
    } else if (weightLabel) {
      return `(${weightLabel})`;
    }

    return '';
  }

  const getSelectedItems = () => {
    if (!reportData?.periodShrinkageReport) {
      return [];
    }

    let data = [...reportData.periodShrinkageReport.items];

    // Only include items in the selected categories
    if (Object.values(selectedCategories).some((value) => value)) {
      data = data.filter((item) => {
        return selectedCategories[item.item.category.id];
      });
    }

    return data;
  }

  /* Calculate the total shrinkage */
  let totalShrinkage = currency(0);
  if (reportData?.periodShrinkageReport) {
    let data = getSelectedItems();

    data.forEach((item) => {
      const shrinkageInUnits = item.actualUsage - item.expectedUsage;
      const shrinkageCost = currency(item.item.currentPrice.unitPrice).multiply(shrinkageInUnits);
  
      totalShrinkage = totalShrinkage.add(shrinkageCost);
    });
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Reports' to='/reports' />
          <Breadcrumb label='Period Shrinkage Report' />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', justifyContent: 'space-between', '@media (max-width: 1151px)': { alignItems: 'flex-start', flexDirection: 'column' } }}>
          <StyledHeading tag='h3'>Period Shrinkage Report</StyledHeading>

          {(periodId && !reportIsLoading) &&
            <View>
              <StyledParagraph bold> Report For:</StyledParagraph>
              <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                <StyledParagraph>{`${reportData?.periodShrinkageReport?.period.name} (${reportData?.periodShrinkageReport?.period.startDate} - ${reportData?.periodShrinkageReport?.period.endDate})`}</StyledParagraph>
                <Button label='Change' variant='tertiary' role='button' action={() => { setQueryParams({}); }} />
              </View>
            </View>
          }
        </View>
      </Cell>
      {periodId ?
        ((reportIsLoading || categoriesAreLoading) ?
          <Cell lg={12} md={8} sm={4}>
            <View style={{ alignItems: 'center', marginTop: '64px' }}>
              <AtomSpinner size='medium' />
              <StyledHeading tag='h6' style={{ textAlign: 'center' }}>Hang tight, we're working on this report for you...</StyledHeading>
            </View>
          </Cell>
          :
          <>
            <Cell lg={6} md={6} sm={4}>
              <Card size='medium'>
                <View style={{ gap: '16px' }}>
                  <StyledHeading tag='h6'>Total Shrinkage</StyledHeading>
                  {totalShrinkage.value >= 0 ?
                    <View style={{ alignItems: 'center', color: Colors.error500, flexDirection: 'row' }} key={'shrinkage-total-negative'}>
                      <Icon icon={Icons.Minus} size='large' />
                      <StyledParagraph style={{ fontSize: '32px', fontWeight: 600 }}>{totalShrinkage.format()}</StyledParagraph>
                    </View>
                    :
                    <View style={{ alignItems: 'center', color: Colors.primary500, flexDirection: 'row' }} key={'shrinkage-total-postive'}>
                      <Icon icon={Icons.Plus} size='large' />
                      <StyledParagraph style={{ fontSize: '32px', fontWeight: 600 }}>{totalShrinkage.multiply(-1).format()}</StyledParagraph>
                    </View>
                  }
                </View>
              </Card>
            </Cell>
            <Cell lg={12} md={8} sm={4}>
              <Card size='medium'>
                <View style={{ alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                  <View style={{ alignItems: 'flex-end', flexDirection: 'row', gap: '16px' }}>
                    <SingleSelect label='Sort By:' name='sortBy' value={queryParams.get('sort') || 'NAME'} onChange={(_, value) => { updateSearchParam('sort', value || 'NAME'); setItemsPage(0); }} style={{ width: '200px' }} >
                      <Choice label='Name' value='NAME' />
                      <Choice label='Highest Cost' value='HIGHEST_COST' />
                    </SingleSelect>

                    <MultiSelect name='categoriesFilter' value={selectedCategories} onChange={(_, value) => { setSelectedCategories(value); setItemsPage(0); }} entityLabel='categories' placeholder='Filter by category' style={{ maxWidth: '240px', width: '240px' }}>
                      {categoriesData?.itemCategories?.map((category) => {
                        return (
                          <Choice label={category.name} value={category.id} key={category.id} />
                        );
                      })}
                    </MultiSelect>
                  </View>

                  <PageButtons currentPage={itemsPage} numPages={Math.ceil(getSelectedItems().length / ITEMS_PER_PAGE)} onPageChange={setItemsPage} />
                </View>
                <View style={{ maxWidth: '100%', overflowX: 'auto', width: '100%' }}>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHeaderCell style={{ maxWidth: '300px', width: '300px' }}>Item</TableHeaderCell>
                        <TableHeaderCell style={{ maxWidth: '100px', width: '100px' }}>Expected Usage</TableHeaderCell>
                        <TableHeaderCell style={{ maxWidth: '100px', width: '100px' }}>Actual Usage</TableHeaderCell>
                        <TableHeaderCell style={{ maxWidth: '200px', width: '200px' }}>Shrinkage</TableHeaderCell>
                        <TableHeaderCell style={{ maxWidth: '200px', width: '200px' }}>Waste Cost</TableHeaderCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {shrinkageData.map((item) => {
                        const shrinkage = formatDecimalAmount(item.actualUsage - item.expectedUsage);

                        return (
                          <TableRow key={item.item.id}>
                            <TableCell><Link linkStyle={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '300px' }} href={`/items/${item.item.id}`}>{item.item.name}</Link></TableCell>
                            <TableCell>{formatDecimalAmount(item.expectedUsage)} units</TableCell>
                            <TableCell>{formatDecimalAmount(item.actualUsage)} units</TableCell>
                            <TableCell>
                              {shrinkage > 0 ?
                                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '2px', color: Colors.error500, fontWeight: 600 }}><Icon icon={Icons.Minus} size='small' /> {formatDecimalAmount(shrinkage)} units <span style={{ color: Colors.neutral700, fontSize: '14px', fontStyle: 'italic', marginLeft: '4px' }}>{getItemVolumeAndWeightLabels(shrinkage, item.item)}</span></View>
                                :
                                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '2px', color: Colors.primary500, fontWeight: 600 }}><Icon icon={Icons.Plus} size='small' /> {formatDecimalAmount(-1 * shrinkage)} units <span style={{ color: Colors.neutral700, fontSize: '14px', fontStyle: 'italic', marginLeft: '4px' }}>{getItemVolumeAndWeightLabels(shrinkage, item.item)}</span></View>
                              }
                            </TableCell>
                            <TableCell>
                              {shrinkage > 0 ?
                                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '2px', color: Colors.error500, fontWeight: 600 }}><Icon icon={Icons.Minus} size='small' /> {currency(item.item.currentPrice.unitPrice).multiply(shrinkage).format()}</View>
                                :
                                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '2px', color: Colors.primary500, fontWeight: 600 }}><Icon icon={Icons.Plus} size='small' /> {currency(item.item.currentPrice.unitPrice).multiply(-1 * shrinkage).format()}</View>
                              }
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </View>
              </Card>
            </Cell>
          </>
        )
        :
        (periodsAreLoading ?
          <Cell lg={12} md={8} sm={4}>
            <View style={{ alignItems: 'center', marginTop: '64px' }}>
              <AtomSpinner size='medium' />
            </View>
          </Cell>
          :
          <Cell lg={12} md={8} sm={4}>
            <View style={{ alignItems: 'center', marginTop: '64px' }}>
              <Card size='medium' style={{ boxSizing: 'border-box', maxWidth: '400px', minWidth: '400px', width: '400px', '@media (max-width: 767px)': { maxWidth: '100%', minWidth: '100%', width: '100%' } }}>
                <View style={{ gap: '32px' }}>
                  <SingleSelect label='Select a period' name='periodId' value={periodForm.values.periodId} error={periodForm.errors.periodId} onChange={periodForm.handleChange} onValidate={periodForm.handleValidate} required>
                    {periods.map((period, index) => (
                      <Choice label={period.name} description={`${period.startDate} - ${period.endDate}`} value={period.id} key={index} />
                    ))}
                    {hasMorePages() && <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                      <Button label='Load More' variant='tertiary' role='button' action={handleLoadMorePeriods} loading={morePeriodsAreLoading} />
                    </View>}
                  </SingleSelect>

                  <Button label='Generate Report' variant='primary' role='button' action={periodForm.handleSubmit} disabled={periodForm.hasError} />
                </View>
              </Card>
            </View>
          </Cell>
        )
      }
    </StandardGrid>
  );
}