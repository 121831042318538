import { gql, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, AutoOpen, Button, Card, Cell, Choice, Colors, DatePicker, ErrorPage, FormModal, HasProductRole, InventoryRoles, Link, ModalLauncher, PageButtons, Products, SingleSelect, StandardAlert, StandardGrid, StyledHeading, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, View, generateId, useAlertState } from "@barscience/global-components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

/* Get All Sales Periods Query */
const GET_ALL_SALES_PERIODS = gql`
query getAllSalesPeriods($page: Int!, $dateRange: DateRange) {
  inventorySalesPeriods(page: $page, dateRange: $dateRange) {
    salesPeriods {
      id
      name
      startDate
      endDate
    }
    pages
  }
}
`;

type GetAllSalesPeriodsResponse = {
  inventorySalesPeriods: {
    salesPeriods: SalesPeriod[];
    pages: number;
  } | null;
}

type SalesPeriod = {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
}

/* Create Sales Period Mutation */
const CREATE_SALES_PERIOD = gql`
mutation createInventorySalesPeriod($input: CreateInventorySalesPeriodInput!) {
  createInventorySalesPeriod(input: $input) {
    id
    name
    startDate
    endDate
  }
}
`;

type CreateSalesPeriodResponse = {
  createInventorySalesPeriod: SalesPeriod | null;
}

type CreateSalesPeriodInput = {
  name: string;
  startDate: string;
  endDate: string;
}

type DateRange = {
  startDate: string;
  endDate: string;
}

export default function AllSalesPeriods() {
  const navigate = useNavigate();
  const { addAlert } = useAlertState();
  const [page, setPage] = useState<number>(0);
  const [dateRange, setDateRange] = useState<DateRange | null>(null);
  const [selectedRange, setSelectedRange] = useState<string | null>('ALL_TIME');
  const { data: salesData, loading: salesAreLoading } = useQuery<GetAllSalesPeriodsResponse>(GET_ALL_SALES_PERIODS, {
    variables: {
      page: page,
      dateRange: selectedRange === 'CUSTOM' ? dateRange : null,
    },
    fetchPolicy: 'network-only',
  });
  const [createSalesPeriod] = useMutation<CreateSalesPeriodResponse>(CREATE_SALES_PERIOD);

  const handleDateFilterChange = (_: string, value: string | null) => {
    setSelectedRange(value);
  }

  const handleDateRangePick = async (values: DateRange) => {
    setDateRange({
      startDate: values.startDate,
      endDate: values.endDate,
    });

    setSelectedRange('CUSTOM');
  }

  const handleCancelDateRangePick = () => {
    setSelectedRange('ALL_TIME');
  }

  const getDateLabel = (): string => {
    if (!dateRange) {
      return '';
    }

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const start = new Date(dateRange.startDate);
    const end = new Date(dateRange.endDate);

    const label = `${months[start.getMonth()]} ${start.getDate()}${getDayLabel(start.getDate())} - ${months[end.getMonth()]} ${end.getDate()}${getDayLabel(end.getDate())}`;

    return label;
  }

  const getDayLabel = (day: number) => {
    const str = day.toString();
    if (str.charAt(str.length - 1) === '1') {
      return 'st';
    } else if (str.charAt(str.length - 1) === '2') {
      return 'nd';
    } if (str.charAt(str.length - 1) === '3') {
      return 'rd';
    } else {
      return 'th';
    }
  }

  const handleCreateSalesPeriod = async (values: CreateSalesPeriodInput) => {
    const { data, errors } = await createSalesPeriod({
      variables: {
        input: {
          name: values.name,
          startDate: values.startDate,
          endDate: values.endDate,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = (
        <StandardAlert title='Error creating sales period' description={errors[0].message} type='error' id={id} />
      );

      addAlert(id, alert, 10);
    }

    if (data?.createInventorySalesPeriod) {
      navigate(`/sales/${data.createInventorySalesPeriod.id}`);
    }
  }

  const customRangeModal = (
    <FormModal<DateRange> title='Select date range' onSubmit={handleDateRangePick} onCancel={handleCancelDateRangePick} showCloseButton={false} submitLabel='Confirm' initialValues={{ startDate: dateRange?.startDate || '', endDate: dateRange?.endDate || '' }}>
      <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 1151px)': { flexDirection: 'column' } }}>
        <DatePicker label='Start Date' name='startDate' required />
        <DatePicker label='End Date' name='endDate' required />
      </View>
    </FormModal>
  );

  const createSalesPeriodModal = (
    <FormModal<CreateSalesPeriodInput> title='Create Sales Period' submitLabel='Create' onSubmit={handleCreateSalesPeriod} initialValues={{ name: '', startDate: '', endDate: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Period Name' name='name' required />
        <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 1151px)': { flexDirection: 'column' } }}>
          <DatePicker label='Start Date' name='startDate' required />
          <DatePicker label='End Date' name='endDate' required />
        </View>
      </View>
    </FormModal>
  );

  if (salesAreLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (!salesData || !salesData.inventorySalesPeriods) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <ModalLauncher modal={customRangeModal}>
        {({ openModal: openPickDateModal }) => {
          if (selectedRange === 'SET_CUSTOM') {
            return <AutoOpen openModal={openPickDateModal} />
          }

          return <></>
        }}
      </ModalLauncher>

      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', gap: '16px', justifyContent: 'space-between', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
          <StyledHeading tag='h3'>Sales Periods</StyledHeading>
          <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
            <ModalLauncher modal={createSalesPeriodModal}>
              {({ openModal }) => (
                <Button label='Create Sales Period' variant='primary' role='button' action={openModal} />
              )}
            </ModalLauncher>
          </HasProductRole>
        </View>
      </Cell>

      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <SingleSelect name='dateRange' value={selectedRange} onChange={handleDateFilterChange} style={{ maxWidth: '255px', width: '225px' }}>
            <Choice label='All Time' value='ALL_TIME' />
            {dateRange && <Choice label={getDateLabel()} value='CUSTOM' />}
            <View style={{ borderTop: `1px solid ${Colors.neutral200}`, marginTop: '4px', paddingTop: '4px' }}>
              <Choice label='Custom Range' value='SET_CUSTOM' />
            </View>
          </SingleSelect>
          <PageButtons currentPage={page} numPages={salesData.inventorySalesPeriods.pages} onPageChange={setPage} />
        </View>
      </Cell>

      <Cell>
        <Card size='medium'>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Period</TableHeaderCell>
                <TableHeaderCell>Start Date</TableHeaderCell>
                <TableHeaderCell>End Date</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {salesData.inventorySalesPeriods.salesPeriods.map((period, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell><Link href={'/sales/' + period.id} linkStyle={{ maxWidth: '400px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{period.name}</Link></TableCell>
                    <TableCell>{period.startDate}</TableCell>
                    <TableCell>{period.endDate}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Card>
      </Cell>
    </StandardGrid>
  );
}
