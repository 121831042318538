import { gql, useQuery } from "@apollo/client";
import { AtomSpinner, AutoOpen, Button, Card, Cell, Choice, Colors, DatePicker, FormModal, HasProductRole, Icon, Icons, InventoryRoles, ModalLauncher, PageButtons, Products, SingleSelect, StandardGrid, StyledHeading, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, View } from "@barscience/global-components";
import { useState } from "react";
import CreateInvoiceModal from "./CreateInvoiceModal";
import currency from "currency.js";
import { formatDecimalAmount } from "../../util/formatters";

/* Get Invoices Query */
const GET_INVOICES_QUERY = gql`
query getAllInvoices($page: Int!, $dateRange: DateRange) {
  data: inventoryInvoices(page: $page, dateRange: $dateRange) {
    invoices {
      id
      vendor {
        id
        name
      }
      orderDate
      deliveryDate
      itemCount
      caseCount
      subtotal
      totalDeposits
      paidDate
    }
    pages
  }
}
`;

type GetInvoicesQueryResponse = {
  data: {
    invoices: InventoryInvoice[];
    pages: number;
  };
}

type InventoryInvoice = {
  id: string;
  vendor: {
    id: string;
    name: string;
  };
  orderDate: string;
  deliveryDate: string;
  itemCount: number;
  caseCount: number;
  subtotal: string;
  totalDeposits: string;
  paidDate?: string;
}

type DateRange = {
  startDate: string;
  endDate: string;
}

export default function AllInvoices() {
  const [page, setPage] = useState<number>(0);
  const [dateRange, setDateRange] = useState<DateRange | null>(null);
  const [selectedRange, setSelectedRange] = useState<string | null>('ALL_TIME');
  const { data: invoicesData, loading: invoicesAreLoading } = useQuery<GetInvoicesQueryResponse>(GET_INVOICES_QUERY, {
    variables: {
      page: page,
      dateRange: selectedRange === 'CUSTOM' ? dateRange : null,
    },
    fetchPolicy: 'network-only',
  });

  const handleDateFilterChange = (_: string, value: string | null) => {
    setSelectedRange(value);
  }

  const handleDateRangePick = async (values: DateRange) => {
    setDateRange({
      startDate: values.startDate,
      endDate: values.endDate,
    });

    setSelectedRange('CUSTOM');
  }

  const handleCancelDateRangePick = () => {
    setSelectedRange('ALL_TIME');
  }

  const getDateLabel = (): string => {
    if (!dateRange) {
      return '';
    }

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const start = new Date(dateRange.startDate);
    const end = new Date(dateRange.endDate);

    const label = `${months[start.getMonth()]} ${start.getDate()}${getDayLabel(start.getDate())} - ${months[end.getMonth()]} ${end.getDate()}${getDayLabel(end.getDate())}`;

    return label;
  }

  const getDayLabel = (day: number) => {
    const str = day.toString();
    if (str.charAt(str.length - 1) === '1') {
      return 'st';
    } else if (str.charAt(str.length - 1) === '2') {
      return 'nd';
    } if (str.charAt(str.length - 1) === '3') {
      return 'rd';
    } else {
      return 'th';
    }
  }

  const customRangeModal = (
    <FormModal<DateRange> title='Select date range' onSubmit={handleDateRangePick} onCancel={handleCancelDateRangePick} showCloseButton={false} submitLabel='Confirm' initialValues={{ startDate: dateRange?.startDate || '', endDate: dateRange?.endDate || '' }}>
      <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 1151px)': { flexDirection: 'column' } }}>
        <DatePicker label='Start Date' name='startDate' required />
        <DatePicker label='End Date' name='endDate' required />
      </View>
    </FormModal>
  );

  return (
    <StandardGrid>
      <ModalLauncher modal={customRangeModal}>
        {({ openModal: openPickDateModal }) => {
          if (selectedRange === 'SET_CUSTOM') {
            return <AutoOpen openModal={openPickDateModal} />
          }

          return <></>
        }}
      </ModalLauncher>

      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <StyledHeading tag='h3'>Invoices</StyledHeading>
          <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
            <ModalLauncher modal={<CreateInvoiceModal />}>
              {({ openModal }) => (
                <Button label='Create Invoice' variant='primary' role='button' action={openModal} />
              )}
            </ModalLauncher>
          </HasProductRole>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', gap: '16px', justifyContent: 'space-between', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
          <SingleSelect name='dateRange' value={selectedRange} onChange={handleDateFilterChange} style={{ maxWidth: '255px', width: '225px' }}>
            <Choice label='All Time' value='ALL_TIME' />
            {dateRange && <Choice label={getDateLabel()} value='CUSTOM' />}
            <View style={{ borderTop: `1px solid ${Colors.neutral200}`, marginTop: '4px', paddingTop: '4px' }}>
              <Choice label='Custom Range' value='SET_CUSTOM' />
            </View>
          </SingleSelect>
          <PageButtons currentPage={page} numPages={invoicesData?.data.pages || 0} onPageChange={setPage} />
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {invoicesAreLoading ?
          <AtomSpinner size='large' />
          :
          <Card size='medium'>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell style={{ minWidth: '200px' }}>Vendor</TableHeaderCell>
                  <TableHeaderCell style={{ minWidth: '100px' }}>Delivery Date</TableHeaderCell>
                  <TableHeaderCell style={{ minWidth: '100px' }}>Order Date</TableHeaderCell>
                  <TableHeaderCell style={{ minWidth: '50px' }}># Items</TableHeaderCell>
                  <TableHeaderCell style={{ minWidth: '55px' }}># Cases</TableHeaderCell>
                  <TableHeaderCell>Subtotal</TableHeaderCell>
                  <TableHeaderCell>Deposit</TableHeaderCell>
                  <TableHeaderCell>Total Cost</TableHeaderCell>
                  <TableHeaderCell style={{ width: '16px' }}>Paid</TableHeaderCell>
                  <TableHeaderCell></TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {invoicesData?.data.invoices.map((invoice, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{invoice.vendor.name}</TableCell>
                      <TableCell>{invoice.deliveryDate}</TableCell>
                      <TableCell>{invoice.orderDate}</TableCell>
                      <TableCell>{invoice.itemCount}</TableCell>
                      <TableCell>{formatDecimalAmount(invoice.caseCount)}</TableCell>
                      <TableCell>{invoice.subtotal}</TableCell>
                      <TableCell>{invoice.totalDeposits}</TableCell>
                      <TableCell>{currency(invoice.subtotal).add(currency(invoice.totalDeposits)).format()}</TableCell>
                      <TableCell>
                        {invoice.paidDate ?
                          <View><Icon size='medium' icon={Icons.CircleCheckmark} style={{ color: Colors.primary500 }} /></View>
                          :
                          <View><Icon size='medium' icon={Icons.CircleX} style={{ color: Colors.error500 }} /></View>
                        }
                      </TableCell>
                      <TableCell>
                        <Button label='View Invoice' variant='tertiary' role='link' href={'/invoices/' + invoice.id} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Card>
        }
      </Cell>
    </StandardGrid>
  );
}