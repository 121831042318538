import { gql, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, ConfirmModal, DatePicker, FormModal, HasProductRole, Icon, Icons, InfoPanel, InventoryRoles, Link, ModalLauncher, NotFound, PageButtons, Products, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, View } from "@barscience/global-components";
import { useNavigate, useParams } from "react-router-dom";
import { getAmountLabel } from "./EditItemCount";
import { useState } from "react";

/* Get Item Count Details Query */
const GET_ITEM_COUNT_DETAILS = gql`
query getItemCountListDetails($id: ID!) {
  itemCountList(id: $id) {
    id
    name
    date
    numCountPages
    isLocked
  }
}
`;

type GetItemCountDetailsResponse = {
  itemCountList: ItemCountList | null;
}

type ItemCountList = {
  id: string;
  name: string;
  date: string;
  numCountPages: number;
  isLocked: boolean;
}

type ItemCount = {
  item: VendorItem | Recipe;
  unitAmount: number;
  value: string;
}

type VendorItem = {
  id: string;
  name: string;
  category: {
    name: string;
  };
  unitsPerCase: number;
  __typename: 'InventoryItem';
}

type Recipe = {
  id: string;
  name: string;
  recipeCategory: {
    id: string;
  };
  itemCategory: {
    name: string;
  };
  unitsPerBatch: number;
  __typename: 'Recipe';
}

/* Get Items Query */
const GET_ITEMS = gql`
query getItemCountListItems($id: ID!, $page: Int!) {
  itemCountList(id: $id) {
    id
    counts(page: $page) {
      item {
        ... on InventoryItem {
          id
          name
          category {
            name
          }
          unitsPerCase
        }
        ... on Recipe {
          id
          name
          recipeCategory {
            id
          }
          itemCategory {
            name
          }
          unitsPerBatch
        }
      }
      unitAmount
      value
    }
  }
}
`;

type GetItemsResponse = {
  itemCountList: {
    id: string;
    counts: ItemCount[];
  };
}

/* Edit Item Count List Mutation */
const EDIT_ITEM_COUNT_LIST = gql`
mutation editItemCountList($id: ID!, $input: EditItemCountListInput!) {
  editItemCountList(id: $id, input: $input) {
    id
    name
    date
  }
}
`;

type EditItemCountListResponse = {
  editItemCountList: {
    id: string;
    name: string;
    date: string;
  }
}

type EditItemCountListInput = {
  name: string;
  date: string;
}

/* Delete Item Count List Mutation */
const DELETE_ITEM_COUNT_LIST = gql`
mutation deleteItemCountList($id: ID!) {
  deleteItemCountList(id: $id) {
    id
  }
}
`;

type DeleteItemCountListResponse = {
  deleteItemCountList: {
    id: string;
  };
}

/* Lock Item Count Mutation */
const LOCK_ITEM_COUNT = gql`
mutation lockItemCountList($id: ID!) {
  lockItemCountList(id: $id) {
    id
    isLocked
  }
}
`;

type LockItemCountResponse = {
  lockItemCountList: {
    id: string;
    isLocked: boolean;
  } | null;
}

/* Unlock Item Count Mutation */
const UNLOCK_ITEM_COUNT = gql`
mutation unlockItemCountList($id: ID!) {
  unlockItemCountList(id: $id) {
    id
    isLocked
  }
}
`;

type UnlockItemCountResponse = {
  unlockItemCountList: {
    id: string;
    isLocked: boolean;
  } | null;
}

export default function ItemCountDetails() {
  const navigate = useNavigate();
  const { itemCountId } = useParams();
  const [page, setPage] = useState<number>(0);
  const { data: itemCountData, loading: itemCountDataIsLoading, error: itemCountError } = useQuery<GetItemCountDetailsResponse>(GET_ITEM_COUNT_DETAILS, {
    variables: {
      id: itemCountId,
    },
  });
  const { data: itemsData, loading: itemsDataIsLoading } = useQuery<GetItemsResponse>(GET_ITEMS, {
    variables: {
      id: itemCountId,
      page: page,
    },
    fetchPolicy: 'network-only',
  });
  const [editItemCountList] = useMutation<EditItemCountListResponse>(EDIT_ITEM_COUNT_LIST);
  const [deleteItemCountList] = useMutation<DeleteItemCountListResponse>(DELETE_ITEM_COUNT_LIST);
  const [lockItemCount] = useMutation<LockItemCountResponse>(LOCK_ITEM_COUNT);
  const [unlockItemCount] = useMutation<UnlockItemCountResponse>(UNLOCK_ITEM_COUNT);

  if (itemCountDataIsLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (!itemCountId || itemCountError?.graphQLErrors[0].extensions.status === 404) {
    return <NotFound />;
  }

  const handleEditItemCountList = async (values: EditItemCountListInput) => {
    await editItemCountList({
      variables: {
        id: itemCountId,
        input: {
          name: values.name,
          date: values.date,
        },
      },
    });
  }

  const handleDeleteItemCountList = async () => {
    await deleteItemCountList({
      variables: {
        id: itemCountId,
      },
    });

    navigate('/item-counts');
  }

  const handleLockItemCountList = async () => {
    await lockItemCount({
      variables: {
        id: itemCountId,
      },
    });
  }

  const handleUnlockItemCountList = async () => {
    await unlockItemCount({
      variables: {
        id: itemCountId,
      },
    });
  }

  const editItemCountModal = (
    <FormModal<EditItemCountListInput> title='Edit Item Count' onSubmit={handleEditItemCountList} initialValues={{ name: itemCountData?.itemCountList?.name || '', date: itemCountData?.itemCountList?.date || '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Name' description='A descriptive name for the item count' name='name' required />
        <DatePicker label='Date' description='The date the item count is performed' name='date' required style={{ width: '100%' }} disabled={itemCountData?.itemCountList?.isLocked} />
      </View>
    </FormModal>
  );

  const deleteItemCountModal = (
    <ConfirmModal title='Delete Item Count?' onConfirm={handleDeleteItemCountList} confirmLabel='Delete' destructive>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>Are you sure you want to delete this item count list?</StyledParagraph>
        <StyledParagraph>The item count data will be <span style={{ fontWeight: 'bold' }}>permanently deleted</span>.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  const lockItemCountModal = (
    <ConfirmModal title='Lock item count?' confirmLabel='Lock' onConfirm={handleLockItemCountList}>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>Locking this item count will prevent further changes from being made to the item counts. This will also improve reporting performance.</StyledParagraph>
        <StyledParagraph>You will need an admin user to unlock the item count.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  const unlockItemCountModal = (
    <ConfirmModal title='Unlock item count?' confirmLabel='Unlock' onConfirm={handleUnlockItemCountList}>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>Unlocking this item count list will allow the item counts to be edited.</StyledParagraph>
        <InfoPanel type='warning'>
          <StyledParagraph>If changes have been made to previous invoices, item prices, or recipe ingredients, unlocking this item count list may cause significant changes to the listed item values.</StyledParagraph>
        </InfoPanel>
      </View>
    </ConfirmModal>
  );

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Item Counts' to='/item-counts' />
          <Breadcrumb label={itemCountData?.itemCountList?.name || ''} />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
            <StyledHeading tag='h3'>{itemCountData?.itemCountList?.name}</StyledHeading>
            {itemCountData?.itemCountList?.isLocked && <Icon icon={Icons.Lock} size='medium' />}
          </View>

          <View style={{ flexDirection: 'row', gap: '24px', '@media (max-width: 767px)': { justifyContent: 'space-between' } }}>
            <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Employee, InventoryRoles.Manager, InventoryRoles.Admin]}>
              <Button label={itemCountData?.itemCountList?.isLocked ? 'View Location Counts' : 'Edit Item Counts'} role='link' variant='tertiary' href={`/item-counts/${itemCountId}/edit`} />
            </HasProductRole>

            <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]}>
              <ModalLauncher modal={editItemCountModal}>
                {({ openModal: openEditModal }) => (
                  <ModalLauncher modal={deleteItemCountModal}>
                    {({ openModal: openDeleteModal }) => (
                      <ModalLauncher modal={lockItemCountModal}>
                        {({ openModal: openLockModal }) => (
                          <ModalLauncher modal={unlockItemCountModal}>
                            {({ openModal: openUnlockModel }) => (
                              <ActionMenu alignment='right'>
                                <ActionItem label='Edit' onClick={openEditModal} />
                                {!itemCountData?.itemCountList?.isLocked && <ActionItem label='Lock' onClick={openLockModal} />}
                                <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Admin]}>
                                  {itemCountData?.itemCountList?.isLocked && <ActionItem label='Unlock' onClick={openUnlockModel} />}
                                  <ActionItem label='Delete' onClick={openDeleteModal} />
                                </HasProductRole>
                              </ActionMenu>
                            )}
                          </ModalLauncher>
                        )}
                      </ModalLauncher>
                    )}
                  </ModalLauncher>
                )}
              </ModalLauncher>
            </HasProductRole>
          </View>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <Card size='medium'>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <StyledParagraph bold>Item Details</StyledParagraph>
            <PageButtons currentPage={page} numPages={itemCountData?.itemCountList?.numCountPages || 0} onPageChange={setPage} />
          </View>
          {itemsDataIsLoading ?
            <AtomSpinner size='medium' />
            :
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Item</TableHeaderCell>
                  <TableHeaderCell>Category</TableHeaderCell>
                  <TableHeaderCell>Amount On Hand</TableHeaderCell>
                  <TableHeaderCell>Value</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {itemsData?.itemCountList.counts.map((count, index) => {
                  if (count.item.__typename === 'InventoryItem') {
                    return (
                      <TableRow key={index}>
                        <TableCell><Link href={'/items/' + count.item.id}>{count.item.name}</Link></TableCell>
                        <TableCell>{count.item.category.name}</TableCell>
                        <TableCell>{getAmountLabel(count.unitAmount, count.item.unitsPerCase)}</TableCell>
                        <TableCell>{count.value}</TableCell>
                      </TableRow>
                    );
                  } else if (count.item.__typename === 'Recipe') {
                    return (
                      <TableRow key={index}>
                        <TableCell><Link href={`/recipes/${count.item.recipeCategory.id}/${count.item.id}`}>{count.item.name}</Link></TableCell>
                        <TableCell>{count.item.itemCategory.name}</TableCell>
                        <TableCell>{getAmountLabel(count.unitAmount, count.item.unitsPerBatch)}</TableCell>
                        <TableCell>{count.value}</TableCell>
                      </TableRow>
                    );
                  } else {
                    return null;
                  }
                })}
              </TableBody>
            </Table>
          }
        </Card>
      </Cell>
    </StandardGrid>
  );
}