import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, CircularSpinner, Colors, DatePicker, FormModal, HasProductRole, Icon, Icons, InventoryRoles, Link, ModalLauncher, NotFound, PageButtons, Products, Row, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, View } from "@barscience/global-components";
import currency from "currency.js";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { formatDecimalAmount } from "../../util/formatters";

const GET_PERIOD = gql`
query getInventoryPeriod($id: ID!) {
  inventoryPeriod(id: $id) {
    id
    name
    startDate
    endDate
    invoiceTotals {
      totalCost
      byCategory {
        category {
          name
        }
        totalCost
      }
      byVendor {
        vendor {
          name
        }
        totalCost
      }
    }
  }
}
`;

type GetPeriodResponse = {
  inventoryPeriod: Period;
}

const EDIT_PERIOD = gql`
mutation editInventoryPeriod($id: ID!, $input: EditInventoryPeriodInput!) {
  editInventoryPeriod(id: $id, input: $input) {
    id
    name
    startDate
    endDate
  }
}
`;

type EditPeriodResponse = {
  editInventoryPeriod: Period;
}

type Period = {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  invoiceTotals: {
    totalCost: string;
    byCategory: CategoryTotal[] | null;
    byVendor: VendorTotal[] | null;
  };
}

type CategoryTotal = {
  category: {
    name: string;
  };
  totalCost: string;
}

type VendorTotal = {
  vendor: {
    name: string;
  };
  totalCost: string;
}

type EditPeriodInput = {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
}

/* Get Orders Query */
const GET_ORDERS_QUERY = gql`
query getOrdersForPeriod($page: Int!, $dateRange: DateRange) {
  inventoryOrders(page: $page, dateRange: $dateRange) {
    orders {
      id
      vendor {
        id
        name
      }
      orderDate
      deliveryDate
      itemCount
      caseCount
      estimatedTotalCost
      invoice {
        id
      }
    }
    pages
  }
}
`;

type GetOrdersQueryResponse = {
  inventoryOrders: {
    orders: InventoryOrder[];
    pages: number;
  };
}

type InventoryOrder = {
  id: string;
  vendor: {
    id: string;
    name: string;
  };
  orderDate: string;
  deliveryDate: string;
  itemCount: number;
  caseCount: number;
  estimatedTotalCost: string;
  invoice: {
    id: string;
  };
}

/* Get Invoices Query */
const GET_INVOICES = gql`
query getInvoicesForPeriod($page: Int!, $dateRange: DateRange) {
  inventoryInvoices(page: $page, dateRange: $dateRange) {
    invoices {
      id
      deliveryDate
      itemCount
      caseCount
      subtotal
      totalDeposits
      paidDate
      vendor {
        id
        name
      }
      statementTotal
    }
    pages
  }
}
`;

type GetInvoicesResponse = {
  inventoryInvoices: {
    invoices: Invoice[];
    pages: number;
  };
}

type Invoice = {
  id: string;
  deliveryDate: string;
  itemCount: number;
  caseCount: number;
  subtotal: string;
  totalDeposits: string;
  paidDate: number;
  vendor: {
    id: string;
    name: string;
  };
  statementTotal: string;
}

export default function PeriodDetail() {
  const { periodId } = useParams();
  const [ordersPage, setOrdersPage] = useState<number>(0);
  const [invoicesPage, setInvoicesPage] = useState<number>(0);
  const { data: periodData, loading, error } = useQuery<GetPeriodResponse>(GET_PERIOD, {
    variables: {
      id: periodId,
    },
  });
  const [getOrders, { data: ordersData, loading: ordersAreLoading }] = useLazyQuery<GetOrdersQueryResponse>(GET_ORDERS_QUERY);
  const [editPeriod] = useMutation<EditPeriodResponse>(EDIT_PERIOD);
  const [getInvoices, { data: invoicesData, loading: invoicesAreLoading }] = useLazyQuery<GetInvoicesResponse>(GET_INVOICES);

  useEffect(() => {
    if (periodData) {
      getOrders({
        variables: {
          page: ordersPage,
          dateRange: {
            startDate: periodData.inventoryPeriod.startDate,
            endDate: periodData.inventoryPeriod.endDate,
          },
        },
      });

      getInvoices({
        variables: {
          page: invoicesPage,
          dateRange: {
            startDate: periodData.inventoryPeriod.startDate,
            endDate: periodData.inventoryPeriod.endDate,
          },
        },
      });
    }
  }, [ordersPage, periodData, getOrders, getInvoices, invoicesPage]);

  const handleEditPeriod = async (values: EditPeriodInput) => {
    await editPeriod({
      variables: {
        id: values.id,
        input: {
          name: values.name,
          startDate: values.startDate,
          endDate: values.endDate,
        },
      },
    });
  }

  if (!periodId || error?.graphQLErrors[0]?.extensions.status === 404) {
    return (
      <StandardGrid>
        <NotFound />
      </StandardGrid>
    );
  }

  if (loading || !periodData) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  const editPeriodModal = (
    <FormModal<EditPeriodInput> title='Edit Period' onSubmit={handleEditPeriod} initialValues={{
      id: periodId,
      name: periodData.inventoryPeriod.name,
      startDate: periodData.inventoryPeriod.startDate,
      endDate: periodData.inventoryPeriod.endDate,
    }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Period Name' name='name' type='text' required />
        <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 1151px)': { flexDirection: 'column' } }}>
          <DatePicker label='Start Date' name='startDate' required />
          <DatePicker label='End Date' name='endDate' required />
        </View>
      </View>
    </FormModal>
  );

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ gap: '16px' }}>
          <BreadcrumbGroup>
            <Breadcrumb label='Periods' to='/periods' />
            <Breadcrumb label={periodData.inventoryPeriod.name} />
          </BreadcrumbGroup>
          <View style={{ flexDirection: 'row', gap: '32px', justifyContent: 'space-between', '@media (max-width: 767px)': { flexDirection: 'column', gap: '16px' } }}>
            <View style={{ gap: '8px' }}>
              <StyledHeading tag='h3'>{periodData.inventoryPeriod.name}</StyledHeading>
              <StyledParagraph style={{ color: Colors.neutral700 }}>{periodData.inventoryPeriod.startDate} - {periodData.inventoryPeriod.endDate}</StyledParagraph>
            </View>
            <HasProductRole product={Products.Inventory} roles={[InventoryRoles.Manager, InventoryRoles.Admin]} >
              <ModalLauncher modal={editPeriodModal}>
                {({ openModal }) => (
                  <Button label='Edit Period' variant='tertiary' role='button' action={openModal} />
                )}
              </ModalLauncher>
            </HasProductRole>
          </View>
        </View>
      </Cell>
      <Row>
        <Cell lg={3} md={4} sm={4}>
          <Card size='medium' style={{ height: 'calc(100% - 34px)' }}>
            <View style={{ gap: '16px', height: '100%', justifyContent: 'space-between' }}>
              <StyledParagraph bold>Invoice Totals</StyledParagraph>
              <StyledHeading tag='h4'>{periodData.inventoryPeriod.invoiceTotals.totalCost}</StyledHeading>
            </View>
          </Card>
        </Cell>
      </Row>
      <Row>
        <Cell lg={6} md={4} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '8px' }}>
              <StyledParagraph bold>Totals By Category</StyledParagraph>
              {periodData.inventoryPeriod.invoiceTotals.byCategory ?
                (
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHeaderCell>Category</TableHeaderCell>
                        <TableHeaderCell>Total Purchases</TableHeaderCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {periodData.inventoryPeriod.invoiceTotals.byCategory.map((total, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{total.category.name}</TableCell>
                            <TableCell>{total.totalCost}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                )
                :
                <StyledParagraph style={{ color: Colors.neutral700 }}>There are no invoices in this period.</StyledParagraph>
              }
            </View>
          </Card>
        </Cell>
        <Cell lg={6} md={4} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '8px' }}>
              <StyledParagraph bold>Totals By Vendor</StyledParagraph>
              {periodData.inventoryPeriod.invoiceTotals.byVendor ?
                (
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHeaderCell>Vendor</TableHeaderCell>
                        <TableHeaderCell>Total Purchases</TableHeaderCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {periodData.inventoryPeriod.invoiceTotals.byVendor.map((total, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{total.vendor.name}</TableCell>
                            <TableCell>{total.totalCost}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                )
                :
                <StyledParagraph style={{ color: Colors.neutral700 }}>There are no invoices in this period.</StyledParagraph>
              }
            </View>
          </Card>
        </Cell>
      </Row>
      <Cell lg={12} md={8} sm={4}>
        <Card size='medium'>
          <View>
            <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
              <StyledHeading tag='h5'>Orders</StyledHeading>
              <PageButtons currentPage={ordersPage} numPages={ordersData?.inventoryOrders.pages || 0} onPageChange={setOrdersPage} />
            </View>
            {ordersAreLoading ?
              <View style={{ alignItems: 'center' }}>
                <CircularSpinner size='large' />
              </View>
              :
              (
                <View style={{ overflowX: 'auto' }}>
                  <Table style={{ maxWidth: '100%' }}>
                    <TableHeader>
                      <TableRow>
                        <TableHeaderCell style={{ minWidth: '140px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Vendor</TableHeaderCell>
                        <TableHeaderCell style={{ minWidth: '40px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Order Date</TableHeaderCell>
                        <TableHeaderCell style={{ minWidth: '40px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Delivery Date</TableHeaderCell>
                        <TableHeaderCell style={{ minWidth: '20px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}># Items</TableHeaderCell>
                        <TableHeaderCell style={{ minWidth: '20px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}># Cases</TableHeaderCell>
                        <TableHeaderCell style={{ minWidth: '40px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Estimated Total</TableHeaderCell>
                        <TableHeaderCell style={{ width: '16px' }}>Invoice</TableHeaderCell>
                        <TableHeaderCell style={{ width: '40px' }}></TableHeaderCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {ordersData?.inventoryOrders.orders.map((o, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell><Link href={'/vendors/' + o.vendor.id}>{o.vendor.name}</Link></TableCell>
                            <TableCell>{o.orderDate}</TableCell>
                            <TableCell>{o.deliveryDate}</TableCell>
                            <TableCell>{o.itemCount}</TableCell>
                            <TableCell>{formatDecimalAmount(o.caseCount)}</TableCell>
                            <TableCell>{o.estimatedTotalCost}</TableCell>
                            <TableCell>
                              {o.invoice !== null ?
                                <View style={{ alignItems: 'center' }}><Icon size='medium' icon={Icons.CircleCheckmark} style={{ color: Colors.primary500 }} /></View>
                                :
                                <View style={{ alignItems: 'center' }}><Icon size='medium' icon={Icons.CircleX} style={{ color: Colors.error500 }} /></View>
                              }
                            </TableCell>
                            <TableCell style={{ width: '40px' }}>
                              <Button label='View' variant='tertiary' role='link' href={'/orders/' + o.id} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </View>
              )
            }
          </View>
        </Card>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <Card size='medium'>
          <View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <StyledHeading tag='h5'>Invoices</StyledHeading>
              <PageButtons numPages={invoicesData?.inventoryInvoices.pages || 0} currentPage={invoicesPage} onPageChange={setInvoicesPage} />
            </View>
            {invoicesAreLoading ?
              <View style={{ alignItems: 'center' }}>
                <CircularSpinner size='large' />
              </View>
              :
              (
                <View style={{ overflowX: 'auto' }}>
                  <Table style={{ maxWidth: '100%' }}>
                    <TableHeader>
                      <TableRow>
                        <TableHeaderCell style={{ maxWidth: '140px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Vendor</TableHeaderCell>
                        <TableHeaderCell>Delivery Date</TableHeaderCell>
                        <TableHeaderCell># Items</TableHeaderCell>
                        <TableHeaderCell># Cases</TableHeaderCell>
                        <TableHeaderCell>Subtotal</TableHeaderCell>
                        <TableHeaderCell>Deposits</TableHeaderCell>
                        <TableHeaderCell>Total Cost</TableHeaderCell>
                        <TableHeaderCell>Date Paid</TableHeaderCell>
                        <TableHeaderCell style={{ textAlign: 'center' }}>Reconciled</TableHeaderCell>
                        <TableCell style={{ width: '40px' }}></TableCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {invoicesData?.inventoryInvoices.invoices.map((invoice, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell><Link href={'/vendors/' + invoice.vendor.id}>{invoice.vendor.name}</Link></TableCell>
                            <TableCell>{invoice.deliveryDate}</TableCell>
                            <TableCell>{invoice.itemCount}</TableCell>
                            <TableCell>{formatDecimalAmount(invoice.caseCount)}</TableCell>
                            <TableCell>{invoice.subtotal}</TableCell>
                            <TableCell>{invoice.totalDeposits}</TableCell>
                            <TableCell>{currency(invoice.subtotal).add(currency(invoice.totalDeposits)).format()}</TableCell>
                            <TableCell>{invoice.paidDate ? invoice.paidDate : '----'}</TableCell>
                            <TableCell>
                              {currency(invoice.subtotal).add(currency(invoice.totalDeposits)).format() === invoice.statementTotal ?
                                <View style={{ alignItems: 'center' }}><Icon size='medium' icon={Icons.CircleCheckmark} style={{ color: Colors.primary500 }} /></View>
                                :
                                <View style={{ alignItems: 'center' }}><Icon size='medium' icon={Icons.CircleX} style={{ color: Colors.error500 }} /></View>
                              }
                            </TableCell>
                            <TableCell style={{ width: '40px' }}>
                              <Button label='View' variant='tertiary' role='link' href={'/invoices/' + invoice.id} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </View>
              )
            }
          </View>
        </Card>
      </Cell>
    </StandardGrid>
  );
}